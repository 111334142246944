import React, { useState, useEffect, useRef, useContext, Component } from "react";

const PageComponent = ({ props }) => {


    // Layout_DT : DESKTOP, TABLET
    // Layout_M : MOBILE
    return (
        <>

                <div className="header0">
                    {/* <a> */}
                        <div className="left0">가북</div>
                    {/* </a> */}
                    <a href="/general-add">
                    <div className="right0">구입신청하기</div>
                    </a>
                </div>

                {
                    true &&
                    <>
                        <div className="maintable_wrapper0 maintable_wrapper1">
                            <div className="maintable_row0 maintable_row1" style={{ height: `180px`, alignItems : 'center', justifyContent: 'center'}}>
                                <div  className="label0" style={{ fontFamily:`NanumBarunGothic`, }}>
                                아름다운 이 세상을 
                                <br/>
                                '내 것'으로
                                <br/>
                                만들어 사시기 바랍니다.
                                 </div>
                            </div>
                            <img src={`/220722_1.jpg`} style={{width: `100%`, height: `auto` }}/>
                            {/* <img src={`/adBottom1.jpg`} style={{width: `40%`, height: `auto`, marginLeft: `auto`, marginRight: `auto`, marginTop:`40px` }}/> */}
                        </div>

                <div className="footer0">
                    {/* <a> */}
                        상호: 비영리법인 가북<br/>
                        사업자등록: 112-82-62377<br/>
                        대표: 김 영 배<br/>
                        전화: 02 567 3850<br/>
                        주소:서울특별시 강남구 선릉로 82길 28  503호<br/>
                        출판사신고번호 : 2018-000109호<br/>
                        통신판매업신고: 2018-서울용산-1493호<br/>
                    {/* </a> */}

                </div>
                    </>
                }

            <style jsx="true" global="true" suppressHydrationWarning>{`

            .fab0 {
                position: fixed;
                bottom: 10px;
                right: 10px;
            }
            
                html,
                body {
                    overflow: scroll;
                    font-size: 12px;
                }

                .location_wrapper .locationitem0,
                .gapja_wrapper .item0
                 {

                    width: 80px;
                    min-height: 24px;
                    display: flex;
                    flex-direction : row;
                    align-items: center;
                    justify-content: center;

                    font-size: 12px;
                }
                .location_wrapper .activeitem,
                .gapja_wrapper .activeitem
                 {
                    font-weight: bold;
                    text-decoration: underline;
                }

                .maintable_wrapper0 {
                    width: calc(100vw - 8px);
                    min-height: 300px;

                    // max-height: calc(100vh - 200px);
                    margin-left: auto;
                    margin-right : auto;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    border-top : 1px solid #000;
                    border-left : 1px solid #000;
                    border-right : 1px solid #000;

                    margin-top: 100px;
                    margin-bottom: 100px;

                    align-items: flex-start;
                    justify-content: flex-start;

                    
                    
                }

                .maintable_row0 {
                    width: 100%;
                    min-height: 100px;
                    border-bottom : 1px solid #000;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    position: relative;
                }

                .maintable_row1 {
                    background-color: #8d8daa;
                    opacity: 0.7;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    text-align: center;
                    line-height: 1.6;
                }

                .maintable_column0  {
                    width: 100%;
                    height: 100%;
                    min-height: 100px;
                    position: relative;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                }
                .label0
                 {
                    font-family: 'NanumBarunGothic' !important;
                 }
                html, body , * {
                    font-family: 'NanumBarunGothic';
                }
                .label0 {
                    font-family: 'NanumBarunGothic' !important;
                    font-size: 30px !important;

                    width: 100%;
                    margin-top: 10px;
                }
                .header0 {
                    width: 100vw;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #000000;
                    position: fixed;
                    top: 0px;
                    background-color : #fff;
                    z-index: 99;
                }
                .footer0 {
                    width: calc(100vw - 40px);
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #000000;
                    background-color : #fff;
                    text-align: left;
                    padding-top: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 10px;
                    margin-bottom: 10px;

                }
                
                .header0 .left0 {
                    margin-left : 20px;
                    margin-right: auto;
                }
                .header0 .right0 {
                    margin-left : auto;
                    margin-right: 20px;
                }
                a {
                    text-decoration: none;
                    color : #000;
                }
            `}</style>
        </>
    );
};

export default PageComponent;
