import firebase from "firebase";
import "firebase/database";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyC-BsuXCeKvHA9AaBbbn7APrgBY0o8qsEU",
  authDomain: "hyosa1-902e0.firebaseapp.com",
  projectId: "hyosa1-902e0",
  storageBucket: "hyosa1-902e0.appspot.com",
  messagingSenderId: "730063861125",
  appId: "1:730063861125:web:191c3f28d47041376b80ec",
  databaseURL:
    "https://hyosa1-902e0-default-rtdb.asia-southeast1.firebasedatabase.app",
  measurementId: "G-V5EFGGV01D",
};

const fireDb = firebase.initializeApp(firebaseConfig);

// 개발환경에서 에뮬레이터 사용 설정
if (process.env.NODE_ENV === "development") {
  // Realtime Database 에뮬레이터 설정 (호스트와 포트는 Firebase Emulator UI에서 확인)
  firebase.database().useEmulator("127.0.0.1", 9000);
  // Authentication 에뮬레이터 설정
  firebase.auth().useEmulator("http://127.0.0.1:9099");
  // Firestore 등 다른 서비스도 필요시 비슷한 방식으로 설정 가능
  // firebase.firestore().useEmulator("127.0.0.1", 8088);
}

export default fireDb;
