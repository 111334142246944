const loadGookData = async (gapjaIndex, gookNumber, optionNumbner) => {
  try {
    // gapjaIndex: 1-60 (갑자의 순서)
    // gookNumber: 1-12 (국의 순서)
    const data = await import(`./${gapjaIndex}/${gookNumber}_${optionNumbner}`);
    console.warn(
      "gapjaIndex >> ",
      gapjaIndex,
      "gookNumber >> ",
      gookNumber,
      "optionNumbner >> ",
      optionNumbner
    );
    return data.default;
  } catch (error) {
    console.error(
      `Failed to load data for gapja ${gapjaIndex}, gook ${gookNumber}, option ${optionNumbner}`
    );
    return null;
  }
};

export { loadGookData };

// 사용 예시
// 갑자일의 1국 데이터 로드
// const firstGapJaFirstGookData = await loadGookData(1, 1);
