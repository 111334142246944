import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import AddEdit from "./pages/AddEdit";
import GeneralAdd from "./pages/GeneralAdd";
import Shop from "./pages/Shop";
import Home from "./pages/Home";
import Home1 from "./pages/Home1";
import Hyosa from "./pages/Hyosa";
import Hyosa1 from "./pages/Hyosa1";
import View from "./pages/View";
import Church from "./pages/Church";
import ChurchOrigin from "./pages/ChurchOrigin";
import Ios from "./pages/Ios";
import Tablet1 from "./pages/Tablet1";
import AppleOtpTest from "./pages/AppleOtpTest";
import TableOtpTest from "./pages/TableOtpTest";
//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Search from "./pages/Search";
import VideoAdmin from "./pages/VideoAdmin";
import initDevAuth from "./firebase-auto-auth";
function App() {
  const whiteList = [
    "/admin",
    "/admin/add",
    "/admin/update/:id",
    "/admin/view/:id",
    "/admin/about",
    "/search",
  ];

  // 개발 환경에서만 실행하도록 추가
  if (process.env.NODE_ENV === "development") {
    initDevAuth(); // 앱 시작 시 개발 모드이면 자동 로그인 시도
    console.log = function () {};
  }

  return (
    <BrowserRouter>
      <div className="App">
        {whiteList.indexOf(window.location.pathname) > -1 && <Header />}
        <ToastContainer position="top-center" />
        <Switch>
          <Route exact path="/" component={Shop} />
          <Route exact path="/admin" component={Home} />
          {/* <Route exact path="/admin/temp" component={Home1} /> */}
          <Route exact path="/video-admin" component={VideoAdmin} />
          <Route path="/hyosa" component={Hyosa} />
          <Route path="/hyosa-check" component={Hyosa1} />
          <Route path="/church" component={Church} />
          <Route path="/church1" component={ChurchOrigin} />
          <Route path="/ios" component={Ios} />
          <Route path="/web" component={Ios} />
          <Route path="/otp" component={AppleOtpTest} />
          <Route path="/tablet" component={Tablet1} />
          <Route path="/tablet-otp" component={TableOtpTest} />
          {/*  */}
          <Route path="/general-add" component={GeneralAdd} />
          <Route path="/admin/add" component={AddEdit} />
          <Route path="/admin/update/:id" component={AddEdit} />
          <Route path="/admin/view/:id" component={View} />
          <Route path="/admin/about" component={About} />
          <Route path="/admin/search" component={Search} />
        </Switch>
      </div>
      {/* <style>{`
      #root {
        z-index: 2147483649;
      }
      `}</style> */}
    </BrowserRouter>
  );
}

export default App;
