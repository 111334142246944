import parse from "html-react-parser";
import React from "react";

const CurrentSituation = ({
    setBoolMagnifyPopup,
    yookimGuide,
    setObjectMagnify,
    selectedNaejung,
    sootJaIndex,
    selectedGapjaIndex,
    selectedSiIndex,
    selectedGook, // 일자 국 인덱스
    currentSituationData,
}) => {
    return (
        <div
            className="maintable_column0 maintable_column3"
            onClick={() => {
                setBoolMagnifyPopup(true);

                if (yookimGuide === "100") {
                setObjectMagnify({
                    title: "현재 실정",
                    desc:
                    selectedNaejung &&
                    selectedNaejung.default &&
                    selectedNaejung.default.columnAU[
                        5 * (sootJaIndex - 1) + 3
                    ][0],
                });
                }

                if (yookimGuide.indexOf("101") > -1) {
                setObjectMagnify({
                    title: "현재 실정",
                    desc: `

                                        ${
                                            selectedGapjaIndex > -1 &&
                                            selectedSiIndex > -1 &&
                                            currentSituationData &&
                                            currentSituationData[
                                            12 *
                                                (selectedGapjaIndex - 1) +
                                                selectedGook -
                                                1
                                            ] &&
                                            currentSituationData[
                                            12 *
                                                (selectedGapjaIndex - 1) +
                                                selectedGook -
                                                1
                                            ][0] &&
                                            currentSituationData[
                                            12 *
                                                (selectedGapjaIndex - 1) +
                                                selectedGook -
                                                1
                                            ][0]
                                            .replaceAll("과", "")
                                            .replaceAll("課", "")
                                            .replace(
                                                "원수",
                                                "강건정당속결"
                                            )
                                            .replace(
                                                "중심",
                                                "순응유화지연"
                                            )
                                            .replace(
                                                "섭해",
                                                "간난험악침체"
                                            )
                                            .replace(
                                                "지일",
                                                "선택기로모함(+동료,-여인)"
                                            )
                                            .replace(
                                                "요극",
                                                "호가호위무산"
                                            )
                                            .replace(
                                                "복음",
                                                "강폭자만(+)부동신음(-)"
                                            )
                                            .replace(
                                                "반음",
                                                "동요불안배신"
                                            )
                                            .replace(
                                                "별책",
                                                "연루유연색정(+부부동시색정, -삼각관계)"
                                            )
                                            .replace(
                                                "팔전",
                                                "이인동심무효(근친상간 적시에 주목)"
                                            )
                                            .replace(
                                                "묘성",
                                                "은닉풍상암목(-)출행불귀(+)"
                                            )
                                        }

                                        
                                            <br/>
                                            <br/>
                                            ${
                                                selectedGapjaIndex > -1 &&
                                                selectedSiIndex > -1 &&
                                                currentSituationData &&
                                                currentSituationData[
                                                12 *
                                                    (selectedGapjaIndex -
                                                    1) +
                                                    selectedGook -
                                                    1
                                                ][0]
                                            }
                                            <br/>
                                            ${
                                                selectedGapjaIndex > -1 &&
                                                selectedSiIndex > -1 &&
                                                currentSituationData &&
                                                currentSituationData[
                                                12 *
                                                    (selectedGapjaIndex -
                                                    1) +
                                                    selectedGook -
                                                    1
                                                ][1]
                                            }
                                        `,
                });
                }
            }}
            >
            <div className="label0">현재 실정</div>
            <div
                className="value0"
                style={{
                marginTop: `30px`,
                marginLeft: `8px`,
                minHeight:
                    yookimGuide.indexOf("101") > -1 ? `334px` : `184px`,
                height: `auto`,
                textAlign: "left",
                }}
            >
                {yookimGuide === "100" && (
                <>
                    <span style={{ fontWeight: 700 }}>
                    {parse(
                        `${
                        selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                        ] &&
                        selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                        ][0]
                        }
                                    `.split("**")[0]
                    )}
                    </span>
                    <br />
                    **
                    {parse(
                    `${
                        selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.columnAU[
                        5 * (sootJaIndex - 1) + 3
                        ] &&
                        selectedNaejung.default.columnAU[
                        5 * (sootJaIndex - 1) + 3
                        ][0]
                    }
                                `.split("**")[1]
                    )}
                </>
                )}
                <br />
                {yookimGuide.indexOf("101") > -1 && (
                <>
                    <span style={{ fontWeight: 700 }}>
                    {/* {
                                    selectedGapjaIndex > -1 && 
                                    selectedSiIndex > -1 
                                    && objectGui && objectGui.default && currentSituationData 
                                    && ((12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ) > -1 
                                    && currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1]
                                    && currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0] &&
                                    currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("원수") > -1 ? "강건정당속결" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("중심") > -1 ? "순응유화지연" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("섭해") > -1 ? "간난험악침체" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("지일") > -1 ? "선택기로모함(+동료,-여인)" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("요극") > -1 ? "호가호위무산" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("복음") > -1  ? "강폭자만(+)부동신음(-)" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("반음") > -1  ? "동요불안배신" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("별책") > -1  ? "연루유연색정(+부부동시색정, -삼각관계)" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("팔전") > -1  ? "이인동심무효(근친상간 적시에 주목)" 
                                    : currentSituationData[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0].indexOf("묘성") > -1  ? "은닉풍상암목(-)출행불귀(+)" 
                                    : ""
                                    } */}

                    {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        currentSituationData &&
                        currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                        ] &&
                        currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                        ][0] &&
                        currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                        ][0]
                        .replaceAll("과", "")
                        .replaceAll("課", "")
                        .replace("원수", "강건정당속결")
                        .replace("중심", "순응유화지연")
                        .replace("섭해", "간난험악침체")
                        .replace("지일", "선택기로모함(+동료,-여인)")
                        .replace("요극", "호가호위무산")
                        .replace("복음", "강폭자만(+)부동신음(-)")
                        .replace("반음", "동요불안배신")
                        .replace(
                            "별책",
                            "연루유연색정(+부부동시색정, -삼각관계)"
                        )
                        .replace(
                            "팔전",
                            "이인동심무효(근친상간 적시에 주목)"
                        )
                        .replace("묘성", "은닉풍상암목(-)출행불귀(+)")}
                    </span>
                    <br />
                    <br />
                    {selectedGapjaIndex > -1 &&
                    selectedSiIndex > -1 &&
                    currentSituationData &&
                    currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                    ] &&
                    currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                    ][0]}
                    <br />
                    {selectedGapjaIndex > -1 &&
                    selectedSiIndex > -1 &&
                    currentSituationData &&
                    currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                    ] &&
                    currentSituationData[
                        12 * (selectedGapjaIndex - 1) + selectedGook - 1
                    ][1]}
                </>
                )}
            </div>
        </div>
    );

};

export default CurrentSituation;