const jiduTableHeader1 = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
const jiduTable1 = [
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
];
const world1 = [
  {
    index: 0,
    start: `00:01`,
    end: `01:00`,
    day: 0,
    timeIndex: 0,
  },

  {
    index: 1,
    start: `01:01`,
    end: `02:00`,
    day: 0,
    timeIndex: 1,
  },

  {
    index: 2,
    start: `02:01`,
    end: `03:00`,
    day: 0,
    timeIndex: 2,
  },
  {
    index: 3,
    start: `03:01`,
    end: `04:00`,
    day: 0,
    timeIndex: 3,
  },
  {
    index: 4,
    start: `04:01`,
    end: `05:00`,
    day: 0,
    timeIndex: 4,
  },
  {
    index: 5,
    start: `05:01`,
    end: `06:00`,
    day: 0,
    timeIndex: 5,
  },
  {
    index: 6,
    start: `06:01`,
    end: `07:00`,
    day: 0,
    timeIndex: 6,
  },
  {
    index: 7,
    start: `07:01`,
    end: `08:00`,
    day: 0,
    timeIndex: 7,
  },
  {
    index: 8,
    start: `08:01`,
    end: `09:00`,
    day: 0,
    timeIndex: 8,
  },
  {
    index: 9,
    start: `09:01`,
    end: `10:00`,
    day: 0,
    timeIndex: 9,
  },
  {
    index: 10,
    start: `10:01`,
    end: `11:00`,
    day: 0,
    timeIndex: 10,
  },
  {
    index: 11,
    start: `11:01`,
    end: `12:00`,
    day: 0,
    timeIndex: 11,
  },
  {
    index: 12,
    start: `12:01`,
    end: `13:00`,
    day: 0,
    timeIndex: 12,
  },
  {
    index: 13,
    start: `13:01`,
    end: `14:00`,
    day: 0,
    timeIndex: 13,
  },
  {
    index: 14,
    start: `14:01`,
    end: `15:00`,
    day: 0,
    timeIndex: 14,
  },
  {
    index: 15,
    start: `15:01`,
    end: `16:00`,
    day: 0,
    timeIndex: 15,
  },
  {
    index: 16,
    start: `16:01`,
    end: `17:00`,
    day: 0,
    timeIndex: 16,
  },
  {
    index: 17,
    start: `17:01`,
    end: `18:00`,
    day: 0,
    timeIndex: 17,
  },
  {
    index: 18,
    start: `18:01`,
    end: `19:00`,
    day: 0,
    timeIndex: 18,
  },
  {
    index: 19,
    start: `19:01`,
    end: `20:00`,
    day: 0,
    timeIndex: 19,
  },
  {
    index: 20,
    start: `20:01`,
    end: `21:00`,
    day: 0,
    timeIndex: 20,
  },
  {
    index: 21,
    start: `21:01`,
    end: `22:00`,
    day: 0,
    timeIndex: 21,
  },
  {
    index: 22,
    start: `22:01`,
    end: `23:00`,
    day: 0,
    timeIndex: 22,
  },
  {
    index: 23,
    start: `23:01`,
    end: `24:00`,
    day: 0,
    timeIndex: 23,
  },
];

const soYook_StandardArr = ["대안", "유연", "속희", "적구", "소길", "공망"];
const nextPersonOriginArray = [4, 9, 6, 11, 8, 1, 10, 3, 12, 5, 2, 7];
const years1 = [
  "1930",
  "1931",
  "1932",
  "1933",
  "1934",
  "1935",
  "1936",
  "1937",
  "1938",
  "1939",
  "1940",
  "1941",
  "1942",
  "1943",
  "1944",
  "1945",
  "1946",
  "1947",
  "1948",
  "1949",
  "1950",
  "1951",
  "1952",
  "1953",
  "1954",
  "1955",
  "1956",
  "1957",
  "1958",
  "1959",
  "1960",
  "1961",
  "1962",
  "1963",
  "1964",
  "1965",
  "1966",
  "1967",
  "1968",
  "1969",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1977",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
  "2041",
  "2042",
  "2043",
  "2044",
  "2045",
  "2046",
  "2047",
  "2048",
  "2049",
  "2050",
  "2051",
  "2052",
  "2053",
  "2054",
  "2055",
  "2056",
  "2057",
  "2058",
  "2059",
  "2060",
  "2061",
  "2062",
  "2063",
  "2064",
  "2065",
  "2066",
  "2067",
  "2068",
  "2069",
  "2070",
  "2071",
  "2072",
  "2073",
  "2074",
  "2075",
  "2076",
  "2077",
  "2078",
  "2079",
  "2080",
  "2081",
  "2082",
  "2083",
  "2084",
  "2085",
  "2086",
  "2087",
  "2088",
  "2089",
  "2090",
  "2091",
  "2092",
  "2093",
  "2094",
  "2095",
  "2096",
  "2097",
  "2098",
  "2099",
  "2100",
];
const month1 = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const days1 = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const hours1 = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const minutes1 = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];
const seconds1 = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

const yinyang1 = ["양력", "음력"];

const jeolgi1 = [
  "입춘",
  "우수",
  "경칩",
  "춘분",
  "청명",
  "곡우",
  "입하",
  "소만",
  "망종",
  "하지",
  "소서",
  "대서",
  "입추",
  "처서",
  "백로",
  "추분",
  "한로",
  "상강",
  "입동",
  "소설",
  "대설",
  "동지",
  "소한",
  "대한",
  "입춘",
];

const jeolgi0 = [
  "우수 (13) - 춘분 (14)",
  "춘분 (14) - 곡우 (15)",
  "곡우 (15) - 소만 (16)",
  "소만 (16) - 하지 (17)",
  "하지 (17) - 대서 (18)",
  "대서 (18) - 처서 (19)",
  "처서 (19) - 추분 (20)",
  "추분 (20) - 상강 (21)",
  "상강 (21) - 소설 (22)",
  "소설 (22) - 동지 (23)",
  "동지 (23) - 대한 다음해의(12)",
  "대한 다음해의(12) - 우수 다음해의(13)",
];
// ORIGIN
const time1 = {
  seoul1: {
    ja: ["23:32", "01:31", "0"],
    chuk: ["01:32", "03:31", "0"],
    in: ["03:32", "05:31", "0"],
    myo: ["05:32", "07:31", "0"],
    jin: ["07:32", "05:31", "0"],
    sa: ["09:32", "09:31", "0"],
    oo: ["11:32", "13:31", "0"],
    mi: ["13:32", "15:31", "0"],
    sin: ["15:32", "17:31", "0"],
    yu: ["17:32", "19:31", "0"],
    sool: ["19:32", "21:31", "0"],
    hae: ["21:32", "23:31", "0"],
    flag: 0,
  },
  incheon: {
    ja: ["23:34", "01:33", "0"],
    chuk: ["01:34", "03:33", "0"],
    in: ["03:34", "05:33", "0"],
    myo: ["05:34", "07:33", "0"],
    jin: ["07:34", "05:33", "0"],
    sa: ["09:34", "09:33", "0"],
    oo: ["11:34", "13:33", "0"],
    mi: ["13:34", "15:33", "0"],
    sin: ["15:34", "17:33", "0"],
    yu: ["17:34", "19:33", "0"],
    sool: ["19:34", "21:33", "0"],
    hae: ["21:34", "23:33", "0"],
    flag: 0,
  },
  gapyeong: {
    ja: ["23:30", "01:29", "0"],
    chuk: ["01:30", "03:29", "0"],
    in: ["03:30", "05:29", "0"],
    myo: ["05:30", "07:29", "0"],
    jin: ["07:30", "05:29", "0"],
    sa: ["09:30", "09:29", "0"],
    oo: ["11:30", "13:29", "0"],
    mi: ["13:30", "15:29", "0"],
    sin: ["15:30", "17:29", "0"],
    yu: ["17:30", "19:29", "0"],
    sool: ["19:30", "21:29", "0"],
    hae: ["21:30", "23:29", "0"],
    flag: 0,
  },
  chooncheon: {
    ja: ["23:28", "01:27", "0"],
    chuk: ["01:28", "03:27", "0"],
    in: ["03:28", "05:27", "0"],
    myo: ["05:28", "07:27", "0"],
    jin: ["07:28", "05:27", "0"],
    sa: ["09:28", "09:27", "0"],
    oo: ["11:28", "13:27", "0"],
    mi: ["13:28", "15:27", "0"],
    sin: ["15:28", "17:27", "0"],
    yu: ["17:28", "19:27", "0"],
    sool: ["19:28", "21:27", "0"],
    hae: ["21:28", "23:27", "0"],
    flag: 0,
  },
  sokcho: {
    ja: ["23:26", "01:25", "0"],
    chuk: ["01:26", "03:25", "0"],
    in: ["03:26", "05:25", "0"],
    myo: ["05:26", "07:25", "0"],
    jin: ["07:26", "05:25", "0"],
    sa: ["09:26", "09:25", "0"],
    oo: ["11:26", "13:25", "0"],
    mi: ["13:26", "15:25", "0"],
    sin: ["15:26", "17:25", "0"],
    yu: ["17:26", "19:25", "0"],
    sool: ["19:26", "21:25", "0"],
    hae: ["21:26", "23:25", "0"],
    flag: 0,
  },
  gangreung: {
    ja: ["23:24", "01:23", "0"],
    chuk: ["01:24", "03:23", "0"],
    in: ["03:24", "05:23", "0"],
    myo: ["05:24", "07:23", "0"],
    jin: ["07:24", "05:23", "0"],
    sa: ["09:24", "09:23", "0"],
    oo: ["11:24", "13:23", "0"],
    mi: ["13:24", "15:23", "0"],
    sin: ["15:24", "17:23", "0"],
    yu: ["17:24", "19:23", "0"],
    sool: ["19:24", "21:23", "0"],
    hae: ["21:24", "23:23", "0"],
    flag: 0,
  },
  uljin: {
    ja: ["23:22", "01:21", "0"],
    chuk: ["01:22", "03:21", "0"],
    in: ["03:22", "05:21", "0"],
    myo: ["05:22", "07:21", "0"],
    jin: ["07:22", "05:21", "0"],
    sa: ["09:22", "09:21", "0"],
    oo: ["11:22", "13:21", "0"],
    mi: ["13:22", "15:21", "0"],
    sin: ["15:22", "17:21", "0"],
    yu: ["17:22", "19:21", "0"],
    sool: ["19:22", "21:21", "0"],
    hae: ["21:22", "23:21", "0"],
    flag: 0,
  },
  tokyo1: {
    ja: ["23:01", "01:00", "0"],
    chuk: ["01:01", "03:00", "0"],
    in: ["03:01", "05:00", "0"],
    myo: ["05:01", "07:00", "0"],
    jin: ["07:01", "09:00", "0"],
    sa: ["09:01", "11:00", "0"],
    oo: ["11:01", "13:00", "0"],
    mi: ["13:01", "15:00", "0"],
    sin: ["15:01", "17:00", "0"],
    yu: ["17:01", "19:00", "0"],
    sool: ["19:01", "21:00", "0"],
    hae: ["21:01", "23:00", "0"],
    flag: 1,
    foreign: "2400",
  },
  beijing1: {
    ja: ["00:01", "02:00", "0"],
    chuk: ["02:01", "04:00", "0"],
    in: ["04:01", "06:00", "0"],
    myo: ["06:01", "08:00", "0"],
    jin: ["08:01", "10:00", "0"],
    sa: ["10:01", "12:00", "0"],
    oo: ["12:01", "14:00", "0"],
    mi: ["14:01", "16:00", "0"],
    sin: ["16:01", "18:00", "0"],
    yu: ["18:01", "20:00", "0"],
    sool: ["20:01", "22:00", "0"],
    hae: ["22:01", "24:00", "0"],
    flag: 1,
    foreign: "2400",
  },
  bangkok1: {
    ja: ["21:30", "23:29", "-1"],
    chuk: ["23:30", "01:29", "0"],
    in: ["01:30", "03:29", "0"],
    myo: ["03:30", "05:29", "0"],
    jin: ["05:30", "07:29", "0"],
    sa: ["07:30", "09:29", "0"],
    oo: ["09:30", "11:29", "0"],
    mi: ["11:30", "13:29", "0"],
    sin: ["13:30", "15:29", "0"],
    yu: ["15:30", "17:29", "0"],
    sool: ["17:30", "19:29", "0"],
    hae: ["19:30", "21:29", "0"],
    flag: 1,
    foreign: "2130",
  },
  london1: {
    ja: ["15:01", "17:00", "-1"],
    chuk: ["17:01", "19:00", "-1"],
    in: ["19:01", "21:00", "-1"],
    myo: ["21:01", "23:00", "-1"],
    jin: ["23:01", "01:00", "-1"],
    sa: ["01:01", "03:00", "0"],
    oo: ["03:01", "05:00", "0"],
    mi: ["05:01", "07:00", "0"],
    sin: ["07:01", "09:00", "0"],
    yu: ["09:01", "11:00", "0"],
    sool: ["11:01", "13:00", "0"],
    hae: ["13:01", "15:00", "1"],
    flag: 1,
    foreign: "1300",
  },
  bagdard1: {
    ja: ["17:32", "19:31", "-1"],
    chuk: ["19:32", "21:31", "-1"],
    in: ["21:32", "23:31", "-1"],
    myo: ["23:32", "01:31", "-1"],
    jin: ["01:32", "03:31", "0"],
    sa: ["03:32", "05:31", "0"],
    oo: ["05:32", "07:31", "0"],
    mi: ["07:32", "09:31", "0"],
    sin: ["09:32", "11:31", "0"],
    yu: ["11:32", "13:31", "0"],
    sool: ["13:32", "15:31", "1"],
    hae: ["15:32", "17:31", "1"],
    flag: 1,
    foreign: "1332",
  },
  stockholm1: {
    ja: ["15:32", "17:31", "-1"],
    chuk: ["17:32", "19:31", "-1"],
    in: ["19:32", "21:31", "-1"],
    myo: ["21:32", "23:31", "-1"],
    jin: ["23:32", "01:31", "0"],
    sa: ["01:32", "03:31", "0"],
    oo: ["03:32", "05:31", "0"],
    mi: ["05:32", "07:31", "0"],
    sin: ["07:32", "09:31", "0"],
    yu: ["09:32", "11:31", "0"],
    sool: ["11:32", "13:31", "1"],
    hae: ["13:32", "15:31", "1"],
    flag: 1,
  },
  atene1: {
    ja: ["17:01", "19:00", "-1"],
    chuk: ["19:01", "21:00", "-1"],
    in: ["21:01", "23:00", "-1"],
    myo: ["23:01", "01:00", "-1"],
    jin: ["01:01", "03:00", "0"],
    sa: ["03:01", "05:00", "0"],
    oo: ["05:01", "07:00", "0"],
    mi: ["07:01", "09:00", "0"],
    sin: ["09:01", "11:00", "0"],
    yu: ["11:01", "13:00", "0"],
    sool: ["13:01", "15:00", "0"],
    hae: ["15:01", "17:00", "1"],
    flag: 1,
  },

  bombei1: {
    ja: ["19:32", "21:31", "-1"],
    chuk: ["21:32", "23:31", "-1"],
    in: ["23:32", "01:31", "-1"],
    myo: ["01:32", "03:31", "0"],
    jin: ["03:32", "05:31", "0"],
    sa: ["05:32", "07:31", "0"],
    oo: ["07:32", "09:31", "0"],
    mi: ["09:32", "11:31", "0"],
    sin: ["11:32", "13:31", "0"],
    yu: ["13:32", "15:31", "1"],
    sool: ["15:32", "17:31", "1"],
    hae: ["17:32", "19:31", "1"],
    flag: 1,
  },
  sydney1: {
    ja: ["01:01", "03:00", "0"],
    chuk: ["03:01", "05:00", "0"],
    in: ["05:01", "07:00", "0"],
    myo: ["07:01", "09:00", "0"],
    jin: ["09:01", "11:00", "0"],
    sa: ["11:01", "13:00", "0"],
    oo: ["13:01", "15:00", "0"],
    mi: ["15:01", "17:00", "0"],
    sin: ["17:01", "19:00", "0"],
    yu: ["19:01", "21:00", "0"],
    sool: ["21:01", "23:00", "0"],
    hae: ["23:01", "01:00", "0"],
    flag: 1,
  },
  honolulu1: {
    ja: ["05:01", "07:00", "-1"],
    chuk: ["07:01", "09:00", "-1"],
    in: ["09:01", "11:00", "-1"],
    myo: ["11:01", "13:00", "-1"],
    jin: ["13:01", "15:00", "-1"],
    sa: ["15:01", "17:00", "-1"],
    oo: ["17:01", "19:00", "-1"],
    mi: ["19:01", "21:00", "-1"],
    sin: ["21:01", "23:00", "0"],
    yu: ["23:01", "01:00", "0"],
    sool: ["01:01", "03:00", "0"],
    hae: ["03:01", "05:00", "0"],
    flag: 1,
  },

  boenos1: {
    ja: ["11:32", "13:31", "-1"],
    chuk: ["13:32", "15:31", "-1"],
    in: ["15:32", "17:31", "-1"],
    myo: ["17:32", "19:31", "-1"],
    jin: ["19:32", "21:31", "-1"],
    sa: ["21:32", "23:31", "-1"],
    oo: ["23:32", "01:31", "0"],
    mi: ["01:32", "03:31", "0"],
    sin: ["03:32", "05:31", "0"],
    yu: ["05:32", "07:31", "0"],
    sool: ["07:32", "09:31", "0"],
    hae: ["09:32", "11:31", "0"],
    flag: 1,
  },
  vancouber1: {
    ja: ["07:01", "09:00", "-1"],
    chuk: ["09:01", "11:00", "-1"],
    in: ["11:01", "13:00", "-1"],
    myo: ["13:01", "15:00", "-1"],
    jin: ["15:01", "17:00", "-1"],
    sa: ["17:01", "19:00", "-1"],
    oo: ["19:01", "21:00", "-1"],
    mi: ["21:01", "23:00", "-1"],
    sin: ["23:01", "01:00", "-1"],
    yu: ["01:01", "03:00", "0"],
    sool: ["03:01", "05:00", "0"],
    hae: ["05:01", "07:00", "0"],
    flag: 1,
  },
  dallas1: {
    ja: ["09:01", "11:00", "-1"],
    chuk: ["11:01", "13:00", "-1"],
    in: ["13:01", "15:00", "-1"],
    myo: ["15:01", "17:00", "-1"],
    jin: ["17:01", "19:00", "-1"],
    sa: ["19:01", "21:00", "-1"],
    oo: ["21:01", "23:00", "-1"],
    mi: ["23:01", "01:00", "0"],
    sin: ["01:01", "03:00", "0"],
    yu: ["03:01", "05:00", "0"],
    sool: ["05:01", "07:00", "0"],
    hae: ["07:01", "09:00", "0"],
    flag: 1,
  },
  newyork1: {
    ja: ["09:32", "11:31", "-1"],
    chuk: ["11:32", "13:31", "-1"],
    in: ["13:32", "15:31", "-1"],
    myo: ["15:32", "17:31", "-1"],
    jin: ["17:32", "19:31", "-1"],
    sa: ["19:32", "21:31", "-1"],
    oo: ["21:32", "23:31", "-1"],
    mi: ["23:32", "01:31", "0"],
    sin: ["01:32", "03:31", "0"],
    yu: ["03:32", "05:31", "0"],
    sool: ["05:32", "07:31", "0"],
    hae: ["07:32", "09:31", "0"],
    flag: 1,
  },
};
const locationA = [
  { label: "서울", timeFlag: "KR", time: time1.seoul1 },
  { label: "과천", timeFlag: "KR", time: time1.seoul1 },
  { label: "안양", timeFlag: "KR", time: time1.seoul1 },
  { label: "수원", timeFlag: "KR", time: time1.seoul1 },
  { label: "송탄", timeFlag: "KR", time: time1.seoul1 },
  { label: "평택", timeFlag: "KR", time: time1.seoul1 },
  { label: "천안", timeFlag: "KR", time: time1.seoul1 },
  { label: "온양", timeFlag: "KR", time: time1.seoul1 },
  { label: "공주", timeFlag: "KR", time: time1.seoul1 },
  { label: "강경", timeFlag: "KR", time: time1.seoul1 },
  { label: "전주", timeFlag: "KR", time: time1.seoul1 },
  { label: "이리", timeFlag: "KR", time: time1.seoul1 },
  { label: "광주", timeFlag: "KR", time: time1.seoul1 },
  { label: "화순", timeFlag: "KR", time: time1.seoul1 },

  { label: "인천", timeFlag: "KR", time: time1.incheon },
  { label: "김포", timeFlag: "KR", time: time1.incheon },
  { label: "당진", timeFlag: "KR", time: time1.incheon },
  { label: "서산", timeFlag: "KR", time: time1.incheon },
  { label: "대천", timeFlag: "KR", time: time1.incheon },
  { label: "서천", timeFlag: "KR", time: time1.incheon },
  { label: "군산", timeFlag: "KR", time: time1.incheon },
  { label: "영광", timeFlag: "KR", time: time1.incheon },
  { label: "목포", timeFlag: "KR", time: time1.incheon },
  { label: "제주", timeFlag: "KR", time: time1.incheon },
  { label: "서귀포", timeFlag: "KR", time: time1.incheon },

  { label: "가평", timeFlag: "KR", time: time1.gapyeong },
  { label: "양평", timeFlag: "KR", time: time1.gapyeong },
  { label: "증평", timeFlag: "KR", time: time1.gapyeong },
  { label: "여주", timeFlag: "KR", time: time1.gapyeong },
  { label: "이천", timeFlag: "KR", time: time1.gapyeong },
  { label: "청주", timeFlag: "KR", time: time1.gapyeong },
  { label: "대전", timeFlag: "KR", time: time1.gapyeong },
  { label: "진안", timeFlag: "KR", time: time1.gapyeong },
  { label: "장수", timeFlag: "KR", time: time1.gapyeong },
  { label: "남원", timeFlag: "KR", time: time1.gapyeong },
  { label: "구례", timeFlag: "KR", time: time1.gapyeong },
  { label: "순천", timeFlag: "KR", time: time1.gapyeong },

  { label: "춘천", timeFlag: "KR", time: time1.chooncheon },
  { label: "홍천", timeFlag: "KR", time: time1.chooncheon },
  { label: "횡성", timeFlag: "KR", time: time1.chooncheon },
  { label: "원주", timeFlag: "KR", time: time1.chooncheon },
  { label: "충주", timeFlag: "KR", time: time1.chooncheon },
  { label: "점촌", timeFlag: "KR", time: time1.chooncheon },
  { label: "상주", timeFlag: "KR", time: time1.chooncheon },
  { label: "김천", timeFlag: "KR", time: time1.chooncheon },
  { label: "진주", timeFlag: "KR", time: time1.chooncheon },
  { label: "삼천포", timeFlag: "KR", time: time1.chooncheon },

  { label: "속초", timeFlag: "KR", time: time1.sokcho },
  { label: "양양", timeFlag: "KR", time: time1.sokcho },
  { label: "정선", timeFlag: "KR", time: time1.sokcho },
  { label: "영월", timeFlag: "KR", time: time1.sokcho },
  { label: "영주", timeFlag: "KR", time: time1.sokcho },
  { label: "군위", timeFlag: "KR", time: time1.sokcho },
  { label: "대구", timeFlag: "KR", time: time1.sokcho },
  { label: "마산", timeFlag: "KR", time: time1.sokcho },
  { label: "창원", timeFlag: "KR", time: time1.sokcho },
  { label: "진해", timeFlag: "KR", time: time1.sokcho },
  { label: "거제", timeFlag: "KR", time: time1.sokcho },

  { label: "강릉", timeFlag: "KR", time: time1.gangreung },
  { label: "동해", timeFlag: "KR", time: time1.gangreung },
  { label: "삼척", timeFlag: "KR", time: time1.gangreung },
  { label: "태백", timeFlag: "KR", time: time1.gangreung },
  { label: "청송", timeFlag: "KR", time: time1.gangreung },
  { label: "영천", timeFlag: "KR", time: time1.gangreung },
  { label: "경주", timeFlag: "KR", time: time1.gangreung },
  { label: "김해", timeFlag: "KR", time: time1.gangreung },
  { label: "부산", timeFlag: "KR", time: time1.gangreung },

  { label: "울진", timeFlag: "KR", time: time1.uljin },
  { label: "영덕", timeFlag: "KR", time: time1.uljin },
  { label: "포항", timeFlag: "KR", time: time1.uljin },
  { label: "울산", timeFlag: "KR", time: time1.uljin },

  {
    label: "런던",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 10,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 8,
      },
    ],
  },
  {
    label: "GMT",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 10,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 8,
      },
    ],
  },
  {
    label: "파리",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 8,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 9,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 7,
      },
    ],
  },

  {
    label: "카이로",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 7,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 8,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 6,
      },
    ],
  },
  {
    label: "아테네",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 7,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 8,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 6,
      },
    ],
  },
  {
    label: "바그다드",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 6,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 7,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 5,
      },
    ],
  },
  {
    label: "카라치",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 4,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 5,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 3,
      },
    ],
  },
  {
    label: "방콕",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 2,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 3,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 1,
      },
    ],
  },
  {
    label: "홍콩",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 1,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 2,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 3,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 0,
      },
    ],
  },
  {
    label: "시드니",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: 0,
        timeIndex: 23,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 0,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 1,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 2,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 3,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: 0,
        timeIndex: 22,
      },
    ],
  },

  {
    label: "호놀롤루",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 19,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 20,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 16,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 17,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 18,
      },
    ],
  },
  {
    label: "샌프란시스코",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 17,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 18,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 16,
      },
    ],
  },
  {
    label: "시카고",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 16,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 14,
      },
    ],
  },
  {
    label: "달라스",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 16,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 14,
      },
    ],
  },
  {
    label: "뉴욕",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 15,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 13,
      },
    ],
  },
  {
    label: "리우데자네이루",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 13,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 11,
      },
    ],
  },

  {
    label: "나이로비",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "뉴델리",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "뉴올리언스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "도쿄/동경",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "디트로이트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "로마",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "로스앤젤레스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "리스본",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마닐라",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마드리드",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마이애미",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "멕시코시티",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "모스크바",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "몬트리올",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베를린",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베이루트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베이징",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "벤쿠버",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "보스턴",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "봄베이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "부다페스트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "부에노스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "브뤼셀",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "빈",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "상파울루",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "상하이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "세인트피터즈버그",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "스톡홀름",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "시애틀",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "싱가포르",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "암스텔담",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "앵커리지",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "양곤",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "오슬로",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "오타와",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "와르소",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "워싱턴",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "자카르타",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "캔버러",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "캘거타",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "코펜하겐",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "콜롬보",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "프라하",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "하노이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "헬싱키",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
];
const location1 = [
  {
    label: "런던",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 10,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 8,
      },
    ],
  },
  {
    label: "GMT",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 10,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 8,
      },
    ],
  },
  {
    label: "파리",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 8,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 9,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 7,
      },
    ],
  },

  {
    label: "카이로",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 7,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 8,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 21,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 6,
      },
    ],
  },
  {
    label: "아테네",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 7,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 8,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 21,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 6,
      },
    ],
  },
  {
    label: "바그다드",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 6,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 7,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 5,
      },
    ],
  },
  {
    label: "카라치",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 4,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 5,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 3,
      },
    ],
  },
  {
    label: "방콕",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 2,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 3,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 1,
      },
    ],
  },
  {
    label: "홍콩",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 1,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 2,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 3,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 0,
      },
    ],
  },
  {
    label: "시드니",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: 0,
        timeIndex: 23,
      },

      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 0,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 1,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 2,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 3,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 4,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 5,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 6,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 7,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 8,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 9,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 10,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: 0,
        timeIndex: 11,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: 0,
        timeIndex: 12,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: 0,
        timeIndex: 13,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: 0,
        timeIndex: 22,
      },
    ],
  },

  {
    label: "호놀롤루",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 19,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 20,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 16,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 17,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 18,
      },
    ],
  },
  {
    label: "샌프란시스코",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 17,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 18,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 16,
      },
    ],
  },
  {
    label: "시카고",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 16,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 14,
      },
    ],
  },
  {
    label: "달라스",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 15,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 16,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 13,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 14,
      },
    ],
  },
  {
    label: "뉴욕",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 14,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 15,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 11,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 13,
      },
    ],
  },
  {
    label: "리우데자네이루",
    timeFlag: "Other",
    time: "use-option",
    option: [
      {
        index: 0,
        start: `00:01`,
        end: `01:00`,
        day: -1,
        timeIndex: 12,
      },
      {
        index: 1,
        start: `01:01`,
        end: `02:00`,
        day: 0,
        timeIndex: 13,
      },

      {
        index: 2,
        start: `02:01`,
        end: `03:00`,
        day: 0,
        timeIndex: 14,
      },
      {
        index: 3,
        start: `03:01`,
        end: `04:00`,
        day: 0,
        timeIndex: 15,
      },
      {
        index: 4,
        start: `04:01`,
        end: `05:00`,
        day: 0,
        timeIndex: 16,
      },
      {
        index: 5,
        start: `05:01`,
        end: `06:00`,
        day: 0,
        timeIndex: 17,
      },
      {
        index: 6,
        start: `06:01`,
        end: `07:00`,
        day: 0,
        timeIndex: 18,
      },
      {
        index: 7,
        start: `07:01`,
        end: `08:00`,
        day: 0,
        timeIndex: 19,
      },
      {
        index: 8,
        start: `08:01`,
        end: `09:00`,
        day: 0,
        timeIndex: 20,
      },
      {
        index: 9,
        start: `09:01`,
        end: `10:00`,
        day: 0,
        timeIndex: 21,
      },
      {
        index: 10,
        start: `10:01`,
        end: `11:00`,
        day: 0,
        timeIndex: 22,
      },
      {
        index: 11,
        start: `11:01`,
        end: `12:00`,
        day: 0,
        timeIndex: 23,
      },
      {
        index: 12,
        start: `12:01`,
        end: `13:00`,
        day: -1,
        timeIndex: 0,
      },
      {
        index: 13,
        start: `13:01`,
        end: `14:00`,
        day: -1,
        timeIndex: 1,
      },
      {
        index: 14,
        start: `14:01`,
        end: `15:00`,
        day: -1,
        timeIndex: 2,
      },
      {
        index: 15,
        start: `15:01`,
        end: `16:00`,
        day: -1,
        timeIndex: 3,
      },
      {
        index: 16,
        start: `16:01`,
        end: `17:00`,
        day: -1,
        timeIndex: 4,
      },
      {
        index: 17,
        start: `17:01`,
        end: `18:00`,
        day: -1,
        timeIndex: 5,
      },
      {
        index: 18,
        start: `18:01`,
        end: `19:00`,
        day: -1,
        timeIndex: 6,
      },
      {
        index: 19,
        start: `19:01`,
        end: `20:00`,
        day: -1,
        timeIndex: 7,
      },
      {
        index: 20,
        start: `20:01`,
        end: `21:00`,
        day: -1,
        timeIndex: 8,
      },
      {
        index: 21,
        start: `21:01`,
        end: `22:00`,
        day: -1,
        timeIndex: 9,
      },
      {
        index: 22,
        start: `22:01`,
        end: `23:00`,
        day: -1,
        timeIndex: 10,
      },
      {
        index: 23,
        start: `23:01`,
        end: `24:00`,
        day: -1,
        timeIndex: 11,
      },
    ],
  },

  {
    label: "나이로비",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "뉴델리",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "뉴올리언스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "도쿄/동경",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "디트로이트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "로마",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "로스앤젤레스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "리스본",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마닐라",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마드리드",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "마이애미",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "멕시코시티",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "모스크바",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "몬트리올",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베를린",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베이루트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "베이징",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "벤쿠버",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "보스턴",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "봄베이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "부다페스트",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "부에노스",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "브뤼셀",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "빈",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "상파울루",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "상하이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "세인트피터즈버그",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "스톡홀름",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "시애틀",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "싱가포르",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "암스텔담",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "앵커리지",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "양곤",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "오슬로",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "오타와",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "와르소",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "워싱턴",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "자카르타",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "캔버러",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "캘거타",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "코펜하겐",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "콜롬보",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "프라하",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "하노이",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
  {
    label: "헬싱키",
    timeFlag: "Other",
    time: "use-option",
    option: world1,
  },
];
const location2 = [
  { label: "서울", time: time1.seoul1 },
  { label: "과천", time: time1.seoul1 },
  { label: "안양", time: time1.seoul1 },
  { label: "수원", time: time1.seoul1 },
  { label: "송탄", time: time1.seoul1 },
  { label: "평택", time: time1.seoul1 },
  { label: "천안", time: time1.seoul1 },
  { label: "온양", time: time1.seoul1 },
  { label: "공주", time: time1.seoul1 },
  { label: "강경", time: time1.seoul1 },
  { label: "전주", time: time1.seoul1 },
  { label: "이리", time: time1.seoul1 },
  { label: "광주", time: time1.seoul1 },
  { label: "화순", time: time1.seoul1 },

  { label: "인천", time: time1.incheon },
  { label: "김포", time: time1.incheon },
  { label: "당진", time: time1.incheon },
  { label: "서산", time: time1.incheon },
  { label: "대천", time: time1.incheon },
  { label: "서천", time: time1.incheon },
  { label: "군산", time: time1.incheon },
  { label: "영광", time: time1.incheon },
  { label: "목포", time: time1.incheon },
  { label: "제주", time: time1.incheon },
  { label: "서귀포", time: time1.incheon },

  { label: "가평", time: time1.gapyeong },
  { label: "양평", time: time1.gapyeong },
  { label: "증평", time: time1.gapyeong },
  { label: "여주", time: time1.gapyeong },
  { label: "이천", time: time1.gapyeong },
  { label: "청주", time: time1.gapyeong },
  { label: "대전", time: time1.gapyeong },
  { label: "진안", time: time1.gapyeong },
  { label: "장수", time: time1.gapyeong },
  { label: "남원", time: time1.gapyeong },
  { label: "구례", time: time1.gapyeong },
  { label: "순천", time: time1.gapyeong },

  { label: "춘천", time: time1.chooncheon },
  { label: "홍천", time: time1.chooncheon },
  { label: "횡성", time: time1.chooncheon },
  { label: "원주", time: time1.chooncheon },
  { label: "충주", time: time1.chooncheon },
  { label: "점촌", time: time1.chooncheon },
  { label: "상주", time: time1.chooncheon },
  { label: "김천", time: time1.chooncheon },
  { label: "진주", time: time1.chooncheon },
  { label: "삼천포", time: time1.chooncheon },

  { label: "속초", time: time1.sokcho },
  { label: "양양", time: time1.sokcho },
  { label: "정선", time: time1.sokcho },
  { label: "영월", time: time1.sokcho },
  { label: "영주", time: time1.sokcho },
  { label: "군위", time: time1.sokcho },
  { label: "대구", time: time1.sokcho },
  { label: "마산", time: time1.sokcho },
  { label: "창원", time: time1.sokcho },
  { label: "진해", time: time1.sokcho },
  { label: "거제", time: time1.sokcho },

  { label: "강릉", time: time1.gangreung },
  { label: "동해", time: time1.gangreung },
  { label: "삼척", time: time1.gangreung },
  { label: "태백", time: time1.gangreung },
  { label: "청송", time: time1.gangreung },
  { label: "영천", time: time1.gangreung },
  { label: "경주", time: time1.gangreung },
  { label: "김해", time: time1.gangreung },
  { label: "부산", time: time1.gangreung },

  { label: "울진", time: time1.uljin },
  { label: "영덕", time: time1.uljin },
  { label: "포항", time: time1.uljin },
  { label: "울산", time: time1.uljin },
];
const jeolgiMixArray1 = [
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
];
const sookjabyeol_1 = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1];
const pdfPageNumber = [
  4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 32, 34, 36, 38, 40, 42, 44, 46,
  48, 50, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 82, 84, 86, 88, 90, 92,
  94, 96, 98, 100, 102, 108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 128,
  134, 136, 138, 140, 142, 144, 146, 148, 150, 152, 154, 160, 162, 164, 166,
  168, 170, 172, 174, 176, 178, 180, 186, 188, 190, 192, 194, 196, 198, 200,
  202, 204, 206, 212, 214, 216, 218, 220, 222, 224, 226, 228, 230, 232, 238,
  240, 242, 244, 246, 248, 250, 252, 254, 256, 258, 264, 266, 268, 270, 272,
  274, 276, 278, 280, 282, 284, 290, 292, 294, 296, 298, 300, 302, 304, 306,
  308, 31,
];
const pdfPageNumber1 = [
  [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  [30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50],
  [56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76],
  [82, 84, 86, 88, 90, 92, 94, 96, 98, 100, 102],
  [108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 128],
  [134, 136, 138, 140, 142, 144, 146, 148, 150, 152, 154],
  [160, 162, 164, 166, 168, 170, 172, 174, 176, 178, 180],
  [186, 188, 190, 192, 194, 196, 198, 200, 202, 204, 206],
  [212, 214, 216, 218, 220, 222, 224, 226, 228, 230, 232],
  [238, 240, 242, 244, 246, 248, 250, 252, 254, 256, 258],
  [264, 266, 268, 270, 272, 274, 276, 278, 280, 282, 284],
  [290, 292, 294, 296, 298, 300, 302, 304, 306, 308, 310],
];
const gan10 = [
  "갑 (甲)",
  "을 (乙)",
  "병 (丙)",
  "정 (丁)",
  "무 (戊)",
  "기 (己)",
  "경 (庚)",
  "신 (辛)",
  "임 (壬)",
  "계 (癸)",
];
const ganji12 = [
  "자 (子)",
  "축 (丑)",
  "인 (寅)",
  "묘 (卯)",
  "진 (辰)",
  "사 (巳)",
  "오 (午)",
  "미 (未)",
  "신 (申)",
  "유 (酉)",
  "술 (戌)",
  "해 (亥)",
];

const ganji12_0 = [
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
];
const time12_0 = [
  "23<br/>01",
  "01<br/>03",
  "03<br/>05",
  "05<br/>07",
  "07<br/>09",
  "09<br/>11",
  "11<br/>13",
  "13<br/>15",
  "15<br/>17",
  "17<br/>19",
  "19<br/>21",
  "21<br/>23",
];
const time12_1 = [
  "쥐해<br/>10<br/>11<br/>월<br/>고귀",
  "소해<br/>3,6<br/>9,12<br/>월<br/>고귀",
  "범해<br/>&nbsp;<br/>정2<br/>월<br/>고귀",
  "토끼<br/>&nbsp;<br/>정2<br/>월<br/>고귀",
  "용해<br/>3,6<br/>9,12<br/>월<br/>고귀",
  "뱀해<br/>4<br/>5<br/>월<br/>고귀",
  "말해<br/>4<br/>5<br/>월<br/>고귀",
  "양해<br/>3,6<br/>9,12<br/>월<br/>고귀",
  "원숭<br/>7<br/>8<br/>월<br/>고귀",
  "닭해<br/>7<br/>8<br/>월<br/>고귀",
  "개해<br/>3,6<br/>9,12<br/>월<br/>고귀",
  "돼지<br/>10<br/>11<br/>월<br/>고귀",
];
const time12_2 = [
  "음11<br/>차귀",
  "음12<br/>차귀",
  "정월<br/>차귀",
  "음2<br/>차귀",
  "음3<br/>차귀",
  "음4<br/>차귀",
  "음5<br/>차귀",
  "음6<br/>차귀",
  "음7<br/>차귀",
  "음8<br/>차귀",
  "음9<br/>차귀",
  "음10<br/>차귀",
];
const time12_3 = [
  "대한<br/>우수<br/>고위",
  "동지<br/>대한<br/>고위",
  "소설<br/>동지<br/>고위",
  "상강<br/>소설<br/>고위",
  "추분<br/>상강<br/>고위",
  "처서<br/>추분<br/>고위",
  "대서<br/>처서<br/>고위",
  "하지<br/>대서<br/>고위",
  "소만<br/>하지<br/>고위",
  "곡우<br/>소만<br/>고위",
  "춘분<br/>곡우<br/>고위",
  "우수<br/>춘분<br/>고위",
];
const time12_4 = [
  "음시<br/>力人",
  "사오<br/>力人",
  "십십일<br/>力人",
  "십십일<br/>力人",
  "사오<br/>力人",
  "정이<br/>力人",
  "정이<br/>力人",
  "사오<br/>力人",
  "토월<br/>力人",
  "토월<br/>力人",
  "사오<br/>力人",
  "칠팔<br/>力人",
];
const time12_5 = [
  "정2<br/>34<br/>56<br/>9십이<br/>우고",
  "정2<br/>78<br/>십십이<br/><br/>우고",
  "45<br/>78<br/>36<br/>9십이<br/>우고",
  "45<br/>78<br/>36<br/>9십이<br/>우고",
  "정2<br/>34<br/>56<br/>9십이<br/>우고",
  "34<br/>56<br/>78<br/>9십이<br/>우고",

  "34<br/>56<br/>78<br/>9십이<br/>우고",
  "36<br/>78<br/>십십이<br/>&nbsp;<br/>우고",
  "정2<br/>78<br/>십십이<br/><br/>우고",
  "정2<br/>78<br/>십십이<br/><br/>우고",
  "정2<br/>78<br/>십십이<br/><br/>우고",
  "정2<br/>34<br/>56<br/>9십이<br/>우고",
];
const time12_5_1 = [
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
  "人",
];
const time12_6 = [
  "종교<br/>성공<br/>갑인<br/>순중<br/>십십일<br/>&nbsp;",
  "종교<br/>성공<br/>갑인<br/>순중<br/>3,6<br/>9,12",
  "종교<br/>성공<br/>갑진<br/>순중<br/>정2<br/>&nbsp;",
  "종교<br/>성공<br/>갑진<br/>순중<br/>정2<br/>&nbsp;",
  "종교<br/>성공<br/>갑오<br/>순중<br/>3,6<br/>9,12",
  "종교<br/>성공<br/>갑오<br/>순중<br/>4,5<br/>&nbsp;",
  "종교<br/>성공<br/>갑신<br/>순중<br/>4,5<br/>&nbsp;",
  "종교<br/>성공<br/>갑신<br/>순중<br/>3,6<br/>9,12",
  "종교<br/>성공<br/>갑술<br/>순중<br/>7,8<br/>&nbsp;",
  "종교<br/>성공<br/>갑술<br/>순중<br/>7,8<br/>&nbsp;",
  "종교<br/>성공<br/>갑자<br/>순중<br/>3,6<br/>9,12",
  "종교<br/>성공<br/>갑자<br/>순중<br/>십십일<br/>&nbsp;",
];
const time12_6_1 = [
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
  "고뇌<br/>해소",
];
const time12_7 = [
  "갑자<br/>순중<br/>10일<br/>갑신<br/>갑오<br/>20<br/>일간",
  "갑자<br/>갑술<br/>순중<br/>20<br/>일간<br/>&nbsp;<br/>&nbsp;",
  "갑인<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑진<br/>순중<br/>해당<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>",
  "갑진<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "병무<br/>일<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑오<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑신<br/>순중<br/>해당<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>",
  "갑신<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑술<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑술<br/>순중<br/>10<br/>일간<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;",
  "갑진<br/>갑인<br/>순중<br/>20<br/>일간<br/>&nbsp;<br/>&nbsp;",
];
const ganji12_1 = [
  "자",
  "축",
  "인",
  "묘",
  "진 ",
  "사",
  "오 ",
  "미 ",
  "신 ",
  "유 ",
  "술 ",
  "해 ",
];

const hyosagyeolTable1 = [
  // 동지~대한
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],

  // 대한~우수
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],

  // 우수~춘분
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  // 춘분~곡우
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  // 곡우~소만
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  // 소만~하지
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  // 하지~대서
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  // 대서~처서
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  // 처서~추분
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  // 추분~상강
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  // 상강~소설
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  // 소설~동지
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
];

const ganji12_2 = [
  {
    label: "子",
    index: 6,
    originIndex: 0,
  },

  {
    label: "丑",
    index: 11,
    originIndex: 1,
  },

  {
    label: "寅",
    index: 8,
    originIndex: 2,
  },
  {
    label: "卯",
    index: 1,
    originIndex: 3,
  },
  {
    label: "辰",
    index: 10,
    originIndex: 4,
  },
  {
    label: "巳",
    index: 3,
    originIndex: 5,
  },
  {
    label: "午",
    index: 12,
    originIndex: 6,
  },
  {
    label: "未",
    index: 5,
    originIndex: 7,
  },
  {
    label: "申",
    index: 2,
    originIndex: 8,
  },
  {
    label: "酉",
    index: 7,
    originIndex: 9,
  },
  {
    label: "戌",
    index: 4,
    originIndex: 10,
  },
  {
    label: "亥",
    index: 9,
    originIndex: 11,
  },
];

const gapja60 = [
  "갑자 (甲子)",
  "을축 (乙丑)",
  "병인 (丙寅)",
  "정묘 (丁卯)",
  "무진 (戊辰)",
  "기사 (己巳)",
  "경오 (庚午)",
  "신미 (辛未)",
  "임신 (壬申)",
  "계유 (癸酉)",
  "갑술 (甲戌)",
  "을해 (乙亥)",
  "병자 (丙子)",
  "정축 (丁丑)",
  "무인 (戊寅)",
  "기묘 (己卯)",
  "경진 (庚辰)",
  "신사 (辛巳)",
  "임오 (壬午)",
  "계미 (癸未)",
  "갑신 (甲申)",
  "을유 (乙酉)",
  "병술 (丙戌)",
  "정해 (丁亥)",
  "무자 (戊子)",
  "기축 (己丑)",
  "경인 (庚寅)",
  "신묘 (辛卯)",
  "임진 (壬辰)",
  "계사 (癸巳)",
  "갑오 (甲午)",
  "을미 (乙未)",
  "병신 (丙申)",
  "정유 (丁酉)",
  "무술 (戊戌)",
  "기해 (己亥)",
  "경자 (庚子)",
  "신축 (辛丑)",
  "임인 (壬寅)",
  "계묘 (癸卯)",
  "갑진 (甲辰)",
  "을사 (乙巳)",
  "병오 (丙午)",
  "정미 (丁未)",
  "무신 (戊申)",
  "기유 (己酉)",
  "경술 (庚戌)",
  "신해 (辛亥)",
  "임자 (壬子)",
  "계축 (癸丑)",
  "갑인 (甲寅)",
  "을묘 (乙卯)",
  "병진 (丙辰)",
  "정사 (丁巳)",
  "무오 (戊午)",
  "기미 (己未)",
  "경신 (庚申)",
  "신유 (辛酉)",
  "임술 (壬戌)",
  "계해 (癸亥)",
];

const gapja60_0 = [
  `甲子`,
  `乙丑`,
  `丙寅`,
  `丁卯`,
  `戊辰`,
  `己巳`,
  `庚午`,
  `辛未`,
  `壬申`,
  `癸酉`,
  `甲戌`,
  `乙亥`,

  `丙子`,
  `丁丑`,
  `戊寅`,
  `己卯`,
  `庚辰`,
  `辛巳`,
  `壬午`,
  `癸未`,
  `甲申`,
  `乙酉`,
  `丙戌`,
  `丁亥`,

  `戊子`,
  `己丑`,
  `庚寅`,
  `辛卯`,
  `壬辰`,
  `癸巳`,
  `甲午`,
  `乙未`,
  `丙申`,
  `丁酉`,
  `戊戌`,
  `己亥`,

  `庚子`,
  `辛丑`,
  `壬寅`,
  `癸卯`,
  `甲辰`,
  `乙巳`,
  `丙午`,
  `丁未`,
  `戊申`,
  `己酉`,
  `庚戌`,
  `辛亥`,

  `壬子`,
  `癸丑`,
  `甲寅`,
  `乙卯`,
  `丙辰`,
  `丁巳`,
  `戊午`,
  `己未`,
  `庚申`,
  `辛酉`,
  `壬戌`,
  `癸亥`,
];

const menu1 = [
  "1. 목적사 달성여부",
  "2. 계획, 도보사 측정",
  "3. 당면 소송사 승패",
  "4. 선출직 성품, 능력",
  "5. 남녀 용모, 가문, 배우 시기",
  "6. 출생연도별 익명 사항",
  "7. 형이상",
  // "8. 모망사 종합",
  // "9. 국수 해설",
  // "10. 방문 전화 남녀",
  // "11. 일생사",
];
const menu2 = [
  "1. 보기요령",
  "1. 일자변경",
  "1. 기도 효과 유무",
  "1. 간절 기도효과 일시",
  "1. 천성 선악 여부",
  "1. 거주 경관",
  "1. 달력 보기",
  "1. 해당 국수 재 확인",
];
const newmenu1 = [
  "01. 상대선입견",
  "02. 목적사달성여부",
  "03. 당면소송승패",
  "04. 남녀용모가문배우시기",
  "05. 선출직 판단 추이",
  "06. 계획, 도모 측정",
  "07. 형이상법",
  "08. 출생연도별 익명상황",
  "09. 남녀 성력 및 태위 선호",
  "10. 24항목별 성부단정",
  "11. 전화상담즉단",
  "12. 지두생활응용",
  // "13. 출산길일길시선택",
];
const newmenu2 = [
  "01. 일생 남녀 성격 궤적",
  "02. 전체구체적합행년특이", // 2,3,4는 합쳐서 전체,구체,적합, 행년, 특이성 종합
  "03. 질병",
  "04. 친화 구해 자녀",
  "05. 현재신상",
  "06. 일생침범 귀해",
  "07. 남녀 성력 및 태위 선호",
  // "8. 생일별 운세즉단",
  "08. 명좌문신불법",
  "09. 육임가묘좌법",
  "-",
  "-",
  "-",
];
const newmenu101_1 = [
  "13. 각 일별 질병 육친사",
  "14. 매년 월별신수",
  "15. 행운 감정",
];
const newmenu101_2 = [
  "13. 부모선후망 처 자녀수",
  "14. 일간 나이별사상",
  "15. 일주별 부부남녀궁합",
];

const danjeongMenu1 = [
  "사업사",
  "대인 관계사",
  "직원 채용사",
  "진위 파악사",
  "소송 승패사",
  "주식 투자사",
  "상품 구별사",
  "낙찰사",
  "구재사",
  "매매사",
  "사건 심리사",
  "공명사",
  "가택 가정사",
  "길흉 예측사",
  "질병사",
  "형이상사",
  "도난사",
  "이혼사",
  "몽중사",
  "농경사",
  "축산사",
  "수렵사",
  "어획사",
  "도박사",
  "사복사",
  // "시험선거취직",
  // "가출사",
  // "혼인사",
  // "임신사",
  // "행년 상신법",
];

const jeolgiValue1 = [
  0, 21355, 42843, 64498, 86335, 108366, 130578, 152958, 175471, 198077, 220728,
  243370, 265955, 288432, 310767, 332928, 354903, 376685, 398290, 419736,
  441060, 462295, 483493, 504693, 525949,
];

const LUNAR_LAST_YEAR = 1939;
var lunarMonthTable = [
  [
    2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2,
  ] /* 양력 1940년 1월은 음력 1939년에 있음 그래서 시작년도는 1939년*/,
  [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 2, 1, 2, 2, 4, 1, 1, 2, 1, 2, 1] /* 1941 */,
  [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
  [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
  [1, 1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2],
  [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
  [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
  [2, 5, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
  [2, 2, 1, 2, 1, 2, 3, 2, 1, 2, 1, 2],
  [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2] /* 1951 */,
  [1, 2, 1, 2, 4, 2, 1, 2, 1, 2, 1, 2],
  [1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2, 2],
  [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
  [2, 1, 4, 1, 1, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],
  [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
  [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
  [2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1],
  [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2] /* 1961 */,
  [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
  [2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
  [1, 2, 5, 2, 1, 1, 2, 1, 1, 2, 2, 1],
  [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
  [1, 2, 2, 1, 2, 1, 5, 2, 1, 2, 1, 2],
  [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
  [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
  [1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1, 2] /* 1971 */,
  [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2, 1],
  [2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1, 2],
  [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
  [2, 2, 1, 2, 1, 2, 1, 5, 2, 1, 1, 2],
  [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1],
  [2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
  [2, 1, 1, 2, 1, 6, 1, 2, 2, 1, 2, 1],
  [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
  [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2] /* 1981 */,
  [2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2, 2],
  [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
  [2, 1, 2, 2, 1, 1, 2, 1, 1, 5, 2, 2],
  [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
  [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
  [2, 1, 2, 2, 1, 5, 2, 2, 1, 2, 1, 2],
  [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
  [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
  [1, 2, 1, 1, 5, 1, 2, 1, 2, 2, 2, 2],
  [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2] /* 1991 */,
  [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
  [1, 2, 5, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
  [1, 2, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2],
  [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
  [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
  [2, 1, 1, 2, 3, 2, 2, 1, 2, 2, 2, 1],
  [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
  [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
  [2, 2, 2, 3, 2, 1, 1, 2, 1, 2, 1, 2] /* 2001 */,
  [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
  [1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
  [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 1],
  [2, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
  [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
  [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
  [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
  [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1] /* 2011 */,
  [2, 1, 6, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
  [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
  [2, 1, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
  [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2],
  [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1] /* 2021 */,
  [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
  [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
  [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
  [1, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
  [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
  [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
  [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1] /* 2031 */,
  [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 5, 2],
  [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
  [2, 2, 1, 2, 1, 4, 1, 1, 2, 2, 1, 2],
  [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
  [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
  [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
  [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1],
  [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2] /* 2041 */,
  [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
];

export {
  jeolgiValue1,
  LUNAR_LAST_YEAR,
  lunarMonthTable,
  jiduTableHeader1,
  jiduTable1,
  world1,
  soYook_StandardArr,
  nextPersonOriginArray,
  years1,
  month1,
  days1,
  hours1,
  minutes1,
  seconds1,
  yinyang1,
  jeolgi1,
  jeolgi0,
  time1,
  locationA,
  location1,
  location2,
  jeolgiMixArray1,
  sookjabyeol_1,
  pdfPageNumber,
  pdfPageNumber1,
  gan10,
  ganji12,
  ganji12_0,
  time12_0,
  time12_1,
  time12_2,
  time12_3,
  time12_4,
  time12_5,
  time12_5_1,
  time12_6,
  time12_6_1,
  time12_7,
  ganji12_1,
  hyosagyeolTable1,
  ganji12_2,
  gapja60,
  gapja60_0,
  menu1,
  menu2,
  newmenu1,
  newmenu2,
  newmenu101_1,
  newmenu101_2,
  danjeongMenu1,
};
