/**
 * arrayIndex가 5일 때 사용하는 HTML 문자열을 생성하는 함수.
 * @param {object} selectedNaejung - 해당 객체 내부에 default, columnCL 등 데이터가 있음
 * @param {number} sootJaIndex - 계산에 사용되는 인덱스 값
 * @param {string} arrayItem - 상단 제목 등 추가로 표시할 문자열
 * @returns {string} 최종 HTML 문자열
 */
export function generateReturnStringForArrayIndex5(
  selectedNaejung,
  sootJaIndex,
  arrayItem
) {
  // 안전하게 값을 가져오는 헬퍼 함수
  function getValue(row, colIndex) {
    return selectedNaejung &&
      selectedNaejung.default &&
      selectedNaejung.default.columnCL &&
      selectedNaejung.default.columnCL[row] &&
      selectedNaejung.default.columnCL[row][colIndex]
      ? selectedNaejung.default.columnCL[row][colIndex]
      : "";
  }

  // 하나의 블록(섹션)을 생성하는 헬퍼 함수
  // alwaysParen이 true이면 모든 라인에 괄호로 감싼 값을 추가하고,
  // false이면 첫 2라인에만 괄호를 추가합니다.
  function generateBlock(title, col, alwaysParen = true) {
    const lines = [];
    const base = 5 * (sootJaIndex - 1);
    for (let i = 0; i < 5; i++) {
      const row = base + i + 3;
      const mainVal = getValue(row, col);
      const baseVal = getValue(row, 0);
      // console.log("row, col : ", sootJaIndex, row, col);
      // console.log("mainVal : ", mainVal);
      // console.log("baseVal : ", baseVal);

      // mainVal과 baseVal의 문자열 길이가 0인 경우 해당 라인을 건너뜁니다.
      if (mainVal.length === 0 && baseVal.length === 0) {
        continue;
      }
      let line = "";
      if (alwaysParen) {
        line = baseVal.trim().length > 0 ? `${mainVal}` : `${mainVal}`;
      } else {
        line = `${mainVal}<br/>`;
      }
      if (line.trim().length > 0) {
        lines.push(line);
      }
    }
    return `
    <div class="label0" style="font-family: 'NanumBarunGothic'; width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
      ${title}
      <div style="display: inline-flex; font-weight: 700; width: 50%; margin-left: auto; text-align: left;">
        ${lines.join("")}
      </div>
    </div>
    <br/>`;
  }

  // 각 섹션에 대한 정의 (title, 컬럼 인덱스, 괄호 출력 여부)
  const sections = [
    { title: "행사거행集散", col: 1, alwaysParen: true },
    { title: "안건반대등유무", col: 2, alwaysParen: true },
    { title: "약속이행등來不", col: 3, alwaysParen: true },
    { title: "도모사難易", col: 4, alwaysParen: true },
    { title: "진퇴向背등여하", col: 5, alwaysParen: true },
    { title: "가출등遠近", col: 6, alwaysParen: true },
    { title: "이전이사거류여부", col: 7, alwaysParen: true },
    { title: "임대차친소 공동생활", col: 8, alwaysParen: true },
    { title: "성취달성 방향", col: 9, alwaysParen: true },
    { title: "낮", col: 10, alwaysParen: true },
    { title: "밤", col: 11, alwaysParen: true },
  ];

  // 각 섹션을 생성하여 최종 HTML로 결합
  const content = sections
    .map((sec) => generateBlock(sec.title, sec.col, sec.alwaysParen))
    .join("\n");

  return (
    `<span class="label0" style="font-size: 18px; font-family: 'NanumBarunGothic';">${arrayItem}</span><br/>` +
    `<div style="margin-top: 10px;">${content}</div>`
  );
}
