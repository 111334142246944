import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Button0 = styled.div`
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  color: #fff;
  background-color: #8d8ea9;
  border: 1px solid #000;
  border-radius: 0px;

  padding-left: 4px;
  padding-right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

function TimeSelect({
  ganji12_0,
  time12_0,
  ganji12_2,
  intSelectedIndex2,
  specificPersonValue,
  parse,
  headerTitle,
  timeTitleList,
}) {
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  // console.warn(isPc, isTablet, isMobile);

  return (
    <>
      <div
        className="maintable_row0"
        style={{
          height: "80px !important",
          minHeight: "80px !important",
          maxHeight: "80px !important",
          justifyContent: "center",
          whiteSpace: "nowrap",
          flexWrap: "nowrap",
          fontWeight: 700,
          fontSize: 14,
          marginTop: 12,
          marginBottom: 12,
          borderBottom: "1px solid #fff",
        }}
      >
        ■ {headerTitle}
      </div>
      <div
        style={{
          marginLeft: 8,
          fontSize: 14,
          height: 20,
          fontWeight: 700,
        }}
      >
        <span>■ 정시</span>
        <span style={{ marginLeft: 8, fontSize: isMobile ? "12px" : "14px" }}>
          ({timeTitleList[0]})
        </span>
      </div>
      <div
        className="maintable_row0 maintable_row3"
        style={{
          height: "40px !important",
          minHeight: "40px !important",
          maxHeight: "40px !important",
          justifyContent: "center",
          whiteSpace: "nowrap",
          flexWrap: "nowrap",
        }}
      >
        {Array.from(ganji12_0).map((arrayItem, arrayIndex) => (
          <Button0
            key={`ganji0-${arrayIndex}`}
            className={
              arrayIndex === intSelectedIndex2
                ? `activeitem item0 item${arrayIndex}`
                : `item0 item${arrayIndex}`
            }
            onClick={() => specificPersonValue(arrayIndex)}
            style={{
              height: 30,
              fontSize: 14,
              fontWeight: 900,
              padding: 0,
              width: "calc(100% / 12)",
              background:
                arrayIndex >= 3 && arrayIndex <= 8 ? "#FF869E" : "#5CA6E8",
              color: arrayIndex >= 3 && arrayIndex <= 8 ? "#000" : "#fff",
            }}
          >
            {arrayItem.replace("(", "").replace(")", "")}
          </Button0>
        ))}
      </div>
      <div
        className="maintable_row0 maintable_row3"
        style={{
          height: "40px !important",
          minHeight: "40px !important",
          maxHeight: "40px !important",
          justifyContent: "center",
          whiteSpace: "nowrap",
          flexWrap: "nowrap",
        }}
      >
        {Array.from(time12_0).map((arrayItem, arrayIndex) => (
          <Button0
            key={`time0-${arrayIndex}`}
            className={
              arrayIndex === intSelectedIndex2
                ? `activeitem item0 item${arrayIndex}`
                : `item0 item${arrayIndex}`
            }
            onClick={() => specificPersonValue(arrayIndex)}
            style={{
              height: 30,
              fontSize: 14,
              fontWeight: 900,
              padding: 0,
              width: "calc(100% / 12)",
              background:
                arrayIndex >= 3 && arrayIndex <= 8 ? "#FF869E" : "#5CA6E8",
              color: arrayIndex >= 3 && arrayIndex <= 8 ? "#000" : "#fff",
            }}
          >
            {parse(arrayItem.replace("(", "").replace(")", ""))}
          </Button0>
        ))}
      </div>
      <div
        style={{
          marginLeft: 8,
          fontSize: 14,
          height: 20,
          fontWeight: 700,
        }}
      >
        <span>■ 차객</span>{" "}
        <span style={{ marginLeft: 8 }}>({timeTitleList[1]})</span>
      </div>
      <div
        className="maintable_row0 maintable_row3"
        style={{
          height: "40px !important",
          minHeight: "40px !important",
          maxHeight: "40px !important",
          justifyContent: "center",
          whiteSpace: "nowrap",
          flexWrap: "nowrap",
        }}
      >
        {Array.from(ganji12_2)
          .sort((a, b) => a.index - b.index)
          .map((arrayItem, arrayIndex) => (
            <Button0
              key={`ganji2-${arrayIndex}`}
              className={
                arrayIndex === intSelectedIndex2
                  ? `activeitem item0 item${arrayIndex}`
                  : `item0 item${arrayIndex}`
              }
              onClick={() => specificPersonValue(arrayItem.originIndex)}
              style={{
                height: 30,
                fontSize: 14,
                fontWeight: 900,
                padding: 0,
                width: "calc(100% / 12)",
                background:
                  arrayItem.label === "卯" ||
                  arrayItem.label === "辰" ||
                  arrayItem.label === "巳" ||
                  arrayItem.label === "午" ||
                  arrayItem.label === "未" ||
                  arrayItem.label === "申"
                    ? "#FF869E"
                    : "#5CA6E8",
                color:
                  arrayItem.label === "卯" ||
                  arrayItem.label === "辰" ||
                  arrayItem.label === "巳" ||
                  arrayItem.label === "午" ||
                  arrayItem.label === "未" ||
                  arrayItem.label === "申"
                    ? "#000"
                    : "#fff",
              }}
            >
              {arrayItem.label.replace("(", "").replace(")", "")}
            </Button0>
          ))}
      </div>
      <div
        style={{
          marginLeft: 8,
          fontSize: 14,
          height: 20,
          fontWeight: 700,
        }}
      >
        <span>■ 선택숫자</span>
        <span style={{ marginLeft: 8 }}>({timeTitleList[2]})</span>
      </div>
      <div
        className="maintable_row0 maintable_row3"
        style={{
          height: "40px !important",
          minHeight: "40px !important",
          maxHeight: "40px !important",
          justifyContent: "center",
          whiteSpace: "nowrap",
          flexWrap: "nowrap",
        }}
      >
        {Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).map(
          (arrayItem, arrayIndex) => (
            <Button0
              key={`num-${arrayIndex}`}
              className={
                arrayIndex === intSelectedIndex2
                  ? `activeitem item0 item${arrayIndex}`
                  : `item0 item${arrayIndex}`
              }
              onClick={() => specificPersonValue(arrayIndex)}
              style={{
                height: 30,
                fontSize: 14,
                fontWeight: 900,
                padding: 0,
                width: "calc(100% / 12)",
                background:
                  arrayIndex >= 3 && arrayIndex <= 8 ? "#FF869E" : "#5CA6E8",
                color: arrayIndex >= 3 && arrayIndex <= 8 ? "#000" : "#fff",
              }}
            >
              {arrayItem}
            </Button0>
          )
        )}
      </div>
    </>
  );
}

export default TimeSelect;
