const columnA = [
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`01`, `좌`],
  [`23:32`, `04 `],
  [`01:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`02`, `좌`],
  [`01:32`, `09`],
  [`03:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`03`, `좌`],
  [`03:32`, `06`],
  [`05:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`04`, `좌`],
  [`05:32`, `11`],
  [`07:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`05`, `좌`],
  [`07:32`, `08`],
  [`09:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`06`, `좌`],
  [`09:32`, `01`],
  [`11:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`07`, `좌`],
  [`11:32`, `10`],
  [`13:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`08`, `좌`],
  [`13:32`, `03`],
  [`15:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`09`, `좌`],
  [`15:32`, `12`],
  [`17:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`10`, `좌`],
  [`17:32`, `05`],
  [`19:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`11`, `좌`],
  [`19:32`, `02`],
  [`21:31`, `내용 해당`],
  [``, ``],
  [``, ``],
  [`12`, `좌`],
  [`21:32`, `07`],
  [`23:31`, `내용 해당`],
  [``, ``],
];
const columnD = [
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, 0, 0, ``, ``, ``, ``],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `궤후`,
    `천부적좌고우면신중성자`,
    `신뢰`,
    7,
    2,
    `없음`,
    `01.21\n~
  02.19`,
    `보통크기 \n고환큼
  
  음모
  적음 
  분위기파`,
    `조용\n담담
  
  성교후곧잠`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `고립적`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 4\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `설득`,
    8,
    1,
    `없음`,
    `02.20\n~
  03.20`,
    `굵음\n적극적 매너
  
  강력한 성교 함`,
    `문을 열면 적극적이 됨\n
  감격을 숨기지않음`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적진취창의성자`,
    `고집`,
    9,
    12,
    `없음`,
    `03.21\n~
  04.20`,
    `단순 서툼\n시간 짧음
  
  전,후 없이 본격적 임`,
    `주도적\n적극적
  
  완벽
  주의자 임`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `인자`,
    `천부적좌고우면신중성자`,
    `신뢰`,
    10,
    11,
    `없음`,
    `04.21\n~
  05.20`,
    `때와 장소 안 가림\n
  발정
  타입
   곧 
  시듬`,
    `정숙\n하고 
  억제심 강함
  
  분비물 많음`,
  ],
  [
    `4.5월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `인자`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 3\n7 10`,
    `집요`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `설득`,
    11,
    10,
    `없음`,
    `05.21\n~
  06.20`,
    `기교파 반응 감상 타입\n
  크고, 즐김`,
    `위쪽\n 절정감에 집요함
  
  주도권을 빼앗기도`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적진취창의성자`,
    `고집`,
    12,
    9,
    `없음`,
    `06.21\n~
  07.20`,
    `기교가 세밀함`,
    `분비물 충분 \n속전속결 형
  질이 큼
  
  소극적 못견딤
  절정 빠름`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `진세자`,
    `진進`,
    `발전적`,
    `3 후`,
    `예의`,
    `천부적좌고우면신중성자`,
    `불성`,
    1,
    8,
    `없음`,
    `07.21\n~
  08.20`,
    `정력파 女만족시키려 함\n
  쉽게 지침`,
    `적극적 타입 수축 형\n
  중년 쇠퇴 후 회복함`,
  ],
  [
    `4.5월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `예의`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `10후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `단절자`,
    `단斷`,
    `고립적`,
    `6 후`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `퇴세자`,
    `퇴褪`,
    `퇴휴적`,
    `14\n710`,
    `산만`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `2 14\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `불성`,
    2,
    7,
    `없음`,
    `08.21\n~
  09.20`,
    `헌신 리드 파 \n더러 포경
  
  성지식 풍부함`,
    `부끄러움 많이 탐\n
  성기는 명물임`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `2 5\n8 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `1 5\n8 9`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적진취창의성자`,
    `불성`,
    3,
    6,
    `특별있음`,
    `09.21\n~
  10.20`,
    `조루증 많음\n
  음경은 작은 편`,
    `음모 많음\n
  수축 형이고 능란함`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `소외자`,
    `소疏`,
    `쇠락적 `,
    `6 후`,
    `포살`,
    `천부적좌고우면신중성자`,
    `신뢰`,
    4,
    5,
    `있음`,
    `10.21\n~
  11.20`,
    `보통이상 크기\n
  극적. 본능에 그침`,
    `은밀함\n절정을 끝없이 함
  
  전신
  경련. 수축 형 강력
   명물`,
  ],
  [
    `4.5월`,
    `단절자`,
    `단斷`,
    `고립적`,
    `3 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `성세자`,
    `성盛`,
    `왕성적`,
    `현재`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `퇴세자`,
    `퇴退`,
    `퇴휴적`,
    `9 후`,
    `포살`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `진세자`,
    `진進`,
    `발전적`,
    `1 4\n7 10`,
    `명철`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `단절자`,
    `단斷`,
    `종교재난`,
    `1 2\n7 10`,
    `아둔`,
    `천부적중정신앙심자`,
    `신뢰`,
    5,
    4,
    `없음`,
    `11.21\n~
  12.20`,
    `통크기. 강직도 있음\n
  오래 지속. 女몸매 감상하려 함`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `진세자`,
    `진進`,
    `종교발전`,
    `2 4\n5 7`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `퇴세자`,
    `퇴退`,
    `종교탄핵`,
    `1 2\n4 5`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `소외자`,
    `소疏`,
    `종교한산`,
    `2 4\n5 11`,
    `아둔`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `성세자`,
    `성盛`,
    `종교대성`,
    `현재`,
    `후덕`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `1.2월`,
    `퇴세자`,
    `퇴退`,
    `활인탄핵`,
    `10후\n11후`,
    `궤후`,
    `천부적진취창의성자`,
    `고집`,
    6,
    3,
    `없음`,
    `12.21\n~
  01.20`,
    `보통 크기. 강직도 있음\n
  여러 번. 대담女 즐김`,
    `적극적 \n상위 취함`,
  ],
  [
    `4.5월`,
    `소외자`,
    `소疏`,
    `활인한산`,
    `5 6\n7`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `7.8월`,
    `진세자`,
    `진進`,
    `활인발전`,
    `2 후\n3 후`,
    `지혜`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `10.11`,
    `왕세자`,
    `성盛`,
    `활인대성`,
    `현재`,
    `지헤`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    `3.6\n9.12`,
    `단절자`,
    `단斷`,
    `활인재난`,
    `1 4\n7 10`,
    `궤후`,
    ``,
    ``,
    0,
    0,
    ``,
    ``,
    ``,
    ``,
  ],
];
const columnS = [
  [``, ``, ``, ``, ``, ``],
  [`관련`, `속사정`, ``, ``, `外業신변男上`, `內事가정女下`],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``],
  [`박관`, `직업직위불안 관련사`, `小`, ``, ``, ``],
  [`극부`, `남편 갈등사`, `小`, `4`, `타인압박`, `색정사有`],
  [`색정`, `내녀 색정사 有`, `大`, `개시기`, `도적악의`, `비용과출`],
  [
    `수방실탈(須防失脫)`,
    `소유를 잃게 되는 것을 유의방지해야 하는 상`,
    `大`,
    ``,
    `공격말것`,
    `尊卑불화`,
  ],
  [
    `존비불화(尊卑不和)`,
    `윗 인과 아래 종업원이 불화하는 상-私情 관계 `,
    `小`,
    ``,
    ``,
    ``,
  ],
  [`후원`, `후원조력사`, `小`, ``, ``, ``],
  [
    `송쟁`,
    `외사 급속송쟁파재실종사-상해우려`,
    `大`,
    `5`,
    `급속송쟁`,
    `침체공허`,
  ],
  [`자녀`, `자녀 관련사`, `小`, `배양기`, `執政적의`, `도적악의`],
  [
    `불상조허(不相助虛)`,
    `서로간 돕기로 한 일은 허사가 되고 마는 상`,
    `小`,
    ``,
    `(근무환경`,
    `공격말것`,
  ],
  [
    `발탁도약(拔擢跳躍)`,
    `뽑혀서 영전하여 기개를 펼칠 수 있게 되는 상`,
    `大`,
    ``,
    `좋음)`,
    ``,
  ],
  [`금전`, `금전사업이권 처 관련사`, `大`, ``, ``, `애증화합`],
  [
    `매사 허성불성`,
    `음1,2,10,11월 방문자는 종교진출하면 성공함`,
    `小`,
    `6`,
    `송구경황`,
    `증오손해`,
  ],
  [
    `매사 영속성없음`,
    `금전사업이권 처 관련사는 선천적 기복재해를 겪음`,
    `小`,
    `존재기`,
    `(통정인이`,
    `파재극처`,
  ],
  [
    `관흉상외(官凶常畏)`,
    `관직자는 흉을 만나고 일반인은 두려움을 만나게 되는 상`,
    `大`,
    ``,
    `나타나두`,
    `면모훼손`,
  ],
  [
    `불변근신(不辨謹愼)`,
    `큰 뜻을 이루기 위해 말을 삼가고 근신해야 하는 상`,
    `小`,
    ``,
    `려움)`,
    `執政부족`,
  ],
  [
    `색정`,
    `외남 색정사 有-음2월 결속완성 대재획득 후 부모災害발생`,
    `大`,
    ``,
    ``,
    ``,
  ],
  [
    `매사 허성불성`,
    `음1,2,10,11월 방문자는 종교진출하면 성공함`,
    `小`,
    `7`,
    `색정사有`,
    `결속단합`,
  ],
  [
    `매사 영속성없음`,
    `금전사업이권여인사는 선천적 기복재해를 겪음`,
    `小`,
    `단절기`,
    `改拜之封`,
    `송구경황`,
  ],
  [
    `취재화귀(取財化鬼)`,
    `옳지 못한 금전을 취하면 감옥 가는 상`,
    `大`,
    ``,
    `군자대경`,
    `(통정인이`,
  ],
  [
    `손괴재물(損壞財物)`,
    `금전과 물품이 손실된 상`,
    `小`,
    ``,
    `입각상`,
    `나타남)`,
  ],
  [`후원`, `후원조력침체 관련사`, `小`, ``, `침체공허`, ``],
  [`동요`, `외사 동요불안분산사`, `大`, ``, `동요분산`, `압박받음`],
  [`부친`, `부친 관련사`, `小`, `8`, `허획경구`, `身物수손`],
  [
    `결절구사(決折舊事)`,
    `엣 일을 단절함이 좋고 새 일은 이루어지지 않는 상`,
    `小`,
    `침체기`,
    `(도적 못잡`,
    ``,
  ],
  [
    `개배지봉(改拜之封)`,
    `절을 고쳐서 해야 하는 고위로 부터 직위를 받는 상-입각`,
    `大`,
    ``,
    `고놀램)`,
    ``,
  ],
  [`관사`, `관사 관련사`, `大`, ``, ``, `동요불안`],
  [`동요`, `내부가정 기로-동요불안분산사`, `大`, `9`, `면모훼손`, `출행이동`],
  [`출행`, `내부가정 기로 출행이동사`, `小`, `소외기`, `총애爭乘`, `허획경구`],
  [
    `사다불일(事多不一)`,
    `일은 많으나 서로 뜻이 맞지않아 일치가 안 되는 상`,
    `小`,
    ``,
    `車측근관`,
    ``,
  ],
  [`허획경구(虛獲驚懼)`, `도적은 못잡고 놀래기만 하는 상`, `小`, ``, `련`, ``],
  [`관사`, `관사 관련사`, `大`, ``, ``, ``],
  [`직업`, `직업 관련사`, `大`, `10`, `사안결속`, `수난번민`],
  [
    `결속`,
    `외사 사안결속사-외화내하 함`,
    `小`,
    `침해기`,
    `간음무도`,
    `총애爭乘`,
  ],
  [
    `범모음닉(凡謀陰匿)`,
    `모든 계회이 남모르게 감추는 것인 상`,
    `小`,
    ``,
    ``,
    `(車측근관`,
  ],
  [`간음무도(姦淫無道)`, `음란사가 너무심한 상`, `小`, ``, ``, `련됨)`],
  [
    `결속`,
    `결속사를 음2월 완성하여 大財획득하나 부모 災害 발생함`,
    `小`,
    ``,
    ``,
    ``,
  ],
  [
    `송쟁`,
    `외사 급속송쟁파재실종사-상해우려`,
    `大`,
    `11`,
    `급속송쟁`,
    `입박받음`,
  ],
  [`모친`, `모친 관련사`, `小`, `쇠퇴기`, `私門청원`, `간음무도`],
  [
    `관가상불(官可常不)`,
    `관직자는 대략 좋으나 일반인은 불길한 상`,
    `小`,
    ``,
    `함이 좋음`,
    ``,
  ],
  [`손괴재물(損壞財物)`, `금전과 물품이 손실된 상`, `大`, ``, ``, ``],
  [`형제`, `형제 관련사`, `小`, ``, ``, ``],
  [`출행`, `외사 출행이동원행사`, `小`, `12`, `출행이동`, `급속사有`],
  [`급속`, `내부가정 급속사`, `大`, `과시기`, `송구경황`, `私門청원`],
  [
    `흉악우심(凶惡尤甚)`,
    `흉이 매우 심한 상`,
    `小`,
    ``,
    `(통정인이`,
    `함이 좋음`,
  ],
  [`주사개흉(主事皆凶)`, `주력사가 모두 흉한 상`, `大`, ``, `나타남)`, ``],
  [`녹위`, `직위녹위 관련사`, `小`, ``, ``, ``],
  [`자산`, `자산 관련사`, `小`, `1`, `생업재해`, `가정손해`],
  [`권리`, `권리 관련사`, `大`, `절정기`, `백사俱憂`, `손괴재물`],
  [
    `기피전진(忌避前進)`,
    `맞부딛치 말고 피하라-는 상`,
    `小`,
    ``,
    `(모두근심`,
    ``,
  ],
  [`손괴재물(損壞財物)`, `금전과 재물이 부서진 상`, `大`, ``, `뿐인상)`, ``],
  [`후원`, `후원조력 관련사`, `小`, ``, ``, ``],
  [`막힘`, `외교막힘사`, `大`, `2`, `외교막힘`, `침체경구`],
  [`침체`, `침체경구(驚懼)사`, `小`, `면모기`, `소식대기`, `백사俱憂`],
  [
    `기리전진(豈利前進)`,
    `어찌 이익만 보고 나아가려고만 하는가-의 상-혼인은 좋음`,
    `小`,
    ``,
    `(와도나쁨)`,
    `(모두 근심`,
  ],
  [
    `고위면접(高位面接)`,
    `토서헌책으로 고위인을 마중하게 되는 상 `,
    `大`,
    ``,
    ``,
    `뿐인상)`,
  ],
  [`박관`, `직업직위 불안사`, `小`, ``, ``, ``],
  [`극부`, `남편 갈등사-가정막힘`, `小`, `3`, `급속사有`, `가정막힘`],
  [`급속`, `외사 급속사`, `大`, `하습기`, `尊卑상몽`, `면모훼손`],
  [
    `전도흠녕(前途欠寧)`,
    `앞으로 가려하는 전도의 안녕사에 흠이 있게 되는 상`,
    `大`,
    ``,
    `(신분나이 `,
    `소식대기`,
  ],
  [
    `음신조격(音信阻隔)`,
    `소식을 기다리나 오지 않는 상-와도 나쁨`,
    `小`,
    ``,
    `음란)`,
    `(와도나쁨)`,
  ],
];
const columnZ = [
  [``, ``, 0, ``, ``],
  [`단계`, `단계`, 0, `가능`, `불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明시작`, ``, 0, ``, `직업직위남편갈등색정사로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明종료`, ``, 0, `후원문서송쟁자녀사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, ``, `세속허사자로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明하향`, ``, 0, `후원문서부친조력사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗절정`, 0, ``, `관사직업가정동요충행사로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗시작`, 0, ``, `관사직업사로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗종료`, 0, `후원문서송쟁모친조력사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `형제친구동료조력출행사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明진행`, ``, 0, `직녹자산권리사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, `暗진행`, 0, `후원막힘침체투서헌책고위면접사로가능`, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [`明절정`, ``, 0, ``, `지업직위남편갈등급속사로불가`],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
  [``, ``, 0, ``, ``],
];
const columnAF = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];
const columnAS = [
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [0],
  [2],
  [0],
  [0],
  [0],
  [0],
  [3],
  [0],
  [0],
  [0],
  [0],
  [4],
  [0],
  [0],
  [0],
  [0],
  [5],
  [0],
  [0],
  [0],
  [0],
  [6],
  [0],
  [0],
  [0],
  [0],
  [7],
  [0],
  [0],
  [0],
  [0],
  [8],
  [0],
  [0],
  [0],
  [0],
  [9],
  [0],
  [0],
  [0],
  [0],
  [10],
  [0],
  [0],
  [0],
  [0],
  [11],
  [0],
  [0],
  [0],
  [0],
  [12],
  [0],
  [0],
  [0],
  [0],
  [1],
];
const columnAU = [
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게속전속결하는 것이 유리함`,
    0,
    0,
    `명분이 있는 사안이므로 신속히 종결해야만 하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,
    0,
    0,
    `1녀2남 삼각관계有**일이 거꾸로 진행됨**산속으로 깊이 숨소싶은 심정임**여건조건이 부족불비함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게속전속결하는 것이 유리함`,
    0,
    0,
    `명분이 있는 사안이므로 신속히 종결해야만 하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,
    0,
    0,
    `일이 옛 것으로 되돌아가는 상**일이 거꾸로 띄엄띄엄 진행되나 정상임`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게속전속결하는 것이 유리함`,
    0,
    0,
    `명분이 있는 사안이므로 신속히 종결해야만 하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,
    0,
    0,
    `신규사가 있음**일이 갑자기 발생함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,
    0,
    0,
    `풍상고초 후 성공할 人`,
    0,
    0,
    `복잡다단한 일을 하나로 묶으려 함-음2월 또는 7,8월에 완성-大財 획득 후 부모 근심 발생함**아래 사람이 윗사람을 핍박함**가택이사예정`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응 유화적으로 사안을 지체시켜 후로 미루어야만 유리함`,
    0,
    0,
    `역상적 사안으로 억울하더라도 유순한 자세로 따지지말고 물러서서 후결하는 것으로 하면 반드시 유리해지는 人 `,
    0,
    0,
    `정대한 엣 일들이 단절 됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `동요불안분산배신**만약 타인과 다툰다면 간신이 있고 천지가 뒤집힌 격이므로 화가 밖에서 오니 신중해야 함`,
    0,
    0,
    `반복무상하고 내부가정사의 기로에 서있는 처지이 人`,
    0,
    0,
    `신규사가 있으나 본인이 직접 나서면 안 되고 타인을 앞세워야 함**어떤 일도 오래가지 못함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `순응유화유순후결**만약 타인과 다툰다면 절대 맞서지 말고 순응 유화적으로 사안을 지체시켜 후로 미루어야만 유리함`,
    0,
    0,
    `역상적 사안으로 억울하더라도 유순한 자세로 따지지말고 물러서서 후결하는 것으로 하면 반드시 유리해지는 人 `,
    0,
    0,
    `군자가 때를 기다리는 격이나 결과는 없고 반복사를 겪고 있으며 점점 기우는 상**독신고독각방주말부부생리사별적 처지임`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `금전여인의혹질투**만약 타인과 다툰다면 상대는 항복하거나 화해의 뜻을 갖고 있음`,
    0,
    0,
    `저주소송의 조짐이 있는 人`,
    0,
    0,
    `복잡다단한 사안을 하나로 묶는 결속사를 음2월 또는 7,8월에 완성하여 大財를 획득하나 부모신변의 근심이 발생함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `호가호위(狐假虎威)세**만약 타인과 다툰다면 상대는 호가호위 세를 부릴 뿐 공격의사가 없어 피해도 없고 있다해도 가벼움`,
    0,
    0,
    `옳지 못한 일이 밝혀져 천둥에 놀란 듯한 심중이나 상대는 호가호위 세를 부릴 뿐이므로 종용히 있으면 두려움이 자연 사라지게 되는 人`,
    0,
    0,
    `신규사가 있음**일이 결과없이 반복됨`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `간난험악고초**만약 타인과 다툰다면 근심걱정을 풀기 어려움으로 신중해야 하고 일을 뒤로 미루는 것이 현명함`,
    0,
    0,
    `풍상고초 후 성공할 人`,
    0,
    0,
    `일이 띄엄띄엄 진행됨**출행의 상**입을 닫아야 하는 입장**방문전화가 여름철이면 여자가 남자를 적극적으로 유혹하여 함께 도망칠 속심이 있음`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `강건정당속결**만약 타인과 다툰다면 먼저 나서서 정당하게속전속결하는 것이 유리함`,
    0,
    0,
    `명분이 있는 사안이므로 신속히 종결해야만 하고 좌고우면하거나 뒤로 미루면 되려 불리해지는 人`,
    0,
    0,
    `1남2녀 삼각관계有**일이 순서적으로 나아가는 듯하나 거꾸로 됨**입을 닫아야 할 입장임**조건여건이 부족불비함`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [
    `부동신음(不動呻吟)**만약 타인과 다툰다면 상대는 기회를 노리고 움직이지는 않음`,
    0,
    0,
    `스스로 유약심을 갖고 적극적인 활동을 하지 않으며 신음하는 人-신체의 자유가 억압된 경우도 있음`,
    0,
    0,
    `오고 가지 못하는 상**산으로 깊이 숨고싶은 심정 임`,
    0,
    0,
  ],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
  [``, 0, 0, ``, 0, 0, ``, 0, 0],
];
const columnBE = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `충성`,
    `악성`,
    `거짓`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `아권리가져갈인`,
    `아발동후회사`,
    `기사`,
    `신구중간사`,
    `외사`,
    `이면사`,
    `좌고우면`,
    `선성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익인-비용출비`,
    `타발동급속사`,
    `기사`,
    `신사`,
    `내사`,
    `이면사`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이득인`,
    `타발동사`,
    `미사`,
    `구사`,
    `내사`,
    `표면사`,
    `충성`,
    `악성`,
    `거짓`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동사`,
    `기사`,
    `신구중간사`,
    `내사`,
    `표면사`,
    `좌고우면`,
    `선성`,
    `진위반반`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `피해인`,
    `타발동사`,
    `기사`,
    `신사`,
    `내사`,
    `표면사`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익인`,
    `아 발동사`,
    `기사`,
    `신구중간사`,
    `외사`,
    `표면사`,
    `충성`,
    `악성`,
    `위선`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동급속사`,
    `미사`,
    `구사`,
    `내사`,
    `이면사`,
    `좌고우면`,
    `선성`,
    `위선`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `무력인`,
    `타발동급속사`,
    `기사`,
    `신사`,
    `내사`,
    `이면사`,
    `배신`,
    `보성`,
    `위선`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `이익인`,
    `타발동사`,
    `기사`,
    `구사`,
    `내사`,
    `표면사`,
    `충성-무력`,
    `악성`,
    `거짓`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동급속사`,
    `기사`,
    `구사`,
    `내사`,
    `이면사`,
    `좌고우면-무력`,
    `선성`,
    `거짓`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `손해인`,
    `타발동사-급속사겹침`,
    `미사`,
    `신사`,
    `내사`,
    `표면사이면사겹침`,
    `배신`,
    `보성`,
    `진실`,
  ],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnBO = [
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠-공허`, `쇠-공허`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `쇠-공허`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `쇠`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`쇠`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
  [`흥`, `흥`],
  [``, ``],
  [``, ``],
  [``, ``],
  [``, ``],
];
const columnBS = [
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`승`, `승`, `승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`무력`, `무력`, ` 무력`],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, ` 승`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [`  승`, `승`, `  승`],
  [`화해됨`, `화해됨`, `화해됨`],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [``, ``, ``],
  [` 비김`, `비김`, ` 비김`],
  [``, ``, ``],
  [``, ``, ``],
];
const columnBV = [
  // [0,0,0,``,0,0,0,``],
  // [용모,가문,合6,``,용모,가문,合6,``],
  // [0,0,0,``,0,0,0,``],
  [2, 3, 5, `음5월`, 3, 2, 5, `음11월-연기우려`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 2, 4, `음4월`, 2, 2, 4, `음10월-연기우려`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음3월`, 3, 2, 5, `음9월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음10월-연기 또는 파혼우려`, 3, 1, 4, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 2, 4, `음5월`, 3, 2, 5, `음5월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 5, `음4월`, 2, 2, 4, `음4월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음3월`, 3, 3, 6, `음1월-연기우려`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 2, 5, `음6월-연기 또는 파혼우려`, 3, 1, 4, `음12월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음3월`, 2, 1, 3, `음9월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 1, 3, `음10월`, 2, 1, 3, `음10월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [2, 2, 4, `음7월`, 2, 3, 5, `음1월-연기우려`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [3, 3, 6, `음6월`, 2, 2, 4, `음12월`],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
  [0, 0, 0, ``, 0, 0, 0, ``],
];
const columnCE = [
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인품이 훌륭하고 업무도 수준을 유지함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인품이 훌륭하나 업무는 저조함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`성격이 폭력적이고 조급하여 업무를 전반적으로 실패함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `고집이 세고 여러 복잡한 일을 일관시키는 결속을 단행하여 음2월 도는 7,8월에 완성시켜 재정을 크게 확보 함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인품이 훌륭하고 업무도 수준을 유지하나 기존의 관계성이 단절됨`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `인정미는 있으나 사심이 있어 업무 전반이 안정되지 목하고 동요불안분산배신사를 일으킴`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `성격이 온순하고 준수 미모이나 업무가 강하지 못해 초반을 허무하게 보내고 중 종반에 수준을 유지함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `고집이 세고 여러 복잡한 일을 일관시키는 결속을 단행하여 음2월에 완성시켜 음2월 또는 7,8월에 재정을 크게 확보 함`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `사교성이 좋아 동료의 협조를 얻으나 대외 내부 조건이 무력하여 노력에 비해 업무 수준이 미미함 `,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`고집이 세서 친소가 분명하여 업무 전반이 크게 저조함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [`인격이 훌륭하고 내외적 치목이 유별하나 업무는 실패함`, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [
    `고집이 세서 업무를 본인 위주로 진행하다가 종 후반에 송사를 일으키고 매우 불안한 심정이 됨`,
    0,
    0,
    0,
    0,
    0,
  ],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
  [``, 0, 0, 0, 0, 0],
];
const columnCL = [
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [``, ``, ``, ``, ``, ``, ``, ``, ``, ``, `낮`, `밤`],

  [
    `1.2월`,
    `月\n적용`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n선택적`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
    `月\n무시`,
  ],
  [`4.5월`, `集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `반대없음`,
    `불이행`,
    `선이후이`,
    `進向`,
    `근`,
    `留`,
    `주인가해`,
    `정서`,
    ``,
    `정남`,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],

  [`1.2월`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ` 多集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `중반제압`,
    `불이행`,
    `선이후이`,
    `進向`,
    `  근`,
    `  留`,
    `주인조력`,
    `북북서`,
    ``,
    `남남서`,
  ],
  [`10.11`, ``, `失期불가`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],

  [`1.2월`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ` 多集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `본인제압`,
    `이행`,
    `선이후費`,
    `進向`,
    `  근`,
    `  留`,
    `주인조력`,
    `정북`,
    ``,
    `북서`,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],

  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, `  集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `본인반대`,
    `불이행`,
    `선이후이`,
    `退背`,
    `  원`,
    `  去`,
    `주인가해`,
    `북북동`,
    `정서`,
    ``,
  ],
  [`10.11`, ``, `발현안됨`, ``, ``, ``, ``, ``, `주인이사`, ``, ``, ``],
  [`3.6\n9.12`, `多集`, ``, ``, ``, ``, ``, ``, `참고`, ``, ``, ``],
  [`1.2월`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ` 多集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `본인동조`,
    `불이행`,
    `선난후이`,
    `退背`,
    `  근`,
    `  去`,
    `세입가해`,
    `북북동`,
    `북서`,
    ``,
  ],
  [`10.11`, ``, `측근제압`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ` 多集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `극렬함`,
    `이행`,
    `선난후이`,
    `進向`,
    `  원`,
    `  留`,
    `세입가해`,
    `정북`,
    `동남`,
    ``,
  ],
  [`10.11`, ``, `본인제압`, ``, ``, ``, ``, ``, ``, ``, `세밀심사`, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, `함`, ``],
  [`1.2월`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `반대없음`,
    `불이행`,
    `본인무력`,
    `退背`,
    `  근`,
    `  去`,
    `주인가해`,
    `동남`,
    `정북`,
    ``,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `반대없음`,
    `불이행`,
    `본인사안`,
    ` 退背`,
    `  근`,
    ` 去`,
    `주인무력`,
    `남남동`,
    `북북동`,
    ``,
  ],
  [`10.11`, ``, ``, ``, `모두무력`, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, `  集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, `  集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `본인제압`,
    `이행`,
    `사안무력`,
    `進向`,
    `  원`,
    `  留`,
    `주인무력`,
    `남남동`,
    `정남`,
    ``,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, `  集`, `반대있음`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `기세없음`,
    `불이행`,
    `선이후이`,
    `退背`,
    `  원`,
    ` 去`,
    `주인가해`,
    `남남서`,
    ``,
    `정남`,
  ],
  [`10.11`, ``, `본인제압`, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ` 多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ` 集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    ``,
    `반대없음`,
    `불이행`,
    `선損후費`,
    `退背`,
    `  원`,
    `  去`,
    `무난`,
    `서남`,
    ``,
    `동남`,
  ],
  [`10.11`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ` 多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`1.2월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`4.5월`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [
    `7.8월`,
    `  集`,
    `반대없음`,
    `불이행`,
    `선난후攻`,
    `進向`,
    `  근`,
    `  留`,
    `무난`,
    `정서`,
    ``,
    `남남동`,
  ],
  [`10.11`, ` 多集`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
  [`3.6\n9.12`, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``, ``],
];
const columnCY = [
  [``, ``, ``, ``, 0, ``, 0, ``],
  [``, ``, ``, ``, 0, ``, 0, ``],
  [``, ``, ``, ``, 0, ``, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `제례부정`, "제례위법", `육친`, 0, ``],
  [`질병`, `신부정귀`, `신不定`, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, `도로귀`, `교통사망`, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, `제례귀`, `제례위범`, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `상가부정`, "조문왕래", `육친`, 0, ``],
  [`질병`, `제례귀`, `제례위범`, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ` 수귀`, `익사`, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, `제례귀`, `제례위범`, ``, 0, `육친`, 0, ``],
  [`질병`, `수귀`, `익사`, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `음사부정`, "제사잘못", `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, `제례귀`, `제례위범`, ``, 0, `육친`, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, `부정물入`, "금속類", `육친`, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, ` 燒身`, ``, 0, `묘좌`, 0, `특별있음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, `자살귀`, `燒身`, ``, 0, `묘좌`, 0, `있음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, ``, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, 0, `없음`],
  [``, ``, ``, ``, 0, `묘향`, 0, ``],
  [`생업`, ``, ``, `상가부정`, 0, `전경`, 0, ``],
  [``, ``, ``, ``, 0, `묘속`, 0, ``],
  [`가정`, ``, ``, ``, 0, `육친`, 0, ``],
  [`질병`, ``, ``, ``, 0, `묘좌`, `북북서`, `없음`],
  [``, ``, ``, ``, 0, `묘향`, `남남동`, ``],
  [`생업`, ``, ``, `제례부정`, "제례위범", `전경`, "바다포구", ``],
  [``, ``, ``, ``, 0, `묘속`, "水", ``],
  [`가정`, ``, ``, `상가부정`, "조문왕래", `육친`, "모친", ``],
];
const columnDH = [
  [
    `출생연도`,
    `24`,
    `96`,
    `25`,
    `97`,
    `26`,
    `98`,
    `27`,
    `99`,
    `28`,
    `00`,
    `29`,
    ``,
    `30`,
    `102`,
    `19`,
    `91`,
    `20`,
    `92`,
    `21`,
    `93`,
    `22`,
    `94`,
    `23`,
    `95`,
  ],
  [
    0,
    `36`,
    ``,
    `37`,
    ``,
    `38`,
    ``,
    `39`,
    ``,
    `40`,
    ``,
    `41`,
    ``,
    `42`,
    ``,
    `31`,
    ``,
    `32`,
    ``,
    `33`,
    ``,
    `34`,
    ``,
    `35`,
    ``,
  ],
  [
    0,
    `48`,
    ``,
    `49`,
    ``,
    `50`,
    ``,
    `51`,
    ``,
    `52`,
    ``,
    `53`,
    ``,
    `54`,
    ``,
    `43`,
    ``,
    `44`,
    ``,
    `45`,
    ``,
    `46`,
    ``,
    `47`,
    ``,
  ],
  [
    0,
    `60`,
    ``,
    `61`,
    ``,
    `62`,
    ``,
    `63`,
    ``,
    `64`,
    ``,
    `65`,
    `01`,
    `66`,
    `02`,
    `55`,
    `03`,
    `56`,
    `04`,
    `57`,
    `05`,
    `58`,
    ``,
    `59`,
    ``,
  ],
  [
    0,
    `72`,
    `08`,
    `73`,
    `09`,
    `74`,
    `10`,
    `75`,
    `11`,
    `76`,
    `12`,
    `77`,
    `13`,
    `78`,
    `14`,
    `67`,
    `15`,
    `68`,
    `16`,
    `69`,
    `17`,
    `70`,
    `06`,
    `71`,
    `07`,
  ],
  [
    0,
    `84`,
    `20`,
    `85`,
    `21`,
    `86`,
    `22`,
    `87`,
    `23`,
    `88`,
    `24`,
    `89`,
    `25`,
    `90`,
    `26`,
    `79`,
    `27`,
    `80`,
    `28`,
    `81`,
    `29`,
    `82`,
    `18`,
    `83`,
    `19`,
  ],
  [
    2,
    `외사 급속사`,
    `소식대기-와도 나쁨`,
    `내녀 색정사`,
    `모두 근심사 뿐`,
    `외사 송쟁파재극처실종사-상해우려`,
    `모사 미수사`,
    `매사 허성불성-세속허사자**내사 애증가출사`,
    `음1,2,11월 방문자는 종교진출하면 성공함`,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `매사 위역사`,
    `문서훼손사`,
    `내사 출행이동사`,
    `장차 위해 불변근신`,
    ``,
    `발탁영전도약희사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `송구경황사-통정인 출현-두려움`,
    `내사 급속사-내사기로출행이동사`,
    `집정기거좋음`,
    `직녹자산권리사`,
    `도적악의-공격말 것`,
    ``,
    `尊卑불화사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `전화위복`,
    `전도**빈궁**병재`,
    ``,
    `전도**빈궁`,
    ``,
    ``,
    `고발투서헌책사`,
    `빈궁`,
    `사사성취-부족`,
    `관재`,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    ``,
    `빈궁**병재`,
    ``,
    `빈궁`,
    ``,
    `침체驚懼사`,
    `관재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    3,
    ``,
    `尊卑불화사`,
    `외사 급속사`,
    `소식대기-와도 나쁨`,
    `내녀 색정사`,
    `모두 근심사 뿐`,
    `외사 송쟁파재극처실종사-상해우려`,
    `모사 미수사`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면 자립함`,
    `매사 위역사`,
    `문서파기사`,
    `내사 출행이동사`,
    `장차위해 불변근신`,
    ``,
    `발탁영전도약희사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `송구경황사-통정인나타남-두려움`,
    `내사 급속사-내사 기로출행이동사`,
    `집정기거좋음`,
    `직녹자산권리사`,
    `도적악의-공격말 것`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `관재`,
    ``,
    `재액해소**전화위복`,
    `빈궁**병재`,
    ``,
    `빈궁`,
    ``,
    ``,
    `고발투서헌책사`,
    `빈궁`,
    `사사성취-부족`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    ``,
    `전도**빈궁**병재`,
    ``,
    `전도**빈궁`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    4,
    `직녹자산권리사`,
    `도적악의-공격말 것`,
    ``,
    `尊卑불화사`,
    `외사 급속사`,
    `소식대기-와도 나쁨`,
    `내녀 색정사`,
    `모두 근심사 뿐`,
    `외사 송쟁파재극처실종사-상해우려`,
    `모사 미수사`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `매사 위역사`,
    `문서파기사`,
    `내사 출행이동사`,
    `장차위해 불변근신`,
    ``,
    `발탁영전도약희사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `송구경황사-통정인나타남-두려움`,
    `내사 급속사-내사기로출행이동사`,
    `집정기거좋음`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `전도**빈궁`,
    ``,
    ``,
    ``,
    `재액해소**전화위복`,
    `빈궁**병재`,
    `재액해소`,
    `빈궁`,
    ``,
    ``,
    `고발투서헌책사`,
    `빈궁`,
    `사사성취-부족`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    ``,
    ``,
    `전도**빈궁**병재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    5,
    `상대진심情불측사`,
    `내사 급속사-내사기로출행이동사`,
    `퇴가 좋고 전진 나쁨`,
    `직녹자산권리사`,
    `왕래사송계류사-퇴피길함`,
    `매사 위역사`,
    `외사 급속사`,
    `필유성취사`,
    `고시낙제-사송무방사`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `화소복지사`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `매사 위역사**총애쟁乘사`,
    `문서파기사`,
    `내사 출행이동사`,
    `도둑 못잡고 놀램`,
    `입각 대경사`,
    ``,
    `외사 송쟁파재극처실종사-상해우려`,
    `손괴재물사-이동사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `빈궁**병재`,
    ``,
    ``,
    ``,
    `빈궁`,
    ``,
    `전화위복`,
    `전도`,
    ``,
    `전도**빈궁`,
    ``,
    `빈궁`,
    `私門청원이 좋음`,
    ``,
    `간음무도사`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    `빈궁**관제**병재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    6,
    `손괴재물사-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `상대짐심情불측사`,
    `내사 급속사-내사 기로출행이동사`,
    `퇴가 좋고 전진 나쁨`,
    `직녹자산권리사`,
    `왕래사송계류사-퇴피 길함`,
    `매사 위역사`,
    `필유성취사`,
    `외사 급속사`,
    `고시낙제-사송무방사`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종-상해우려`,
    `화소복지사`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매시 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `매사 위역사`,
    `총애쟁乘사`,
    `도둑 멋잡고 놀램`,
    `내사 출행이동사`,
    `입각 대경사`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `관재**빈궁**병재`,
    ``,
    `전도**빈궁**병재`,
    ``,
    `전도**관재`,
    ``,
    `빈궁`,
    ``,
    `재액해소**전화위복`,
    ``,
    `재액해소`,
    `빈궁**관재`,
    ``,
    `빈궁`,
    `私門청원이 좋음`,
    ``,
    `간음무도사`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    7,
    `입각 대경사`,
    ``,
    `손괴재물사-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `상대진심情불측사`,
    `내사 급속사-내사 기로출행이동사`,
    `퇴가 좋고 전진 나쁨`,
    `직녹자산권리사`,
    `왕래사송계류사-퇴피 길함`,
    `매사 위역사`,
    `필유성취사`,
    `외사 급속사**재액해소`,
    `고시낙제-사송무방사`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `화소복지사`,
    `매사 영속성없음**내사 애증가출사**私門청원好`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사**간음무도사`,
    `종교진출하면자립함`,
    `총애쟁乘사`,
    `매사 위역사`,
    `도둑 못잡고 놀램`,
    `내사 출행이동사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `음4,5월화재주의**동요분산사`,
    `관재`,
    `동요불안분산사`,
    `빈궁**병재`,
    `동요불안분산사`,
    `전도**관재**병재`,
    `동요불안분산사`,
    `전도**관재`,
    `동요불안분산사`,
    `빈궁`,
    `동요불안분산사**전화위복`,
    `관재`,
    `동요불안분산사**재액해소`,
    `빈궁**관재`,
    `동요불안분산사`,
    `빈궁`,
    `동요불안분산사`,
    ``,
    `동요불안분산사`,
    ``,
    `동요불안분산사**전화위복`,
    `빈궁`,
    `음4,5월화재주의**동요분산사`,
    `빈궁**관재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    8,
    `도둑 못잡고 놀램`,
    `내사 출행이동사`,
    `입각 대경사`,
    ``,
    `손괴재물사-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `상대진심情불측사`,
    `내사 급속사-내사 기로출행이동사`,
    `퇴가 좋고 전진 나쁨`,
    `직녹자산권리사`,
    `왕래사송계류사-퇴피 길함`,
    `매사 위역사`,
    `필유성취사`,
    `외사 급속사`,
    `고시난제-사송무방사`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `화소복지사`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사**간음무도사`,
    `종교진출하면자립함`,
    `총애쟁乘사`,
    `매사 위역사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    `전도**빈궁**병재`,
    `빈궁**관재**병재`,
    `재액해소`,
    ``,
    `재액해소`,
    `전도**빈궁`,
    ``,
    `전화위복`,
    `관재`,
    ``,
    `빈궁`,
    ``,
    `전도**빈궁`,
    `私門청원이 좋음`,
    ``,
    ``,
    ``,
    `전화위복`,
    `전도**빈궁**관재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    9,
    `총애쟁乘사`,
    `매사 위역사`,
    `도둑 못잡고 놀램`,
    `내사 출행이동사`,
    ``,
    `손괴재물사-이동사`,
    `입각 대경사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `상대진심情불측사`,
    `내사 급속사-내사 기로출행이동사`,
    `퇴가 좋고 전진 나쁨`,
    `직녹자산권리사`,
    `왕래사송계류사-퇴피 길함`,
    `매사 위역사`,
    `필유성취사`,
    `외사 급속사`,
    `고시낙제-사송무방사`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `화소복지사`,
    `매사영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사**간음무도사`,
    `종교진출하면자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `전화위복`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    `빈궁**병재`,
    ``,
    `빈궁**병재`,
    ``,
    ``,
    `빈궁`,
    ``,
    `전화위복`,
    `전도`,
    ``,
    `전도**빈궁`,
    ``,
    `빈궁`,
    `私門청원이 좋음`,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    10,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `문서파기사`,
    `매사 위역사`,
    `주력사 흉함`,
    `내사 출행이동사`,
    ``,
    `타인사건연루사`,
    `모망사 미수사-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `상대진심情불측사`,
    `내사 급속사-내사 기로출행이동사`,
    `가정가족불녕-절傷사`,
    `직녹자산권리사`,
    ``,
    `尊卑불화사`,
    `도둑잡기 어려움`,
    `외사 급속사`,
    `妾婦 상호간 무시함`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `송구경황사-통정인나타남-두려움`,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `재난불측사`,
    `빈궁`,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `관재`,
    `음4,5월화재주의`,
    `빈궁**관재`,
    ``,
    ``,
    `전도**빈궁**병재`,
    ``,
    `전도**빈궁**병재`,
    ``,
    ``,
    ``,
    `전화위복**재액해소`,
    `빈궁`,
    `빈궁`,
    `재액해소`,
    ``,
    ``,
    `私門청원이 좋음`,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    11,
    `매사 영속성없음**내사 애증가출사`,
    `종교진출하면자립함`,
    `매사 영속성없음**외남 색정사`,
    `종교진출하면자립함`,
    `매사 위역사`,
    `총애쟁乘사`,
    `비곡차욕사-숨겨진 일이폭로됨`,
    `내사 출행이동사`,
    ``,
    `발탁영전도약희사`,
    `봉림유蛇격-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `고시득길사-괴이송사`,
    `내사 급속사-내사 기로출행이동사`,
    `간음사이익-정대즉앙화`,
    `직녹자산권리사`,
    `매사 위역사`,
    `왕래사송계류사-퇴피 길함`,
    `외사 급속사`,
    `비상지愛사`,
    `모두 근심 뿐`,
    `내녀 색정사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `손괴재물사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `꾸며낸거짓이간질사`,
    ``,
    `제사불리-도난사`,
    `빈궁`,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    `빈궁`,
    ``,
    `빈궁**병재`,
    ``,
    ``,
    `빈궁`,
    ``,
    `전화위복`,
    `전도`,
    ``,
    `전도**빈궁`,
    ``,
    `빈궁**관재**병재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    12,
    `외사 송쟁파재극처실종사-상해우려`,
    `모사 미수사`,
    `매사 영속성없음**내사 애증가출사**고발투서헌책사`,
    `종교진출하면자립함`,
    `매사 허성불성-세속허사자**외남 색정사`,
    `음1,2,10,11월 방문자는 종교진출하면 성공함`,
    `매사 위역사`,
    `문서파기사`,
    `장차를 위한 불변근신`,
    `내사 출행이동사`,
    ``,
    `발탁영전도약희사`,
    `송구경황사-치부 아는 자 출현-두려움-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `집정기거좋음`,
    `내사 급속사-내사 기로출행이동사`,
    `도적악의-공격말 것`,
    `직녹자산권리사`,
    ``,
    `尊卑불화사`,
    `외사 급속사`,
    `소식대기-와도 나쁨`,
    `모두 근심 뿐`,
    `내녀 색정사`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `전도**관재`,
    ``,
    `재액해소`,
    `빈궁`,
    `사사성취-부족`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월 화재주의**재액해소`,
    `빈궁**관재`,
    `음4,5월화재주의`,
    `관재`,
    ``,
    ``,
    `빈궁**병재`,
    `재액해소`,
    `빈궁`,
    ``,
    ``,
    ``,
    `빈궁**병재`,
    `폐한인**전화위복**재액해소`,
    ``,
    `전도**빈궁`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    1,
    `내녀 색정사`,
    `모두 근심 뿐`,
    `외사 송쟁파재극처실종사-상해우려`,
    `모사 미수사`,
    `매사 허성불성-세속허사자**내사 애증가출사`,
    `음1,2,10,11월 방문자는 종교진출하면 성공함`,
    `매사 허성불성-세속허사자**외남 색정사`,
    `음1,2,10,11월 방문자는 종교진출하면 성공함`,
    `매사 위역사`,
    `문서파기사`,
    `장차를 위한 불변근신`,
    `내사 출행이동사`,
    ``,
    `발탁영전도약희사`,
    `송구경황사-치부 아는자 출현-두려움-이동사`,
    `외사 송쟁파재극처실종사-상해우려`,
    `집정기거좋음`,
    `내사 급속사-내사 기로출행이동사`,
    `도적악의-공격말 것`,
    `직녹자산권리사`,
    ``,
    `尊卑불화사`,
    `외사 급속사`,
    `소식대기-와도 나쁨`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
  [
    0,
    `빈궁`,
    ``,
    ``,
    ``,
    `고발투서헌책사`,
    `빈궁`,
    `사사성취-부족`,
    ``,
    `전화위복`,
    `빈궁`,
    `음4,5월화재주의`,
    `전도**빈궁**관재`,
    `음4,5월화재주의`,
    `전도**관재`,
    ``,
    ``,
    ``,
    `빈궁**병재`,
    ``,
    `빈궁`,
    ``,
    ``,
    `전화위복`,
    `빈궁**병재`,
  ],
  [
    0,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
  ],
];

const CHOSA1 = [
  `■막히고 폐기되고 관재걱정     때문에 속깨나 타겠네? 
  마음만 바쁘겠고… 
  돕는자가 생겨도 독이 
  숨어 있고…
⊖집에 든 상가부정을 해소시    키고 일을 바꿔서 하면 좋    게 되겠어!
⊕⊖신변신화선영부정침범했네?
`,
  `■ 한 여자에 남자가 둘이네?     관재고심이 크겠어 !
   여자가 괴롭겠어!

⊕⊖가택신화선영 부정도 
    들어 있고…


⊕신변상가부정이 침범했어 !
`,
  `■ 돈을 써야 재액을 면하게     생겼는데 돈이 없는 격일세?     이권상실사도 생겼고 
   집도 넘어가는데 
⊕가택상가부정으로 
  病者까지 있겠어!
⊕전흉사귀, 
⊖신부정귀를 잡는게 급하겠고!
`,
  `■ 구슬이 서말인데 꿰어지      지가 않겠어! 
    돕는자도 흉계를 감추고      있고…
    이권도 상실하겠어!
⊕수귀, 
⊖도로귀 해소가 길이야!

⊕신변상가부정도 침범했네?
`,
  `■ 변동수가 있네 ? 
   빨리 서둘러야 좋겠어! 
   그런데 육친골육이 등 
   돌리고 있네? 상극하야 !

⊕가택상가부정ㆍ신불제사귀, ⊖신부정귀를 없애지 않으면    일이 안 풀리겠어!
`,
  `■ 지금까지 일과 인연들이     모두 끝나는 운세네? 
   연연하지 말고 

⊕신불제사귀ㆍ수귀,
⊖신부정귀ㆍ여귀를해소시키고    새 일로 출발하면 좋겠어!
`,
  `■말이 뛰어도 다리가 부러지는 형국이네?색정불화에반복왕래에 결실이 없겠어 !
산은 높고 골짜기는 깊고열을 잃고 겨우 하나를 건지는 운세야!
⊕수귀, 
⊖여귀 잡는게 더 급하네!
⊕⊖신변여고음강부정도침범
    했네 ?
`,
  `■ 돈도 이권도 상실하는 
   운세네? 
   돈이고 여자고 모두 말     뿐이고 실속이 없게 
   생겼어!

⊕⊖가택에 여고음강 부정이      잔뜩 끼어서 그러네!
`,
  `■ 의심 질투외 모두 텅 
   비었는데 이럴까 저럴까     망설여본들 뾰족한 수가     있겠어?여러건을 하나로     묶겠는데
   약 주고 병주는 숨은 귀신
⊕전흉사귀, 
⊖도로귀나 잡고 앞을 봐야지!
`,
  `■ 천둥이 친다고 다 벼락      맞지는 않지!
   입만 꼭 다물고 

⊕자살귀, 
⊖여귀를 쫓아버리면 걱정이    없겠어!

⊖신변상가부정이 침범했네?
`,
  `■ 가정은 침침하고, 금전은     비어버리고…
   이권상실사도 생겼고 !
男：⊖집 밑에 해골이 있네?
女：⊕남의 남자를 꿰차고 집         나서게 생겼네?
      여귀 장난이네
이게 다 ⊕자살귀, 
        ⊖가택상가부정이 듬.?
`,
  `■ 시작은 좋았는데 끝이 
   안보이네? 1男 2女 삼각     관계도 있겠고…
   만사 허실이고, 이권까지     상실할 운세야! 
   기도ㆍ치성만이 사는 길     이네!

⊖신변상가부정이 침범했어!`,
];
const YOGYEOL1 = [
  `■ 나를 돕는 것이 특별하나 처음에는 손해를 입는다. 나쁜 일로 움직이게 되니 편안하지는 못하겠다.
  `,
  `■ 내 녹을 범과 도적이 가져간다. 좋게 맺어져 미워하지는 않는다. 밤정단은 종업원이 해를 끼치고 동업이나 교섭사는 당장은 좋다.
  `,
  `■ 돕는 척 하면서 나를 해치려하나 별 수는 없다. 원망과 미운 마음이 생기나 모든 것이 허망하게 된다.
  `,
  `■ 말도 뛰고 정신도 뛰지만 놀래고 손해날 일만 있다. 군자는 매사가 안 풀리고 서민은 근심스러운 일이 생기리라.
  `,
  `■ 사람과 집에 모두 재앙이 생긴다. 도적이나 귀신이 돈을 뺏어간다. 귀인에게 돈을 바치는 것은 매우 좋아 목적을 이루리라.
  `,
  `■ 칡을 캐려면 머리를 찾아야 하는데 어두운데 숨어 찾기가 어렵다. 
  밤정단은 관살에 도적이 붙고 집에도 숨은 귀살이 있다.
  `,
  `■ 두 귀인이 극을 받고 나를 돕는이도 힘을 못쓸 사정이다.
  깨끗지 못한 자가 돕기는 하나 열을 잃고 하나를 얻는 격이다.
  `,
  `■ 도적맞는 일이 생기고 밤정단은 귀인이 돕지 못한다. 나무가 썩어 바퀴를 못 만들고 꾀하는 일은 간사스러움이 있다.
  `,
  `■ 낮정단은 숨은 귀신이 범을 타고 오니 남이 막아줄까 믿지마라. 동업도 교섭사도 무슨 이득이 될까. 돈은 못 벌고 재앙만 생기겠다.
  `,
  `■ 말타고 활을 쏘니 그 화살에는 맞지 않겠다.
  화살 맞는 것은 면하나 범이 묘에 올라 부르고 있다.
  `,
  `■ 재물과 일덕이 공망되고 범이 묘위에 앉아 있으니 질병 문제는 매우 나쁘고 혼이 몸밖으로 빠져나갈까 걱정이다.
  `,
  `■의지하는 것이 상책이다. 여우가 범 가죽을 쓰고 호랑이 행세를 한다. 내 재물을 이미 잃었으니 생각밖에 무엇을 바랄까.`,
];
const SIBO1 = [
  `■ 목적사를 중지하고 계획과
  방침을 변경시켜야 
  성취 된다.
  엎드려 움직이지 못하면서 마음만 분주하다.
  움직임이 관재로 발전한다.
  조용히 가을을 기다려야 좋다.
  `,
  `■ 가정에 부정이 들었다.
  어떤 일도 추진이 막힌다.
  멀리 떠나고 싶은 심정이다. 1女2男의 삼각관계가 있다. 
  남자가 여자에게 헌신하나 상대에게 피해를 준다.
  `,
  `■ 옛것으로 다시 돌아가는
  운세이다.
  타인의 교사에 의해 다툼이 일어나고 금전ㆍ여자로 인해 재해가 발생한다.
  남의 호의나 금전을 함부로 받아들이면 나중에 재해가 있게 된다.
  `,
  `■ 일을 개신시키려 해도
  여의치 않고, 凶을 凶으로
  해소시킨다.
  무슨 일이든 처음의 상황에 대처해야 한다. 경과될수록 이익이 없게 된다.
  남의 호의나 금전을 함부로 받아들이면 나중에 재해가 있게 된다.
  금전 여인의 凶이 기한다.
  `,
  `■ 자충수적 우환이 있다.
  신불의 진노를 받고 있다.
  四月 임신사가 있다.
  주위를 청결히 하고 고사ㆍ치성을 드리면 吉하다.
  금전사가 큰 것 같아도 이 재물을 취하지 말라.
  재화의 씨앗이 된다. 
  그러나 凶은 경미하게 끝난다. 호의자에게는 암독이 있다.
  `,
  `■ 자충수적 우환이 있다.
  가내 우환이 있고 가장을
  속이며 가인이 원한을
  품고 가해하려 한다.
  관직자는 대발ㆍ대귀하는 경사가 있다.
  일반인은 가정에서 관재사가 일어난다.
  모든 일을 새롭게 시작해야 좋다. 천거ㆍ추천을 받는다.
  `,
  `■ 배신당했다. 
  가내 자충수적 우환이 있고
  가장을 속이며 가인이 원한을
  품고 가해하려 한다.
  득실이 일정하지 못하다.
  일은 두 갈래이고 재차 시도한다. 부부 각기 외정을 갖고 있다.
  
  ⊖부인에게 재난이 있다.
  교섭사는 성립되지 못한다.
  `,
  `■ 가정에 부정이 들었다.
  금전과 이권을 잃는다.
  허명무실한 일이 많다.
  은닉ㆍ음모사가 있다.
  성공이 안된다.
  때를 기다리는 것이 현명하다. 
  단, 酉띠, 戌띠는 좋다.
  四月 임신사가 있다.
  `,
  `■ 財와 女子를 손상하고
  이권상실사가 있다.
  돕는자도 암독을 품고 있다.
  이름만 큰 돈이고 공허한 것 뿐이다.
  움직이면 화가 된다.
  정단사도 공론ㆍ공성이고 참된 뜻이 없는 일이다.
  凶은 해소되나 吉은 완성되지 않는다.
  四月 임신사가 있다.
  `,
  `■ 저주사가 있다.
  일이 양갈래이고
  심중 놀램이 크다.
  큰 것은 안 좋고 작은 것에 만족해야 한다.
  스스로 싸울 힘이 없다.
  그러나 내가 유리하고 상대는 불리하다. 
  공직자는 퇴직하지 않으면 재화를 당한다.
  `,
  `■ 辰戌丑未月, 가을에는 
  吉하다.
  모망사는 이익이 있다.
  ⊕女는, 男을 유혹하여 가출하려는 상이다.
  만사 지체되고 처음에는 凶하고 나중에는 吉이다.
  
  ⊖집안에 사골이 묻혀 있다.
  상가부정도 들었다.
  四月 임신사가 있다.
  `,
  `■ 상대(여자)가 스스로 
  와서 손해를 끼친다.
  만사거 허모ㆍ부실하다.
  방지하려는 계책은 졸책일 뿐이다.
  시작은 있어도 끝이 없다.
  이권과 금전 여자를 상실한다.
  四月 임신사가 있다.`,
];
const SIBO2_1 = [
  `■자손ㆍ박관가택내사이다.
  내방조짐 상의 : 
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,
  `■사업ㆍ후원신변외사이다.
  내방조짐 상의 :  
  움직이면 재액이 발생하고 불리하다. 도적ㆍ어획 정단은 잡는다.
  `,
  `■ 관ㆍ부, 신변외사이다.
  내방조짐 상의 : 
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■ 관ㆍ부, 가택동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다
  `,
  `■ 사업ㆍ후원반목대립사이다.
  내방조짐 상의 : 
  이전·이사가 있고,병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.부모재해 예방필요
  `,
  `■ 관ㆍ부, 신변외사이다.
  내방조짐 상의 :  
  흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다.
  `,
  `■ 관ㆍ부, 가택동요사이다.
  내방조짐 상의 : 
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다
  `,
  `■ 財ㆍ女내외사이다.
  내방조짐 상의 :  
  어떤 일이든 처음 불성한다.
  이권상실하고 재·녀 재액이 있다.
  `,
  `■ 사업ㆍ후원신변반목사이다.
  내방조짐 상의 : 
  병ㆍ관재는 흉하고 明이 좋고 暗은 나쁘다.부모 재해 예방이 필요하다.
  `,
  `■관ㆍ부, 가택동요사이다.
  내방조짐 상의 :  
  원행·이동사가 있다흉재소산ㆍ혼인성립ㆍ가택정단 안녕ㆍ정사 利 암사 불리하다
  `,
  `■ 사업ㆍ후원반목대립사이다.
  내방조짐 상의 : 
  은근하고 伏하라.
  임신사가 있다.
  `,
  `■사업ㆍ후원반목대립사이다.
  내방조짐 상의 :  
  은근하고 伏하라.
  허위․사기사가 있다.`,
];
const SIBO2_2 = [];

const SAGWA_IMAGE1 = [
  `/static/images/sagwa/048/row-1-column-1.png`,
  `/static/images/sagwa/048/row-1-column-2.png`,
  `/static/images/sagwa/048/row-1-column-3.png`,
  `/static/images/sagwa/048/row-1-column-4.png`,
  `/static/images/sagwa/048/row-1-column-5.png`,
  `/static/images/sagwa/048/row-1-column-6.png`,
  `/static/images/sagwa/048/row-1-column-7.png`,
  `/static/images/sagwa/048/row-1-column-8.png`,
  `/static/images/sagwa/048/row-1-column-9.png`,
  `/static/images/sagwa/048/row-1-column-10.png`,
  `/static/images/sagwa/048/row-1-column-11.png`,
  `/static/images/sagwa/048/row-1-column-12.png`,
];

const HEASYEOL = [
  // 1
  `   이 과는 천지일여복(天地一如伏)으로 움직이지 않고, 음일(陰日)은 스스로 유(柔)하다고 자신하므로 이같은 이름이 붙었다. 복음과격(伏吟課格)의 원칙(原則)에 이르기를 ｢월장가시(月將加時)하여 12신이 각각 본궁에 앉고 일진음양(日辰陰陽)이 엎드려 움직이지 않으며 말전에 금일정신(金日丁神)이 승하니 고요한 가운데 움직임을 지배한다｣고 하였다.
    이 과의 음신(陰神)은 지신(支神)을 초전으로 한다. 정사일(丁巳日)․신사일(辛巳日)․기사일(己巳日)은 사(巳)를 사용하여 3전은 사신인(巳申寅)으로 되고 정축일(丁丑日)․신축일(辛丑日)․기축일(己丑日)에는 축(丑)을 초전으로 하여 축술미(丑戌未)가 3전으로 된다. 정미일(丁未日)․신미일(辛未日)․기미일(己未日)은 미(未)를 초전으로 하여 미축술(未丑戌)이 3전이 된다. 즉 음일(陰日)은 스스로 유하다고 믿고, 이 과는 자형(自刑)의 신이 초전으로 되어 두전격(杜傳格)이라 부른다. 중전은 일간상신을 사용하고 말전은 지형(支刑)을 사용한다. 즉 진오유해(辰午酉亥) 자형(自刑)의 신을 초전으로 하는 것은 두색(杜塞)으로 되어 두전격(杜傳格)이라 부른다.
    점사(占事)는 중지하고, 계획을 고쳐서 구할 때는 성취된다. 3전에 청(靑)․상(常)․귀(貴)가 승하면 몸은 앉아 있으면서 경사를 맞는다. 3전 중에 백호․육합․정마(丁馬) 중 어느 것인가가 승하면 고요한 가운데서 움직여 통신은 즉시 온다. 구진을 보면 굽히면 필 수가 없고, 움직임과 정지에서 모두 정체가 있다. 태음을 보면 음사(陰私)는 명백하게 하기 어렵다. 천공을 보면 허사(虛詐)를 주관한다. 일간이 왕상이면 길조가 있다.
    백법에 ｢금일(金日)에 정신(丁神)을 만나는 것은 흉화의 사건으로 움직인다｣고 하였다. 비전에는 ｢남을 방문하면 외출하고 없으며, 술(戌)의 지괴(地魁)가 3전 중에 보이는 것은 참관격(斬關格)으로 된다]고 하였다.    일귀는 순행하며 3전은 현(玄)․상(常)․청(靑)이고, 야귀는 역행하여 3전이 백(白)․상(常)․후(后)이다.`,
  // 2
  `   이 과는 제3과에서 1상이 1하를 극하여 초전으로 되니 원수과(元首課)이다. 원수란 4과 중에 따로 극이 없고 1상 1하의 극 뿐인 것을 초용으로 한다. 상에서 하를 극하는 것은 순(順)으로서 바르고 동란 반상적인 도리가 없으며 64과의 첫째이므로 원수라 이름한다.
    이 과를 얻으면 만사가 순리로와 첫째가는 과이다. 천지가 위(位)를 얻고 사물이 새로워 상하주종(上下主從)이 화합한다. 어버이와 자식간에 친밀하며, 결혼은 길하고 가정 내에 기쁨이 있다. 일간일지 초전 년명이 왕상하고 길장이 승하면 부귀․명리가 현달한다. 길격(吉格)을 이루면 더욱 가조(佳調)의 신시(神示)이다. 임신하면 남아를 잉태하고 사업은 이익을 얻는다. 관직․구직은 모두 좋은 형세이다.
    그 해의 지지가 초전으로 되면 그 해 안으로 보응이 있다. 월건의 지(支)가 초전으로 되면 그 달 안으로 보응이 있다. 길장은 길한 보응, 흉장은 대소를 불문하고 그 달 안으로 보응이 있다. 오늘의 지지가 초전으로 되면 그 순(旬) 안으로 응험이 있다. 일간기궁(日干寄宮)의 지지가 초용으로 되면 그 당일에 길흉의 응험이 있다. 어느 과격이나 상기한 법칙이 적용된다.
    오행의 이법은 형사(刑事)에 통하는 것이다. 원수과(元首課)는 위가 강하고 아래가 약하므로 남자의 일이다. 퇴여격(退茹格)은 일명 실우격(失友格)이라고도 하는데, 무슨 일이나 하려고 하면서 할 수가 없어 인정(人情)이 없어진다. 4과상신이 3전을 이루는 것은 회환격(廻環格)으로서 모든 일을 반복할 뿐이다.
    백법에 ｢왕록이 신(身)에 임하면 길하지만 망동을 꺼린다｣고 하였다. 비전에는 ｢일간기궁 지지가 일지상신에 임하는 것은 양성(兩性)을 초래한다｣고 하였다. 또는 동거하는 형상으로 된다. 술(戌)이 일지상신에 가해져서 초전을 이루면 움직이려고 하여도 곤란하다. 일귀는 순행하며 3전은 상․백․공이다. 3전 중에 현무가 승하면 무언가 놀랍고 의혹되는 사건을 만난다.`,
  // 3
  `   이 과는 제2과에서 1상이 1하를 극하여 초전으로 되니 원수과이다. 일지상신이 녹으로 되고 초전의 오(午)는 일간의 관귀로 된다. 말전에서 칠살을 생하면 타인의 교사(敎唆)로 하여 다툼이 생긴다. 오직 인(寅)은 순공(旬空)으로 되어 흉이 경하다. 재에서 칠살을 생하니 재로 인하여 재화가 있으므로 남에게서 선물을 받지 말아야 한다.
    이 과는 일간일지상신이 비견(比肩)․겁재(劫財)로서 재를 얻으려해도 인(寅)의 재가 공망하므로 미재(微財)이다. 일간일지의 교차육해(交叉六害)는 화합한 중에 원수가 생긴다. 건록이 지(支)에 임하는 것은 윗사람․존장․또는 신불(神佛)에 금전 또는 물품을 증여하여 재액을 면하는 암시이다.
    백법에 ｢건록이 지상에 임하면 권섭부정(權攝不正)한 과다｣라고 하였다. 육임백법(六壬百法)의 제8법을 참고하기 바란다. 
    비전에는 ｢초전 오화(午火)는 인목(寅木)의 자손이다｣라고 하였다. 오화(午火)에서 인(寅)의 말전은 장생으로 되어 자식이 어미를 돌보므로 고조격(顧助格)이라 부른다. 기쁨이 있고 화평한 격이다. 3전에 오(午)와 인(寅)이 있어서 술(戌)의 한 신(神)을 기다려 3합하므로 술월(戌月) 또는 술일(戌日)에 주사(主事)가 발응(發應)한다. 이것을 기일대용(期一待用)이라 한다. 일귀는 순행하며 3전은 구(勾)․주(朱)․귀(貴)이고, 야귀는 역행하여 3전이 귀(貴)․주(朱)․구(勾)이다. 공명․구직 및 그 밖의 대부분은 모두 길조로 변화되기 쉽다.`,
  // 4
  `   이 과는 4과 중 제4과에서 1상이 1하를 극하니 사신(巳申)을 초전으로 하여 원수과(元首課)로 된다. 일간상신 미(未)에 정신이 앉고, 초전은 일지의 역마로 되며, 금일정마(金日丁馬)에 극되어 흉동(凶動)의 원인으로 된다. 3전의 사인해(巳寅亥)는 원태격(元胎格)이며, 제4과가 발용(發用)으로 되는 것은 맥월격(驀越格)으로 주사는 맥연히 나타난다. 초전은 일간을 극하나 일간상신의 인(印)으로 생화하여 덕신으로 되니 만사에서 우려가 흉을 보고 흉을 해소한다. 중․말전에 재와 설기가 공망하여 전(傳)으로 가지 않는다고 한다. 초전의 한 신으로 길흉을 간점한다.
    백법에 ｢금일정신(金日丁神)을 만나는 것은 흉화로 움직이고, 둔귀가 승하면 재해가 적지 않다｣고 하였다. 말전의 식신(食神)이 공망을 만나니 병을 점단하면 가슴을 앓고 음식을 먹을 수 없다. 병점은 좋지 않다. 신(辛)에서 보아 망신(亡神)으로 되기 때문이다. 초전은 둔간 재성으로 되어 재에서 관귀를 생하여 일간을 극하므로 재를 증여받지 말아야 한다. 후일에 재난(災難)을 만난다. 다행히 미토(未土)에 생화하여 흉이 화하여 길로 되지만 정신이 또 일간을 극하므로 군자는 위험에 가까이 하지 않는 것이 안전하다. 
    일귀는 역행하며 3전은 현(玄)․귀(貴)․합(合)이며, 야귀는 3전이 사(蛇)․구(勾)․백(白)이다. 일귀는 길장이고 야귀는 흉장이다.`,
  // 5
  `   이 과는 4과 중 3과에 모두 3상이 3하를 극하니 비용과(比用課)를 취할 수 없으므로 4맹상신(四孟上神)의 극인 미해(未亥)를 사용하여 초전으로 하니 섭해과(涉害課)로 되며, 3전의 미묘해(未卯亥)는 곡직격(曲直格)이다.
    일간에서 보아 전재로 되나 일간상신이 午의 칠살을 생하므로 이 재물은 취하지 말아야 한다. 그러나 말전의 해(亥)는 오화(午火)를 제어하고 일지상신의 미(未)는 인(印)으로 생화하므로 흉을 만나도 면할 수 있다. 섭해과는 무슨 일에서나 양단(兩端)을 지배한다. 3상이 3하를 극하므로 소인에게는 이롭지 못하다. 정신(丁神)이 일지의 택상에 있어서 화환(禍患)의 기(機)는 이미 움직였으나 다행히 중․말전이 공망하여흉을 해제한다. 섭해과는 가려고 해도 갈 수가 없다. 상에서 하를 극하면 우려와 흉해가 모두 경미하다.
    4맹상신(四孟上神)을 취하여 초전으로 하는 것은 견기격(見機格)이라 한다. 4맹은 춘하추동의 처음이며 일의 시작이다. 화복을 간직하므로 기(機)를 소상하게 간점해야 한다. 주사(主事)에는 의혹되는 사건이 있는데 신속하게 개변해야 한다. 구태를 지킬 때는 삽체되어 풀기 어려우며, 잘 채산하면 이기니 채산을 무시하지 말아야 한다. 만사가 처음은 곤란하고 후에 쉽게 풀린다.
    백법에 ｢귀신이 승하여 천을신기격(天乙神祗格)으로 되며, 이 과는 일간일지가 모두 상신에서 극되므로 피차가 손해로 된다｣고 하였다. 중․말전이 공망하면 전으로 가지 않으니 초전에 의하여 길흉을 구해야 한다. 
  3전목국(三傳木局)의 공망은 썩은 나무라 하여 재(財)가 힘이 없다. 일귀는 역행하며 3전은 백(白)․후(后)․합(合)이고, 야귀는 3전이 후(后)․합(合)․백(白)이다, 초전이 실(實)이고, 중, 말전이 공망이면 처음에는 길하나 종국에서는 빈 것으로 된다.`,
  // 6
  `   이 과는 제1과․제2과․제3과에 상하의 극이 있고 하에서 상을 적(賊)한다. 제3과 오해(午亥)를 사용하여 초전으로 하니 중심과로 된다.
    일간상신 및 초전의 오(午)가 일간을 극하여 귀살(鬼殺)이 와서 일간을 상(傷)한다. 4절(絶)의 격은 구사결절(舊事結絶)에 좋지 않으므로 새롭게 도모해야 한다. 일지상신에 귀신 태상이 승하여 공관(公官)의 구직은 가장 길조이다. 일간상신에 백호․현무의 흉신(凶神)․노도(魯都)․겁살이 승하여 역마로 되니 도난이나 재액을 부르고 혹은 관재(官災)를 초래한다.
    백법에 ｢귀살이 천을에 승하는 것은 신기격이다｣라고 하였다. 비전에는 ｢일지상신 관성에 귀신이 승하니 고위의 관직을 얻는다｣고 하였다. 4절격(四絶格)은 모든 일에서 조격되며, 흉사 결절이라 하여 흉이 결절(結絶)된다. 관귀가 제지(制地)에 있어서 흉을 만나도 자연히 경미하다. 초전은 중. 중전은 말전을 생하여 체생격(遞生格)이니 남의 천거를 얻는다 
    일귀는 역행하며 3전은 상․사․공이며, 야귀는 순행하여 3전이 귀․백․주이다. 일간일지는 사해(巳亥)의 충극으로 수화상극하며 교섭사는 피차가 뜻을 달리한다.`,
  // 7
  `   이 과는 천지가 충궁(冲宮)에 있으므로 반음과로 된다. 상극의 사해(巳亥)를 초전으로 하여 반복(返腹) 신음(呻吟)하는 형상이다.
    오는 자는 가려고 생각하고 떠날 때는 돌아올 것을 바란다. 득실이 일정하지 않으며, 산은 높고 골짜기는 깊다. 물건을 얻으면 잃고, 사물이 패하면 다시 이루어진다. 3전이 동해사(巳亥巳)는 무슨 일이나 새롭게 다시 하고, 재물은 문장사(文章事)를 구하여 취득한다. 만사는 침묵을 지키는 것이 가장 길하며, 사물은 두 길로 된다. 움직이려 하나 움직이 못하고, 재해(災害)는 밖으로부터 오며, 배역분리(背逆分離)하는 일이 일어난다. 이 과에서 3전 중에 청룡이 승하면 재화를 해소한다. 상하와 어버이․자식간에 모두 노여움을 품고 부부는 외정(外情)이 있으며, 무슨 일이나 중복된다. 질병 간점은 두 가지 증상(症狀)에 침범되며, 초․말전이 다 역마 관귀이지만 해(亥)의 제지(制地)에 있다. 특히 사(巳)는 오늘의 일덕(日德)으로 된다. 모사는 밖에서 구하며, 재의 기쁨이 있다 해도 경미하다.
    백법에 ｢양귀(兩貴)가 극을 받아 웃사람․존장의 원조를 얻지 못한다. 비전에는 ｢사시(巳時)에 간점하는 경우는 가택 이동사건이 있고, 천후가 사(巳)에 임하고 절(絶)에 가해지면 부인에게 재화가 일어난다｣고 하였다. 
    일귀는 역행하며 3전은 현․합․현이다. 야귀는 순행하며 3전이 후․청․후이다. 과전 중에 일귀가 중첩되면 병이나 소송 사건은 가장 흉하다. 교차상극(交叉相剋)하니 피차의 교섭사는 성립되지 않는다.`,
  // 8
  `   이 과는 4과 중의 제3과까지 상하의 극이 있고 하에서 상을 극하는 묘술(卯戌)을 초전으로 하는 중심과이다. 초전이 공망하여 고허격(孤虛格)이다. 일간에서 묘(卯)는 재성(財星)이자 초전으로 초전이 공망한 것은 실재(失財)의 건이다. 초전의 공망은 동기의 공망이므로 허명 무실한 일이 많다. 과전이 공망인데 묘(卯)의 년월(年月) 또는 년명상신에 묘(卯)가 승할 경우에는 전실공망(塡實空亡)이라 하여 공망으로 보지 말아야 한다.
    백법에 ｢묘(卯)와 신(申)은 단륜(斷輪)으로 되지만, 공망이 되면 나무의 재료가 썩은 나무로 되어 조각하기 어렵다. 즉 기물을 만들 수 없다｣고 하였다. 
    비전에는 ｢신(申)에 주작이 승할 때는 서신이 도중에서 온다｣고 하였다. 신(申)은 전송(傳送)이라 하여 도로의 신이다. 초전의 묘(卯)에 현무가 승하고 제4과의 유(酉)에 6합이 승하여 과전 중에 있을 때는 반드시 강도를 만난다. 말전에 사호(蛇虎)가 앉고 일간일지상신에 현무가 승하면 은닉 사건이나 음모 사건이 있다. 
    일귀는 역행하며 3전은 후․공․사이며, 야귀는 순행하여 3전이 현․주․백이다. 귀신이 유(酉)에 임하는 것은 여덕격(勵德格)으로서 모든 일에서 때를 기다리는 것이 좋다.`,
  // 9
  `   이 과는 제1과와 제4과에서 하가 상을 극하고 음일이니 음비를 사용하여 미묘(未卯)로써 초전으로 하니 지일비용과(知一比用課)이다. 3전이 3합목국(三合木局)이므로 곡직격(曲直格)이다. 이 과는 4과와 3전의 초․말전이 공망하니 거의 전공(全空)이다. 일간일지가 교차지합하나 함께 공망하니 명맥만 있는 기쁨이나 화합의 형상도 공허하게 된다. 중전만이 실(實)이므로 흉은 해소되지만 길사는 이루어지기 어렵다. 곡직격의 공망은 썩은 나무이다.
    백법에 ｢금일(金日) 미(未)에 정신(丁神)이 승하니 움직이면 화해(禍害)를 초래한다｣고 하였다. 순내(旬內) 공망은 움직이면 화해(禍害)를 초래한다｣고 하였다. 순내(旬內) 공망은 제96법에 해당하며 재(財)의 공망은 재, 또는 처가 결핍된다. 갑진(甲辰)의 순(旬)은 인묘(寅卯) 공망이며 신해일(辛亥日)은 귀신 공망에 해당한다. 이 과는 초전․말전이 공망하고 중전이 실이라 해도 탈기로 된다. 거의 모두가 공망으로 중첩되는 것은 길함도 흉함도 없다. 정단 사건은 공론․공성으로 되어 참된 뜻이 없다. 목국(木局)의 재효도 이미 공망된 것이므로 외래의 재를 구하기 어렵다. 
    일귀는 역행하며 3전은 백(白)․합(合)․후(后)이며, 점혼은 자유 결혼이다. 야귀는 순행하며 3전이 사(蛇)․청(靑)․현(玄)이다.`,
  // 10
  `   이 과는 상하에 극이 없고 제3과․제4과의 일간이 동극(斜剋)으로 된다. 일간에서 극하는 것과 천신에서 일간을 극하는 것이 있으면 천신에서 극하는 것을 채용하는 것이 원칙이니 제4과 사인(巳寅)을 사용하여 초전으로 한다. 즉 천신에서의 극을 사용하는 것은 호시격(蒿矢格)이며, 4맹신(四孟神)의 3전은 원태격이 된다.
    이 과는 멀리서 극하여 용(用)을 이루므로 요극과라고 이름하며, 멀리에서 극하므로 길흉이 모두 완만하고 또 경하다. 정단사는 처음에는 번개같이 두려운 감이 있지만 종국에 가서는 무사하다. 범의 위세를 비는 여우와 같다. 흉한 것 같아도 오래되면 자연히 소멸된다. 길흉은 정해지기 어렴고, 손님이 있다면 나에게 원수질  뜻이 있다. 천장의 길흉에 따라 단정해야 한다.
    이 과와 같이 두 극이 있는 것은 사물에 두 개의 뜻이 있다. 계획․모사의 정단은 급히 하지말고 서서히 하는 것이 좋다. 특히 호시격은 요극하는 힘이 약하여 손상되기 어렵고, 따라서 해는 극히 경하다. 3전의 길장은 외출하면 이익이 있다. 정세는 천장에 의하여 알 수 있다. 만일 흉장이 승할 때는 흉화가 내몸에 파급되며 사람이 와서 나를 해친다. 제2과를 초전으로 하면 스스로 싸울 힘이 없으며, 일에는 공허한 것이 많다. 선두에 나서면 좋지 않다. 제3과를 초전으로 하면 일지의 양신(陽神)이 스스로 양양상극(兩陽相剋)하므로 일은 흉이 중하니 먼저 움직이지 말아야 한다. 제4과를 초전으로 하면 힘이 없고 나에게 이로우며, 상대방에게는 이롭지 않다. 작은 일에서는 이익이 있으나 큰 사건에서는 이익이 없다.
    백법에 ｢일간상신 묘(墓)에 백호가 승하면 승리를 얻으나 후에 크게 패한다. 일귀(日鬼)가 초전으로 되고 관성이 공망이 되면 퇴직하지 않으면 재해를 초래한다. 초․중전은 지형(支刑)으로 되고 말전의 자형(自刑)은 질병․소송 사건에서 불리하다. 일귀(日貴)는 순행하며 3전은 합․공․현이고, 야귀(夜貴)는 3전이 후․주․청이다.`,
  // 11
  `   이 과는 4과 중에서 2상이 2하를 극하고 모두 음일이니 비용(比用)을 취할 수 없으므로 섭해과(涉害課)로 되며, 지반4맹신(地盤四孟神)의 축해(丑亥)를 사용하여 초전으로 한다. 3전은 한 신(神)을 사이에 두고 전을 이루므로 순간전격(順間傳格)이다. 60간지 중에는 순간전이 12국, 역간전(逆間傳)이 12국 있다. 순간전은 일이 순(順)으로 되고 역간전은 일이 역으로 이루어진다.
    일간이 왕상할 때는 길을 내리고 이익은 어른에게 있다. 만일 휴수(休囚)하고 길흉이 승하면 흉조가 있으며, 3전 축묘사(丑卯巳)는 출호격(出戶格)라 부른다. 묘(卯)는 문호라 하고 사(巳)는 지호(地戶)라 부른다. 축(丑)에서 사(巳)에 전(傳)하는 것은 사람이 문을 나가는 형상이니 이같은 이름이 있다. 사람을 방문해도 집에 없고, 여행은 떠나게 된다. 망사․모사는 이익이 있다. 상․중류의 사람에게는 이롭고 하급직의 사람에게는 불길한 격이다. 중전의 재효, 말전의 사(巳)의 덕신(德神)도 모두 공망한다.
    순미(旬尾)의 축(丑)이 초전으로 되는 것은 폐구격(閉口格)이며, 병자는 약과 음식을 먹지 못하니 필경은 사망하게 된다. 섭해는 만사가 지체되어 가려고 해고 가지 못한다. 일간일지상신에 축(丑)의 지합(支合)이 있으면 처음에는 흉하고 후에는 길로 화한다.
    백법에 중․말전의 공망은 전(傳)으로 가지 않는다. 초전의 한 신(神)으로 길흉을 예측해야 한다. 공망에 천공이 승하면 만사가 공허한 일이니 깊이 추구하지 말아야 한다. 3전에 후(后)․합(合)이 승하는 것은 중매인이 없이 정의가 투합된다. 즉 자유 결혼의 형상이다.
    비전에는 ｢축(丑)에 백호가 승하여 지상에 가해지면 집안에 사골(死骨)이 있다｣고 하였다. 또는 이웃에 관통(棺桶)의 용건이 생기거나 죽은 사람의 재앙(災殃)이 있다. 일귀는 순행하며 3전은 후(后)․사(蛇)․합(合)으로서 일녀격(泆女格)이다. 야귀는 역행하며 3전이 백(白)․현(玄)․후(后)이다.`,
  // 12
  `   이 과는 4과 중 제4과에서 1상이 1하를 극하여 원수과(元首課)로 된다. 일지는 일간상신에 가해져서 일간에서 탈기로 된다. 3전 축인묘(丑寅卯)의 진여과(進茹課)이나 중․말전이 공망하므로 전진에 지장이 있다. 일간상신 해(亥)와 일지상신 자(子)에 탈기로 되어 만사가 허모부실하다. 4과이지만 3과뿐이니 불비격(不備格)이다. 또 순미(旬尾)가 초전에 가해지면 폐구격(閉口格)이다.
    백법에 ｢탈상탈(脫上脫)을 만나는 것은 허사(虛詐)를 방지하려고 계책하여 졸책(拙策)으로 된다｣고 하였다. 비전에서는 ｢일간의 해(亥)는 일간에서 탈설로 되고, 현무가 승하여 초전은 일간의 묘(墓)로 된다. 중․말전은 공망하므로 도적은 잡기 어렵다｣고 하였다.
    일귀는 순행하며 3전은 후(后)․귀(貴)․사(蛇)이고, 야귀는 역행하며 3전이 청(靑)․구(勾)․합(合)이다. 초전은 실(實)이며 중․말전이  공허하므로 시작이 있으나 끝이 없다. 이 과는 말전이 묘인(卯寅)이니 천지가 모두 공망으로 된다. 비전(秘傳) 심인(心印)의 부(賻)에 이르기를 ｢천지(天地) 상하가 함께 공망으로 되면 재의 일을 정단하면 손상을 초래한다. 그러나 부실우환(不實憂患)은 흩어진다. 죄과는 해소되고 병은 제거되며 안강하게 된다. 그러나 초전 공망이 중첩되는 경우는 신병은 치료되고 구병은 죽는다.`,
];

// '/static/pdf_haeseol/'/static/pdf_raejung/_haeseol_-1.pdf',
const PDF_RAEJUNG1 = [];

const PDF_DANJEONG1 = [];

export default {
  columnA,
  columnD,
  columnS,
  columnZ,
  columnAF,
  columnAS,
  columnAU,
  columnBE,
  columnBO,
  columnBS,
  columnBV,
  columnCE,
  columnCL,
  columnCY,
  columnDH,
  //
  CHOSA1,
  YOGYEOL1,
  SIBO1,
  SIBO2_1,
  SIBO2_2,
  SAGWA_IMAGE1,
  HEASYEOL,
};
