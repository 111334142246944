import firebase from "../firebase"; // 구성 파일에서 로컬 에뮬레이터 설정이 적용된 Firebase 인스턴스를 가져옴

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Component,
  useCallback,
} from "react";
import moment from "moment";
import dayjs from "dayjs";
import appEnv from "../assets/appEnv";
import ContentContainer from "../components/others/ContentContainer";
import Header from "../components/layout/Header";
import Layout_DTM from "../components/layout/Layout_DTM";
import appStrings from "../assets/appStrings";
import useWindowSize from "../useWindowSize";
import parse from "html-react-parser";
import styled from "styled-components";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import ImageZoom from "react-image-zooom";
import SiboStatus from "../components/ios/SiboStatus";
import CurrentSituation from "../components/ios/CurrentSituation";

// import Firebase from "firebase";

import { generateReturnStringForArrayIndex5 } from "../utils/generateReturnStringForArrayIndex5";
import TimeSelect from "../components/ios/TimeSelect";
import { getPillarsFromDate } from "../components/ios/DateTimePillarConverter";

import {
  jiduTableHeader1,
  jiduTable1,
  world1,
  soYook_StandardArr,
  nextPersonOriginArray,
  years1,
  month1,
  days1,
  hours1,
  minutes1,
  seconds1,
  yinyang1,
  jeolgi1,
  jeolgi0,
  time1,
  locationA,
  location1,
  location2,
  jeolgiMixArray1,
  sookjabyeol_1,
  pdfPageNumber,
  pdfPageNumber1,
  gan10,
  ganji12,
  ganji12_0,
  time12_0,
  time12_1,
  time12_2,
  time12_3,
  time12_4,
  time12_5,
  time12_5_1,
  time12_6,
  time12_6_1,
  time12_7,
  ganji12_1,
  hyosagyeolTable1,
  ganji12_2,
  gapja60,
  gapja60_0,
  menu1,
  menu2,
  newmenu1,
  newmenu2,
  newmenu101_1,
  newmenu101_2,
  danjeongMenu1,
  jeolgiValue1,
  LUNAR_LAST_YEAR,
  lunarMonthTable,
} from "../components/data/constants/IOS_Data";

const Button1_1 = styled.div`
  cursor: pointer;
  width: 100px;
  min-width: 20px;
  height: 20px;
  color: #fff;
  background-color: #8d8ea9;
  border: 1px solid #000;
  border-radius: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;
const Button0 = styled.div`
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  color: #fff;
  background-color: #8d8ea9;
  border: 1px solid #000;
  border-radius: 0px;

  padding-left: 4px;
  padding-right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

const PopupContainer = styled.div`
  width: calc(100vw - 60px);
  height: calc(100vh - 100px);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  left: 30px;
  top: 50px;

  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #000;

  position: fixed;
  z-index: 991;

  overflow: scroll;
`;

const PageComponent = ({ props }) => {
  const windowSize = useWindowSize();
  // 100 (9/7일 이전 버전), 101(9/7 이후 버전), 101_1 (status5), 101_1_1(status6)
  const [yookimGuide, setYookimVersion] = useState("101");
  const [isMyOpponentSForce, setIsMyOpponentSForce] = useState(false); // 본인상대 보기 권한 여부
  const [selectedGue1, setSelectedGue1] = useState(-1);
  const [selectedGue2, setSelectedGue2] = useState(-1);
  const [tableMagnify, setTableMagnify] = useState(1);

  const [selectedJuek1, setSelectedJuek1] = useState(-1);
  const [selectedJuek2, setSelectedJuek2] = useState(-1);
  const [
    selectedBirthCalendarGapjaIndex1,
    setSelectedBirthCalendarGapjaIndex1,
  ] = useState(-1);

  const [objectGui, setObjectGui] = useState(require("../dataForm1/data0_0"));
  const [objectDanJ, setObjectDanJ] = useState(
    require("../dataForm1/data0_0_0")
  );
  const [objectILSANGMENU, setObjectILSANGMENU] = useState(
    require("../dataForm1/data0_0_0_0")
  );
  const [object101_1, setObject101_1] = useState(
    require("../dataForm1/data0_0_0_0_0")
  );

  const [objectJooggi, setObjectJooggi] = useState(
    require("../jeolgi/joonggi")
  );

  const [textSelectedCategory, setTextSelectedCategory] = useState("");
  const [selectedGapja, setSelectedGapja] = useState("");
  const [selectedNaejung, setSelectedNaejung] = useState(
    require("../dataForm1/data1_1")
  );
  const naejungRef = useRef(null);
  naejungRef.current = selectedNaejung;

  const [selectedGapjaIndex, setSelectedGapjaIndex] = useState(0);
  const [selectedSi, setSelectedSi] = useState("");
  const [selectedGook, setSelectedGook] = useState(0);
  const [selectedSiIndex, setSelectedSiIndex] = useState(0); // 0부터 시작
  const [selectedSiIndex1, setSelectedSiIndex1] = useState(1); // 1부터 시작

  const [boolPopup1, setBoolPopup1] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [textSelectedLocation, setTextSelectedLocation] = useState("");
  const [textPrevSelectedLocation, setTextPrevSelectedLocation] = useState("");
  const locationRef = useRef(null);
  locationRef.current = textSelectedLocation;

  const [intSelectedIndex1, setIntSelectedIndex1] = useState(0);
  const [intSelectedIndex2, setIntSelectedIndex2] = useState(0);
  const [currentData, setCurrentData] = useState(null);
  const [selectedSpecificDate, setSelectedSpecificDate] = useState(null);
  const [inputType, setInputType] = useState("");
  const [htmlPopupContent, setHtmlPopupContent] = useState(``);
  const [htmlPopupContentSub, setHtmlPopupContentSub] = useState(``);

  const [gookSuDecideArray, setGookSuDecideArray] = useState([]);
  const [JoongGiIndex, setJoongGiIndex] = useState(-1);
  // console.log("cloumnS", selectedNaejung.default.columnS.length);

  const [popupContents, setPopupContents] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");

  const [searchedInput101, setSearchedInput101] = useState(
    moment().format("YYYY")
  );
  const [searchedInput102, setSearchedInput102] = useState(
    moment().format("MM")
  );
  const [searchedInput103, setSearchedInput103] = useState(
    moment().format("DD")
  );
  const [searchedInput104, setSearchedInput104] = useState(
    moment().format("HH")
  );
  const [searchedInput105, setSearchedInput105] = useState(
    moment().format("mm")
  );
  const [searchedInput106, setSearchedInput106] = useState(
    moment().format("mm")
  );
  const [searchedInput107, setSearchedInput107] = useState("양력");

  const [searchedInput201, setSearchedInput201] = useState(
    moment().format("YYYY")
  );
  const [searchedInput202, setSearchedInput202] = useState(
    moment().format("MM")
  );
  const [searchedInput203, setSearchedInput203] = useState(
    moment().format("DD")
  );
  const [searchedInput204, setSearchedInput204] = useState(
    moment().format("HH")
  );
  const [searchedInput205, setSearchedInput205] = useState(
    moment().format("mm")
  );
  const [searchedInput206, setSearchedInput206] = useState("01");
  const [searchedInput207, setSearchedInput207] = useState("양력");

  const [searchedInput301, setSearchedInput301] = useState(
    moment().format("YYYY")
  );
  const [searchedInput302, setSearchedInput302] = useState(
    moment().format("MM")
  );
  const [searchedInput303, setSearchedInput303] = useState(
    moment().format("DD")
  );
  const [searchedInput304, setSearchedInput304] = useState(
    moment().format("HH")
  );
  const [searchedInput305, setSearchedInput305] = useState(
    moment().format("mm")
  );
  const [searchedInput306, setSearchedInput306] = useState(
    moment().format("mm")
  );
  const [searchedInput307, setSearchedInput307] = useState("양력");

  const [searchedInput401, setSearchedInput401] = useState(
    moment().format("YYYY")
  );
  const [searchedInput402, setSearchedInput402] = useState(
    moment().format("MM")
  );
  const [searchedInput403, setSearchedInput403] = useState(
    moment().format("DD")
  );
  const [searchedInput404, setSearchedInput404] = useState(
    moment().format("HH")
  );
  const [searchedInput405, setSearchedInput405] = useState(
    moment().format("mm")
  );
  const [searchedInput406, setSearchedInput406] = useState(
    moment().format("mm")
  );
  const [searchedInput407, setSearchedInput407] = useState("양력");

  const [gyeolGoolgi1, setGyeolGoolgi1] = useState(1);

  const [saveInput100, setSaveInput100] = useState("");
  const [saveResult001, setSaveResult001] = useState({
    col001: "",
    col002: "",
    col003: "",
    col004: "",
    col005: "",
    col006: "",
    col007: "",
    col008: "",
    col009: "",
    col010: "",
    col011: "",
  });
  const [saveInput101, setSaveInput101] = useState("");
  const [saveInput102, setSaveInput102] = useState("");
  const [saveInput102_1, setSaveInput102_1] = useState("");
  const [saveInput103, setSaveInput103] = useState("");
  const [saveInput104, setSaveInput104] = useState("");
  const [saveInput105, setSaveInput105] = useState("");
  const [saveInput106, setSaveInput106] = useState("");
  const [saveInput107, setSaveInput107] = useState("");
  const [saveInput108, setSaveInput108] = useState("");
  const [saveInput109, setSaveInput109] = useState("");
  const [saveInput110, setSaveInput110] = useState("");

  const [currentDisplayDate, setCurrentDisplayDate] = useState(null);
  const [currentDisplayDate_ILSAENG, setCurrentDisplayDate_ILSAENG] =
    useState(null);

  const [selectedGapja_ILSAENG, setSelectedGapja_ILSAENG] = useState("");
  const [selectedGapjaIndex_ILSAENG, setSelectedGapjaIndex_ILSAENG] =
    useState(0);
  const [selectedSi_ILSAENG, setSelectedSi_ILSAENG] = useState("");
  const [selectedGook_ILSAENG, setSelectedGook_ILSAENG] = useState(0);
  const [selectedSiIndex_ILSAENG, setSelectedSiIndex_ILSAENG] = useState(0);
  const [selectedSiIndex1_ILSAENG, setSelectedSiIndex1_ILSAENG] = useState(0);
  const [selectedNaejung_ILSAENG, setSelectedNaejung_ILSAENG] = useState(null);
  const [gookSuDecideArray_ILSAENG, setGookSuDecideArray_ILSAENG] = useState(
    []
  );

  const [sootJaIndex, setSootJaIndex] = useState(2);
  const [sootJaIndex_ILSAENG, setSootJaIndex_ILSAENG] = useState(2);

  const [soYook1, setSoYook1] = useState(1);
  const [soYook2, setSoYook2] = useState(1);
  const [soYook3, setSoYook3] = useState(1);

  const [soYook1_ILSAENG, setSoYook_ILSAENG1] = useState(1);
  const [soYook2_ILSAENG, setSoYook2_ILSAENG] = useState(1);
  const [soYook3_ILSAENG, setSoYook3_ILSAENG] = useState(1);

  const [timeCheckSiIndex1, setTimeCheckSiIndex1] = useState(1);
  const [timeCheckSiIndex2, setTimeCheckSiIndex2] = useState(-1);
  const [boolTimeChangePopup, setBoolTimeChangePopup] = useState(false);

  const [boolMagnifyPopup, setBoolMagnifyPopup] = useState(false);
  const [objectMagnify, setObjectMagnify] = useState({ title: "", desc: "" });

  const [timeCheckSiIndex11, setTimeCheckSiIndex11] = useState(-1);

  const [closePopupRecently, setClosePopupRecently] = useState(-1);
  const recentlyRef = useRef(null);
  recentlyRef.current = closePopupRecently;

  const [activeEffect1, setActiveEffect1] = useState("");

  const [blockSystem, setBlockSystem] = useState(false);

  const [showIlsaengImage1, setShowIlsaengImage1] = useState(true);
  const [showIlsaengImage2, setShowIlsaengImage2] = useState(true);
  const [showIlsaengImage3, setShowIlsaengImage3] = useState(true);

  const [showIlsaengImage4, setShowIlsaengImage4] = useState(true);
  const [jiduIndex1, setJiduIndex1] = useState(0);
  const rerenderCount = useRef(0);

  const [messageFromAndroid, setMessageFromAndroid] = useState(""); // 1054396855

  useEffect(() => {
    rerenderCount.current = parseInt(moment().format("ss"));
  });

  useEffect(() => {
    if (firebase && process.env.NODE_ENV === "development") {
      const contactPhoneNo = "1062996648";
      const contactKey = "01062996648"; // 고유 식별자, 예를 들어 연락처 번호

      // 연락처 데이터를 추가하는 예제
      const newContact = {
        contact: contactKey,
        name: "홍길동",
        email: "hong@gmail.com",
        status: "YES",
        status3: "YES",
        status5: "YES",
        status6: "YES",
        status25: "NO",
      };

      firebase
        .database()
        .ref("contacts")
        .child(contactKey)
        .set(newContact)
        .then(() => {
          console.warn("새 연락처가 성공적으로 추가되었습니다.");
        })
        .catch((error) => {
          console.error("연락처 추가 실패:", error);
        });

      window.localStorage.setItem("yookimAi_Phone", contactPhoneNo);

      setMessageFromAndroid("" + contactPhoneNo.replace(/-/g, ""));
    }
  }, []);

  useEffect(() => {
    const currentDate = moment(
      `${searchedInput101}-${searchedInput102}-${searchedInput103} ${searchedInput104}:${searchedInput105}:${searchedInput106}`
    );
    console.warn("dayjs >>> ", currentDate.format("YYYY-MM-DD HH:mm"));
    const resultData = getPillarsFromDate(currentDate);
    console.warn("resultData >>> ", resultData);
  }, [
    searchedInput101,
    searchedInput102,
    searchedInput103,
    searchedInput104,
    searchedInput105,
    searchedInput106,
  ]);

  // console.warn("yookimGuide >>> ", yookimGuide);

  // console.warn("isMyOpponentSForce >>> ", isMyOpponentSForce);

  // console.warn("messageFromAndroid >>> ", messageFromAndroid);
  /**
   * 초기 로딩 시 권한이 개발 모드일 때 최고 권한이 아니라면 최고 권한을 할당 처리하는 부분
   */
  useEffect(() => {
    if (process.env.NODE_ENV === "development" && yookimGuide !== "101_1_1") {
      // setYookimVersion("101_1_1");
    }
  }, [yookimGuide]);
  // no use
  const [searchedInputHourMinSec, setSearchedInputHourMinSec] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    day: moment().format("DD"),
    hour: moment().format("HH"),
    min: moment().format("mm"),
    sec: moment().format("ss"),
  });
  const [currentTime, setCurrentTime] = useState(moment());
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef2 = useRef();
  const onUpdate2 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef2;
    var sendScale = scale * 1.0;

    console.log("scale", scale);

    if (scale <= 1.2) {
    } else {
      sendScale = scale * 1.14;
    }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef3 = useRef();
  const onUpdate3 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef3;
    var sendScale = scale;
    console.log("scale", scale);

    if (scale <= 1.2) {
    } else {
      sendScale = scale * 1.14;
    }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef4 = useRef();
  const onUpdate4 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef4;
    var sendScale = scale;
    console.log("scale", scale);
    if (scale <= 1.2) {
    } else {
      sendScale = scale * 1.32;
    }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef5 = useRef();
  const onUpdate5 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef5;

    var sendScale = scale;
    console.log("scale", scale);

    if (scale <= 1.2) {
    } else {
      sendScale = scale * 1.22;
    }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef6 = useRef();
  const onUpdate6 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef6;
    var sendScale = scale;
    console.log("scale", scale);
    if (scale <= 1.2) {
    } else {
      sendScale = scale * 1.32;
    }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef7 = useRef();
  const onUpdate7 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef7;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef8 = useRef();
  const onUpdate8 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef8;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef9 = useRef();
  const onUpdate9 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef9;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef11 = useRef();
  const onUpdate11 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef11;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef10 = useRef();
  const onUpdate10 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef10;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef12 = useRef();
  const onUpdate12 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef12;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef13 = useRef();
  const onUpdate13 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef13;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const imgRef21 = useRef();
  const onUpdate21 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef21;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef22 = useRef();
  const onUpdate22 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef22;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef23 = useRef();
  const onUpdate23 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef23;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef24 = useRef();
  const onUpdate24 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef24;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef25 = useRef();
  const onUpdate25 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef25;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const imgRef26 = useRef();
  const onUpdate26 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef26;
    var sendScale = scale;
    // console.log("scale", scale)
    // if (scale <= 1.2 ) {
    // } else {
    //   sendScale = scale *  1.32;
    // }
    if (img) {
      const value = make3dTransformValue({ x, y, scale: sendScale });
      img.style.setProperty("transform", value);
    }
  }, []);

  // function printDate(){
  //     var year = document.getElementById("year").value;
  //     var month = document.getElementById("month").value;
  //     var day = document.getElementById("day").value;
  //     alert(solarToLunar(year, month, day));
  // }

  /*  음력 달력 배열
        음력은 모든 달이 29일 ~ 30일 으로만 이루어짐
        음력에도 윤달이 있을 경우 2월에 1일이 추가되는 식이 아니라
        한달이 추가되어짐
        1-> 29일, 2->30일
        3, 4, 5, 6은 윤달이 추가로 생성됨
        3-> 29일 + 윤29일, 4-> 29일 + 윤30일
        5-> 30일 + 윤29일, 6-> 30일 + 윤30일
    */
  /* 절기 구분 */

  // 음력 계산을 위한 객체
  function myDate(year, month, day, leapMonth) {
    this.year = year;
    this.month = month;
    this.day = day;
    this.leapMonth = leapMonth;
  }

  function getSolar(year, month, day, isLeapMonth) {
    var o = lunarCalc(year, month, day, 2, isLeapMonth ? 1 : 0);
    // o.dayOfWeekStr = getDayOfWeekStr(o.solYear, o.solMonth, o.solDay);
    // o.dayOfWeekNum = getDayOfWeekNum(o.solYear, o.solMonth, o.solDay);
    return o;
  }

  // 양력을 음력으로 계산
  function lunarCalc(year, month, day, type, leapmonth) {
    var solYear, solMonth, solDay;
    var lunYear, lunMonth, lunDay;

    // lunLeapMonth는 음력의 윤달인지 아닌지를 확인하기위한 변수
    // 1일 경우 윤달이고 0일 경우 음달
    var lunLeapMonth, lunMonthDay;
    var i, lunIndex;

    var solMonthDay = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    /* range check */
    if (year < 1940 || year > 2040) {
      alert("1940년부터 2040년까지만 지원합니다");
      return;
    }

    /* 속도 개선을 위해 기준 일자를 여러개로 한다 */
    if (year >= 2000) {
      /* 기준일자 양력 2000년 1월 1일 (음력 1999년 11월 25일) */
      solYear = 2000;
      solMonth = 1;
      solDay = 1;
      lunYear = 1999;
      lunMonth = 11;
      lunDay = 25;
      lunLeapMonth = 0;

      solMonthDay[1] = 29; /* 2000 년 2월 28일 */
      lunMonthDay = 30; /* 1999년 11월 */
    } else if (year >= 1970) {
      /* 기준일자 양력 1970년 1월 1일 (음력 1969년 11월 24일) */
      solYear = 1970;
      solMonth = 1;
      solDay = 1;
      lunYear = 1969;
      lunMonth = 11;
      lunDay = 24;
      lunLeapMonth = 0;

      solMonthDay[1] = 28; /* 1970 년 2월 28일 */
      lunMonthDay = 30; /* 1969년 11월 */
    } else {
      /* 기준일자 양력 1940년 1월 1일 (음력 1939년 11월 22일) */
      solYear = 1940;
      solMonth = 1;
      solDay = 1;
      lunYear = 1939;
      lunMonth = 11;
      lunDay = 22;
      lunLeapMonth = 0;

      solMonthDay[1] = 29; /* 1940 년 2월 28일 */
      lunMonthDay = 29; /* 1939년 11월 */
    }

    lunIndex = lunYear - LUNAR_LAST_YEAR;

    // type이 1일때는 입력받은 양력 값에 대한 음력값을 반환
    // 2일 때는 입력받은 음력 값에 대한 양력값을 반환
    // 반복문이 돌면서 양력 값들과 음력 값들을 1일 씩 증가시키고
    // 입력받은 날짜값과 양력 값이 일치할 때 음력값을 반환함
    while (true) {
      if (type == 1 && year == solYear && month == solMonth && day == solDay) {
        return new myDate(lunYear, lunMonth, lunDay, lunLeapMonth);
      } else if (
        type == 2 &&
        year == lunYear &&
        month == lunMonth &&
        day == lunDay &&
        leapmonth == lunLeapMonth
      ) {
        return new myDate(solYear, solMonth, solDay, 0);
      }

      // 양력의 마지막 날일 경우 년도를 증가시키고 나머지 초기화
      if (solMonth == 12 && solDay == 31) {
        solYear++;
        solMonth = 1;
        solDay = 1;

        // 윤년일 시 2월달의 총 일수를 1일 증가
        if (solYear % 400 == 0) solMonthDay[1] = 29;
        else if (solYear % 100 == 0) solMonthDay[1] = 28;
        else if (solYear % 4 == 0) solMonthDay[1] = 29;
        else solMonthDay[1] = 28;
      }
      // 현재 날짜가 달의 마지막 날짜를 가리키고 있을 시 달을 증가시키고 날짜 1로 초기화
      else if (solMonthDay[solMonth - 1] == solDay) {
        solMonth++;
        solDay = 1;
      } else solDay++;

      // 음력의 마지막 날인 경우 년도를 증가시키고 달과 일수를 초기화
      if (
        lunMonth == 12 &&
        ((lunarMonthTable[lunIndex][lunMonth - 1] == 1 && lunDay == 29) ||
          (lunarMonthTable[lunIndex][lunMonth - 1] == 2 && lunDay == 30))
      ) {
        lunYear++;
        lunMonth = 1;
        lunDay = 1;

        if (lunYear > 2043) {
          alert("입력하신 달은 없습니다.");
          break;
        }

        // 년도가 바꼈으니 index값 수정
        lunIndex = lunYear - LUNAR_LAST_YEAR;

        // 음력의 1월에는 1 or 2만 있으므로 1과 2만 비교하면됨
        if (lunarMonthTable[lunIndex][lunMonth - 1] == 1) lunMonthDay = 29;
        else if (lunarMonthTable[lunIndex][lunMonth - 1] == 2) lunMonthDay = 30;
      }
      // 현재날짜가 이번달의 마지막날짜와 일치할 경우
      else if (lunDay == lunMonthDay) {
        // 윤달인데 윤달계산을 안했을 경우 달의 숫자는 증가시키면 안됨
        if (lunarMonthTable[lunIndex][lunMonth - 1] >= 3 && lunLeapMonth == 0) {
          lunDay = 1;
          lunLeapMonth = 1;
        }
        // 음달이거나 윤달을 계산 했을 겨우 달을 증가시키고 lunLeapMonth값 초기화
        else {
          lunMonth++;
          lunDay = 1;
          lunLeapMonth = 0;
        }

        // 음력의 달에 맞는 마지막날짜 초기화
        if (lunarMonthTable[lunIndex][lunMonth - 1] == 1) lunMonthDay = 29;
        else if (lunarMonthTable[lunIndex][lunMonth - 1] == 2) lunMonthDay = 30;
        else if (lunarMonthTable[lunIndex][lunMonth - 1] == 3) lunMonthDay = 29;
        else if (
          lunarMonthTable[lunIndex][lunMonth - 1] == 4 &&
          lunLeapMonth == 0
        )
          lunMonthDay = 29;
        else if (
          lunarMonthTable[lunIndex][lunMonth - 1] == 4 &&
          lunLeapMonth == 1
        )
          lunMonthDay = 30;
        else if (
          lunarMonthTable[lunIndex][lunMonth - 1] == 5 &&
          lunLeapMonth == 0
        )
          lunMonthDay = 30;
        else if (
          lunarMonthTable[lunIndex][lunMonth - 1] == 5 &&
          lunLeapMonth == 1
        )
          lunMonthDay = 29;
        else if (lunarMonthTable[lunIndex][lunMonth - 1] == 6) lunMonthDay = 30;
      } else lunDay++;
    }
  }

  // 양력을 음력날짜로 변환
  function solarToLunar(solYear, solMonth, solDay) {
    // 날짜 형식이 안맞을 경우 공백 반환
    if (
      !solYear ||
      solYear == 0 ||
      !solMonth ||
      solMonth == 0 ||
      !solDay ||
      solDay == 0
    ) {
      return "";
    }

    // 양력의 달마다의 일수
    var solMonthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // 윤년일 시 2월에 1일 추가
    if (solYear % 400 == 0 || (solYear % 4 == 0 && solYear % 100 != 0))
      solMonthDays[1] += 1;

    if (
      solMonth < 1 ||
      solMonth > 12 ||
      solDay < 1 ||
      solDay > solMonthDays[solMonth - 1]
    ) {
      return "";
    }

    // console.log("[testtt]solYear", solYear);
    // console.log("[testtt]solMonth", solMonth);
    // console.log("[testtt]solDay", solDay);
    /* 양력/음력 변환 */
    var date = lunarCalc(solYear, solMonth, solDay, 1);

    return (
      "음력 " +
      date.year +
      "년 " +
      (date.leapMonth ? "(윤)" : "") +
      date.month +
      "월 " +
      date.day +
      "일"
    );
  }

  // 양력을 음력날짜로 변환
  function solarToLunar_toJson(solYear, solMonth, solDay) {
    // 날짜 형식이 안맞을 경우 공백 반환
    if (
      !solYear ||
      solYear == 0 ||
      !solMonth ||
      solMonth == 0 ||
      !solDay ||
      solDay == 0
    ) {
      return "";
    }
    // 양력의 달마다의 일수
    var solMonthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // 윤년일 시 2월에 1일 추가
    if (solYear % 400 == 0 || (solYear % 4 == 0 && solYear % 100 != 0))
      solMonthDays[1] += 1;
    if (
      solMonth < 1 ||
      solMonth > 12 ||
      solDay < 1 ||
      solDay > solMonthDays[solMonth - 1]
    ) {
      return "";
    }
    /* 양력/음력 변환 */
    var date = lunarCalc(solYear, solMonth, solDay, 1);
    return {
      year: date.year,
      month: date.month,
      day: date.day,
      leap: date.leapMonth ? "(윤)" : "",
    };
  }

  function __GOOKSU_DECIDE_AS_JOOGI(
    paramYear,
    paramMonth,
    paramDay,
    paramHour,
    paramMinute,
    paramSecond
  ) {
    console.log("__GOOKSU_DECIDE_AS_JOOGI --- 0 --- 1");

    const decideYearFlag0 = parseInt(paramYear); // `올해 혹은 입력한 해`의 이전 해
    const decideYearFlagIndex0 = parseInt(paramYear) - 1930;
    const decideYearFlag1 = parseInt(paramYear) + 1; // `올해 혹은 입력한 해`
    const decideYearFlagIndex1 = parseInt(paramYear) + 1 - 1930; //
    const decideYearFlag2 = parseInt(paramYear + 2); // `올해 혹은 입력한 해`의 다음해의 동지-대한, 우수처리
    const decideYearFlagIndex2 = parseInt(paramYear) + 2 - 1930;

    const joonggi0 = objectJooggi.default.JOONGGI[decideYearFlagIndex0];
    const joonggi1 = objectJooggi.default.JOONGGI[decideYearFlagIndex1];
    const joonggi2 = objectJooggi.default.JOONGGI[decideYearFlagIndex2];

    const yearFormat1 = "YYYY-MM-DD HH:mm:ss";

    const decideJoolgiDate1 = [
      // 입력한 해 이전 0,1,2,3,4,5,6,7,8,9,10,11
      {
        yearAppend: -1,
        gookDecideColumnIndex: 8,
        date: moment(joonggi0.joonggi[21], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 9,
        date: moment(joonggi0.joonggi[22], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 10,
        date: moment(joonggi0.joonggi[23], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 11,
        date: moment(joonggi1.joonggi[12], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      // 입력한 해
      {
        yearAppend: 0,
        gookDecideColumnIndex: 0,
        date: moment(joonggi1.joonggi[13], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 1,
        date: moment(joonggi1.joonggi[14], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 2,
        date: moment(joonggi1.joonggi[15], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 3,
        date: moment(joonggi1.joonggi[16], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 4,
        date: moment(joonggi1.joonggi[17], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 5,
        date: moment(joonggi1.joonggi[18], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 6,
        date: moment(joonggi1.joonggi[19], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 7,
        date: moment(joonggi1.joonggi[20], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 8,
        date: moment(joonggi1.joonggi[21], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 9,
        date: moment(joonggi1.joonggi[22], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 10,
        date: moment(joonggi1.joonggi[23], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 11,
        date: moment(joonggi2.joonggi[12], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      // 입력한해 다음해 중기
      {
        yearAppend: 1,
        gookDecideColumnIndex: 0,
        date: moment(joonggi2.joonggi[13], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 1,
        date: moment(joonggi2.joonggi[14], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 2,
        date: moment(joonggi2.joonggi[15], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 3,
        date: moment(joonggi2.joonggi[16], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
    ];
    var forloopIndex = 0;
    for (var i = 0; i < decideJoolgiDate1.length; i++) {
      var moment1 = moment(decideJoolgiDate1[i].date, yearFormat1);
      var check1 = moment(
        `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}:${paramSecond}`
      );
      if (moment1.isBefore(check1)) {
        forloopIndex = i;
      } else {
      }
    }

    var gookSuDecideArray1 =
      jeolgiMixArray1[decideJoolgiDate1[forloopIndex].gookDecideColumnIndex];
    setJoongGiIndex(decideJoolgiDate1[forloopIndex].gookDecideColumnIndex);
    setGookSuDecideArray(gookSuDecideArray1);

    console.log("당면 [중기판단] :::: ", forloopIndex);
    console.log("당면 [중기판단] decideJoolgiDate1", decideJoolgiDate1);
    console.log(
      "당면 [중기판단] gookDecideColumnIndex] :::: ",
      decideJoolgiDate1[forloopIndex].gookDecideColumnIndex
    );
    console.log(
      "당면 [중기판단]  - jooggi Name ",
      jeolgi0[decideJoolgiDate1[forloopIndex].gookDecideColumnIndex]
    );

    // '우수 (13) - 춘분 (14)',
    // '춘분 (14) - 곡우 (15)',
    // '곡우 (15) - 소만 (16)',
    // '소만 (16) - 하지 (17)',
    // '하지 (17) - 대서 (18)',
    // '대서 (18) - 처서 (19)',
    // '처서 (19) - 추분 (20)',
    // '추분 (20) - 상강 (21)',
    // '상강 (21) - 소설 (22)',
    // '소설 (22) - 동지 (23)',
    // '동지 (23) - 대한 다음해의(12)',
    // '대한 다음해의(12) - 우수 다음해의(13)',
    const gyeolGoolgiString =
      jeolgi0[decideJoolgiDate1[forloopIndex].gookDecideColumnIndex];
    if (gyeolGoolgiString.indexOf("동지 (23) -") > -1) {
      setGyeolGoolgi1(11);
    }
    if (gyeolGoolgiString.indexOf("대한 다음해의(12) -") > -1) {
      setGyeolGoolgi1(12);
    }
    if (gyeolGoolgiString.indexOf("대한 (12) -") > -1) {
      setGyeolGoolgi1(12);
    }
    if (gyeolGoolgiString.indexOf("우수 (13) -") > -1) {
      setGyeolGoolgi1(1);
    }
    if (gyeolGoolgiString.indexOf("춘분 (14) -") > -1) {
      setGyeolGoolgi1(2);
    }
    if (gyeolGoolgiString.indexOf("곡우 (15) -") > -1) {
      setGyeolGoolgi1(3);
    }
    if (gyeolGoolgiString.indexOf("소만 (16) -") > -1) {
      setGyeolGoolgi1(4);
    }
    if (gyeolGoolgiString.indexOf("하지 (17) -") > -1) {
      setGyeolGoolgi1(5);
    }
    if (gyeolGoolgiString.indexOf("대서 (18) -") > -1) {
      setGyeolGoolgi1(6);
    }
    if (gyeolGoolgiString.indexOf("처서 (19) -") > -1) {
      setGyeolGoolgi1(7);
    }
    if (gyeolGoolgiString.indexOf("추분 (20) -") > -1) {
      setGyeolGoolgi1(8);
    }
    if (gyeolGoolgiString.indexOf("상강 (21) -") > -1) {
      setGyeolGoolgi1(9);
    }
    if (gyeolGoolgiString.indexOf("소설 (22) -") > -1) {
      setGyeolGoolgi1(10);
    }

    console.log(`"0" + parseInt(paramHour)  + 1`, parseInt(paramHour) + 1);
    console.log(
      `"0" + parseInt(paramMinute)  - 18`,
      parseInt(paramMinute) - 18
    );
    // DEV
    // setCurrentDateData(moment(`${paramYear}-${paramMonth}-${paramDay} ${"03"}:${"10"}`, "YYYY-MM-DD HH:mm"), gookSuDecideArray1)
    // PROD
    setCurrentDateData(
      moment(
        `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}`,
        "YYYY-MM-DD HH:mm"
      ),
      gookSuDecideArray1
    );
  }

  // 2023-01-31작업
  useEffect(() => {
    // console.log("selectedSiIndex", selectedSiIndex)
    // console.log("gyeolGoolgi1", gyeolGoolgi1);
    // console.log("ganzi_0")
    //
    // ganzi_0[]

    if (selectedSiIndex >= 0 && gyeolGoolgi1 > 0) {
      console.log("selectedSiIndex", selectedSiIndex);
      console.log("jiduTable1[selectedSiIndex]", jiduTable1[selectedSiIndex]);
      console.log(
        `@@ jiduTable1[selectedSiIndex][gyeolGoolgi1 - 1]`,
        jiduTable1[selectedSiIndex][gyeolGoolgi1 - 1]
      );
      const ganzzzi = jiduTable1[selectedSiIndex][gyeolGoolgi1 - 1];
      const ganjiString1 = ganji12_0[ganzzzi - 1];
      const jiduTableArr1 =
        objectILSANGMENU &&
        objectILSANGMENU.default &&
        objectILSANGMENU.default.VERSION001_JIDU;

      console.log("ganjiString1 -  ganji12_0[ganzzzi - 1]", ganjiString1);

      const indexOf1 = jiduTableArr1.findIndex((findItem, findIndex) => {
        console.log("jiduTableArr1 - findItem", findItem[4]);
        if (findItem[4].indexOf(ganjiString1) > -1) {
          return findItem;
        }
      });

      console.log("indexOf1 - indexOf1", indexOf1);

      setJiduIndex1(indexOf1);
    }
  }, [selectedSiIndex, gyeolGoolgi1]);

  function __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
    paramYear,
    paramMonth,
    paramDay,
    paramHour,
    paramMinute,
    paramSecond
  ) {
    const decideYearFlag0 = parseInt(paramYear); // `올해 혹은 입력한 해`의 이전 해
    const decideYearFlagIndex0 = parseInt(paramYear) - 1930;
    const decideYearFlag1 = parseInt(paramYear) + 1; // `올해 혹은 입력한 해`
    const decideYearFlagIndex1 = parseInt(paramYear) + 1 - 1930; //
    const decideYearFlag2 = parseInt(paramYear + 2); // `올해 혹은 입력한 해`의 다음해의 동지-대한, 우수처리
    const decideYearFlagIndex2 = parseInt(paramYear) + 2 - 1930;

    const joonggi0 = objectJooggi.default.JOONGGI[decideYearFlagIndex0];
    const joonggi1 = objectJooggi.default.JOONGGI[decideYearFlagIndex1];
    const joonggi2 = objectJooggi.default.JOONGGI[decideYearFlagIndex2];

    const yearFormat1 = "YYYY-MM-DD HH:mm:ss";
    const decideJoolgiDate1 = [
      // 입력한 해 이전 0,1,2,3,4,5,6,7,8,9,10,11
      {
        yearAppend: -1,
        gookDecideColumnIndex: 8,
        date: moment(joonggi0.joonggi[21], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 9,
        date: moment(joonggi0.joonggi[22], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 10,
        date: moment(joonggi0.joonggi[23], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: -1,
        gookDecideColumnIndex: 11,
        date: moment(joonggi1.joonggi[12], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      // 입력한 해
      {
        yearAppend: 0,
        gookDecideColumnIndex: 0,
        date: moment(joonggi1.joonggi[13], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 1,
        date: moment(joonggi1.joonggi[14], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 2,
        date: moment(joonggi1.joonggi[15], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 3,
        date: moment(joonggi1.joonggi[16], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 4,
        date: moment(joonggi1.joonggi[17], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 5,
        date: moment(joonggi1.joonggi[18], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 6,
        date: moment(joonggi1.joonggi[19], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 7,
        date: moment(joonggi1.joonggi[20], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 8,
        date: moment(joonggi1.joonggi[21], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 9,
        date: moment(joonggi1.joonggi[22], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 10,
        date: moment(joonggi1.joonggi[23], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 0,
        gookDecideColumnIndex: 11,
        date: moment(joonggi2.joonggi[12], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      // 입력한해 다음해 중기
      {
        yearAppend: 1,
        gookDecideColumnIndex: 0,
        date: moment(joonggi2.joonggi[13], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 1,
        date: moment(joonggi2.joonggi[14], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 2,
        date: moment(joonggi2.joonggi[15], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
      {
        yearAppend: 1,
        gookDecideColumnIndex: 3,
        date: moment(joonggi2.joonggi[16], "YYYY년 MM월 DD일 HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
    ];

    var forloopIndex = 0;
    for (var i = 0; i < decideJoolgiDate1.length; i++) {
      // console.log(`[] :::: ${i}` );
      var moment1 = moment(decideJoolgiDate1[i].date, yearFormat1);
      var check1 = moment(
        `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}:${paramSecond}`
      );
      console.log(
        `[중기판단] :::: ${decideJoolgiDate1[i].gookDecideColumnIndex}`,
        moment1.isBefore(check1)
      );
      // console.log(`[] :::: ${i}` ,moment1.format(yearFormat1));
      if (moment1.isBefore(check1)) {
        forloopIndex = i;
      } else {
      }
    }

    var gookSuDecideArray1 =
      jeolgiMixArray1[decideJoolgiDate1[forloopIndex].gookDecideColumnIndex];
    console.log("gookSuDecideArray1", gookSuDecideArray1);
    // console.log("[gookSuDecideArray1] :::: " , gookSuDecideArray1);
    setGookSuDecideArray_ILSAENG(gookSuDecideArray1);
    // setSelectedGook(gookSuDecideArray1[selectedSiIndex])

    setCurrentDateData_ILSAENG(
      moment(
        `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}`,
        "YYYY-MM-DD HH:mm"
      ),
      gookSuDecideArray1
    );
  }

  if (selectedGook == 0) {
    console.log("[초기로드시 타야함] 여기에 타는지 체크");

    setSearchedInput101(moment().format("YYYY"));
    setSearchedInput102(moment().format("MM"));
    setSearchedInput103(moment().format("DD"));
    setSearchedInput104(moment().format("HH"));
    setSearchedInput105(moment().format("mm"));
    setSearchedInput106(moment().format("ss"));

    setSearchedInput201(moment().format("YYYY"));
    setSearchedInput202(moment().format("MM"));
    setSearchedInput203(moment().format("DD"));
    setSearchedInput204(moment().format("HH"));
    setSearchedInput205(moment().format("mm"));
    setSearchedInput206(moment().format("ss"));

    __GOOKSU_DECIDE_AS_JOOGI(
      moment().format("YYYY"),
      moment().format("MM"),
      moment().format("DD"),
      moment().format("HH"),
      moment().format("mm"),
      moment().format("ss")
    );

    __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
      moment().format("YYYY"),
      moment().format("MM"),
      moment().format("DD"),
      moment().format("HH"),
      moment().format("mm"),
      moment().format("ss")
    );
  }

  useEffect(() => {
    if (textSelectedLocation != textPrevSelectedLocation) {
      setSearchedInput101(moment().format("YYYY"));
      setSearchedInput102(moment().format("MM"));
      setSearchedInput103(moment().format("DD"));
      setSearchedInput104(moment().format("HH"));
      setSearchedInput105(moment().format("mm"));
      setSearchedInput106(moment().format("ss"));

      setSearchedInput201(moment().format("YYYY"));
      setSearchedInput202(moment().format("MM"));
      setSearchedInput203(moment().format("DD"));
      setSearchedInput204(moment().format("HH"));
      setSearchedInput205(moment().format("mm"));
      setSearchedInput206(moment().format("ss"));

      __GOOKSU_DECIDE_AS_JOOGI(
        moment().format("YYYY"),
        moment().format("MM"),
        moment().format("DD"),
        moment().format("HH"),
        moment().format("mm"),
        moment().format("ss")
      );

      __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
        moment().format("YYYY"),
        moment().format("MM"),
        moment().format("DD"),
        moment().format("HH"),
        moment().format("mm"),
        moment().format("ss")
      );

      var timer1 = setTimeout(function () {
        setTextPrevSelectedLocation(textSelectedLocation);

        clearTimeout(timer1);
      }, 100);
    }
  }, [textSelectedLocation]);

  useEffect(() => {
    // 번호인식(휴대폰, 핸드폰)123123
    const yookimAi_Phone = window.localStorage.getItem("yookimAi_Phone");

    if (yookimAi_Phone && yookimAi_Phone != "") {
      var phonePureValue = yookimAi_Phone.replaceAll("+").replaceAll("-");
      setMessageFromAndroid("" + phonePureValue);
    } else {
      window.location.href = "/otp";
    }

    setSearchedInput101(moment().format("YYYY"));
    setSearchedInput102(moment().format("MM"));
    setSearchedInput103(moment().format("DD"));
    setSearchedInput104(moment().format("HH"));
    setSearchedInput105(moment().format("mm"));
    setSearchedInput106(moment().format("ss"));

    setSearchedInput201(moment().format("YYYY"));
    setSearchedInput202(moment().format("MM"));
    setSearchedInput203(moment().format("DD"));
    setSearchedInput204(moment().format("HH"));
    setSearchedInput205(moment().format("mm"));
    setSearchedInput206(moment().format("ss"));

    __GOOKSU_DECIDE_AS_JOOGI(
      moment().format("YYYY"),
      moment().format("MM"),
      moment().format("DD"),
      moment().format("HH"),
      moment().format("mm"),
      moment().format("ss")
    );

    __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
      moment().format("YYYY"),
      moment().format("MM"),
      moment().format("DD"),
      moment().format("HH"),
      moment().format("mm"),
      moment().format("ss")
    );

    // @@ TODO
    // 1초마다 점검

    // if (messageFromAndroid == "") {

    //     setBlockSystem(true);
    // }
    // if (messageFromAndroid != "") {

    //     setBlockSystem(false) ;
    // }

    timer = setInterval(() => {
      // console.log('[CHECK] - RUN - 1 second check')
      // 다음 시간대체크 (팝업 승인 거절 체크); => 일정 시간뒤에 자동 거절
      // @@ TODO
      // if (locationRef.current == "") {

      // } else {
      //     clearInterval(timer);
      // }
      // console.log("rerenderCount.current", rerenderCount.current)
      timeCheck(locationRef.current);
    }, 1000);
    return () => clearInterval(timer);

    //   window && window.BRIDGE && window.BRIDGE.testAndroid();
  }, []);

  useEffect(() => {
    if (activeEffect1 != "") {
      var timer1 = setTimeout(function () {
        setActiveEffect1("");
        clearTimeout(timer1);
      }, 100);
    }
  }, [activeEffect1]);

  useEffect(() => {
    setSaveInput100("");
    setSaveInput101("");
    setSaveInput102("");
    setSaveInput102_1("");
    setSaveInput103("");
    setSaveInput104("");
    setSaveInput105("");
    setSaveInput106("");
    setSaveInput107("");
    setSaveInput108("");
    setSaveInput109("");
    setSaveInput110("");
    setSaveResult001({
      col001: "",
      col002: "",
      col003: "",
      col004: "",
      col005: "",
      col006: "",
      col007: "",
      col008: "",
      col009: "",
      col010: "",
      col011: "",
    });
  }, [htmlPopupContent]);

  useEffect(() => {
    // console.log("[testtttttt]searchedInput102", searchedInput102)
    // console.log("[testtttttt]searchedInput102", searchedInput103)
    if (searchedInput103 == 31) {
      // 1,3,5,7,8,10, 12
      // 2,4,6,9, 11
      var int1 = parseInt(searchedInput102);
      console.log("int1", int1);
      if (
        int1 == 1 ||
        int1 == 3 ||
        int1 == 5 ||
        int1 == 7 ||
        int1 == 8 ||
        int1 == 10 ||
        int1 == 12
      ) {
        // setSearchedInput103(searchedInput103)
      } else {
        setSearchedInput103("01");
      }
    }
  }, [searchedInput102]);
  useEffect(() => {
    // console.log("[testtttttt]searchedInput102", searchedInput202)
    // console.log("[testtttttt]searchedInput102", searchedInput203)
    if (searchedInput203 == 31) {
      // 1,3,5,7,8,10, 12
      // 2,4,6,9, 11
      var int1 = parseInt(searchedInput202);
      if (
        int1 == 1 ||
        int1 == 3 ||
        int1 == 5 ||
        int1 == 7 ||
        int1 == 8 ||
        int1 == 10 ||
        int1 == 12
      ) {
      } else {
        setSearchedInput203("01");
      }
    }
  }, [searchedInput202]);

  const [fbContactKeyName, setFbContactKeyName] = useState("");
  // const [fbContactKeyName, setFbContactKeyName] = useState('-N81fhx30HJMzKKKhwSK'); // 01025363850 / -N81fhx30HJMzKKKhwSK

  // console.log("fbContactKeyName", fbContactKeyName)

  // 카운트 기능
  useEffect(() => {
    if (fbContactKeyName != "") {
      firebase
        .database()
        .ref()
        .child("contacts")
        .child(`/${fbContactKeyName}`)
        .update({ recent_date: moment().format("YYYY-MM-DD HH:mm:ss") });
      firebase
        .database()
        .ref()
        .child("contacts")
        .child(`/${fbContactKeyName}/date`)
        .push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`);
    }
  }, [fbContactKeyName]);

  //20230130
  function MyComponent() {
    const openWindow = () => {
      //const url = 'http://gabook.co.kr/static/juya.html';
      const url = "http://vwck.org/ai/juya.html";
      // 새 창을 열기 위한 JavaScript
      window.open(url, "_blank");
    };

    return (
      <div>
        {/* 버튼을 클릭할 때 openWindow 함수를 호출하도록 onClick 이벤트를 추가합니다. */}
        <button onClick={openWindow}>버튼 클릭</button>
      </div>
    );
  }

  //20230613
  //20230613
  //20230613
  //20240215
  /* 
var testClientIP = "111.111.111.111";
const [clientIP, setIP] = useState(testClientIP);
*/

  const [clientIP, setIP] = useState("");

  /*
const getData = async () => {
  console.log("clientIP 1->" + clientIP);
  const ipData = await fetch('https://geolocation-db.com/json/');
  clientIP = await ipData.json();
  console.log("clientIP 2->" + clientIP);
  setIP(clientIP);
};
*/

  const getData = async () => {
    try {
      const ipDataResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipDataResponse.json();
      const clientIP = ipData.ip;
      console.log("clientIP->" + clientIP);
      setIP(clientIP);
    } catch (error) {
      console.error("Error fetching IP data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const userAgent = window.navigator.userAgent;
  var isPcFlag = false;

  /*
console.log("clientIP-->"+ clientIP);
console.log("userAgent-->"+ userAgent);
*/

  if (userAgent.indexOf("Windows") != -1) {
    isPcFlag = true;
    console.log("PC에서 접속 중입니다");
  }

  if (isPcFlag) {
    //컴퓨터로 접속할때
    console.log("clientIP_20240215_1-->" + clientIP);
    console.log("fbContactKeyName-->" + fbContactKeyName);

    if (clientIP) {
      //클라이언트 아이피가 인식 되었을 경우

      if (fbContactKeyName) {
        //고유식별번호 인식 되었을 경우

        var status7 = "";
        var registerIP = "";
        var myPhoneNumber = "";

        //등록된 아이피 및 검증
        const fetchDataRegisterIP = async (value) => {
          const userInfo = await firebase
            .database()
            .ref()
            .child("contacts")
            .child(`/${value}`)
            .once("value");
          status7 = userInfo.child("status7").val();
          registerIP = userInfo.child("contact_ip").val();
          myPhoneNumber = userInfo.child("contact").val();

          console.log("myPhoneNumber::" + myPhoneNumber);
          console.log("status7-->" + status7);
          console.log("clientIP-->" + clientIP);
          console.log("registerIP-->" + registerIP);

          //20230618 해당 계정 강제 차단 처리

          if (
            myPhoneNumber == "01072963424" ||
            myPhoneNumber == "01091290554" ||
            myPhoneNumber == "01046428325" ||
            // myPhoneNumber == "01053758761" ||
            myPhoneNumber == "01036943057" ||
            myPhoneNumber == "01056606532"
          ) {
            timer = null;
            alert("사용오류 입니다. 관리자에게 문의하시길 바랍니다.");
            //console.log("사용오류 입니다. 관리자에게 문의하시길 바랍니다.");
            window.location.href = "about:blank";
          } else {
            //if(myPhoneNumber == "01098777631"){

            /*
              console.log("myPhoneNumber-->" + myPhoneNumber);
              */
            if (status7 == "" || status7 == null || status7 == "NO") {
              // timer = null;
              // alert(
              //   "추가인증(PC/노트북)을 관리자에게서 서비스 요청하여 주세요"
              // );
              //console.log("추가인증(PC/노트북)을 관리자에게서 서비스 요청하여 주세요");
              // window.location.href = "about:blank";
            } else if (status7 == "YES") {
              // if (clientIP !== registerIP) {
              //   timer = null;
              //   alert("등록된 단말기에서만 사용 가능합니다");
              //   //console.log("등록된 단말기에서만 사용가능합니다");
              //   window.location.href = "about:blank";
              // }
            }
          } //end myPhoneNumber
        };
        fetchDataRegisterIP(fbContactKeyName);
      } //end fbContactKeyName
    } //end clientIP
  } //end 컴퓨터로 접속할때

  //20230613
  //20230613
  //20230613

  useEffect(() => {
    // 2번
    window.addEventListener("message", function (e) {
      console.log("parent message");
      console.log(e.data); // { childData : 'test data' }
      console.log("e.origin : " + e.origin); //http://123.com(자식창 도메인)

      // console.log("e.data", e.data)
      // alert("e.data")
      if (e.data && e.data.childData) {
        const findIndex1 = gapja60_0.findIndex((findingItem) => {
          if (findingItem == e.data.childData) {
            return findingItem;
          }
        });

        if (findIndex1 > -1) {
          setBoolPopup1(false);
          setPopupType("");
          setSelectedBirthCalendarGapjaIndex1(findIndex1);
          setHtmlPopupContent("출산길일길시선택");
          return;
        }
      }
    });

    const eventFromAndroid = async (event) => {
      console.log("[번호 점검 체크] :::: -0001");
      console.log("[번호 점검 체크] :::: -0001 - 1", event);
      console.log(
        "[번호 점검 체크] :::: -0001 - 2",
        JSON.stringify(event.detail)
      );
      console.log("[번호 점검 체크] :::: -0001 - 3", event.detail.data);
      console.log("[번호 점검 체크] :::: -0002", event.detail.data);
      setMessageFromAndroid("" + event.detail.data);
    };
    window.addEventListener("javascriptFunction", eventFromAndroid);

    if (window.BRIDGE) {
      // window.BRIDGE.showToastMessage("안드로이드 앱과 서버가 연결 되었습니다.");
      window.BRIDGE.callJavaScriptFunction();
    }
    return () => {
      window.removeEventListener("javascriptFunction", eventFromAndroid);
    };
  }, []);

  useEffect(() => {
    if (messageFromAndroid == "") {
      setBlockSystem(true);
    }

    if (messageFromAndroid != "") {
      //todo : 8210번호일 경우 처리하는 로직 (8210이 어떤 번호인지 파악이 필요함)
      if (
        messageFromAndroid &&
        ("" + messageFromAndroid).indexOf("8210") == 0
      ) {
        console.log("[번호 점검 체크] :::: - 8210 (4)");

        firebase
          .database()
          .ref()
          .child("contacts")
          .on("value", (snapshot) => {
            const data = snapshot.val();
            console.warn("[테스트] >> data", data);
          });

        console.log("[테스트] >> --------------------");
        console.log("[테스트] >> []", messageFromAndroid.substr(4, 15));
        console.log("[테스트] >> []", "010" + messageFromAndroid.substr(4, 15));
        console.log("[테스트] >> ===================");
        firebase
          .database()
          .ref()
          .child("contacts")
          .orderByChild("contact")
          .equalTo("010" + messageFromAndroid.substr(4, 15))
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              const data = snapshot.val();

              console.log("[테스트] >> snapshot.val()");
              console.log("[테스트] >> data", data);
              const keyName =
                Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0];

              if (keyName) {
                // firebase.database().ref().child("contacts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                // firebase.database().ref().child("contacts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                if (data[keyName].status === "YES") {
                  setBlockSystem(false);

                  lastAuthCheckHandler(data, keyName);

                  if (data[keyName] && data[keyName].status3 === "YES") {
                    setYookimVersion("101");

                    if (data[keyName] && data[keyName].status5 == "YES") {
                      setYookimVersion("101_1");
                    }
                  } else {
                    setYookimVersion("100");
                  }
                } else {
                  setBlockSystem(true);
                }
              } else {
                setBlockSystem(true);
              }
              setFbContactKeyName(keyName);

              if (snapshot.val() && snapshot.val()[keyName].location != "") {
                setTextSelectedLocation(snapshot.val()[keyName].location);

                setSearchedInput101(moment().format("YYYY"));
                setSearchedInput102(moment().format("MM"));
                setSearchedInput103(moment().format("DD"));
                setSearchedInput104(moment().format("HH"));
                setSearchedInput105(moment().format("mm"));
                setSearchedInput106(moment().format("ss"));

                setSearchedInput201(moment().format("YYYY"));
                setSearchedInput202(moment().format("MM"));
                setSearchedInput203(moment().format("DD"));
                setSearchedInput204(moment().format("HH"));
                setSearchedInput205(moment().format("mm"));
                setSearchedInput206(moment().format("ss"));

                __GOOKSU_DECIDE_AS_JOOGI(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );

                __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );
              }
            } else {
              // alert("존재하지 않는 회원입니다.");
              setBlockSystem(true);
            }
          });
      }

      // todo : 10으로 시작하는 핸드폰 번호 처리 로직
      if (messageFromAndroid && ("" + messageFromAndroid).indexOf("10") == 0) {
        console.log("[번호 점검 체크] :::: - 10 (2)");
        console.log("[테스트] >> --------------------");
        console.log("[테스트] >> []", messageFromAndroid.substr(2, 15));
        console.log("[테스트] >> []", "010" + messageFromAndroid.substr(2, 15));
        console.log("[테스트] >> ===================");

        firebase
          .database()
          .ref()
          .child("contacts")
          .orderByChild("contact")
          .equalTo("010" + messageFromAndroid.substr(2, 15))
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              const data = snapshot.val();

              const keyName =
                Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0];

              if (keyName) {
                if (data[keyName].status === "YES") {
                  setBlockSystem(false);

                  lastAuthCheckHandler(data, keyName);

                  if (data[keyName] && data[keyName].status3 === "YES") {
                    setYookimVersion("101");
                    if (data[keyName] && data[keyName].status5 === "YES") {
                      setYookimVersion("101_1");
                      if (data[keyName] && data[keyName].status6 === "YES") {
                        setYookimVersion("101_1_1");
                      }
                    }
                  } else {
                    setYookimVersion("100");
                  }
                } else {
                  setBlockSystem(true);
                }
              } else {
                setBlockSystem(true);
              }

              setFbContactKeyName(keyName);
              if (snapshot.val() && snapshot.val()[keyName].location != "") {
                setTextSelectedLocation(snapshot.val()[keyName].location);

                setSearchedInput101(moment().format("YYYY"));
                setSearchedInput102(moment().format("MM"));
                setSearchedInput103(moment().format("DD"));
                setSearchedInput104(moment().format("HH"));
                setSearchedInput105(moment().format("mm"));
                setSearchedInput106(moment().format("ss"));

                setSearchedInput201(moment().format("YYYY"));
                setSearchedInput202(moment().format("MM"));
                setSearchedInput203(moment().format("DD"));
                setSearchedInput204(moment().format("HH"));
                setSearchedInput205(moment().format("mm"));
                setSearchedInput206(moment().format("ss"));

                __GOOKSU_DECIDE_AS_JOOGI(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );

                __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );
              }
            } else {
              // alert("존재하지 않는 회원입니다.");
              setBlockSystem(true);
            }
          });
      }

      if (messageFromAndroid && ("" + messageFromAndroid).indexOf("010") == 0) {
        console.log("[번호 점검 체크] :::: - 010 (3) ");

        console.log("[테스트] >> --------------------");
        console.log("[테스트] >> []", messageFromAndroid.substr(3, 15));
        console.log("[테스트] >> []", "010" + messageFromAndroid.substr(3, 15));
        console.log("[테스트] >> ===================");
        firebase
          .database()
          .ref()
          .child("contacts")
          .orderByChild("contact")
          .equalTo(messageFromAndroid.trim())
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              const data = snapshot.val();

              console.log("[테스트] >> snapshot.val()");
              console.log("[테스트] >> data", data);
              const keyName =
                Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0];

              if (keyName) {
                // firebase.database().ref().child("contacts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                // firebase.database().ref().child("contacts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                lastAuthCheckHandler(data, keyName);

                if (data[keyName].status == "YES") {
                  setBlockSystem(false);
                  if (data[keyName] && data[keyName].status3 == "YES") {
                    setYookimVersion("101");
                    if (data[keyName] && data[keyName].status5 == "YES") {
                      setYookimVersion("101_1");
                      if (data[keyName] && data[keyName].status6 == "YES") {
                        setYookimVersion("101_1_1");
                      }
                    }
                  } else {
                    setYookimVersion("100");
                  }
                } else {
                  setBlockSystem(true);
                }
              } else {
                setBlockSystem(true);
              }

              setFbContactKeyName(keyName);
              if (snapshot.val() && snapshot.val()[keyName].location != "") {
                setTextSelectedLocation(snapshot.val()[keyName].location);

                setSearchedInput101(moment().format("YYYY"));
                setSearchedInput102(moment().format("MM"));
                setSearchedInput103(moment().format("DD"));
                setSearchedInput104(moment().format("HH"));
                setSearchedInput105(moment().format("mm"));
                setSearchedInput106(moment().format("ss"));

                setSearchedInput201(moment().format("YYYY"));
                setSearchedInput202(moment().format("MM"));
                setSearchedInput203(moment().format("DD"));
                setSearchedInput204(moment().format("HH"));
                setSearchedInput205(moment().format("mm"));
                setSearchedInput206(moment().format("ss"));

                __GOOKSU_DECIDE_AS_JOOGI(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );

                __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                  moment().format("YYYY"),
                  moment().format("MM"),
                  moment().format("DD"),
                  moment().format("HH"),
                  moment().format("mm"),
                  moment().format("ss")
                );
              }
            } else {
              // alert("존재하지 않는 회원입니다.");
              setBlockSystem(true);
            }
          });
      }
    }
  }, [messageFromAndroid]);

  /**
   * 서버 정보로 부터 권한 체크 신규 부여
   * @param authData
   * @param keyName
   */
  const lastAuthCheckHandler = (authData, keyName) => {
    if (process.env.NODE_ENV === "development") {
      setYookimVersion("101_1_1");
    }

    setIsMyOpponentSForce(authData[keyName]?.status25 === "YES"); // 본인상대 보기 권한 여부
  };

  var timer;
  var changePopup = false;
  function timeCheck(paramLocation) {
    setCurrentTime(moment());
    const paramYear = moment().format("YYYY");
    const paramMonth = moment().format("MM");
    const paramDay = moment().format("DD");
    const paramHour = moment().format("HH");
    const paramMinute = moment().format("mm");
    const paramSec = moment().format("ss");

    const YYYYMMDD = moment(
      `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYYMMDD");
    const HHmm = moment(
      `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}`,
      "YYYY-MM-DD HH:mm"
    ).format("HH:mm");
    var tempMoment = moment(YYYYMMDD + HHmm, "YYYYMMDDHH:mm");
    const COMPARE_PARAM1 = moment(
      `${paramYear}-${paramMonth}-${paramDay} ${paramHour}:${paramMinute}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYYMMDD");

    var intSelectedIndex1 = 0;
    // console.log("[test time check] - 0 ", intSelectedIndex1 )

    if (locationRef.current != "") {
      // console.log("[test time check] - 1 ", intSelectedIndex1 )
      intSelectedIndex1 = locationA.findIndex((locationItem, locationIndex) => {
        if (locationItem.label == locationRef.current) {
          return locationItem;
        }
      });
      // console.log("[test time check] - 1 ", intSelectedIndex1 )
      if (intSelectedIndex1 < 0) {
        intSelectedIndex1 = 0;
      }
    }

    if (locationA[intSelectedIndex1].timeFlag == "KR") {
      // console.log("[test] time check - 0 - ", locationA[intSelectedIndex1]);
      // console.log("[test] locationA[intSelectedIndex1]", locationA[intSelectedIndex1])
      // console.log("[test] locationA[intSelectedIndex1].time", locationA[intSelectedIndex1].time)
      var selectedPlusMinusFlag = 0;

      // console.log(`parseInt(moment().format("HH")) % 2 == 1`, parseInt(moment().format("HH")) % 2 == 1)
      // console.log("> [국내] 시간체크1, 경계값(분)  :", moment(YYYYMMDD + locationA[intSelectedIndex1].time.ja[0], "YYYYMMDDHH:mm").format("mm").toString() )
      // console.log("> [국내] 시간체크2, 시간흐름(분) :", moment().format("mm").toString() )

      // // TOPOF // DEV ::::
      // if (
      //     // (rerenderCount.current % 60 < 2)
      //     // &&
      //     (moment(YYYYMMDD + locationA[intSelectedIndex1].time.ja[0], "YYYYMMDDHH:mm").format("mm").toString() == "32") //moment().format("mm").toString()
      //     &&
      //     parseInt(moment().format("HH")) % 2 == 1
      // ) {
      //     setPopupType("")
      //     setBoolPopup1(false)
      //     setHtmlPopupContent(null)
      //     setBoolTimeChangePopup(true);
      //     setClosePopupRecently(0);
      // }
      // // ENDOF // DEV ::::

      // TOPOF // PROD ::::
      if (
        rerenderCount.current % 60 < 2 &&
        moment(
          YYYYMMDD + locationA[intSelectedIndex1].time.ja[0],
          "YYYYMMDDHH:mm"
        )
          .format("mm")
          .toString() == moment().format("mm").toString() &&
        parseInt(moment().format("HH")) % 2 == 1
      ) {
        setPopupType("");
        setBoolPopup1(false);
        setHtmlPopupContent(null);
        setBoolTimeChangePopup(true);
        setClosePopupRecently(0);
      }
      // TOPOF // DEV ::::
    } else {
      // console.log("[해외]locationA[intSelectedIndex1].timeFlag ", locationA[intSelectedIndex1].timeFlag )
      const selected12Arr1 = [
        "자",
        "축",
        "인",
        "묘",
        "진",
        "사",
        "오",
        "미",
        "신",
        "유",
        "술",
        "해",
      ];
      const HHmmStr1 = HHmm.replaceAll(":", "");
      const HHmmInt1 = parseInt(HHmmStr1);
      // 해외 시간 검증
      var checkInt = 0;
      for (var i = 0; i < 24; i++) {
        if (locationA[intSelectedIndex1].option[i]) {
          const optionStr1 = locationA[intSelectedIndex1].option[
            i
          ].start.replaceAll(":", "");
          const optionStr2 = locationA[intSelectedIndex1].option[
            i
          ].end.replaceAll(":", "");
          const optionInt1 = parseInt(optionStr1);
          const optionInt2 = parseInt(optionStr2);

          const condition1 = optionInt1 - HHmmInt1;
          const condition2 = optionInt2 - HHmmInt1;

          if (condition1 > 0 && condition2 < 0) {
            break;
          }
          if (condition2 > 0 && condition1 < 0) {
            break;
          }
          checkInt = checkInt + 1;
        }
      }

      var selInt = checkInt;
      if (checkInt == 24) {
        selInt = 0;
      }

      console.log(
        `moment().format("mm").toString()`,
        moment().format("mm").toString()
      );
      console.log(
        ` parseInt(locationA[intSelectedIndex1].option[checkInt].start.substr(0,2)) % 2 == 1`,
        parseInt(
          locationA[intSelectedIndex1].option[selInt].start.substr(0, 2)
        ) %
          2 ==
          1
      );
      console.log(
        "[해외] >>  [시간 경계(전체)]",
        locationA[intSelectedIndex1].option[selInt].start
      );
      console.log(
        "[해외] >>  [시간 경계(시)]",
        parseInt(locationA[intSelectedIndex1].option[selInt].start.substr(0, 2))
      );
      console.log(
        "[해외] >>  [홀짝 판단] ",
        parseInt(
          locationA[intSelectedIndex1].option[selInt].start.substr(0, 2)
        ) %
          2 ==
          1
      );

      if (
        rerenderCount.current % 60 < 5 &&
        (parseInt(moment().format("mm")) == 0 ||
          parseInt(moment().format("mm")) == "00") &&
        parseInt(
          locationA[intSelectedIndex1].option[selInt].start.substr(0, 2)
        ) %
          2 ==
          1
      ) {
        setPopupType("");
        setBoolPopup1(false);
        setHtmlPopupContent(null);
        setBoolTimeChangePopup(true);
        setClosePopupRecently(0);
        recentlyRef.current = 0;
      }
    }
  }

  function nextPerson(paramMode) {
    if (paramMode == "당면") {
      const nextIndex = nextPersonOriginArray[selectedSiIndex] - 1;
      console.log("selectedSiIndex", selectedSiIndex);
      console.log(
        "nextPersonOriginArray[selectedSiIndex] - 1",
        nextPersonOriginArray[selectedSiIndex] - 1
      );
      console.log("nextIndex", nextIndex);
      setSelectedSiIndex(nextIndex);
      setSelectedSiIndex1(nextIndex + 1);
      setSelectedSi(ganji12[nextIndex]);
      setSelectedGook(gookSuDecideArray[nextIndex]);

      const findIndex1 = soYook_StandardArr.findIndex((findItem) => {
        if (findItem == soYook2) {
          return findItem;
        }
      });

      var lunarYookIndex3 = (nextIndex + findIndex1) % 6;
      setSoYook3(soYook_StandardArr[lunarYookIndex3]);

      const sookjabyeol = sookjabyeol_1.findIndex((findItem) => {
        if (findItem == gookSuDecideArray[nextIndex]) {
          return findItem;
        }
      });

      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(0부터 인덱스) + 0 ",
        sookjabyeol
      );
      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(1부터 일반값) 1",
        sookjabyeol + 1
      );
      if (selectedGook > 0) {
        setSootJaIndex(sookjabyeol + 1);
      } else {
        setSootJaIndex(+1);
      }
    } else {
    }
  }

  // paramSelectedSi == 12 일때 체크
  function specificPersonValue(paramSelectedSi) {
    setSelectedSiIndex(paramSelectedSi);
    setSelectedSiIndex1(paramSelectedSi + 1);
    setSelectedSi(ganji12[paramSelectedSi]);

    setSelectedGook(gookSuDecideArray[paramSelectedSi]);

    const sookjabyeol = sookjabyeol_1.findIndex((findItem) => {
      if (findItem == gookSuDecideArray[paramSelectedSi]) {
        return findItem;
      }
    });
    console.log(
      "[sookjabyeol] 숫자별 해당내용의 인덱스(0부터 인덱스) + 0 ",
      sookjabyeol
    );
    console.log(
      "[sookjabyeol] 숫자별 해당내용의 인덱스(1부터 일반값) 1",
      sookjabyeol + 1
    );
    if (selectedGook > 0) {
      setSootJaIndex(sookjabyeol + 1);
    } else {
      setSootJaIndex(+1);
    }
  }

  function setCategory(paramStr1) {
    setTextSelectedCategory(paramStr1);
  }

  // 갑자일 판단
  function setCurrentDateData(paramDate, paramCurrentJoongiDecideArray) {
    const YYYYMMDD = paramDate.format("YYYYMMDD");
    const HHmm = paramDate.format("HH:mm");

    console.log("paramDate : ", YYYYMMDD, HHmm);

    var intSelectedIndex1 = 0;
    // console.log("[해외]textSelectedLocation - 0", textSelectedLocation);
    if (textSelectedLocation != "") {
      // console.log("[해외]textSelectedLocation - 1", textSelectedLocation);
      intSelectedIndex1 = locationA.findIndex((locationItem, locationIndex) => {
        if (locationItem.label == textSelectedLocation) {
          return locationItem;
        }
      });
      // console.log("[해외]textSelectedLocation - 2", intSelectedIndex1);
      if (intSelectedIndex1 == -1) {
        intSelectedIndex1 = 0;
      }
    } else {
    }
    var selected12 = "자"; // 12: 자축인묘진사오미신유술해
    var selected12Index = 0; // 12: 자축인묘진사오미신유술해
    var selected10 = "갑"; // 10: 갑을별정무기경신임계
    var selected10Index = 0;
    var selectedPlusMinusFlag = 0;
    if (true) {
      var tempMoment = moment(YYYYMMDD + HHmm, "YYYYMMDDHH:mm");
      var tempMomentPrevious = moment(
        YYYYMMDD + HHmm,
        "YYYYMMDDHH:mm"
      ).subtract(1, "days");
      var tempMomentNext = moment(YYYYMMDD + HHmm, "YYYYMMDDHH:mm").add(
        1,
        "days"
      );

      // console.log("intSelectedIndex1", intSelectedIndex1)
      // console.log("locationA[intSelectedIndex1]", locationA[intSelectedIndex1])
      // console.log("locationA[intSelectedIndex1].time", locationA[intSelectedIndex1].time)

      var lastTimeFix = 0;
      if (locationA[intSelectedIndex1].timeFlag == "KR") {
        if (locationA[intSelectedIndex1].time.ja) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.ja[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.ja[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();

          if (diff1 >= 0) {
            selected12 = "자";
            selected12Index = 0;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.ja[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 0", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "해";
            //     selected12Index = 11;
            // } else {
            //     selected12 = "자";
            //     selected12Index = 0;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.chuk) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.chuk[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.chuk[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "축";
            selected12Index = 1;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.chuk[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 1", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "자";
            //     selected12Index = 0;
            // } else {

            //     selected12 = "축";
            //     selected12Index = 1;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.in) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.in[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.in[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "인";
            selected12Index = 2;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.in[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 2", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "축";
            //     selected12Index = 1;
            // } else {

            //     selected12 = "인";
            //     selected12Index = 2;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.myo) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.myo[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.myo[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "묘";
            selected12Index = 3;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.myo[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 3", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "인";
            //     selected12Index = 2;
            // } else {

            //     selected12 = "묘";
            //     selected12Index = 3;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.jin) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.jin[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.jin[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "진";
            selected12Index = 4;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.jin[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 4", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "묘";
            //     selected12Index = 3;
            // } else {

            //     selected12 = "진";
            //     selected12Index = 4;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.sa) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sa[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sa[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "사";
            selected12Index = 5;

            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.sa[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 5", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "진";
            //     selected12Index = 4;
            // } else {

            //     selected12 = "사";
            //     selected12Index = 5;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.oo) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.oo[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.oo[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "오";
            selected12Index = 6;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.oo[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 6", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "사";
            //     selected12Index = 5;
            // } else {

            //     selected12 = "오";
            //     selected12Index = 6;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.mi) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.mi[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.mi[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "미";
            selected12Index = 7;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.mi[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 7", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "오";
            //     selected12Index = 6;
            // } else {

            //     selected12 = "미";
            //     selected12Index = 7;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.sin) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sin[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sin[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            const timeBound1 =
              parseInt(
                moment(
                  YYYYMMDD + locationA[intSelectedIndex1].time.sin[0],
                  "YYYYMMDDHH:mm"
                ).format("mm")
              ) - parseInt(moment().format("mm")); //

            selected12 = "신";
            selected12Index = 8;
            // console.log("timeBound1 - 8", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "미";
            //     selected12Index = 7;
            // } else {

            //     selected12 = "신";
            //     selected12Index = 8;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.yu) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.yu[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.yu[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "유";
            selected12Index = 9;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.yu[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 9", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "신";
            //     selected12Index = 8;
            // } else {

            //     selected12 = "유";
            //     selected12Index = 9;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.sool) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sool[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sool[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "술";
            selected12Index = 10;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.sool[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 10", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "유";
            //     selected12Index = 9;
            // } else {
            //     selected12 = "술";
            //     selected12Index = 10;
            // }
          }
        }
        if (locationA[intSelectedIndex1].time.hae) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.hae[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.hae[0],
            "YYYYMMDDHH:mm"
          );
          var diffMomentLast2 = diffMoment && diffMoment.format("mm");
          var tempMomentLast1 = tempMoment && tempMoment.format("HH");
          var tempMomentLast2 = tempMoment && tempMoment.format("mm");

          console.log("tempMomentLast2", tempMomentLast2);
          console.log("diffMomentLast2", diffMomentLast2);

          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "해";
            selected12Index = 11;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.hae[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 11", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "술";
            //     selected12Index = 10;
            // } else {
            //     selected12 = "해";
            //     selected12Index = 11;
            // }

            if (
              parseInt(tempMomentLast1) == 23 &&
              parseInt(tempMomentLast2) >= parseInt(diffMomentLast2)
            ) {
              tempMoment = tempMomentNext;
              lastTimeFix = 1;
              selected12 = "자";
              selected12Index = 0;
            }
          }
        }
        selectedPlusMinusFlag = 0;
      } else {
        const selected12Arr1 = [
          "자",
          "축",
          "인",
          "묘",
          "진",
          "사",
          "오",
          "미",
          "신",
          "유",
          "술",
          "해",
        ];
        const HHmmStr1 = HHmm.replaceAll(":", "");
        const HHmmInt1 = parseInt(HHmmStr1);
        // 해외 시간 검증
        var checkInt = 0;
        for (var i = 0; i < 24; i++) {
          if (locationA[intSelectedIndex1].option[i]) {
            const optionStr1 = locationA[intSelectedIndex1].option[
              i
            ].start.replaceAll(":", "");
            const optionStr2 = locationA[intSelectedIndex1].option[
              i
            ].end.replaceAll(":", "");
            const optionInt1 = parseInt(optionStr1);
            const optionInt2 = parseInt(optionStr2);
            const condition1 = optionInt1 - HHmmInt1;
            const condition2 = optionInt2 - HHmmInt1;
            if (condition1 > 0 && condition2 < 0) {
              break;
            }
            if (condition2 > 0 && condition1 < 0) {
              break;
            }
            checkInt = checkInt + 1;
          }
        }

        // var selIndex = checkInt - 1;
        // if (selIndex < 0) {
        //     selIndex = 23
        // }
        var selIndex = checkInt;
        if (checkInt == 24) {
          selIndex = 0;
        }

        // selectedPlusMinusFlag = locationA[intSelectedIndex1].option[selIndex].day; // 날짜에서 더해서 (빼기, 더하기 공정)
        // selected12Index = parseInt((locationA[intSelectedIndex1].option[selIndex].timeIndex) / 2);
        // console.log("[test001] checkInt", checkInt)
        // console.log("[test001] locationA[intSelectedIndex1].option[selIndex].timeIndex", locationA[intSelectedIndex1].option[selIndex].timeIndex)

        if (locationA[intSelectedIndex1].option[selIndex].timeIndex == 23) {
          selectedPlusMinusFlag = selectedPlusMinusFlag + 1;
          selected12 = "자";
          selected12Index = 0;
        }

        if (locationA[intSelectedIndex1].option[selIndex].timeIndex == 0) {
          selectedPlusMinusFlag = selectedPlusMinusFlag + 1;
          selected12 = "자";
          selected12Index = 0;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 1 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 2
        ) {
          selected12 = "축";
          selected12Index = 1;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 3 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 4
        ) {
          selected12 = "인";
          selected12Index = 2;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 5 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 6
        ) {
          selected12 = "묘";
          selected12Index = 3;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 7 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 8
        ) {
          selected12 = "진";
          selected12Index = 4;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 9 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 10
        ) {
          selected12 = "사";
          selected12Index = 5;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 11 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 12
        ) {
          selected12 = "오";
          selected12Index = 6;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 13 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 14
        ) {
          selected12 = "미";
          selected12Index = 7;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 15 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 16
        ) {
          selected12 = "신";
          selected12Index = 8;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 17 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 18
        ) {
          selected12 = "유";
          selected12Index = 9;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 19 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 20
        ) {
          selected12 = "술";
          selected12Index = 10;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 21 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 22
        ) {
          selected12 = "해";
          selected12Index = 11;
        }
      }
      var day1 = moment("1930-07-13", "YYYY-MM-DD");
      // 일자 바뀐 버전
      var day2 = paramDate;
      var diffDays = day2.diff(day1, "days");
      var diffDays_60 = (diffDays + 0) % 60;

      if (diffDays_60 < 0) {
        diffDays_60 = 60 - diffDays_60;
      }

      const fix12Index = selected12Index;
      // console.log("gapja60[diffDays_60]", gapja60[diffDays_60])// 0일때 처리
      // console.log("selected12Index", fix12Index)
      // console.log("selected12Index + 1", fix12Index + 1)
      // console.log("[해외][도시별 보정 전] 0 selectedGapjaIndex", diffDays_60 + 1)
      // console.log("[해외][도시별 보정 후] + selectedGapjaIndex", diffDays_60 + 1 + parseInt(selectedPlusMinusFlag))
      // console.log(`[해외] 보정값 확인`, parseInt(selectedPlusMinusFlag))

      var DIFF_FIX =
        diffDays_60 + parseInt(selectedPlusMinusFlag) + lastTimeFix;
      // console.log(`[해외] 보정값 확인`, parseInt(selectedPlusMinusFlag))

      if (DIFF_FIX >= 60) {
        DIFF_FIX = 0;
      }

      setSelectedGapja(gapja60[DIFF_FIX]);
      setSelectedGapjaIndex(DIFF_FIX + 1);
      setSelectedSi(ganji12[fix12Index]);
      setSelectedSiIndex(fix12Index);
      setSelectedSiIndex1(fix12Index + 1);

      const objectLunar = solarToLunar_toJson(
        paramDate.format("YYYY"),
        paramDate.format("MM"),
        paramDate.format("DD")
      );
      console.log("objectLunar", objectLunar);
      var lunarYook1 = "";
      var lunarYookIndex1 = -1;
      var lunarYookIndex2 = -1;
      var lunarYookIndex3 = -1;
      if (objectLunar.month == 1 || objectLunar.month == 7) {
        var lunarYook1 = "대안";
        lunarYookIndex1 = 0;
        lunarYookIndex2 = 0;
        lunarYookIndex3 = 0;
        setSoYook1(lunarYook1);
      }
      if (objectLunar.month == 2 || objectLunar.month == 8) {
        var lunarYook1 = "유연";
        lunarYookIndex1 = 1;
        lunarYookIndex2 = 1;
        lunarYookIndex3 = 1;
        setSoYook1(lunarYook1);
      }
      if (objectLunar.month == 3 || objectLunar.month == 9) {
        var lunarYook1 = "속희";
        lunarYookIndex1 = 2;
        lunarYookIndex2 = 2;
        lunarYookIndex3 = 2;
        setSoYook1(lunarYook1);
      }
      if (objectLunar.month == 4 || objectLunar.month == 10) {
        var lunarYook1 = "적구";
        lunarYookIndex1 = 3;
        lunarYookIndex2 = 3;
        lunarYookIndex3 = 3;
        setSoYook1(lunarYook1);
      }
      if (objectLunar.month == 5 || objectLunar.month == 11) {
        var lunarYook1 = "소길";
        lunarYookIndex1 = 4;
        lunarYookIndex2 = 4;
        lunarYookIndex3 = 4;
        setSoYook1(lunarYook1);
      }
      if (objectLunar.month == 6 || objectLunar.month == 12) {
        var lunarYook1 = "공망";
        lunarYookIndex1 = 5;
        lunarYookIndex2 = 5;
        lunarYookIndex3 = 5;
        setSoYook1(lunarYook1);
      }

      // console.log("lunarYookIndex1", lunarYookIndex1);
      // console.log("lunarYookIndex2",lunarYookIndex2);
      lunarYookIndex2 = (lunarYookIndex1 + objectLunar.day - 1) % 6;
      console.log("objectLunar.day", objectLunar.day);
      console.log("lunarYookIndex2", lunarYookIndex2);
      console.log("selectedSiIndex", fix12Index);
      // console.log("lunarYookIndex2 % 6", lunarYookIndex2 )
      setSoYook2(soYook_StandardArr[lunarYookIndex2]);
      lunarYookIndex3 = (fix12Index + lunarYookIndex2) % 6;
      setSoYook3(soYook_StandardArr[lunarYookIndex3]);

      // console.log("temp - 0")

      if (paramDate) {
        setCurrentDisplayDate(
          paramDate.add(parseInt(selectedPlusMinusFlag), "days")
        );
      } else {
        setCurrentDisplayDate(
          moment().add(parseInt(selectedPlusMinusFlag), "days")
        );
      }

      // default 에러를 위해서 초기화
      console.log("[DIFF_FIX]", DIFF_FIX);

      let selectedNaejungData = undefined;
      if (DIFF_FIX === 0 || DIFF_FIX === 60) {
        selectedNaejungData = require("../dataForm1/data1_1");
      }
      if (DIFF_FIX === 1) {
        selectedNaejungData = require("../dataForm1/data1_2");
      }
      if (DIFF_FIX === 2) {
        selectedNaejungData = require("../dataForm1/data1_3");
      }
      if (DIFF_FIX === 3) {
        selectedNaejungData = require("../dataForm1/data1_4");
      }
      if (DIFF_FIX === 4) {
        selectedNaejungData = require("../dataForm1/data1_5");
      }
      if (DIFF_FIX === 5) {
        selectedNaejungData = require("../dataForm1/data1_6");
      }
      if (DIFF_FIX === 6) {
        selectedNaejungData = require("../dataForm1/data1_7");
      }
      if (DIFF_FIX === 7) {
        selectedNaejungData = require("../dataForm1/data1_8");
      }
      if (DIFF_FIX === 8) {
        selectedNaejungData = require("../dataForm1/data1_9");
      }
      if (DIFF_FIX === 9) {
        selectedNaejungData = require("../dataForm1/data1_10");
      }
      if (DIFF_FIX === 10) {
        selectedNaejungData = require("../dataForm1/data2_1");
      }
      if (DIFF_FIX === 11) {
        selectedNaejungData = require("../dataForm1/data2_2");
      }
      if (DIFF_FIX === 12) {
        selectedNaejungData = require("../dataForm1/data2_3");
      }
      if (DIFF_FIX === 13) {
        selectedNaejungData = require("../dataForm1/data2_4");
      }
      if (DIFF_FIX === 14) {
        selectedNaejungData = require("../dataForm1/data2_5");
      }
      if (DIFF_FIX === 15) {
        selectedNaejungData = require("../dataForm1/data2_6");
      }
      if (DIFF_FIX === 16) {
        selectedNaejungData = require("../dataForm1/data2_7");
      }
      if (DIFF_FIX === 17) {
        selectedNaejungData = require("../dataForm1/data2_8");
      }
      if (DIFF_FIX === 18) {
        selectedNaejungData = require("../dataForm1/data2_9");
      }
      if (DIFF_FIX === 19) {
        selectedNaejungData = require("../dataForm1/data2_10");
      }
      if (DIFF_FIX === 20) {
        selectedNaejungData = require("../dataForm1/data3_1");
      }
      if (DIFF_FIX === 21) {
        selectedNaejungData = require("../dataForm1/data3_2");
      }
      if (DIFF_FIX === 22) {
        selectedNaejungData = require("../dataForm1/data3_3");
      }
      if (DIFF_FIX === 23) {
        selectedNaejungData = require("../dataForm1/data3_4");
      }
      if (DIFF_FIX === 24) {
        selectedNaejungData = require("../dataForm1/data3_5");
      }
      if (DIFF_FIX === 25) {
        selectedNaejungData = require("../dataForm1/data3_6");
      }
      if (DIFF_FIX === 26) {
        selectedNaejungData = require("../dataForm1/data3_7");
      }
      if (DIFF_FIX === 27) {
        selectedNaejungData = require("../dataForm1/data3_8");
      }
      if (DIFF_FIX === 28) {
        selectedNaejungData = require("../dataForm1/data3_9");
      }
      if (DIFF_FIX === 29) {
        selectedNaejungData = require("../dataForm1/data3_10");
      }
      if (DIFF_FIX === 30) {
        selectedNaejungData = require("../dataForm1/data4_1");
      }
      if (DIFF_FIX === 31) {
        selectedNaejungData = require("../dataForm1/data4_2");
      }
      if (DIFF_FIX === 32) {
        selectedNaejungData = require("../dataForm1/data4_3");
      }
      if (DIFF_FIX === 33) {
        selectedNaejungData = require("../dataForm1/data4_4");
      }
      if (DIFF_FIX === 34) {
        selectedNaejungData = require("../dataForm1/data4_5");
      }
      if (DIFF_FIX === 35) {
        selectedNaejungData = require("../dataForm1/data4_6");
      }
      if (DIFF_FIX === 36) {
        selectedNaejungData = require("../dataForm1/data4_7");
      }
      if (DIFF_FIX === 37) {
        selectedNaejungData = require("../dataForm1/data4_8");
      }
      if (DIFF_FIX === 38) {
        selectedNaejungData = require("../dataForm1/data4_9");
      }
      if (DIFF_FIX === 39) {
        selectedNaejungData = require("../dataForm1/data4_10");
      }
      if (DIFF_FIX === 40) {
        selectedNaejungData = require("../dataForm1/data5_1");
      }
      if (DIFF_FIX === 41) {
        selectedNaejungData = require("../dataForm1/data5_2");
      }
      if (DIFF_FIX === 42) {
        selectedNaejungData = require("../dataForm1/data5_3");
      }
      if (DIFF_FIX === 43) {
        selectedNaejungData = require("../dataForm1/data5_4");
      }
      if (DIFF_FIX === 44) {
        selectedNaejungData = require("../dataForm1/data5_5");
      }
      if (DIFF_FIX === 45) {
        selectedNaejungData = require("../dataForm1/data5_6");
      }
      if (DIFF_FIX === 46) {
        selectedNaejungData = require("../dataForm1/data5_7");
      }
      if (DIFF_FIX === 47) {
        selectedNaejungData = require("../dataForm1/data5_8");
      }
      if (DIFF_FIX === 48) {
        selectedNaejungData = require("../dataForm1/data5_9");
      }
      if (DIFF_FIX === 49) {
        selectedNaejungData = require("../dataForm1/data5_10");
      }
      if (DIFF_FIX === 50) {
        selectedNaejungData = require("../dataForm1/data6_1");
      }
      if (DIFF_FIX === 51) {
        selectedNaejungData = require("../dataForm1/data6_2");
      }
      if (DIFF_FIX === 52) {
        selectedNaejungData = require("../dataForm1/data6_3");
      }
      if (DIFF_FIX === 53) {
        selectedNaejungData = require("../dataForm1/data6_4");
      }
      if (DIFF_FIX === 54) {
        selectedNaejungData = require("../dataForm1/data6_5");
      }
      if (DIFF_FIX === 55) {
        selectedNaejungData = require("../dataForm1/data6_6");
      }
      if (DIFF_FIX === 56) {
        selectedNaejungData = require("../dataForm1/data6_7");
      }
      if (DIFF_FIX === 57) {
        selectedNaejungData = require("../dataForm1/data6_8");
      }
      if (DIFF_FIX === 58) {
        selectedNaejungData = require("../dataForm1/data6_9");
      }
      if (DIFF_FIX === 59) {
        selectedNaejungData = require("../dataForm1/data6_10");
      }

      if (selectedNaejungData) {
        setSelectedNaejung(selectedNaejungData);
      }

      // //  TEMP
      // if (DIFF_FIX > 14 && DIFF_FIX % 4 == 1) {
      //     setSelectedNaejung(require("../dataForm1/data1_1"));
      // }
      // if (DIFF_FIX > 14 && DIFF_FIX % 4 == 2) {
      //     setSelectedNaejung(require("../dataForm1/data1_2"));
      // }
      // if (DIFF_FIX > 14 && DIFF_FIX % 4 == 3) {
      //     setSelectedNaejung(require("../dataForm1/data2_2"));
      // }
      // if (DIFF_FIX > 14 && DIFF_FIX % 4 == 0) {
      //     setSelectedNaejung(require("../dataForm1/data2_3"));
      // }

      setGookSuDecideArray(paramCurrentJoongiDecideArray);

      setSelectedGook(paramCurrentJoongiDecideArray[fix12Index]);

      const sookjabyeol = sookjabyeol_1.findIndex((findItem) => {
        if (findItem == paramCurrentJoongiDecideArray[fix12Index]) {
          return findItem;
        }
      });

      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(0부터 인덱스) + 0 ",
        sookjabyeol
      );
      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(1부터 일반값) 1",
        sookjabyeol + 1
      );
      if (selectedGook > 0) {
        setSootJaIndex(sookjabyeol + 1);
      } else {
        setSootJaIndex(+1);
      }
    }
  }

  function setCurrentDateData_ILSAENG(
    paramDate,
    paramCurrentJoongiDecideArray
  ) {
    const YYYYMMDD = paramDate.format("YYYYMMDD");
    const HHmm = paramDate.format("HH:mm");

    var intSelectedIndex1 = 0;
    if (textSelectedLocation != "") {
      intSelectedIndex1 = locationA.findIndex((locationItem, locationIndex) => {
        if (locationItem.label == textSelectedLocation) {
          return locationItem;
        }
      });

      if (intSelectedIndex1 < 0) {
        intSelectedIndex1 = 0;
      }
    }
    var selected12 = "자"; // 12: 자축인묘진사오미신유술해
    var selected12Index = 0; // 12: 자축인묘진사오미신유술해
    var selected10 = "갑"; // 10: 갑을별정무기경신임계
    var selected10Index = 0;
    var selectedPlusMinusFlag = 0;
    if (true) {
      var tempMoment = moment(YYYYMMDD + HHmm, "YYYYMMDDHH:mm");
      var tempMomentPrevious = moment(
        YYYYMMDD + HHmm,
        "YYYYMMDDHH:mm"
      ).subtract(1, "days");
      var tempMomentNext = moment(YYYYMMDD + HHmm, "YYYYMMDDHH:mm").add(
        1,
        "days"
      );

      var lastTimeFix = 0;
      if (locationA[intSelectedIndex1].timeFlag == "KR") {
        if (locationA[intSelectedIndex1].time.ja) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.ja[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.ja[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "자";
            selected12Index = 0;
          }
        }
        if (locationA[intSelectedIndex1].time.chuk) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.chuk[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.chuk[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "축";
            selected12Index = 1;
          }
        }
        if (locationA[intSelectedIndex1].time.in) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.in[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.in[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "인";
            selected12Index = 2;
          }
        }
        if (locationA[intSelectedIndex1].time.myo) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.myo[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.myo[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "묘";
            selected12Index = 3;
          }
        }
        if (locationA[intSelectedIndex1].time.jin) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.jin[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.jin[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "진";
            selected12Index = 4;
          }
        }
        if (locationA[intSelectedIndex1].time.sa) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sa[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sa[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "사";
            selected12Index = 5;
          }
        }
        if (locationA[intSelectedIndex1].time.oo) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.oo[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.oo[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "오";
            selected12Index = 6;
          }
        }
        if (locationA[intSelectedIndex1].time.mi) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.mi[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.mi[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "미";
            selected12Index = 7;
          }
        }
        if (locationA[intSelectedIndex1].time.sin) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sin[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sin[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "신";
            selected12Index = 8;
          }
        }
        if (locationA[intSelectedIndex1].time.yu) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.yu[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.yu[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "유";
            selected12Index = 9;
          }
        }
        if (locationA[intSelectedIndex1].time.sool) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.sool[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.sool[0],
            "YYYYMMDDHH:mm"
          );
          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "술";
            selected12Index = 10;
          }
        }
        if (locationA[intSelectedIndex1].time.hae) {
          selectedPlusMinusFlag = parseInt(
            locationA[intSelectedIndex1].time.hae[2]
          );
          var diffMoment = moment(
            YYYYMMDD + locationA[intSelectedIndex1].time.hae[0],
            "YYYYMMDDHH:mm"
          );
          var diffMomentLast2 = diffMoment && diffMoment.format("mm");
          var tempMomentLast1 = tempMoment && tempMoment.format("HH");
          var tempMomentLast2 = tempMoment && tempMoment.format("mm");

          const diff1 =
            tempMoment.toDate().getTime() - diffMoment.toDate().getTime();
          if (diff1 >= 0) {
            selected12 = "해";
            selected12Index = 11;
            // const timeBound1 = parseInt(moment(YYYYMMDD + locationA[intSelectedIndex1].time.hae[0], "YYYYMMDDHH:mm").format("mm")) -  parseInt(moment().format("mm")) //
            // console.log("timeBound1 - 11", timeBound1);
            // if (timeBound1 > 0) {
            //     selected12 = "술";
            //     selected12Index = 10;
            // } else {
            //     selected12 = "해";
            //     selected12Index = 11;
            // }
            if (
              parseInt(tempMomentLast1) == 23 &&
              parseInt(tempMomentLast2) >= parseInt(diffMomentLast2)
            ) {
              tempMoment = tempMomentNext;
              lastTimeFix = 1;
              selected12 = "자";
              selected12Index = 0;
            }
          }
        }
        // console.log("selectedPlusMinusFlag", selectedPlusMinusFlag);
        selectedPlusMinusFlag = 0;
      } else {
        const selected12Arr1 = [
          "자",
          "축",
          "인",
          "묘",
          "진",
          "사",
          "오",
          "미",
          "신",
          "유",
          "술",
          "해",
        ];
        const HHmmStr1 = HHmm.replaceAll(":", "");
        const HHmmInt1 = parseInt(HHmmStr1);
        // 해외 시간 검증
        var checkInt = 0;
        for (var i = 0; i < 24; i++) {
          if (locationA[intSelectedIndex1].option[i]) {
            const optionStr1 = locationA[intSelectedIndex1].option[
              i
            ].start.replaceAll(":", "");
            const optionStr2 = locationA[intSelectedIndex1].option[
              i
            ].end.replaceAll(":", "");
            const optionInt1 = parseInt(optionStr1);
            const optionInt2 = parseInt(optionStr2);
            const condition1 = optionInt1 - HHmmInt1;
            const condition2 = optionInt2 - HHmmInt1;
            if (condition1 > 0 && condition2 < 0) {
              break;
            }
            if (condition2 > 0 && condition1 < 0) {
              break;
            }
            checkInt = checkInt + 1;
          }
        }

        // var selIndex = checkInt - 1;
        // if (selIndex < 0) {
        //     selIndex = 23
        // }
        var selIndex = checkInt;
        if (checkInt == 24) {
          selIndex = 0;
        }

        // selectedPlusMinusFlag = locationA[intSelectedIndex1].option[selIndex].day; // 날짜에서 더해서 (빼기, 더하기 공정)
        // selected12Index = parseInt((locationA[intSelectedIndex1].option[selIndex].timeIndex) / 2);
        // console.log("[test001] checkInt", checkInt)
        // console.log("[test001] locationA[intSelectedIndex1].option[selIndex].timeIndex", locationA[intSelectedIndex1].option[selIndex].timeIndex)

        if (locationA[intSelectedIndex1].option[selIndex].timeIndex == 23) {
          selectedPlusMinusFlag = selectedPlusMinusFlag + 1;
          selected12 = "자";
          selected12Index = 0;
        }

        if (locationA[intSelectedIndex1].option[selIndex].timeIndex == 0) {
          selectedPlusMinusFlag = selectedPlusMinusFlag + 1;
          selected12 = "자";
          selected12Index = 0;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 1 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 2
        ) {
          selected12 = "축";
          selected12Index = 1;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 3 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 4
        ) {
          selected12 = "인";
          selected12Index = 2;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 5 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 6
        ) {
          selected12 = "묘";
          selected12Index = 3;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 7 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 8
        ) {
          selected12 = "진";
          selected12Index = 4;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 9 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 10
        ) {
          selected12 = "사";
          selected12Index = 5;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 11 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 12
        ) {
          selected12 = "오";
          selected12Index = 6;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 13 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 14
        ) {
          selected12 = "미";
          selected12Index = 7;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 15 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 16
        ) {
          selected12 = "신";
          selected12Index = 8;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 17 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 18
        ) {
          selected12 = "유";
          selected12Index = 9;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 19 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 20
        ) {
          selected12 = "술";
          selected12Index = 10;
        }
        if (
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 21 ||
          locationA[intSelectedIndex1].option[selIndex].timeIndex == 22
        ) {
          selected12 = "해";
          selected12Index = 11;
        }
      }

      // 2022-05-11 - 갑자일 (정해져있는 갑자일 중에 계속 60갑자가 계속 돕니다. / 만세력 전체 앱에서 확인 완료)
      // 1930-07-13 - 갑자일 (정해져있는 갑자일 중에 계속 60갑자가 계속 돕니다. / 만세력 전체 앱에서 확인 완료)
      var day1 = moment("1930-07-13", "YYYY-MM-DD");
      // 일자 바뀐 버전
      var day2 = paramDate;
      var diffDays = day2.diff(day1, "days");
      var diffDays_60 = (diffDays + 0) % 60;
      // console.log("diffDays", diffDays)
      // console.log("diffDays_60", diffDays_60)

      if (diffDays_60 < 0) {
        diffDays_60 = 60 - diffDays_60;
      }
      // console.log("gapja60[diffDays_60]", gapja60[diffDays_60])// 0일때 처리

      const fix12Index = selected12Index;
      // console.log("[중기판단2 일생] selected12Index", selected12Index)
      // console.log("[중기판단2 일생] selected12Index + 1", fix12Index + 1)
      // console.log(" => ganji12[selected12Index]", ganji12[fix12Index]);
      // console.log("[중기판단2 일생] [도시별 보정 전] 0 selectedGapjaIndex", diffDays_60 + 1)
      // console.log("[중기판단2 일생] [도시별 보정 후] + selectedPlusMinusFlag", diffDays_60 + 1 + parseInt(selectedPlusMinusFlag))

      var DIFF_FIX =
        diffDays_60 + parseInt(selectedPlusMinusFlag) + lastTimeFix;

      if (DIFF_FIX >= 60) {
        DIFF_FIX = 0;
      }
      setSelectedGapja_ILSAENG(gapja60[DIFF_FIX]);
      setSelectedGapjaIndex_ILSAENG(DIFF_FIX + 1);
      setSelectedSi_ILSAENG(ganji12[fix12Index]);
      setSelectedSiIndex_ILSAENG(fix12Index);
      setSelectedSiIndex1_ILSAENG(fix12Index + 1);

      // const objectLunar = solarToLunar_toJson(paramDate.format("YYYY"), paramDate.format("MM"), paramDate.format("DD"));
      // console.log("objectLunar", objectLunar)
      // var lunarYook1 =  ""
      // var lunarYookIndex1 = -1;
      // var lunarYookIndex2 = -1;
      // var lunarYookIndex3 = -1;
      // if (objectLunar.month == 1 || objectLunar.month == 7) {
      //     var lunarYook1 =  "대안";
      //     lunarYookIndex1 = 0;
      //     lunarYookIndex2 = 0;
      //     lunarYookIndex3 = 0;
      //     setSoYook1(lunarYook1);
      // }
      // if (objectLunar.month == 2 || objectLunar.month == 8) {
      //     var lunarYook1 =  "유연";
      //     lunarYookIndex1 = 1;
      //     lunarYookIndex2 = 1;
      //     lunarYookIndex3 = 1;
      //     setSoYook1(lunarYook1);
      // }
      // if (objectLunar.month == 3 || objectLunar.month == 9) {
      //     var lunarYook1 =  "속희";
      //     lunarYookIndex1 = 2;
      //     lunarYookIndex2 = 2;
      //     lunarYookIndex3 = 2;
      //     setSoYook1(lunarYook1);
      // }
      // if (objectLunar.month == 4 || objectLunar.month == 10) {
      //     var lunarYook1 =  "적구";
      //     lunarYookIndex1 = 3;
      //     lunarYookIndex2 = 3;
      //     lunarYookIndex3 = 3;
      //     setSoYook1(lunarYook1);
      // }
      // if (objectLunar.month == 5 || objectLunar.month == 11) {
      //     var lunarYook1 =  "소길";
      //     lunarYookIndex1 = 4;
      //     lunarYookIndex2 = 4;
      //     lunarYookIndex3 = 4;
      //     setSoYook1(lunarYook1);
      // }
      // if (objectLunar.month == 6 || objectLunar.month == 12) {
      //     var lunarYook1 =  "공망";
      //     lunarYookIndex1 = 5;
      //     lunarYookIndex2 = 5;
      //     lunarYookIndex3 = 5;
      //     setSoYook1(lunarYook1);
      // }

      // console.log("lunarYookIndex1", lunarYookIndex1);
      // console.log("lunarYookIndex2",lunarYookIndex2);
      // lunarYookIndex2 = (lunarYookIndex1 + ((lunarYookIndex1 + objectLunar.day) % 6)) % 6
      // console.log("lunarYookIndex2 % 6", lunarYookIndex2 )
      // setSoYook2(soYook_StandardArr[lunarYookIndex2 ]);
      // lunarYookIndex3 = (selectedSiIndex + (lunarYookIndex1 + ((lunarYookIndex1 + objectLunar.day) % 6)) % 6) % 6
      // console.log("lunarYookIndex3", lunarYookIndex3);
      // setSoYook3(soYook_StandardArr[lunarYookIndex3]);

      // console.log("temp - 0")

      if (paramDate) {
        setCurrentDisplayDate_ILSAENG(paramDate);
      } else {
        setCurrentDisplayDate_ILSAENG(moment());
      }

      // console.log("temp - 1", paramDate)
      // console.log("temp - 1")
      // console.log("DIFF_FIX", DIFF_FIX)

      if (DIFF_FIX == 0 || DIFF_FIX == 60) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_1"));
      }
      if (DIFF_FIX == 1) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_2"));
      }
      if (DIFF_FIX == 2) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_3"));
      }
      if (DIFF_FIX == 3) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_4"));
      }
      if (DIFF_FIX == 4) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_5"));
      }
      if (DIFF_FIX == 5) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_6"));
      }
      if (DIFF_FIX == 6) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_7"));
      }
      if (DIFF_FIX == 7) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_8"));
      }
      if (DIFF_FIX == 8) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_9"));
      }
      if (DIFF_FIX == 9) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data1_10"));
      }
      if (DIFF_FIX == 10) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_1"));
      }
      if (DIFF_FIX == 11) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_2"));
      }
      if (DIFF_FIX == 12) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_3"));
      }
      if (DIFF_FIX == 13) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_4"));
      }
      if (DIFF_FIX == 14) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_5"));
      }

      if (DIFF_FIX == 15) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_6"));
      }
      if (DIFF_FIX == 16) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_7"));
      }
      if (DIFF_FIX == 17) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_8"));
      }
      if (DIFF_FIX == 18) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_9"));
      }
      if (DIFF_FIX == 19) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data2_10"));
      }
      if (DIFF_FIX == 20) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_1"));
      }
      if (DIFF_FIX == 21) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_2"));
      }
      if (DIFF_FIX == 22) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_3"));
      }
      if (DIFF_FIX == 23) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_4"));
      }
      if (DIFF_FIX == 24) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_5"));
      }
      if (DIFF_FIX == 25) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_6"));
      }
      if (DIFF_FIX == 26) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_7"));
      }
      if (DIFF_FIX == 27) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_8"));
      }
      if (DIFF_FIX == 28) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_9"));
      }
      if (DIFF_FIX == 29) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data3_10"));
      }
      if (DIFF_FIX == 30) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_1"));
      }
      if (DIFF_FIX == 31) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_2"));
      }
      if (DIFF_FIX == 32) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_3"));
      }
      if (DIFF_FIX == 33) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_4"));
      }
      if (DIFF_FIX == 34) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_5"));
      }
      if (DIFF_FIX == 35) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_6"));
      }
      if (DIFF_FIX == 36) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_7"));
      }
      if (DIFF_FIX == 37) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_8"));
      }
      if (DIFF_FIX == 38) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_9"));
      }
      if (DIFF_FIX == 39) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data4_10"));
      }
      if (DIFF_FIX == 40) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_1"));
      }
      if (DIFF_FIX == 41) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_2"));
      }
      if (DIFF_FIX == 42) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_3"));
      }
      if (DIFF_FIX == 43) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_4"));
      }
      if (DIFF_FIX == 44) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_5"));
      }
      if (DIFF_FIX == 45) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_6"));
      }
      if (DIFF_FIX == 46) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_7"));
      }
      if (DIFF_FIX == 47) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_8"));
      }
      if (DIFF_FIX == 48) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_9"));
      }
      if (DIFF_FIX == 49) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data5_10"));
      }
      if (DIFF_FIX == 50) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_1"));
      }
      if (DIFF_FIX == 51) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_2"));
      }
      if (DIFF_FIX == 52) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_3"));
      }
      if (DIFF_FIX == 53) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_4"));
      }
      if (DIFF_FIX == 54) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_5"));
      }
      if (DIFF_FIX == 55) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_6"));
      }
      if (DIFF_FIX == 56) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_7"));
      }
      if (DIFF_FIX == 57) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_8"));
      }
      if (DIFF_FIX == 58) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_9"));
      }
      if (DIFF_FIX == 59) {
        setSelectedNaejung_ILSAENG(require("../dataForm1/data6_10"));
      }

      setGookSuDecideArray_ILSAENG(paramCurrentJoongiDecideArray);
      setSelectedGook_ILSAENG(paramCurrentJoongiDecideArray[fix12Index]);
      const sookjabyeol = sookjabyeol_1.findIndex((findItem) => {
        if (findItem == paramCurrentJoongiDecideArray[fix12Index]) {
          return findItem;
        }
      });
      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(0부터 인덱스) + 0 ",
        sookjabyeol
      );
      console.log(
        "[sookjabyeol] 숫자별 해당내용의 인덱스(1부터 일반값) 1",
        sookjabyeol + 1
      );
      if (selectedGook_ILSAENG > 0) {
        setSootJaIndex_ILSAENG(sookjabyeol + 1);
      } else {
        setSootJaIndex_ILSAENG(+1);
      }
    }
  }

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    if (popupTitle && popupContents) {
      setHtmlPopupContent(popupContents);
    }
  }, [popupTitle, popupContents]);

  // 핸드폰, 휴대전화, 휴대폰 번호가 인식이 안되었을때

  if (blockSystem == true && process.env.NODE_ENV !== "development") {
    return (
      <Layout_DTM
        style={{
          marginTop: appEnv.isMarqueeEnabled == true ? 45 + 0 : 0,
        }}
      >
        {true && (
          <>
            <div
              className="maintable_wrapper0 maintable_wrapper1"
              style={{
                borderTop: !(
                  selectedSiIndex1 == 1 ||
                  selectedSiIndex1 == 2 ||
                  selectedSiIndex1 == 3 ||
                  selectedSiIndex1 == 10 ||
                  selectedSiIndex1 == 11 ||
                  selectedSiIndex1 == 12
                )
                  ? `8px solid #B93160`
                  : `8px solid #1F4690`,
              }}
            >
              {/* 
                             ${parseInt(getRandomArbitrary(0,11))} */}

              <div
                className="maintable_row0 maintable_row1"
                style={{
                  minHeight: `80vh`,
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "left",
                  width: `80vw`,
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  fontWeight: "700",
                  flexDirection: "column",
                }}
              >
                <img
                  src={"/static/taotemple_img001.png"}
                  className=""
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
                <br />
                {messageFromAndroid && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {messageFromAndroid}
                  </>
                )}

                {/*                             
                             잠시만 기다려주세요. <br/>
                             지금 번호인증여부를 서버에서 판단하고 있습니다.<br/>
                             잠시 후 귀하의 전화번호가 화면에 뜨고 구동이 됩니다.<br/>
                             귀하의 전화번호가 뜨지 않는 경우<br/>실행이 불가합니다. */}

                {/* 알림 : <br/>
                            이 번호는 저희에게 등록되지 않았으므로 실행이 불가합니다.<br/>
                            02-567-3850으로 문의 바랍니다.<br/><br/>
                            비영리법인 도서출판 가북 육임 AI연구소 배상 */}
              </div>
            </div>
          </>
        )}
      </Layout_DTM>
    );
  }

  // 신규
  if (false) {
    return (
      <>
        <div className="">
          병인 판단
          <table>
            <tr></tr>
            <tr>귀 : 근심 걱정을 너무 많이 하여 생긴 병입니다.</tr>
            <tr>
              사 : 두렵고 놀라고 근심되고 쉽게 판단이 안되는 일로 병이
              생겼습니다.
            </tr>
            <tr>
              주 : 신명 기도를 잘못하거나 구설에 의한 저주 때문에 생긴 병입니다.
            </tr>
            <tr>합 : 경사스러운 일로 너무 지쳐서 생긴 병입니다.</tr>
            <tr>구 : 협박이나 심한 언사에 의해 압박을 받아 생긴 병입니다.</tr>
            <tr>청 : 경영 재물의 근심으로 병이 생긴 것입니다.</tr>
            <tr>
              공 : 속거나 사기를 당하고도 말못하고 속앓이로 인한 병입니다.
            </tr>
            <tr>백 : 상가에 조문하거나 병 문안 간 일로 생긴 병입니다.</tr>
            <tr>
              상 : 술을 너무 많이 마시거나 음식을 너무 폭식하여 생긴 병입니다.
            </tr>
            <tr>현 : 도난을 당해 억울하여 생긴 병입니다.</tr>
            <tr>음 : 바람난 일이 확연치 못해 울화가 치밀어 생긴 병입니다.</tr>
            <tr>후 : 주색 방사을 너무 심하게 하여 생긴 병입니다.</tr>
          </table>
          병증 판단
          <table>
            <tr></tr>
            일지상신 구 (신후) : 초 1료품화 (여기에 금가 타면 보구는 사이고
            ☆구는 1원입니다.) 일지상신 곳 (등명) : 초 1포%.L (여기에 호이 타면
            트해입니다.) 일지상신 F (천괴) : 81부산 (여기에 또이 타면 45년
            10%입니다.) 일지상신 # (종괴): 호 멋☆5% (여기에 속이 타면 tUMT6
            입니다.) 일지상신 # (전송) : 초 분굴품: (여기에 몸이 타면 118 .
            1※합입니다.) 女子孕危 일지상신 * (소길) : 초 월매만 (여기에 밝이
            다면 A사* 입니다.) 일지상신 수 (승광): 초 11%터품 (여기에 맞가 타면
            1WJE. T체입니다) 인지상신 E (태을) : 초 * 10 (여기에 보가 타면 돼
            1,입니다.) 일지상신 1 (천강) : 초 초대표※ (여기에 쇠가 타면 1411%
            입니다.) 일지상신 Ip (태충) : 10화 3 (여기에 습이 타면 1:체포
            입니다.) 일지상신 ☆ (공조): 호 총 1 (여기에 품이 타면 HTM7제입니다.)
            일지상신 표 (대길) : 1 하고자요 (여기에 화가 다면 1. 입니다.)
          </table>
          <br />
          지금까지 궁핍 곤경 고초를 겪는 자는 비로소 상승 운세가 되고, 고위 품족
          상승 운세 자는 면직 등 하향됨 묘소 육친 판단
          <br />
          1. 위 일별 납음 오행의 묘신을 다음 지반에서 찾음
          <br />
          <table>
            <tr>
              <td>일별 납음오행</td>
              <td>木</td>
              <td>火</td>
              <td>土</td>
              <td>金</td>
              <td>水</td>
            </tr>
            <tr>
              <td>지반묘신</td>
              <td>未</td>
              <td>戌</td>
              <td>戌</td>
              <td>丑</td>
              <td>辰</td>
            </tr>
          </table>
          2.
          <table>
            <tr>
              <td>각월 초하루 기준일수</td>
              <td>
                寅申巳亥
                <br />
                7·7·16일
              </td>
              <td>
                子午卯酉
                <br />
                10·20일
              </td>
              <td>
                辰戌丑未
                <br />
                9·3·18일
              </td>
            </tr>
            <tr>
              <td>위 지반묘신의 상 천반지지</td>
              <td>辰戌丑未 子午卯酉 寅申巳亥</td>
              <td>辰戌丑未 子午卯酉 寅申巳亥</td>
              <td>辰戌丑未 子午卯酉 寅申巳亥</td>
              <td>辰戌丑未 子午卯酉 寅申巳亥</td>
              <td>辰戌丑未 子午卯酉 寅申巳亥</td>
            </tr>
            <tr>
              <td>위 지반묘신의 하 천반지지</td>
              <td>未</td>
              <td>戌</td>
              <td>戌</td>
              <td>丑</td>
              <td>辰</td>
            </tr>
            {/* 月 火 水 木 金 土 日 */}
            <tr>
              <td>확정된 납음오행</td>
              <td>木</td>
              <td>火</td>
              <td>土</td>
              <td>金</td>
              <td>水</td>
            </tr>
          </table>
          3. 얼간 대묘 상신 초중 본기의 통변 육친 판단
          <table>
            <tr>
              <td>식상 : </td>
              <td>양지지 </td>
              <td>고조부 </td>
              <td>음지지 </td>
              <td>고조모 </td>
            </tr>
            <tr>
              <td>재성 : </td>
              <td>양지지 </td>
              <td>증조부 </td>
              <td>음지지 </td>
              <td>증조모 </td>
            </tr>
            <tr>
              <td>재성 : </td>
              <td>양지지 </td>
              <td>정처 </td>
              <td>음지지 </td>
              <td>첩녀 </td>
            </tr>
            <tr>
              <td>관살 : </td>
              <td>양지지 </td>
              <td>조부 </td>
              <td>음지지 </td>
              <td>조모 </td>
            </tr>
            <tr>
              <td>인수 : </td>
              <td>양지지 </td>
              <td>부친 </td>
              <td>음지지 </td>
              <td>모친 </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 700 }}>본인 </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 500 }}>식상 </td>
              <td>양지지 </td>
              <td>자식 </td>
              <td>음지지 </td>
              <td>여식 </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 500 }}>재성 </td>
              <td>양지지 </td>
              <td>손자 </td>
              <td>음지지 </td>
              <td>손녀 </td>
            </tr>
          </table>
          4. 천장별(묘상신에 접지된)
          <br />
          산소 망인 사망 이유 및 종사직
          <table>
            <tr>
              <td>귀 : </td>
              <td>관직 묘 고종명자</td>
            </tr>
            <tr>
              <td>사 : </td>
              <td>산액 혈광사</td>
            </tr>
            <tr>
              <td>주 : </td>
              <td>문서구설 놀라 자살 (자일 투정)</td>
            </tr>
            <tr>
              <td>합 : </td>
              <td>목수</td>
            </tr>
            <tr>
              <td>구 : </td>
              <td>옥송사 졸도사</td>
            </tr>
            <tr>
              <td>청 : </td>
              <td>부귀자가 천하게 사망함</td>
            </tr>
            <tr>
              <td>공 : </td>
              <td>도로사망 자취없음 (풍질, 별전도 - 쓰러짐 간질)</td>
            </tr>
            <tr>
              <td>백 : </td>
              <td>훙인 흉병사망</td>
            </tr>
            <tr>
              <td>상 : </td>
              <td>약식주 타살사망</td>
            </tr>
            <tr>
              <td>현 : </td>
              <td>도적행중 도검 혈광사망</td>
            </tr>
            <tr>
              <td>음 : </td>
              <td>간사병</td>
            </tr>
            <tr>
              <td>후 : </td>
              <td>산액 빈졸사</td>
            </tr>
          </table>
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        className="layout_dtm"
        pageSub1={textSelectedCategory}
        componentCode={appEnv.isMarqueeEnabled == true ? `test/top` : ""}
      ></Header>
      {/* 시간변경 경고창 */}
      {
        // true &&
        boolMagnifyPopup && (
          <PopupContainer>
            <Button0
              onClick={() => {
                setBoolMagnifyPopup(false);
                setHtmlPopupContent("");
                setHtmlPopupContentSub("");

                setSelectedBirthCalendarGapjaIndex1(-1);
                setTableMagnify(1);
              }}
            >
              닫기
            </Button0>
            <Button0
              style={{
                position: "fixed",
                bottom: 28,
                width: `calc(100vw - 70px)`,
                zIndex: 90,
              }}
              onClick={() => {
                setBoolMagnifyPopup(false);
                setHtmlPopupContent("");
                setHtmlPopupContentSub("");
                setSelectedBirthCalendarGapjaIndex1(-1);
                setTableMagnify(1);
              }}
            >
              닫기
            </Button0>
            <div
              className="value0"
              style={{
                fontSize: `24px`,
                marginTop: `20px`,
                fontWeight: "700",
                zIndex: 99,
              }}
            >
              {objectMagnify && objectMagnify.title}
            </div>

            <div
              className="value0"
              style={{
                fontSize: `22px`,
                marginTop: `36px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "left",
                maxWidth: `calc(100% - 20px)`,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {parse(`${objectMagnify && objectMagnify.desc}`)}
            </div>
          </PopupContainer>
        )
      }
      {
        // true &&
        boolTimeChangePopup && (
          <PopupContainer style={{ maxHeight: 400 }}>
            <div
              className="value0"
              style={{ fontSize: `24px`, marginTop: `40px` }}
            >
              시간변경하시겠습니까? <br />
              다음시간 기준으로 변경됩니다.
              <br />
            </div>

            <div
              className="value0"
              style={{
                fontSize: `16px`,
                marginTop: `40px`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className=""
                style={{
                  width: `50px`,
                  height: `50px`,
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: `25px`,
                  border: `1px solid #000`,
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#FF869E",
                  color: "#000",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setClosePopupRecently(0);
                  setBoolTimeChangePopup(false);
                  recentlyRef.current = 0;
                }}
              >
                취소
              </div>
              <div
                className=""
                style={{
                  width: `50px`,
                  height: `50px`,
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: `25px`,
                  border: `1px solid #000`,
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#415A9B",
                  color: "#fff",
                  marginLeft: `12px`,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolTimeChangePopup(false);
                  setClosePopupRecently(0);
                  recentlyRef.current = 0;

                  setSearchedInput101(moment().format("YYYY"));
                  setSearchedInput102(moment().format("MM"));
                  setSearchedInput103(moment().format("DD"));
                  setSearchedInput104(moment().format("HH"));
                  setSearchedInput105(moment().format("mm"));
                  setSearchedInput106(moment().format("ss"));

                  setSearchedInput201(moment().format("YYYY"));
                  setSearchedInput202(moment().format("MM"));
                  setSearchedInput203(moment().format("DD"));
                  setSearchedInput204(moment().format("HH"));
                  setSearchedInput205(moment().format("mm"));
                  setSearchedInput206(moment().format("ss"));

                  __GOOKSU_DECIDE_AS_JOOGI(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    moment().format("HH"),
                    moment().format("mm"),
                    moment().format("ss")
                  );

                  __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    moment().format("HH"),
                    moment().format("mm"),
                    moment().format("ss")
                  );
                }}
              >
                변경
              </div>
            </div>
          </PopupContainer>
        )
      }

      {/* 팝업 출력 시작 */}
      {htmlPopupContent && htmlPopupContent != "" && (
        <PopupContainer>
          <Button0
            style={{
              position: "fixed",
              width: `calc(100vw - 70px)`,
              zIndex: 90,
            }}
            onClick={() => {
              setHtmlPopupContent("");
              setHtmlPopupContentSub("");
              setTableMagnify(1);
              setSelectedBirthCalendarGapjaIndex1(-1);
              setPopupContents(null);
              setPopupTitle(null);
            }}
          >
            닫기
          </Button0>
          <Button0
            style={{
              position: "fixed",
              bottom: 28,
              width: `calc(100vw - 70px)`,
              zIndex: 90,
            }}
            onClick={() => {
              setHtmlPopupContent("");
              setHtmlPopupContentSub("");
              setTableMagnify(1);
              setSelectedBirthCalendarGapjaIndex1(-1);
              setPopupContents(null);
              setPopupTitle(null);
            }}
          >
            닫기
          </Button0>
          <br />
          <br />

          {htmlPopupContent &&
          (htmlPopupContent != "옥추부" ||
            htmlPopupContent != "고귀명수" ||
            htmlPopupContent != "국수해설_일생사" ||
            htmlPopupContent != "국수해설_당면사") ? (
            <div style={{ fontWeight: 700 }}>
              {true ? (
                <>
                  {popupTitle
                    ? popupTitle
                    : parse(
                        htmlPopupContent
                          .replace("고귀명수", "")
                          .replace("색인_일생사", "")
                          .replace("국수해설_당면사", "")
                          .replace("국수해설_일생사", "")
                          .replace("명운표", "")
                          .replace("이해보기", "")
                          .replace("성취기도일시", "")
                          .replace("일시별문제즉단", "")
                          .replace("띠별운세즉단", "")
                          .replace("형이상_귀신부정", "")
                          .replace("내정정황", "")
                          .replace("선택_지지_설명_보기", "")
                          .replace("앱내용_출처", "")
                          .replace("앱내용_상담", "")
                          .replace("육임가묘좌법", "")
                          .replace("명좌문신불법", "")
                          .replace("저장_정단", "")
                          .replace("색인_정단", "")
                          .replace("사과삼전_보기요령", "")
                          .replace("대적_처신_방침", "")
                          .replace("육효64", "")
                          .replace("지두법", "")
                          .replace("전화상담즉단", "")
                          .replace("출산길일길시선택", "")
                          .replace("각순별육십갑자", "")
                          .replace("각 일별사", "")
                          .replace("년년세세", "")
                          .replace("행운감정", "")
                          .replace("출생시각별부모선후망", "")
                          .replace("일간천장대나이별사상", "")
                          .replace("출생일주별부부남녀궁합", "")
                          .replace("메뉴1010101_가정사망", "")
                          .replace("메뉴1010101_질병원인", "")
                          .replace("메뉴1010101_종사업", "")
                          .replace("동정즉단운세", "")
                          .replace("업데이트20230126_01", "")
                      )}
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {htmlPopupContent && popupContents && <>{popupContents}</>}

          {htmlPopupContent && htmlPopupContent == "업데이트20230126_01" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                                            사안규모심신고통고민금전大小<br/>
                                            </div>`)}
              <br />
              <br />
              <br />
              <br />
              <div style={{ fontSize: 14 }}>
                {selectedGapjaIndex > -1 &&
                  selectedGook > -1 &&
                  object101_1 &&
                  object101_1.default &&
                  object101_1.default.VERSION20220207_1_1 &&
                  object101_1.default.VERSION20220207_1_1[selectedGapjaIndex] &&
                  object101_1.default.VERSION20220207_1_1[
                    12 * (selectedGapjaIndex - 1) + (selectedSiIndex1 - 1)
                  ] &&
                  parse(`
                                                        <span style="font-weight: 700;">
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[5]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        </span>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[0]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[1]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[2]
                                                            .replaceAll(
                                                              "자",
                                                              "子"
                                                            )
                                                            .replaceAll(
                                                              "축",
                                                              "丑"
                                                            )
                                                            .replaceAll(
                                                              "인",
                                                              "寅"
                                                            )
                                                            .replaceAll(
                                                              "묘",
                                                              "卯"
                                                            )
                                                            .replaceAll(
                                                              "진",
                                                              "辰"
                                                            )
                                                            .replaceAll(
                                                              "사",
                                                              "巳"
                                                            )
                                                            .replaceAll(
                                                              "오",
                                                              "午"
                                                            )
                                                            .replaceAll(
                                                              "미",
                                                              "未"
                                                            )
                                                            .replaceAll(
                                                              "신",
                                                              "申"
                                                            )
                                                            .replaceAll(
                                                              "유",
                                                              "酉"
                                                            )
                                                            .replaceAll(
                                                              "술",
                                                              "戌"
                                                            )
                                                            .replaceAll(
                                                              "해",
                                                              "亥"
                                                            )
                                                          // "축 (丑)","인 (寅)","묘 (卯)"	,"진 (辰)","사 (巳)","오 (午)","미 (未)","신 (申)","유 (酉)","술 (戌)","해 (亥)"

                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[3]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[4]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }
                                                        <br/>
                                                        ${
                                                          object101_1.default.VERSION20220207_1_1[
                                                            12 *
                                                              (selectedGapjaIndex -
                                                                1) +
                                                              (selectedSiIndex1 -
                                                                1)
                                                          ]
                                                            .replaceAll(
                                                              "@1@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@2@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@3@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@4@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@5@",
                                                              ""
                                                            )
                                                            .replaceAll(
                                                              "@6@",
                                                              ""
                                                            )
                                                            .split("@@")[6]
                                                          // .replaceAll("@@1@@", "<br/><span style='font-weight: 700;'>이름1</span> : ")
                                                          // .replaceAll("@@2@@", "<br/><span style='font-weight: 700;'>이름2</span> : ")
                                                          // .replaceAll("@@3@@", "<br/><span style='font-weight: 700;'>이름3</span> : ")
                                                          // .replaceAll("@@4@@", "<br/><span style='font-weight: 700;'>이름4</span> : ")
                                                          // .replaceAll("@@5@@", "<br/><span style='font-weight: 700;'>이름5</span> : ")
                                                          // .replaceAll("@@6@@", "<br/><span style='font-weight: 700;'>이름6</span> : ")
                                                          // .replaceAll("@", "")
                                                        }

                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        
                                                    `)}
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "동정즉단운세" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        동정 즉단 운세<br/>
                        </div>`)}
              <br />
              <br />
              <br />
              <br />
              <div style={{ fontSize: 14 }}>
                위측 사과 삼전 12 궁위 중{" "}
                <span style={{ fontWeight: 700 }}>진(辰)</span> 하 지지 해당 띠
                자는 <br />
                지금껏 곤경에 처해있었다면 <br />
                비로소 솔개가 날개치고 비상하듯 <br />
                운세가 열리고 (수법효과 필 중)
                <br />
                <br />
                <br />
                지금껏 고위와 영화를 누린 자는 <br />
                면직 하향(곤두박질)하는 운세에 이르게 됨 <br />
                (수법효과 없되, 재해 재난 박탈감을 줄이는 쪽으로 가능)
                <br />
                <br />
                <br />단 <span style={{ fontWeight: 700 }}>진(辰)</span> 하
                지지가 축 인 사 미 해당자는 띠 여부와 <br />
                상관 없이 계획 도모 작사가 달성됨
                <br />
                (수법효과 필 중)
                <br />
                <br />
                <br />
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "메뉴1010101_가정사망" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        해당 월별 일별 국수별 <br/>가정 사망자 판단<br/>
                        </div>`)}
              <br />
              <br />
              <br />
              <br />
              <div>
                낮 시간 좌측 천장 밤시간 우측 천장
                <br />
                사과삼전표 - 일간에서 우측 세번째 중간 칸 것<br />
                <br />
                (예시)
                <br />
                <img
                  alt=" 낮 시간 좌측 천장 밤시간 우측 천장"
                  src={"/static/example002_0.png"}
                  className=""
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
                <br />
                {/* 위 초사3, 사망판단은 매 갑일 제10국을 제외한 <br/>
                            각일 해당국수의 사과삼전표 제 3과 (표시) 해당 천장을 참고함.<br/><br/>
                            이 방문자(정시 차객 숫자 방위 적용) <br/>가정에 시체가 있거나 사망자 또는 노인 음령이 있다. <br/>천장과 각일 국수 참고함 */}
              </div>
              <br />
              <br />
              <br />
              <br />
              <table style={{ width: `100%`, fontWeight: `700` }}>
                <tr>
                  <td>육합 (合)</td>
                  <td>가내 시체가 있다</td>
                </tr>
                <tr>
                  <td>등사 (蛇)</td>
                  <td>가내 몰폐구병사망자가 있다.</td>
                </tr>
                <tr>
                  <td>구진 (勾)</td>
                  <td>가내 구악 섭신사가 있다.</td>
                </tr>
                <tr>
                  <td>귀인 (貴)</td>
                  <td>가내 향화가 있다.</td>
                </tr>
                <tr>
                  <td>백호 (白)</td>
                  <td>가내 질병 사망자가 있다.</td>
                </tr>
                <tr>
                  <td>태음 (陰)</td>
                  <td>노인음령이 있다.</td>
                </tr>
              </table>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "메뉴1010101_종사업" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        종사업 천성(격) 분류<br/>
                        </div>`)}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <br />
                <br />
                (예시)
                <br />
                <img
                  src={"/static/example001.png"}
                  className=""
                  style={{ maxWidth: 200 }}
                />
                <div>
                  위의 빨간 영역을 초전이라고 부르며,
                  <br /> 아래 사과삼전표를 참조하십시오.
                </div>
                {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 21px; font-weight: 500;">
                        <br/>
                        종사업 차이
                        <br/>
                        </div>`)}
                <table>
                  <tr>
                    <td>위 초전</td>
                    <td>
                      인 묘<br />
                      봄 겨울
                      <br />
                      음 1, 2, 10, 11월
                      <br />귀 합 청 상 후
                    </td>
                    <td>학자·문관 교육분야 - 인자 유덕</td>
                  </tr>
                  <tr>
                    <td>위 초전</td>
                    <td>
                      인 묘<br />
                      여름 가을 계월
                      <br />
                      음 3·4·5·6·7·8·9·12월
                      <br />사 주 구 공 백 현 음
                    </td>
                    <td>죽·목 기술 분야 - 고집·집요</td>
                  </tr>
                  <tr style={{ borderTop: `4px solid #000` }}>
                    <td>위 초전</td>
                    <td>
                      사 오<br />
                      여름 봄<br />
                      음 4 5 1 2월
                      <br />귀 합 청 상 후
                    </td>
                    <td>문인·문화 분야 - 신실·예의</td>
                  </tr>

                  <tr>
                    <td>위 초전</td>
                    <td>
                      사 오<br />
                      겨울 가을 토월
                      <br />
                      음 10·11·7·8·월
                      <br />사 주 구 공 백 현 음
                    </td>
                    <td>화로 공인 · 화 가공기술 - 간사·용두사미·산만</td>
                  </tr>
                  <tr style={{ borderTop: `4px solid #000` }}>
                    <td>위 초전</td>
                    <td>
                      진 술 축 미<br />
                      진술축미 월<br />
                      음 3·4·5·6·9·12 월<br />귀 합 청 상 후
                    </td>
                    <td>부자(토) 토목산업 - 충량·후덕</td>
                  </tr>
                  <tr>
                    <td>위 초전</td>
                    <td>
                      진 술 축 미<br />
                      봄 가을 겨울
                      <br />
                      음 1·2·7·8·10·11 월<br />사 주 구 공 백 현 음
                    </td>
                    <td>소작·노동·토공 기술 - 아둔</td>
                  </tr>
                  <tr style={{ borderTop: `4px solid #000` }}>
                    <td>위 초전</td>
                    <td>
                      신 유<br />
                      가을 토월 <br />
                      음 7·8·3·6·9·12 월<br />귀 합 청 상 후
                    </td>
                    <td>무관·검·판사·고위 - 강직</td>
                  </tr>
                  <tr>
                    <td>위 초전</td>
                    <td>
                      신 유<br />
                      봄·여름·겨울 <br />
                      음 1·2·7·8·10·11월
                      <br />사 주 구 공 백 현 음
                    </td>
                    <td>도축·인부 - 포악</td>
                  </tr>
                  <tr style={{ borderTop: `4px solid #000` }}>
                    <td>위 초전</td>
                    <td>
                      해 자<br />
                      겨울 가을 <br />
                      음 10·11·7·8월
                      <br />귀 합 청 상 후
                    </td>
                    <td>발명·사상 정신 계 - 총명·지혜</td>
                  </tr>
                  <tr>
                    <td>위 초전</td>
                    <td>
                      해 자<br />
                      토월 봄 여름 <br />
                      음 1·2·4·5·6·9·12월
                      <br />사 주 구 공 백 현 음
                    </td>
                    <td>수산업·수산노동 - 괘후 (거짓 원사꾸밈)</td>
                  </tr>
                </table>
                <br />
                <br />
                <br />
                <br />
                {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 21px; font-weight: 500;">
                        <br/>
                        위 구분 종사업 구분 왕상표
                        <br/>
                        </div>`)}
                <table>
                  <tr>
                    <td>왕상</td>
                    <td>왕상</td>
                    <td>왕상</td>
                    <td>왕상</td>
                    <td>왕상</td>
                  </tr>
                  <tr style={{ border: `4px solid #000` }}>
                    <td>목 인 묘</td>
                    <td>화 사 오</td>
                    <td>토 진 술 축 미</td>
                    <td>금 신 유</td>
                    <td>수 해 자</td>
                  </tr>
                  <tr>
                    <td>
                      봄 겨울
                      <br />
                      (음력 1·2·10·11월)
                      <br />
                    </td>
                    <td>
                      여름 봄<br />
                      (음력 4·5·1·2월)
                      <br />
                    </td>
                    <td>
                      계절 끝달
                      <br />
                      (음력 3·6·9·12·4·5월)
                      <br />
                    </td>
                    <td>
                      가을 토월
                      <br />
                      (음력 7·8·3·6·9·12월)
                      <br />
                    </td>
                    <td>
                      겨울 가을
                      <br />
                      (음력 10·11·7·8월)
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>쇠약</td>
                    <td>쇠약</td>
                    <td>쇠약</td>
                    <td>쇠약</td>
                    <td>쇠약</td>
                  </tr>
                  <tr style={{ border: `4px solid #000` }}>
                    <td>목 인 묘</td>
                    <td>화 사 오</td>
                    <td>토 진 술 축 미</td>
                    <td>금 신 유</td>
                    <td>수 해 자</td>
                  </tr>
                  <tr>
                    <td>
                      여름 가을 토월
                      <br />
                      (음력 4·5·7·8
                      <br />
                      진술축미 월)
                      <br />
                    </td>
                    <td>
                      겨울 가을 토월
                      <br />
                      (음력 10·11·
                      <br />
                      7·8·3·6월)
                      <br />
                    </td>
                    <td>
                      봄 겨울 가을
                      <br />
                      (음력 1·2·
                      <br />
                      10·11·7·8월)
                      <br />
                    </td>
                    <td>
                      봄 겨울 여름
                      <br />
                      (음력 1·2·
                      <br />
                      10·11·4·5월)
                      <br />
                    </td>
                    <td>
                      토월 봄 여름
                      <br />
                      (음력 3·6·9·
                      <br />
                      12·1·2·4·5월)
                      <br />
                    </td>
                  </tr>
                </table>
                <br />
                <br />
                <br />
                <br />
                {parse(
                  `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 21px; font-weight: 700;">사과 삼전표</div>`
                )}
                <div
                  style={{
                    width: "calc(100% - 0px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 12,
                  }}
                >
                  <QuickPinchZoom onUpdate={onUpdate} style={{ width: `100%` }}>
                    <img
                      ref={imgRef}
                      src={
                        selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.SAGWA_IMAGE1[selectedGook - 1]
                      }
                      style={{
                        width: `calc(100vw - 40px)`,
                        maxHeight: `500px`,
                        overflow: "hidden",
                        objectFit: "contain",
                      }}
                      zoom={192}
                    />
                  </QuickPinchZoom>
                </div>
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "메뉴1010101_질병원인" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        <span style="font-weight: 700;">
                        질병 원인
                        </span>
                        <br/>
                        </div>`)}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <br />
                <br />
                {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 16px; font-weight: 500;">
                    <span style="font-weight: 700;">
                        병증 판단
                        </span>
                        <br/><br/>
                        </div>`)}
                (예시)
                <br />
                <img
                  src={"/static/example001_0.png"}
                  className=""
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
                <div>병증은 아래 사과삼전표의 해당 영역을 참조하십시오</div>
                <br />
                <br />
                <table style={{ width: `100%`, textAlign: "left" }}>
                  <tr></tr>
                  일지상신 <span style={{ fontWeight: 700 }}>자</span> :
                  상풍현갈(여기에 <span style={{ fontWeight: 700 }}>천후</span>
                  가 타면 남자는 정액 끓기고 여자는 피가 혈절입니다.) <br />
                  일지상신 <span style={{ fontWeight: 700 }}>축</span> :
                  전광습풍 (여기에 <span style={{ fontWeight: 700 }}>귀인</span>
                  이 타면 안목유루입니다.) <br />
                  일지상신 <span style={{ fontWeight: 700 }}>인</span> :
                  복통비설 (여기에 <span style={{ fontWeight: 700 }}>청룡</span>
                  이 타면 행보간난입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>묘</span> :
                  해소로상 (여기에 <span style={{ fontWeight: 700 }}>육합</span>
                  이 타면 손폐상비입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>진</span> :
                  남자입술파/여자임신위 (여기에{" "}
                  <span style={{ fontWeight: 700 }}>구진</span>이 타면
                  동종부골입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>사</span> :
                  번위구토 (여기에 <span style={{ fontWeight: 700 }}>등사</span>
                  가 타면 기일상재입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>오</span> :
                  심통목혼 (여기에 <span style={{ fontWeight: 700 }}>주작</span>
                  이 타면 상풍하리입니다.)
                  <br />
                  인지상신 <span style={{ fontWeight: 700 }}>미</span> :
                  사통구혈 (여기에 <span style={{ fontWeight: 700 }}>태상</span>
                  이 타면 두면통종입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>신</span> :
                  유루풍난 (여기에 <span style={{ fontWeight: 700 }}>백호</span>
                  가 타면 인후종한입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>유</span> :
                  흉협답풍 (여기에 <span style={{ fontWeight: 700 }}>태음</span>
                  이 타면 골육동통입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>술</span> :
                  동복통독 (여기에 <span style={{ fontWeight: 700 }}>천공</span>
                  이 타면 간위불화입니다.)
                  <br />
                  일지상신 <span style={{ fontWeight: 700 }}>해</span> :
                  기족신허 (여기에 <span style={{ fontWeight: 700 }}>현무</span>
                  가 타면 요퇴위비입니다.)
                  <br />
                </table>
                <br />
                {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 16px; font-weight: 500;">
                        <span style="font-weight: 700;">
                        병인 판단
                        </span>
                        <br/><br/>
                        </div>`)}
                (예시)
                <br />
                <img
                  src={"/static/example001_1.png"}
                  className=""
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
                <div>병인은 아래 사과삼전표의 해당 영역을 참조하십시오</div>
                <br />
                <br />
                <table
                  style={{ width: `100%`, textAlign: "left" }}
                  className={`danjeongMenuTable001`}
                >
                  {/* <tr> */}
                  {/* <td colSpan={1} style={{ fontWeight : "700"}} > */}

                  <tr></tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>귀</span> : 근심 걱정을
                    너무 많이 하여 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(사상고로)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>사</span> : 두렵고 놀라고
                    근심되고 쉽게 판단이 안되는 일로 병이 생겼습니다.
                    <span style={{ fontWeight: 700 }}>(경공우의)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>주</span> : 신명 기도를
                    잘못하거나 구설에 의한 저주 때문에 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(구두저주)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>합</span> : 경사스러운
                    일로 너무 지쳐서 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(희경혼친)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>구</span> : 협박이나 심한
                    언사에 의해 압박을 받아 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(정서견반)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>청</span> : 경영 재물의
                    근심으로 병이 생긴 것입니다.
                    <span style={{ fontWeight: 700 }}>(경영재물)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>공</span> : 속거나 사기를
                    당하고도 말못하고 속앓이로 인한 병입니다.
                    <span style={{ fontWeight: 700 }}>(기망은인)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>백</span> : 상가에
                    조문하거나 병 문안 간 일로 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(조상문질)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>상</span> : 술을 너무 많이
                    마시거나 음식을 너무 폭식하여 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(주식취포)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>현</span> : 도난을 당해
                    억울하여 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(사신포도)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>음</span> : 바람난 일이
                    확연치 못해 울화가 치밀어 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(간사암매)</span>
                  </tr>
                  <tr>
                    <span style={{ fontWeight: 700 }}>후</span> : 주색 방사을
                    너무 심하게 하여 생긴 병입니다.
                    <span style={{ fontWeight: 700 }}>(윤방주색)</span>
                  </tr>
                </table>
                <br />
                <br />
                {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 16px; font-weight: 500;">
                        <span style="font-weight: 700;">
                        속병
                        
                         표면병 여부</span><br/><br/>
                        </div>`)}
                <table style={{ width: `100%`, textAlign: "left" }}>
                  <tr></tr>
                  <span style={{ fontWeight: 700 }}>백호</span>가{" "}
                  <span style={{ fontWeight: 700 }}>해자축인묘진</span>(낮시간
                  좌측 밤시간 우측)에 승해있으면{" "}
                  <span style={{ fontWeight: 700 }}>속병</span>이고,{" "}
                  <span style={{ fontWeight: 700 }}>백호</span>가{" "}
                  <span style={{ fontWeight: 700 }}>사오미신유술</span>(낮시간
                  좌측 밤시간 우측)에 승해있으면{" "}
                  <span style={{ fontWeight: 700 }}>표면병</span>으로 판단함.
                  <br />
                </table>
                <br />
                <br />
                <br />
                <br />
                {parse(
                  `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 21px; font-weight: 700;">사과 삼전표</div>`
                )}
                <div
                  style={{
                    width: "calc(100% - 0px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 12,
                  }}
                >
                  <QuickPinchZoom onUpdate={onUpdate} style={{ width: `100%` }}>
                    <img
                      ref={imgRef}
                      src={
                        selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.SAGWA_IMAGE1[selectedGook - 1]
                      }
                      style={{
                        width: `calc(100vw - 40px)`,
                        maxHeight: `500px`,
                        overflow: "hidden",
                        objectFit: "contain",
                      }}
                      zoom={192}
                    />
                  </QuickPinchZoom>
                </div>
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "출생시각별부모선후망" && (
            <>
              {/* 
                        // (스크롤을 해주셔야합니다.) */}
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        13. 출생시각별 <br/>부모선후망 처자녀수<br/>
                        </div>`)}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                {/* <QuickPinchZoom  onUpdate={onUpdate24}>

<img ref={imgRef24}  src={`/static/images/_il1100/001.png`} 
            style={{ width: `calc(100vw - 40px)`,  overflow : 'hidden',   objectFit: 'contain'}}
                                            zoom={192}
                                        />
                        </QuickPinchZoom> */}
                {parse(`<div  className="" style="font-family: 'NanumBarunGothic';font-size: 12px; font-weight: 500;display:flex; flex-direction: column;">

<br/><br/><br/>

<span style="font-weight: 700;" >시간(時間) 육친(肉親)감별법</span>

<br/>
<br/>

철판 신수 일단이다. 
현대에는 출생시각이 명확하므로 이 법을 써서 효과를 이룰 수 있다.

<br/><br/><br/>
<span style="font-weight: 700;" >一. 출생시(出生時) 15분각별(十五分刻別) 육친감별</span>

<br/><br/>
子時<br/>
1刻 (23:00 ～ 15分) : 형제多, 부모壽<br/>
2刻 (23:16 ～ 30分) : 형제小, 母亡<br/>
3刻 (23:31 ～ 45分) : 형제4․5名, 父母亡<br/>
4刻 (23:46 ～ 60分) : 형제多, 父亡 母壽<br/>
5刻 (24:00 ～ 15分) : 형제無, 부모亡<br/>
6刻 (24:16 ～ 30分) : 형제無, 부모壽<br/>
7刻 (24:31 ～ 45分) : 형제2․3名, 父壽 母亡<br/>
8刻 (24:46 ～ 60分) : 형제無, 父亡<br/>

<br/><br/>
丑時<br/>
1刻 (01:00 ～ 15分) : 형제4․5名, 父壽 母亡<br/>
2刻 (01:16 ～ 30分) : 형제2․3名, 부모壽<br/>
3刻 (01:31 ～ 45分) : 형제2․3名, 父亡<br/>
4刻 (01:46 ～ 60分) : 형제2․3名, 부모亡<br/>
5刻 (02:00 ～ 15分) : 형제 小, 母亡<br/>
6刻 (02:16 ～ 30分) : 형제 無, 부모亡<br/>
7刻 (02:31 ～ 45分) : 형제 小, 부모壽<br/>
8刻 (02:46 ～ 60分) : 형제 小, 父亡<br/>
<br/><br/>
寅時<br/>
1刻 (03:00 ～ 15分) : 형제4․5名, 父亡 母壽<br/>
2刻 (03:16 ～ 30分) : 형제 多, 부모壽<br/>
3刻 (03:31 ～ 45分) : 형제2․3名, 부모亡<br/>
4刻 (03:46 ～ 60分) : 형제2․3名, 父壽 母亡<br/>
5刻 (04:00 ～ 15分) : 형제 無, 父亡<br/>
6刻 (04:16 ～ 30分) : 형제 小, 母亡<br/>
7刻 (04:31 ～ 45分) : 형제4․5名, 부모亡<br/>
8刻 (04:46 ～ 60分) : 형제 小, 부모壽<br/>
<br/><br/>
卯時<br/>
1刻 (05:00 ～ 15分) : 형제 多, 父壽 母亡<br/>
2刻 (05:16 ～ 30分) : 형제2․3名, 父壽 母亡<br/>
3刻 (05:31 ～ 45分) : 형제4․5名, 부모壽<br/>
4刻 (05:46 ～ 60分) : 형제 無, 부모亡<br/>
5刻 (06:00 ～ 15分) : 형제 多, 父亡<br/>
6刻 (06:16 ～ 30分) : 형제 小, 母亡<br/>
7刻 (06:31 ～ 45分) : 형제2․3名, 부모壽<br/>
8刻 (06:46 ～ 60分) : 형제2․3名, 부모亡<br/>
<br/><br/>
辰時<br/>
1刻 (07:00 ～ 15分) : 형제3․4名, 父母亡<br/>
2刻 (07:16 ～ 30分) : 형제 無, 부모壽<br/>
3刻 (07:31 ～ 45分) : 형제 4․5名, 부모亡<br/>
4刻 (07:46 ～ 60分) : 형제 4․5名, 父亡 母壽<br/>
5刻 (08:00 ～ 15分) : 형제 2․3名, 부모壽<br/>
6刻 (08:16 ～ 30分) : 형제 少, 父母亡<br/>
7刻 (08:31 ～ 45分) : 형제 無, 母亡<br/>
8刻 (08:46 ～ 60分) : 형제 無, 父亡<br/>
<br/><br/>
巳時<br/>
1刻 (09:00 ～ 15分) : 형제 4․5名, 부모壽<br/>
2刻 (09:16 ～ 30分) : 형제 4․5名, 母亡<br/>
3刻 (09:31 ～ 45分) : 형제 2․3名, 父亡 母壽<br/>
4刻 (09:46 ～ 60分) : 형제 2․3名, 父母亡<br/>
5刻 (10:00 ～ 15分) : 형제 無, 父亡 母壽<br/>
6刻 (10:16 ～ 30分) : 형제 少, 부모壽<br/>
7刻 (10:31 ～ 45分) : 형제 2․3名, 父壽 母亡<br/>
8刻 (10:46 ～ 60分) : 형제 少, 父母亡<br/>
<br/><br/>
午時<br/>
1刻 (11:00 ～ 15分) : 형제 4․5名, 父母亡<br/>
2刻 (11:16 ～ 30分) : 형제 4․5名, 父亡<br/>
3刻 (11:31 ～ 45分) : 형제 多, 父母壽<br/>
4刻 (11:46 ～ 60分) : 형제 無, 母亡<br/>
5刻 (12:00 ～ 15分) : 형제 2․3名, 父壽 母亡<br/>
6刻 (12:16 ～ 30分) : 형제 多, 父壽 母亡<br/>
7刻 (12:31 ～ 45分) : 형제 2․3名, 父母亡<br/>
8刻 (12:46 ～ 60分) : 형제 2․3名, 父亡 母壽<br/>
<br/><br/>
未時<br/>
1刻 (13:00 ～ 15分) : 형제 多, 父亡 母壽<br/>
2刻 (13:16 ～ 30分) : 형제 無, 父母亡<br/>
3刻 (13:31 ～ 45分) : 형제 2․3名, 母亡<br/>
4刻 (13:46 ～ 60分) : 형제 無, 父母壽<br/>
5刻 (14:00 ～ 15分) : 형제 2․3名, 父壽 母亡<br/>
6刻 (14:16 ～ 30分) : 형제 多, 母亡<br/>
7刻 (14:31 ～ 45分) : 형제 2․3名, 父母壽<br/>
8刻 (14:46 ～ 60分) : 형제 2․3名, 父母亡<br/>
<br/><br/>
申時<br/>
1刻 (15:00 ～ 15分) : 형제 4․5名, 父亡, 母壽<br/>
2刻 (15:16 ～ 30分) : 형제 4․5名, 母亡<br/>
3刻 (15:31 ～ 45分) : 형제 4․5名, 父母壽<br/>
4刻 (15:46 ～ 60分) : 형제 4․5名, 父母亡<br/>
5刻 (16:00 ～ 15分) : 형제 無, 父壽, 母亡<br/>
6刻 (16:16 ～ 30分) : 형제 少, 父母亡<br/>
7刻 (16:31 ～ 45分) : 형제 4․5名, 父亡<br/>
8刻 (16:46 ～ 60分) : 형제 2․3名, 父母壽<br/>
<br/><br/>
酉時<br/>
1刻 (17:00 ～ 15分) : 형제 2․3名, 父母亡<br/>
2刻 (17:16 ～ 30分) : 형제 父壽, 母亡<br/>
3刻 (17:31 ～ 45分) : 형제 無, 父母壽<br/>
4刻 (17:46 ～ 60分) : 형제 2․3名, 父亡, 母壽<br/>
5刻 (18:00 ～ 15分) : 형제 無, 父亡, 母壽<br/>
6刻 (18:16 ～ 30分) : 형제 少, 父壽, 母亡<br/>
7刻 (18:31 ～ 45分) : 형제 少, 父母亡<br/>
8刻 (18:46 ～ 60分) : 형제 多, 父母壽<br/>
<br/><br/>
戌時<br/>
1刻 (19:00 ～ 15分) : 형제 2․3名, 父母壽<br/>
2刻 (19:16 ～ 30分) : 형제 4․5名, 父亡<br/>
3刻 (19:31 ～ 45分) : 형제 4․5名, 父母亡<br/>
4刻 (19:46 ～ 60分) : 형제 4․5名, 父壽, 母亡<br/>
5刻 (20:00 ～ 15分) : 형제 2․3名, 父母亡<br/>
6刻 (20:16 ～ 30分) : 형제 少, 父亡, 母壽<br/>
7刻 (20:31 ～ 45分) : 형제 4․5名, 父母壽<br/>
8刻 (20:46 ～ 60分) : 형제 2․3名, 母亡<br/>
<br/><br/>
亥時<br/>
1刻 (21:00 ～ 15分) : 형제 無, 부모亡<br/>
2刻 (21:16 ～ 30分) : 형제 2․3名, 父亡, 母壽<br/>
3刻 (21:31 ～ 45分) : 형제 無, 母亡<br/>
4刻 (21:46 ～ 60分) : 형제 無, 父母壽<br/>
5刻 (22:00 ～ 15分) : 형제 多, 父壽, 母亡<br/>
6刻 (22:16 ～ 30分) : 형제 4․5名, 父亡<br/>
7刻 (22:31 ～ 45分) : 형제 2․3名, 부모亡<br/>
8刻 (22:46 ～ 60分) : 형제 2․3名, 父母壽<br/>
<br/><br/><br/>
<span style="font-weight: 700;" >二. 출생시(出生時) 8분각별(八分刻別) 夫․妻․子息감별</span>


<table>
<tr>
<th>
子時 / 남편관계 / 자식관계 / 처첩관계
<th>
</tr>
<tr><td>	一分界 (23:00 ～ 8分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	二分界 (23:08 ～ 16分)	/	夫 剋	/	少	/	剋 妻	</td></tr>
<tr><td>	三分界 (23:16 ～ 24分)	/	夫 佳	/	4	/	和 妻	</td></tr>
<tr><td>	四分界 (23:24 ～ 32分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	五分界 (23:32 ～ 40分)	/	夫又再	/	無	/	有 妻	</td></tr>
<tr><td>	六分界 (23:40 ～ 48分)	/	夫 皆	/	1	/	賢 妻	</td></tr>
<tr><td>	七分界 (23:48 ～ 56分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	八分界 (23:56 ～ 24:04分)	/	夫又亡	/	多	/	亡 妻	</td></tr>
<tr><td>	九分界 (24:04 ～ 12分)	/	夫 有	/	少	/	剋 妻	</td></tr>
<tr><td>	十分界 (24:12 ～ 20分)	/	夫 和	/	2	/	桂 妻	</td></tr>
<tr><td>	十一分界 (24:20 ～ 28分)	/	夫 又	/	少	/	續 妻	</td></tr>
<tr><td>	十二分界 (24:28 ～ 36分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	十三分界 (24:36 ～ 44分)	/	夫 又	/	多	/	有 妻	</td></tr>
<tr><td>	十四分界 (24:44 ～ 52分)	/	夫 又	/	多	/	又 妻	</td></tr>
<tr><td>	十五分界 (24:52 ～ 60分)	/	夫又亡	/	無	/	有 妻	</td></tr>

</table>

<table>
<tr>
    <th>丑時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (01:00 ～ 8分)	/	夫 桂	/	4	/	和 妻	</td></tr>
<tr><td>	二分界 (01:08 ～ 16分)	/	夫 剋	/	少	/	剋 妻	</td></tr>
<tr><td>	三分界 (01:16 ～ 24分)	/	夫又再	/	多	/	有 妻	</td></tr>
<tr><td>	四分界 (01:24 ～ 32分)	/	夫 和	/	2	/	賢 妻	</td></tr>
<tr><td>	五分界 (01:32 ～ 40分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	六分界 (01:40 ～ 48分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	七分界 (01:48 ～ 56分)	/	夫 皆	/	1	/	桂 妻	</td></tr>
<tr><td>	八分界 (01:56 ～ 02:04分)	/	夫 又	/	少	/	又 妻	</td></tr>
<tr><td>	九分界 (02:04 ～ 12分)	/	夫又亡	/	多	/	亡 妻	</td></tr>
<tr><td>	十分界 (02:12 ～ 20分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	十一分界 (02:20 ～ 28分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	十二分界 (02:28 ～ 36分)	/	夫 又	/	多	/	續 妻	</td></tr>
<tr><td>	十三分界 (02:36 ～ 44分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	十四分界 (02:44 ～ 52分)	/	夫又再	/	無	/	有 妻	</td></tr>
<tr><td>	十五分界 (02:52 ～ 60分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
</table>
<table>

<table>
<tr>

    <th>寅時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (03:00 ～ 8分)	/	夫 和	/	2	/	賢 妻	</td></tr>
<tr><td>	二分界 (03:08 ～ 16分)	/	夫 剋	/	少	/	剋 妻	</td></tr>
<tr><td>	三分界 (03:16 ～ 24分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	四分界 (03:24 ～ 32分)	/	夫 皆	/	1	/	佳 妻	</td></tr>
<tr><td>	五分界 (03:32 ～ 40分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	六分界 (03:40 ～ 48分)	/	夫 又	/	多	/	又 妻	</td></tr>
<tr><td>	七分界 (03:48 ～ 56分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	八分界 (03:56 ～ 04:04分)	/	夫 又	/	少	/	又 妻	</td></tr>
<tr><td>	九分界 (04:04 ～ 12分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	十分界 (04:12 ～ 20分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	十一分界 (04:20 ～ 28分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	十二分界 (04:28 ～ 36分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	十三分界 (04:36 ～ 44分)	/	夫 佳	/	4	/	和 妻	</td></tr>
<tr><td>	十四分界 (04:44 ～ 52分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	十五分界 (04:52 ～ 60分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
</table>
<table>

<tr>
    <th>卯時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (05:00 ～ 8分)	/	夫 佳	/	1	/	佳 妻	</td></tr>
<tr><td>	二分界 (05:08 ～ 16分)	/	夫 又	/	少	/	續 妻	</td></tr>
<tr><td>	三分界 (05:16 ～ 24分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	四分界 (05:24 ～ 32分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	五分界 (05:32 ～ 40分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	六分界 (05:40 ～ 48分)	/	夫 有	/	2	/	賢 妻	</td></tr>
<tr><td>	七分界 (05:48 ～ 56分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	八分界 (05:56 ～ 06:04分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	九分界 (06:04 ～ 12分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
<tr><td>	十分界 (06:12 ～ 20分)	/	夫 佳	/	4	/	和 妻	</td></tr>
<tr><td>	十一分界 (06:20 ～ 28分)	/	夫 亡	/	少	/	剋 妻	</td></tr>
<tr><td>	十二分界 (06:28 ～ 36分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	十三分界 (06:36 ～ 44分)	/	夫 和	/	無	/	有 妻	</td></tr>
<tr><td>	十四分界 (06:44 ～ 52分)	/	夫 剋	/	多	/	亡 妻	</td></tr>
<tr><td>	十五分界 (06:52 ～ 60分)	/	夫 再	/	多	/	續 妻	</td></tr>

</table>

<table>
<tr>
    <th>巳時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (07:00 ～ 8分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	二分界 (07:08 ～ 16分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	三分界 (07:16 ～ 24分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	四分界 (07:24 ～ 32分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	五分界 (07:32 ～ 40分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	六分界 (07:40 ～ 48分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
<tr><td>	七分界 (07:48 ～ 56分)	/	夫 佳	/	4	/	和 妻	</td></tr>
<tr><td>	八分界 (07:56 ～ 08:04分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	九分界 (08:04 ～ 12分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	十分界 (08:12 ～ 20分)	/	夫 和	/	2	/	賢 妻	</td></tr>
<tr><td>	十一分界 (08:20 ～ 28分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	十二分界 (08:28 ～ 36分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	十三分界 (08:36 ～ 44分)	/	夫 皆	/	1	/	佳 妻	</td></tr>
<tr><td>	十四分界 (08:44 ～ 52分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	十五分界 (08:52 ～ 60分)	/	夫 亡	/	少	/	亡 妻	</td></tr>

</table>



<table>

<tr>
    <th>辰時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (09:00 ～ 8分)	/	夫 佳	/	4	/	和 妻	</td></tr>
<tr><td>	二分界 (09:08 ～ 16分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	三分界 (09:16 ～ 24分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
<tr><td>	四分界 (09:24 ～ 32分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	五分界 (09:32 ～ 40分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	六分界 (09:40 ～ 48分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	七分界 (09:48 ～ 56分)	/	夫 和	/	2	/	賢 妻	</td></tr>
<tr><td>	八分界 (09:56 ～ 10:04分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	九分界 (10:04 ～ 12分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	十分界 (10:12 ～ 20分)	/	夫 皆	/	1	/	佳 妻	</td></tr>
<tr><td>	十一分界 (10:20 ～ 28分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	十二分界 (10:28 ～ 36分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	十三分界 (10:36 ～ 44分)	/	夫 興	/	3	/	强 妻	</td></tr>
<tr><td>	十四分界 (10:44 ～ 52分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	十五分界 (10:52 ～ 60分)	/	夫 再	/	多	/	續 妻	</td></tr>
</table>
<table>
<tr>
    <th>午時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (11:00 ～ 8分)	/	夫 剋	/	少	/	剋 妻	</td></tr>
<tr><td>	二分界 (11:08 ～ 16分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	三分界 (11:16 ～ 24分)	/	夫 强	/	5	/	和 妻	</td></tr>
<tr><td>	四分界 (11:24 ～ 32分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	五分界 (11:32 ～ 40分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	六分界 (11:40 ～ 48分)	/	夫 興	/	3	/	賢 妻	</td></tr>
<tr><td>	七分界 (11:48 ～ 56分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	八分界 (11:56 ～ 12:04分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	九分界 (12:04 ～ 12分)	/	夫 和	/	2	/	佳 妻	</td></tr>
<tr><td>	十分界 (12:12 ～ 20分)	/	夫 無	/	無	/	妻 妻	</td></tr>
<tr><td>	十一分界 (12:20 ～ 28分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	十二分界 (12:28 ～ 36分)	/	夫 皆	/	1	/	强 妻	</td></tr>
<tr><td>	十三分界 (12:36 ～ 44分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	十四分界 (12:44 ～ 52分)	/	夫亡再	/	無	/	亡 妻	</td></tr>
<tr><td>	十五分界 (12:52 ～ 60分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
</table>
<table>
<tr>
    <th>未時 남편관계 / 자식관계 / 처첩관계 </th>

</tr>
<tr><td>	一分界 (13:00 ～ 8分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	二分界 (13:08 ～ 16分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	三分界 (13:16 ～ 24分)	/	夫 興	/	3	/	賢 妻	</td></tr>
<tr><td>	四分界 (13:24 ～ 32分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	五分界 (13:32 ～ 40分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	六分界 (13:40 ～ 48分)	/	夫 和	/	2	/	佳 妻	</td></tr>
<tr><td>	七分界 (13:48 ～ 56分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	八分界 (13:56 ～ 14:04分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	九分界 (14:04 ～ 12分)	/	夫 皆	/	1	/	强 妻	</td></tr>
<tr><td>	十分界 (14:12 ～ 20分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	十一分界 (14:20 ～ 28分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
<tr><td>	十二分界 (14:28 ～ 36分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
<tr><td>	十三分界 (14:36 ～ 44分)	/	夫 剋	/	少	/	剋 妻	</td></tr>
<tr><td>	十四分界 (14:44 ～ 52分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	十五分界 (14:52 ～ 60分)	/	夫 强	/	5	/	和 妻	</td></tr>
</table>

<table>
<tr>

<th>申時 남편관계 / 자식관계 / 처첩관계 </th>

</tr>
<tr><td>	一分界 (15:00 ～ 8分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	二分界 (15:08 ～ 16分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	三分界 (15:16 ～ 24分)	/	夫 和	/	2	/	佳 妻	</td></tr>
<tr><td>	四分界 (15:24 ～ 32分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	五分界 (15:32 ～ 40分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	六分界 (15:40 ～ 48分)	/	夫 皆	/	1	/	强 妻	</td></tr>
<tr><td>	七分界 (15:48 ～ 56分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	八分界 (15:56 ～ 16:04分)	/	夫亡又	/	少	/	剋 妻	</td></tr>
<tr><td>	九分界 (16:04 ～ 12分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
<tr><td>	十分界 (16:12 ～ 20分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	十一分界 (16:20 ～ 28分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	十二分界 (16:28 ～ 36分)	/	夫 强	/	5	/	皆 妻	</td></tr>
<tr><td>	十三分界 (16:36 ～ 44分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	十四分界 (16:44 ～ 52分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	十五分界 (16:52 ～ 60分)	/	夫 興	/	3	/	賢 妻	</td></tr>
</table>

<table>
<tr>

<th>酉時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (17:00 ～ 8分)	/	夫 無	/	子無	/	無 妻	</td></tr>
<tr><td>	二分界 (17:08 ～ 16分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	三分界 (17:16 ～ 24分)	/	夫 皆	/	1	/	强 妻	</td></tr>
<tr><td>	四分界 (17:24 ～ 32分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	五分界 (17:32 ～ 40分)	/	夫亡又	/	少	/	亡 妻	</td></tr>
<tr><td>	六分界 (17:40 ～ 48分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
<tr><td>	七分界 (17:48 ～ 56分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	八分界 (17:56 ～ 18:04分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	九分界 (18:04 ～ 12分)	/	夫 强	/	5	/	和 妻	</td></tr>
<tr><td>	十分界 (18:12 ～ 20分)	/	夫 興	/	3	/	賢 妻	</td></tr>
<tr><td>	十一分界 (18:20 ～ 28分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	十二分界 (18:28 ～ 36分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	十三分界 (18:36 ～ 44分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	十四分界 (18:44 ～ 52分)	/	夫 佳	/	2	/	和 妻	</td></tr>
<tr><td>	十五分界 (18:52 ～ 60分)	/	夫 再	/	多	/	續 妻	</td></tr>
</table>

<table>
<tr>
    <th>戌時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (19: 0 ～ 8分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	二分界 (19:08 ～ 16分)	/	夫亡又	/	少	/	亡 妻	</td></tr>
<tr><td>	三分界 (19:16 ～ 24分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
<tr><td>	四分界 (19:24 ～ 32分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	五分界 (19:32 ～ 40分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	六分界 (19:40 ～ 48分)	/	夫 强	/	5	/	和 妻	</td></tr>
<tr><td>	七分界 (19:48 ～ 56分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	八分界 (19:56 ～ 20:04分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	九分界 (20:04 ～ 12分)	/	夫 興	/	3	/	賢 妻	</td></tr>
<tr><td>	十分界 (20:12 ～ 20分)	/	夫 再	/	4	/	續 妻	</td></tr>
<tr><td>	十一分界 (20:20 ～ 28分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	十二分界 (20:28 ～ 36分)	/	夫 和	/	2	/	佳 妻	</td></tr>
<tr><td>	十三分界 (20:36 ～ 44分)	/	夫 無	/	無	/	無 妻	</td></tr>
<tr><td>	十四分界 (20:44 ～ 52分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	十五分界 (20:52 ～ 60分)	/	夫 皆	/	1	/	强 妻	</td></tr>
</table>

<table>
<tr>
    <th>亥時 남편관계 / 자식관계 / 처첩관계 </th>
</tr>
<tr><td>	一分界 (21:00 ～ 8分)	/	夫再又	/	多	/	有 妻	</td></tr>
<tr><td>	二分界 (21:08 ～ 16分)	/	夫 强	/	5	/	和 妻	</td></tr>
<tr><td>	三分界 (21:16 ～ 24分)	/	夫 亡	/	少	/	亡 妻	</td></tr>
<tr><td>	四分界 (21:24 ～ 32分)	/	夫 有	/	無	/	有 妻	</td></tr>
<tr><td>	五分界 (21:32 ～ 40分)	/	夫 興	/	3	/	賢 妻	</td></tr>
<tr><td>	六分界 (21:40 ～ 48分)	/	夫 亡	/	多	/	亡 妻	</td></tr>
<tr><td>	七分界 (21:48 ～ 56分)	/	夫亡又	/	多	/	亡 妻	</td></tr>
<tr><td>	八分界 (21:56 ～ 22:04分)	/	夫 和	/	2	/	佳 妻	</td></tr>
<tr><td>	九分界 (22:04 ～ 12分)	/	夫 再	/	少	/	續 妻	</td></tr>
<tr><td>	十分界 (22:12 ～ 20分)	/	夫亡又	/	少	/	亡 妻	</td></tr>
<tr><td>	十一分界 (22:20 ～ 28分)	/	夫 皆	/	1	/	强 妻	</td></tr>
<tr><td>	十二分界 (22:28 ～ 36分)	/	夫再又	/	無	/	有 妻	</td></tr>
<tr><td>	十三分界 (22:36 ～ 44分)	/	夫 再	/	多	/	續 妻	</td></tr>
<tr><td>	十四分界 (22:44 ～ 52分)	/	夫 佳	/	4	/	皆 妻	</td></tr>
<tr><td>	十五分界 (22:52 ～ 60分)	/	夫 無	/	無	/	無 妻	</td></tr>
</table>


                        </div>`)}
                {parse(`
                        
                        
                        `)}
              </div>

              <br />
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "일간천장대나이별사상" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        14. 일간 나이별사상</div>`)}
              <div
                style={{
                  width: "calc(100% - 0px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 0,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate25}>
                  <img
                    ref={imgRef25}
                    src={`/static/images/_il1200/001.png`}
                    style={{
                      width: `calc(100vw - 40px)`,
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <br />
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "출생일주별부부남녀궁합" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        15. 일주별 부부남녀궁합</div>`)}
              <div
                style={{
                  width: "calc(100% - 0px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 0,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate25}>
                  <img
                    ref={imgRef25}
                    src={`/static/images/_il1300/001.png`}
                    style={{
                      width: `calc(100vw - 40px)`,
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <br />
            </>
          )}
          {/* 지금해야하는것 */}
          {/* JoongGiIndex > -1 &&
            objectILSANGMENU && objectILSANGMENU.default 
        && objectILSANGMENU.default.VERSION001_JIDU
        && objectILSANGMENU.default.VERSION001_JIDU[(12 * (((JoongGiIndex - 1 ))) ) + (selectedSiIndex) ]
        && objectILSANGMENU.default.VERSION001_JIDU[(12 * (((JoongGiIndex - 1 ))) ) +  (selectedSiIndex) ][4]  */}
          {htmlPopupContent && htmlPopupContent == "각 일별사" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                        13. 각 일별사, 질병사, 육친변수사</div>`)}
              {selectedGapjaIndex > -1 &&
                object101_1 &&
                object101_1.default &&
                object101_1.default.VERSION101_1_1300 &&
                object101_1.default.VERSION101_1_1300[selectedGapjaIndex] && (
                  <>
                    <div
                      style={{
                        width: "calc(100% - 0px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: 0,
                      }}
                    >
                      <QuickPinchZoom onUpdate={onUpdate21}>
                        <img
                          ref={imgRef21}
                          src={
                            object101_1.default.VERSION101_1_1300[
                              selectedGapjaIndex - 1
                            ]
                          }
                          style={{
                            objectFit: "contain",
                            border: `4px solid #000`,
                            overflow: "hidden",
                            maxHeight: 550,
                          }}
                          zoom={192}
                        />
                      </QuickPinchZoom>
                    </div>

                    <br />
                  </>
                )}
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "년년세세" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                            14. 년년세세 매년 월별 신수</div>`)}
              {selectedGapjaIndex > -1 &&
                selectedGook > -1 &&
                object101_1 &&
                object101_1.default &&
                object101_1.default.VERSION101_1_1400 &&
                object101_1.default.VERSION101_1_1400[
                  12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                ] && (
                  <>
                    <div
                      style={{
                        width: "calc(100% - 80px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: 40,
                      }}
                    >
                      <QuickPinchZoom onUpdate={onUpdate22}>
                        <img
                          ref={imgRef22}
                          src={
                            object101_1.default.VERSION101_1_1400[
                              12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                            ]
                          }
                          style={{
                            objectFit: "contain",
                            border: `4px solid #000`,
                            overflow: "hidden",
                            maxHeight: 550,
                          }}
                          zoom={192}
                        />
                      </QuickPinchZoom>
                    </div>

                    <br />
                  </>
                )}
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "행운감정" && (
            <>
              {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">
                                15. 행운감정
                                </div>`)}
              {/* 
                                ${(selectedGapjaIndex - 1)} */}
              {selectedGapjaIndex > -1 &&
                object101_1 &&
                object101_1.default &&
                object101_1.default.VERSION101_2_1500 && (
                  // && object101_1.default.VERSION101_2_1500[selectedGapjaIndex] &&
                  <>
                    <div
                      style={{
                        width: "calc(100% - 20px)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: `10px`,
                        textAlign: "left",
                        fontSize: `18px`,
                        marginBottom: `20px`,
                      }}
                    >
                      {/* <QuickPinchZoom  onUpdate={onUpdate23} style={{ width: `100%`}}>

            <img 
            ref={imgRef23}  
            src={object101_1.default.VERSION101_1_1500[(selectedGapjaIndex - 1)]} 
            style={{ width: `calc(100vw - 40px)`,  overflow : 'hidden',   objectFit: 'contain'}}
                zoom={192}
            />
</QuickPinchZoom> */}
                      {/* (selectedGapjaIndex - 1) % 12 */}
                      {/* {(selectedGapjaIndex - 1) % 12} */}

                      <br />
                      <br />
                      <br />
                      <div
                        style={{
                          fontWeight: 700,
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        일간 {selectedGapja}
                      </div>

                      {parse(
                        object101_1.default.VERSION101_2_1500[
                          (selectedGapjaIndex - 1) % 12
                        ]
                      )}
                    </div>
                  </>
                )}
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "각순별육십갑자" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">각 순별 육십갑자</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate10}>
                  <img
                    ref={imgRef10}
                    src={`/nap001-1.png`}
                    style={{
                      objectFit: "contain",
                      border: `4px solid #000`,
                      overflow: "hidden",
                      maxHeight: 550,
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <br />
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "전화상담즉단" && (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="danjeongMenuTableContainer001">
                <div className="title001">{`전화상담즉단 `}</div>

                <div className="title002">{`묻는 시간 `}</div>

                <div className="danjeongMenu_Container0 ">
                  {Array.from([...ganji12]).map((ganItem, ganIndex) => {
                    return (
                      <div
                        className="danjeongMenu_Item0 "
                        style={{
                          fontWeight: selectedJuek1 == ganIndex ? "900" : "500",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedJuek1(ganIndex);
                          setSelectedJuek2(-1);
                        }}
                      >
                        {ganItem}
                        {ganIndex == 0 && ` (23)`}
                        {ganIndex == 1 && ` (01)`}
                        {ganIndex == 2 && ` (03)`}
                        {ganIndex == 3 && ` (05)`}
                        {ganIndex == 4 && ` (07)`}
                        {ganIndex == 5 && ` (09)`}
                        {ganIndex == 6 && ` (11)`}
                        {ganIndex == 7 && ` (13)`}
                        {ganIndex == 8 && ` (15)`}
                        {ganIndex == 9 && ` (17)`}
                        {ganIndex == 10 && ` (19)`}
                        {ganIndex == 11 && ` (21)`}
                      </div>
                    );
                  })}
                </div>

                <div
                  className=""
                  style={{
                    width: `calc(100% - 40px)`,
                    borderBottom: "1px solid #000",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: `20px`,
                    marginBottom: `12px`,
                    textAlign: "center",
                    paddingBottom: `8px`,
                  }}
                ></div>

                {/* 중기값 + 시간값 */}
                <table
                  style={{ width: `100%` }}
                  className={`danjeongMenuTable001`}
                >
                  <tr>
                    <td colSpan={2}>
                      묻는 내용
                      <br />
                      해당 지지
                    </td>
                    <td colSpan={2}>용건</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 0 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(0);
                      }}
                    >
                      {ganji12_0[0]}
                      {selectedJuek2 == 0 && ` ▲ `}
                    </td>
                    <td colSpan={2}>재화출입, 구재, 대차, 희망, 계획</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 1 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(1);
                      }}
                    >
                      {ganji12_0[1]}
                      {selectedJuek2 == 1 && ` ▲ `}
                    </td>
                    <td colSpan={2}>음신, 서신, 소식</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 2 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(2);
                      }}
                    >
                      {ganji12_0[2]}
                      {selectedJuek2 == 2 && ` ▲ `}
                    </td>
                    <td colSpan={2}>혼인, 약혼</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 3 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(3);
                      }}
                    >
                      {ganji12_0[3]}
                      {selectedJuek2 == 3 && ` ▲ `}
                    </td>
                    <td colSpan={2}>대인, 여행, 외출, 이사</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 4 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(4);
                      }}
                    >
                      {ganji12_0[4]}
                      {selectedJuek2 == 4 && ` ▲ `}
                    </td>
                    <td colSpan={2}>방문, 래객</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 5 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(5);
                      }}
                    >
                      {ganji12_0[5]}
                      {selectedJuek2 == 5 && ` ▲ `}
                    </td>
                    <td colSpan={2}>분실, 도난</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 6 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(6);
                      }}
                    >
                      {ganji12_0[6]}
                      {selectedJuek2 == 6 && ` ▲ `}
                    </td>
                    <td colSpan={2}>도주, 가출, 실종</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 7 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(7);
                      }}
                    >
                      {ganji12_0[7]}
                      {selectedJuek2 == 7 && ` ▲ `}
                    </td>
                    <td colSpan={2}>취직, 시험</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 8 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(8);
                      }}
                    >
                      {ganji12_0[8]}
                      {selectedJuek2 == 8 && ` ▲ `}
                    </td>
                    <td colSpan={2}>소송, 관재, 분쟁</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 9 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(9);
                      }}
                    >
                      {ganji12_0[9]}
                      {selectedJuek2 == 9 && ` ▲ `}
                    </td>
                    <td colSpan={2}>질병, 우환</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 10 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(10);
                      }}
                    >
                      {ganji12_0[10]}
                      {selectedJuek2 == 10 && ` ▲ `}
                    </td>
                    <td colSpan={2}>매매거래, 가격, 계약여부</td>
                  </tr>
                  <tr>
                    <td
                      className={`jeukButton0`}
                      style={{
                        fontWeight: selectedJuek2 == 11 ? "900" : "500",
                        lineHeight: `24px`,
                      }}
                      colSpan={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedJuek2(11);
                      }}
                    >
                      {ganji12_0[11]}
                      {selectedJuek2 == 11 && ` ▲ `}
                    </td>
                    <td colSpan={2}>임신, 출산</td>
                  </tr>

                  <tr>
                    <td colSpan={4}>
                      {/* objectILSANGMENU */}
                      <br />
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        selectedJuek1 > -1 &&
                        selectedJuek2 > -1 && (
                          <div>
                            {parse(`
                    ${
                      selectedGapjaIndex > -1 &&
                      selectedSiIndex > -1 &&
                      selectedJuek1 > -1 &&
                      selectedJuek2 > -1 &&
                      objectILSANGMENU &&
                      objectILSANGMENU.default &&
                      objectILSANGMENU.default
                        .VERSION_ILSANGSAEHWAL_GILHUENG_METHED &&
                      objectILSANGMENU.default
                        .VERSION_ILSANGSAEHWAL_GILHUENG_METHED[
                        12 * selectedJuek1 + selectedJuek1
                      ] &&
                      objectILSANGMENU.default.VERSION_ILSANGSAEHWAL_GILHUENG_METHED[
                        12 * selectedJuek1 + selectedJuek2
                      ][3].replaceAll("▲", "<br/>▲")
                    }<br/><br/>
        ${
          selectedGapjaIndex > -1 &&
          selectedSiIndex > -1 &&
          selectedJuek1 > -1 &&
          selectedJuek2 > -1 &&
          objectILSANGMENU &&
          objectILSANGMENU.default &&
          objectILSANGMENU.default.VERSION_ILSANGSAEHWAL_GILHUENG_METHED &&
          objectILSANGMENU.default.VERSION_ILSANGSAEHWAL_GILHUENG_METHED[
            12 * selectedJuek1 + selectedJuek1
          ] &&
          objectILSANGMENU.default.VERSION_ILSANGSAEHWAL_GILHUENG_METHED[
            12 * selectedJuek1 + selectedJuek2
          ][4] &&
          objectILSANGMENU.default.VERSION_ILSANGSAEHWAL_GILHUENG_METHED[
            12 * selectedJuek1 + selectedJuek2
          ][4].replaceAll("▲", "<br/>▲")
        }`)}
                          </div>
                        )}

                      <br />
                    </td>
                  </tr>
                </table>
              </div>
            </>
          )}
          {/* 2023-01-31작업 */}
          {htmlPopupContent && htmlPopupContent == "지두법" && (
            <>
              <br />
              <br />
              <br />

              <div className="danjeongMenuTableContainer001">
                <div className="title001">{`지두생활응용`}</div>
                <div className="">
                  {objectJooggi &&
                    objectJooggi.default &&
                    objectJooggi.default.JEOLGI1[JoongGiIndex]}
                </div>
                <div className="">{selectedSi} 시 </div>
                <br />
                <table
                  style={{ width: `100%` }}
                  className={`danjeongMenuTable001`}
                >
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      이사 · 치료 · 등산 · 여행
                      <br />
                      낚시 · 피신 · 택일 · 길방
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][4]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      공사등 진행사 차질
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][5]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      모든 계획사 성취
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][6]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      구재 성사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][7]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      구물 성사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][8]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      매매 성사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1][9]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      복중태아 남녀구별
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][10]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      질병치료
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][11]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      약속인이 <br />
                      올지 안올지
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][12]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      방문 면담이 될지 안될지
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][13]}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      초대인이 올지 안올지
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][14]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      국 내외 여행 길흉
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][15]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      산속등 미로탈출
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][16]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      길 흉 예상
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][17]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      도난 분실사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][18]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      부탁 위탁사의 성사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][19] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][19]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      상담 게임등 승부사
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][20]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      방문객의 선 악 판단
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][21]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      도망 방위
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][22]}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      행사 예정일의 <br />
                      청우
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][23]}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      소문 진위
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][24]}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={1} style={{ fontWeight: "700" }}>
                      충성 회의
                      <br />
                      배신 여부
                    </td>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {selectedGapjaIndex > -1 &&
                        selectedSiIndex > -1 &&
                        JoongGiIndex > -1 &&
                        objectILSANGMENU &&
                        objectILSANGMENU.default &&
                        objectILSANGMENU.default.VERSION001_JIDU &&
                        objectILSANGMENU.default.VERSION001_JIDU[jiduIndex1] &&
                        objectILSANGMENU.default.VERSION001_JIDU[
                          jiduIndex1
                        ][25]}
                    </td>
                  </tr>
                </table>
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "출산길일길시선택" && (
            <>
              <br />
              <br />
              <br />
              <div className="danjeongMenuTableContainer001">
                <div className="title001">{`출산길일길시선택 `}</div>

                {selectedBirthCalendarGapjaIndex1 > -1 && (
                  <>
                    <table
                      style={{ width: `100%` }}
                      className={`danjeongMenuTable001`}
                    >
                      <tr>
                        <td colSpan={1}>
                          출생시간 <br />및 방문래정
                        </td>
                        <td colSpan={1} style={{ textAlign: "left" }}>
                          출생년주 출생월주 천간 지지에 따른 명운 변화 판단
                        </td>
                      </tr>
                      {Array.from(
                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                          (loopItem, loopIndex) => {
                            return (
                              <>
                                <tr>
                                  <td colSpan={1}>
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][3]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][4]}
                                  </td>
                                  <td colSpan={1} style={{ textAlign: "left" }}>
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][5]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][6]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][7]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][8]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][9]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][10]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][11]}
                                    <br />
                                    {selectedGapjaIndex > -1 &&
                                      selectedSiIndex > -1 &&
                                      objectILSANGMENU &&
                                      objectILSANGMENU.default &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2 &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ] &&
                                      objectILSANGMENU.default
                                        .VERSION001_CHILDBIRTH_BIRTHESDATA2[
                                        12 * selectedBirthCalendarGapjaIndex1 +
                                          loopItem
                                      ][12]}
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )
                      )}
                    </table>
                  </>
                )}
                {selectedBirthCalendarGapjaIndex1 == -1 && (
                  <>
                    <div className="term_wrapper">
                      <iframe
                        id="cal_iframe"
                        src="/static/manse_frame_trans001.html"
                        // manse_frame_trans001
                        style={{
                          width: `100%`,
                          height: `100%`,
                          minHeight: `calc(100vh - 100px)`,
                        }}
                        frameborder="0"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "대적_처신_방침" && (
            <>
              <br />
              <br />
              <br />
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">대적 처신 방침</div>`
              )}
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `18px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                {parse(
                  `${
                    selectedNaejung &&
                    selectedNaejung.default &&
                    selectedNaejung.default.columnAU[
                      5 * (sootJaIndex - 1) + 3
                    ] &&
                    selectedNaejung.default.columnAU[
                      5 * (sootJaIndex - 1) + 3
                    ][0]
                  }
                                        `.split("**")[1]
                )}
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "저장_정단" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">상담 기록 저장</div>`
              )}
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      성명
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput101}
                        onChange={(e) => {
                          setSaveInput101(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      남녀
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput102}
                        onChange={(e) => {
                          setSaveInput102(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      생년월일
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput102_1}
                        onChange={(e) => {
                          setSaveInput102_1(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      띠<br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput103}
                        onChange={(e) => {
                          setSaveInput103(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <br />
              {/* <div className="value0" style={{ fontSize: `12px`, textAlign : 'left' , width : 'calc(100% - 20px)', marginLeft: `10px`}}>

                                <div className="label0" style={{ fontFamily: 'NanumBarunGothic', width: `calc(100% - 40px)`, marginLeft: 10, marginRight: "auto", textAlign: "left", display: "flex",}}>
                                    <div >
                                        <span style={{ fontWeight: 700}}>정단 <br/>연월일시국수</span>
                                    </div>
                                    <br/>
                                    <div style={{display : "inline-flex", fontWeight: 500,  width: "70%" ,marginLeft:" auto", textAlign: "left", flexDirection: "column"}}>
                                        <span style={{ fontWeight: 700}}>
                                            <input style={{width : `140px`,}} value={saveInput104} onChange={e=> { setSaveInput104(e.target.value)}}/>
                                            <br/>
                                        </span>
                                    </div>	
                                </div>
                            </div>

                            <br/> */}
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      판단내용
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput105}
                        onChange={(e) => {
                          setSaveInput105(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      침범귀
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput106}
                        onChange={(e) => {
                          setSaveInput106(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      부정사숭
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput107}
                        onChange={(e) => {
                          setSaveInput107(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      수법실행여부
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput108}
                        onChange={(e) => {
                          setSaveInput108(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      금액
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput109}
                        onChange={(e) => {
                          setSaveInput109(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      특이사항
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{ width: `140px` }}
                        value={saveInput110}
                        onChange={(e) => {
                          setSaveInput110(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <br />

              {true && (
                <>
                  <br /> <br />
                  <div
                    className="value0"
                    style={{
                      fontSize: `12px`,
                      textAlign: "left",
                      width: "calc(100% - 20px)",
                      marginLeft: `10px`,
                    }}
                  >
                    <div
                      className="label0"
                      style={{
                        fontFamily: "NanumBarunGothic",
                        width: `calc(100% - 40px)`,
                        marginLeft: 10,
                        marginRight: "auto",
                        textAlign: "left",
                        display: "flex",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 700 }}>
                          당면사 기록
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: `calc(100% - 20px)`,
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignItems: "center",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        setSearchedInput301(e.target.value);
                      }}
                      value={searchedInput301}
                    >
                      {years1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;년&nbsp;</span>

                    <select
                      onChange={(e) => {
                        setSearchedInput302(e.target.value);
                      }}
                      value={searchedInput302}
                    >
                      {month1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;월&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput303(e.target.value);
                      }}
                      value={searchedInput303}
                    >
                      {days1
                        .filter((filterItem, filterIndex) => {
                          // 1, 3, 5,7, 8,10,12
                          // 2, 4, 6, 9, , 11
                          // 2
                          if (filterItem == "31") {
                            if (
                              searchedInput302 == "01" ||
                              searchedInput302 == "03" ||
                              searchedInput302 == "05" ||
                              searchedInput302 == "07" ||
                              searchedInput302 == "08" ||
                              searchedInput302 == "10" ||
                              searchedInput302 == "12"
                            ) {
                              return filterItem;
                            } else {
                              return null;
                            }
                          } else {
                            return filterItem;
                          }
                        })
                        .map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;일&nbsp;</span>
                    <div className="" style={{ marginLeft: "auto" }}></div>
                  </div>
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: `calc(100% - 20px)`,
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignItems: "center",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        setSearchedInput304(e.target.value);
                      }}
                      value={searchedInput304}
                    >
                      {hours1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;시&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput305(e.target.value);
                      }}
                      value={searchedInput305}
                    >
                      {minutes1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;분&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput307(e.target.value);
                      }}
                      value={searchedInput307}
                    >
                      {yinyang1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;&nbsp;</span>
                    <div className="" style={{ marginLeft: "auto" }}></div>
                  </div>
                </>
              )}

              {true && (
                <>
                  <br /> <br />
                  <div
                    className="value0"
                    style={{
                      fontSize: `12px`,
                      textAlign: "left",
                      width: "calc(100% - 20px)",
                      marginLeft: `10px`,
                    }}
                  >
                    <div
                      className="label0"
                      style={{
                        fontFamily: "NanumBarunGothic",
                        width: `calc(100% - 40px)`,
                        marginLeft: 10,
                        marginRight: "auto",
                        textAlign: "left",
                        display: "flex",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 700 }}>
                          일생사 기록
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: `calc(100% - 20px)`,
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignItems: "center",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        setSearchedInput401(e.target.value);
                      }}
                      value={searchedInput401}
                    >
                      {years1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;년&nbsp;</span>

                    <select
                      onChange={(e) => {
                        setSearchedInput402(e.target.value);
                      }}
                      value={searchedInput402}
                    >
                      {month1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;월&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput403(e.target.value);
                      }}
                      value={searchedInput403}
                    >
                      {days1
                        .filter((filterItem, filterIndex) => {
                          // 1, 3, 5,7, 8,10,12
                          // 2, 4, 6, 9, , 11
                          // 2
                          if (filterItem == "31") {
                            if (
                              searchedInput402 == "01" ||
                              searchedInput402 == "03" ||
                              searchedInput402 == "05" ||
                              searchedInput402 == "07" ||
                              searchedInput402 == "08" ||
                              searchedInput402 == "10" ||
                              searchedInput402 == "12"
                            ) {
                              return filterItem;
                            } else {
                              return null;
                            }
                          } else {
                            return filterItem;
                          }
                        })
                        .map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;일&nbsp;</span>
                    <div className="" style={{ marginLeft: "auto" }}></div>
                  </div>
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      width: `calc(100% - 20px)`,
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignItems: "center",
                    }}
                  >
                    <select
                      onChange={(e) => {
                        setSearchedInput404(e.target.value);
                      }}
                      value={searchedInput404}
                    >
                      {hours1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;시&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput405(e.target.value);
                      }}
                      value={searchedInput405}
                    >
                      {minutes1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;분&nbsp;</span>
                    <select
                      onChange={(e) => {
                        setSearchedInput407(e.target.value);
                      }}
                      value={searchedInput407}
                    >
                      {yinyang1.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: 700 }}>&nbsp;&nbsp;</span>
                    <div className="" style={{ marginLeft: "auto" }}></div>
                  </div>
                </>
              )}

              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 700 }}>
                      <br />
                    </span>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className=" button_1"
                      style={{
                        width: `148px`,
                        height: 20,
                        marginRight: "auto",
                        background:
                          activeEffect1 == "input"
                            ? "#98adc1"
                            : `rgb(141, 141, 170)`,
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (saveInput101 == "") {
                          return;
                        }

                        console.log("click");
                        firebase
                          .database()
                          .ref()
                          .child("contacts")
                          .child(`/${fbContactKeyName}`)
                          .child("consulting")
                          .push(
                            {
                              col001: saveInput101,
                              col002: saveInput102,
                              col003: saveInput102_1,
                              col004: saveInput103,
                              col005: saveInput104,
                              col006: saveInput105,
                              col007: saveInput106,
                              col008: saveInput107,
                              col009: saveInput108,
                              col010: saveInput109,
                              col011: saveInput110,

                              dan001: searchedInput301,
                              dan002: searchedInput302,
                              dan003: searchedInput303,
                              dan004: searchedInput304,
                              dan005: searchedInput305,
                              dan006: "01",
                              dan007: searchedInput307,

                              ill001: searchedInput401,
                              ill002: searchedInput402,
                              ill003: searchedInput403,
                              ill004: searchedInput404,
                              ill005: searchedInput405,
                              ill006: "01",
                              ill007: searchedInput407,
                            },
                            (err) => {
                              console.log("err", err);
                              if (err) {
                              } else {
                                setHtmlPopupContent(null);
                              }
                            }
                          );
                      }}
                    >
                      저장
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 300 }}>
                      <br />
                    </span>
                  </div>
                  <br />

                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "90%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    부연1. 항목을 모두 채우지 않아도 됨
                  </div>
                </div>
              </div>
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 300 }}>
                      <br />
                    </span>
                  </div>
                  <br />

                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "90%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    부연2. 동명이인일 경우 성명 끝에 1,2,3,4 를 표기해야함.
                  </div>
                </div>
              </div>
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 300 }}>
                      <br />
                    </span>
                  </div>
                  <br />

                  {/* <div style={{display : "inline-flex", fontWeight: 500,  width: "90%" ,marginLeft:" auto", textAlign: "left", flexDirection: "column"}}>
        부연3. 정단 연월일시는 일자변경에, 출생연월일시는 일생사에 입력하여 색인함.
    </div>	 */}
                </div>
              </div>
              <br />
              <br />
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "색인_정단" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">상담 기록 색인 </div>`
              )}
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <div
                  className="label0"
                  style={{
                    fontFamily: "NanumBarunGothic",
                    width: `calc(100% - 40px)`,
                    marginLeft: 10,
                    marginRight: "auto",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      fontWeight: 500,
                      width: "70%",
                      marginLeft: " auto",
                      textAlign: "left",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      <input
                        style={{}}
                        value={saveInput100}
                        placeholder={"성명"}
                        onChange={(e) => {
                          setSaveInput100(e.target.value);
                        }}
                      />
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      className=" button_1"
                      style={{
                        width: `50px`,
                        height: 20,
                        marginLeft: "auto",
                        marginRight: "auto",
                        background:
                          activeEffect1 == "input"
                            ? "#98adc1"
                            : `rgb(141, 141, 170)`,
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        firebase
                          .database()
                          .ref()
                          .child("contacts")
                          .child(`/${fbContactKeyName}`)
                          .child("consulting")
                          .orderByChild("col001")
                          .equalTo(saveInput100)
                          .on("value", (snapshot) => {
                            if (snapshot.val()) {
                              const data = snapshot.val();
                              console.log("data", data);
                              const keyName =
                                Object.keys(snapshot.val()) &&
                                Object.keys(snapshot.val())[0];

                              setSaveResult001(snapshot.val()[keyName]);

                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan001 &&
                                setSearchedInput301(
                                  snapshot.val()[keyName].dan001
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan002 &&
                                setSearchedInput302(
                                  snapshot.val()[keyName].dan002
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan003 &&
                                setSearchedInput303(
                                  snapshot.val()[keyName].dan003
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan004 &&
                                setSearchedInput304(
                                  snapshot.val()[keyName].dan004
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan005 &&
                                setSearchedInput305(
                                  snapshot.val()[keyName].dan005
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan006 &&
                                setSearchedInput306(
                                  snapshot.val()[keyName].dan006
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].dan007 &&
                                setSearchedInput307(
                                  snapshot.val()[keyName].dan007
                                );

                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill001 &&
                                setSearchedInput301(
                                  snapshot.val()[keyName].ill001
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill002 &&
                                setSearchedInput302(
                                  snapshot.val()[keyName].ill002
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill003 &&
                                setSearchedInput303(
                                  snapshot.val()[keyName].ill003
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill004 &&
                                setSearchedInput304(
                                  snapshot.val()[keyName].ill004
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill005 &&
                                setSearchedInput305(
                                  snapshot.val()[keyName].ill005
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill006 &&
                                setSearchedInput306(
                                  snapshot.val()[keyName].ill006
                                );
                              snapshot.val()[keyName] &&
                                snapshot.val()[keyName].ill007 &&
                                setSearchedInput307(
                                  snapshot.val()[keyName].ill007
                                );
                            } else {
                              setSaveResult001({
                                col001: "NO RESULT",
                                col002: "NO RESULT",
                                col003: "NO RESULT",
                                col004: "NO RESULT",
                                col005: "NO RESULT",
                                col006: "NO RESULT",
                                col007: "NO RESULT",
                                col008: "NO RESULT",
                                col009: "NO RESULT",
                                col010: "NO RESULT",
                                col011: "NO RESULT",

                                dan001: "",
                                dan002: "",
                                dan003: "",
                                dan004: "",
                                dan005: "",
                                dan006: "",
                                dan007: "",

                                ill001: "",
                                ill002: "",
                                ill003: "",
                                ill004: "",
                                ill005: "",
                                ill006: "",
                                ill007: "",
                              });
                            }
                          });
                      }}
                    >
                      검색
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <br />
              {
                // saveInput100 != "" &&
                // saveResult001.col001 != "" &&
                true && (
                  <>
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            성명
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col001}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            남녀
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col002}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            생년월일
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col003}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            띠<br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col004}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            정단 <br />
                            연월일시국수
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col005}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            판단내용
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col006}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            침범귀
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col007}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            부정사숭
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col008}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            수법실행여부
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col009}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            금액
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col010}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div
                      className="value0"
                      style={{
                        fontSize: `12px`,
                        textAlign: "left",
                        width: "calc(100% - 20px)",
                        marginLeft: `10px`,
                      }}
                    >
                      <div
                        className="label0"
                        style={{
                          fontFamily: "NanumBarunGothic",
                          width: `calc(100% - 40px)`,
                          marginLeft: 10,
                          marginRight: "auto",
                          textAlign: "left",
                          display: "flex",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 700 }}>
                            특이사항
                            <br />
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "inline-flex",
                            fontWeight: 500,
                            width: "70%",
                            marginLeft: " auto",
                            textAlign: "left",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            <input
                              value={saveResult001.col011}
                              readOnly={true}
                            />
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>

                    {true && (
                      <>
                        <br /> <br />
                        <div
                          className="value0"
                          style={{
                            fontSize: `12px`,
                            textAlign: "left",
                            width: "calc(100% - 20px)",
                            marginLeft: `10px`,
                          }}
                        >
                          <div
                            className="label0"
                            style={{
                              fontFamily: "NanumBarunGothic",
                              width: `calc(100% - 40px)`,
                              marginLeft: 10,
                              marginRight: "auto",
                              textAlign: "left",
                              display: "flex",
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: 700 }}>
                                당면사 기록
                                <br />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                          }}
                        >
                          <select
                            onChange={(e) => {
                              setSearchedInput301(e.target.value);
                            }}
                            value={searchedInput301}
                          >
                            {years1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;년&nbsp;
                          </span>

                          <select
                            onChange={(e) => {
                              setSearchedInput302(e.target.value);
                            }}
                            value={searchedInput302}
                          >
                            {month1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;월&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput303(e.target.value);
                            }}
                            value={searchedInput303}
                          >
                            {days1
                              .filter((filterItem, filterIndex) => {
                                // 1, 3, 5,7, 8,10,12
                                // 2, 4, 6, 9, , 11
                                // 2
                                if (filterItem == "31") {
                                  if (
                                    searchedInput302 == "01" ||
                                    searchedInput302 == "03" ||
                                    searchedInput302 == "05" ||
                                    searchedInput302 == "07" ||
                                    searchedInput302 == "08" ||
                                    searchedInput302 == "10" ||
                                    searchedInput302 == "12"
                                  ) {
                                    return filterItem;
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return filterItem;
                                }
                              })
                              .map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;일&nbsp;
                          </span>
                          <div
                            className=""
                            style={{ marginLeft: "auto" }}
                          ></div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                          }}
                        >
                          <select
                            onChange={(e) => {
                              setSearchedInput304(e.target.value);
                            }}
                            value={searchedInput304}
                          >
                            {hours1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;시&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput305(e.target.value);
                            }}
                            value={searchedInput305}
                          >
                            {minutes1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;분&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput307(e.target.value);
                            }}
                            value={searchedInput307}
                          >
                            {yinyang1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>&nbsp;&nbsp;</span>
                          <div
                            className=""
                            style={{ marginLeft: "auto" }}
                          ></div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 10,
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            className=" button_1"
                            style={{
                              width: `280px`,
                              height: 20,
                              marginRight: "auto",
                              marginLeft: "auto",
                              background:
                                activeEffect1 == "input"
                                  ? "#98adc1"
                                  : `rgb(141, 141, 170)`,
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                            onClick={() => {
                              setActiveEffect1("input");

                              if (searchedInput307 == "양력") {
                                __GOOKSU_DECIDE_AS_JOOGI(
                                  searchedInput301,
                                  searchedInput302,
                                  searchedInput303,
                                  searchedInput304,
                                  searchedInput305,
                                  searchedInput306
                                );
                              } else {
                                /* 양력/음력 변환 */
                                var date = getSolar(
                                  searchedInput301,
                                  searchedInput302,
                                  searchedInput303,
                                  0
                                );
                                console.log("음력 -> 양력", date);
                                __GOOKSU_DECIDE_AS_JOOGI(
                                  date.year,
                                  date.month,
                                  date.day,
                                  searchedInput304,
                                  searchedInput305,
                                  searchedInput306
                                );
                              }

                              setBoolMagnifyPopup(false);
                              setHtmlPopupContent("");
                              setHtmlPopupContentSub("");
                            }}
                          >
                            검색
                          </span>
                        </div>
                      </>
                    )}

                    {true && (
                      <>
                        <br /> <br />
                        <div
                          className="value0"
                          style={{
                            fontSize: `12px`,
                            textAlign: "left",
                            width: "calc(100% - 20px)",
                            marginLeft: `10px`,
                          }}
                        >
                          <div
                            className="label0"
                            style={{
                              fontFamily: "NanumBarunGothic",
                              width: `calc(100% - 40px)`,
                              marginLeft: 10,
                              marginRight: "auto",
                              textAlign: "left",
                              display: "flex",
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: 700 }}>
                                일생사 기록
                                <br />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                          }}
                        >
                          <select
                            onChange={(e) => {
                              setSearchedInput401(e.target.value);
                            }}
                            value={searchedInput401}
                          >
                            {years1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;년&nbsp;
                          </span>

                          <select
                            onChange={(e) => {
                              setSearchedInput402(e.target.value);
                            }}
                            value={searchedInput402}
                          >
                            {month1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;월&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput403(e.target.value);
                            }}
                            value={searchedInput403}
                          >
                            {days1
                              .filter((filterItem, filterIndex) => {
                                // 1, 3, 5,7, 8,10,12
                                // 2, 4, 6, 9, , 11
                                // 2
                                if (filterItem == "31") {
                                  if (
                                    searchedInput402 == "01" ||
                                    searchedInput402 == "03" ||
                                    searchedInput402 == "05" ||
                                    searchedInput402 == "07" ||
                                    searchedInput402 == "08" ||
                                    searchedInput402 == "10" ||
                                    searchedInput402 == "12"
                                  ) {
                                    return filterItem;
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return filterItem;
                                }
                              })
                              .map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;일&nbsp;
                          </span>
                          <div
                            className=""
                            style={{ marginLeft: "auto" }}
                          ></div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 10,
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                          }}
                        >
                          <select
                            onChange={(e) => {
                              setSearchedInput404(e.target.value);
                            }}
                            value={searchedInput404}
                          >
                            {hours1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;시&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput405(e.target.value);
                            }}
                            value={searchedInput405}
                          >
                            {minutes1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;분&nbsp;
                          </span>
                          <select
                            onChange={(e) => {
                              setSearchedInput407(e.target.value);
                            }}
                            value={searchedInput407}
                          >
                            {yinyang1.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span style={{ fontWeight: 700 }}>&nbsp;&nbsp;</span>
                          <div
                            className=""
                            style={{ marginLeft: "auto" }}
                          ></div>
                        </div>
                        <div
                          className=" "
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 10,
                            width: `calc(100% - 20px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            className=" button_1"
                            style={{
                              width: `280px`,
                              height: 20,
                              marginLeft: "auto",
                              marginRight: "auto",
                              background:
                                activeEffect1 == "input"
                                  ? "#98adc1"
                                  : `rgb(141, 141, 170)`,
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                            onClick={() => {
                              console.log("clicked - 207");
                              console.log("clicked - 207");

                              setSearchedInput201(searchedInput401);
                              setSearchedInput202(searchedInput402);
                              setSearchedInput203(searchedInput403);
                              setSearchedInput204(searchedInput404);
                              setSearchedInput205(searchedInput405);
                              setSearchedInput206(searchedInput406);
                              setSearchedInput207(searchedInput407);

                              setActiveEffect1("input");

                              if (searchedInput407 == "양력") {
                                __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                                  searchedInput401,
                                  searchedInput402,
                                  searchedInput403,
                                  searchedInput404,
                                  searchedInput405,
                                  searchedInput406
                                );
                              } else {
                                /* 양력/음력 변환 */
                                /* 양력/음력 변환 */
                                var date = getSolar(
                                  searchedInput401,
                                  searchedInput402,
                                  searchedInput403,
                                  0
                                );
                                console.log("음력 -> 양력", date);
                                __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                                  date.year,
                                  date.month,
                                  date.day,
                                  searchedInput404,
                                  searchedInput405,
                                  searchedInput406
                                );
                              }
                              setHtmlPopupContent("색인_일생사");
                            }}
                          >
                            검색
                          </span>
                        </div>
                      </>
                    )}

                    <br />
                    {/* <div 
                            
                            className=" button_1" 
                            style={{ width : `calc(50vw - 10px)`, height: 20 ,
                            marginLeft : 'auto',
                             marginRight: 'auto',  background : activeEffect1 == "input" ? '#98adc1' : `rgb(141, 141, 170)`, fontSize : 13,
                             fontWeight : 500 }}
                             onClick={e=> {
                                e.stopPropagation();
                                e.preventDefault();
                             }}
                             
                             >확인</div> */}
                  </>
                )
              }
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "선택_지지_설명_보기" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">보기</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `18px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                {/* 갑 (甲)	을 (乙)	병 (丙)	정 (丁)	무 (戊)	기 (己)	경 (庚)	신 (辛)	임 (壬)	계 (癸) */}
                {/* "자 (子)","축 (丑)","인 (寅)","묘 (卯)"	,"진 (辰)","사 (巳)","오 (午)","미 (未)","신 (申)","유 (酉)","술 (戌)","해 (亥)", */}
                1.
                <br /> <span style={{ fontWeight: 700 }}>태세지</span> (당년
                지지) · <br />
                <span style={{ fontWeight: 700 }}>월건지</span>(당월 지지) ·{" "}
                <br />
                <span style={{ fontWeight: 700 }}>월장지</span>(당월 중기 이후
                천기 지지) · <br />
                <span style={{ fontWeight: 700 }}>왕상지</span>(봄 · 겨울 寅 卯
                / 여름 · 봄 巳 午 / 土 월 · 여름 辰 戌 丑 未 / 겨울 · 가을 亥 子
                지지) <br />
                <span style={{ fontWeight: 700 }}>
                  해당자는 신분이 높음 또는 높아질 人
                </span>
                <br />
                <br />
                2. <br />
                <span style={{ fontWeight: 700 }}>의신</span>(60갑자 중 각 순의
                갑에 붙는 지지 : 갑자순 10일 자, 갑술순 10일 술, 갑신순 10일 신,
                갑오순 10일 오, 갑진순 10일 진, 갑인순 10일 인 각 10일간
                유효함)·
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>기신</span>(위 각 2개 순씩
                20일간 유효 지지 갑자순 · 갑술순 20일 丑 지지, 갑신순 · 갑오순
                20일 子 지지, 갑진순 · 갑인순 20일 亥 지지) ·
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>녹덕</span> (갑(甲)일 인(寅) /
                을(乙)일 묘(卯)신 / 병(丙)일 사(巳) / 정(丁)일 오해(午亥) /
                무(戊)일 사(巳) / 기(己)일 오인(午寅) / 경(庚)일 신(申) /
                신(辛)일 유사(酉巳)/ 임(壬)일 해(亥) / 계(癸)일 자사(子巳))·
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>천지덕합</span> (당일 일간
                일자와 방문 시간 해당 유신의 천간지지가 육합하는 경우: 예
                갑인일(甲寅)의 기해시(己亥) 또는 사과삼전중의 천반간지 ){" "}
                <span style={{ fontWeight: 700 }}>
                  해당자는
                  <br /> 재해가 경감되거나 기이하게 해소 또는 성사 됨
                </span>
                <br />
                <br />
                3. <br />
                <span style={{ fontWeight: 700 }}>공망</span> (갑자순 10일 간
                술해(戌亥) 지지, 갑술순 10일간 신유(申酉) 지지, 갑신순 10일간
                오미(午未) 지지, 갑오순 10일간 사오(巳午) 지지, 갑진순 10일간
                인묘(寅卯) 지지, 갑인순 10일간 자축(子丑) 지지) 가 왕상한 경우{" "}
                <span style={{ fontWeight: 700 }}>
                  종교 · 활인업 진출하면 성공함.
                </span>
                <br />
                <br />
                단, 휴수사 자는 소함
                <br />
                <br />
                4. <br />
                왕상자 사(事) 규모 크고 많음
                <br /> 휴수사 자는 사(事) 규모 작고 적음
                <br />
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>
                  이상 방문시간 또는 차객시간을 위주로 사과삼전 해당 유신의
                  천지반 지지이나 여기서는 방문시간 또는 차객시간을 위주로
                  판단함이 효과적임
                </span>
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "사과삼전_보기요령" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">사과삼전 보기요령</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate9}>
                  <img
                    ref={imgRef9}
                    src={`/sagwa_guide0.png`}
                    style={{
                      objectFit: "contain",
                      border: `4px solid #000`,
                      overflow: "hidden",
                      maxHeight: 550,
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <br />
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "앱내용_출처" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">출처</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `12px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">학습서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 육임강론<br/></span>상하
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 육임산책<br/></span>전 6권 통권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 육임대계전서<br/></span>전 60권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 도사에게 물어봐<br/></span>전 1권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 내정비법<br/></span>통권 전 6권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사비결 종합 프로그램<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 각 분개 20종 프로그램<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 소육임자해<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {/* 지금하는것 */}
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  ">경전<br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 보화천응경 지경<br/></span><br/>
                            
                        </div>`)}

                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">학습서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사육임 정해<br/></span>전 1권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사육임 사주감정법<br/></span>전 1권
                            </div>	
                        </div>`)}
                <br />

                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 오행 제례 의식집<br/></span>전 1권
                            </div>	
                        </div>`)}
                <br />

                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">활용서<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">효사 년년세세 매년 월별 신수<br/></span>전 1권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                            <div >

                            <span style="font-weight: 700;  ">원 전<br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">황극경세<br/></span>통권  1권
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">이외 20여종 효사 각 육임서 다수<br/></span>
                            </div>	
                        </div>`)}
                <br />
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  ">이상 공히 한국 저작권 위원회 등록<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {/* {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>
                            <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 700;  "><br/><br/>서책구입 및<br/> 프로그램 교환<br/><br/>문의 : 02 - 567 - 3850 <br/></span>
                            </div>	
                        </div>`
                        )} */}
                <br />
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "앱내용_상담" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">상담</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `14px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 육임서책 (각 학습서 각 활용서) 할인 구입<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 육임 종합 프로그램 '효사비결' 완결판 할인 구입 · 교환<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 육임 모바일 앱 '장중비결' 개신판 '육임 AI' 구입 · 교환<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 귀침 · 사숭침 · 산소탈 · 병해 · 미해결사<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 여러 목적사 달성<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 육임 속성 교습<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">■ 본 앱의 효과적 활용을 위한 정기 설명회 - 매주 토요일 오전 11시<br/></span>
                            </div>	
                        </div>`)}
                <br />
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">문의 : 02 - 567 - 3850&nbsp;&nbsp;&nbsp;&nbsp;효사<br/></span>
                            </div>	
                        </div>`)}
                <br />
                {parse(`
                        
                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                            <div >
                            <span style="font-weight: 700;  "><br/></span>
                            </div><br/>

                            <div style="display : inline-flex; font-weight: 500;   width: 95%;  margin-left: auto; text-align: left;flex-direction: column;">
                            <span style="font-weight: 500;  ">서울특별시 강남구 선릉로82길 28 청록5층 503호<br/></span>
                            </div>	
                        </div>`)}
                <br />
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "승부좌법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div
                className="value0"
                style={{
                  fontSize: `18px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `10px`,
                }}
              >
                <span style={{ fontWeight: 700 }}>각순 10일간 승부좌법</span>
                <br />
                <br />
                (좌담·상담·논쟁·담판·승부 등에 사용하여 유리하게 된다.
                <br />
                특히 상담 · 친견에 활용하면 효과가 크다)
                <br />
                <br />
                <br />
                갑자순 10일간 : 서북 · 북북서쪽 등짐
                <br />
                갑술순 10일간 : 정서 · 서남쪽 등짐
                <br />
                갑신순 10일간 : 정남 · 남남서쪽 등짐
                <br />
                갑오순 10일간 : 동남 · 남남동쪽 등짐
                <br />
                갑진순 10일간 : 정동 · 동북쪽 등짐
                <br />
                갑인순 10일간 : 정북 · 북북동쪽 등짐
                <br />
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>
                  각일 2시간 동안의 승부 좌법
                </span>
                <br />
                <br />
                (게임 도박 경기 등에 활용함)
                <br />
                <br />
                실시간 해당 국수 첫 화면 우측 사과삼전 천지반 중<br /> 천반
                자하(子下) 지반 지지 방위를 등짐
                <br />
                <br />
                이때 각 지역 시간 지지 경계 시작부터 다음 시간 지지 끝까지임.
                <br />
                시간을 잇기 위해서는 자하 지반 지지 방위가 변하므로 그 변한
                방위를 등져야하며, 좌석을 옮겨야 함.
              </div>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "육임가묘좌법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">육임가묘좌법</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate}>
                  <img
                    ref={imgRef}
                    src={`/image_newcontent001.png`}
                    style={{
                      objectFit: "contain",
                      border: `4px solid #000`,
                      overflow: "hidden",
                      maxHeight: 550,
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <div
                className="value0"
                style={{
                  fontSize: `18px`,
                  textAlign: "left",
                  width: "calc(50% - 20px)",
                  marginLeft: `auto`,
                  marginRight: "auto",
                }}
              >
                건 乾 : 서북 좌 (戌)
                <br />
                곤 坤 : 남서 좌 (未)
                <br />
                간 艮 : 북동 좌 (丑)
                <br />
                손 巽 : 동남 좌 (辰)
                <br />
              </div>
              <br />
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "명좌문신불법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">명좌문신불법</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate}>
                  <img
                    ref={imgRef}
                    src={`/image_newcontent002.png`}
                    style={{
                      objectFit: "contain",
                      border: `4px solid #000`,
                      overflow: "hidden",
                      maxHeight: 550,
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>
              <div
                className="value0"
                style={{
                  fontSize: `13px`,
                  textAlign: "left",
                  width: "calc(100% - 20px)",
                  marginLeft: `auto`,
                  marginRight: "auto",
                }}
              >
                ■ 동사명
                <br />
                정동 쪽을 등진 건물과 기운이 맞는 자이고, 문은 정남, 동남이다.
                <br />
                정남 쪽을 등진 건물과 기운이 맞는 자이고, 문은 정동이다.
                <br />
                정북 쪽을 등진 건물과 기운이 맞는 자이고, 문은 동남이다.
                <br />
                동남 쪽을 등진 건물과 기운이 맞는 자이고, 문은 정북, 정동이다.
                <br />
                <br />
                ■ 서사택
                <br />
                동북 쪽을 등진 건물과 기운이 맞는 자이고, 문은 정서, 남서이다.
                <br />
                남서 쪽을 등진 건물과 기운이 맞는 자이고, 문은 서북이다.
                <br />
                서북 쪽을 등진 건물과 기운이 맞는 자이고, 문은 남서이다.
                <br />
                정서 쪽을 등진 건물과 기운이 맞는 자이고, 문은 동북이다.
                <br />
                <br />
                즉, 사람(주인)과 가택과 신상, 불상의 좌 대가 동일한 기운인 방위
                중<br /> 어느 하나로 일치시켜야만 된다는 것이다
                <br />
                <br />
                예1 : 동인이 위 4개 방위 중 정북 쪽 집을 구했다면 주인방, 신상,
                불상은 <br />
                내부 사정에 따라 정동, 정남, 정북,동남 어느 한 쪽에
                <br />
                안치해야만 하고 문은 반드시 동남쪽으로 내야만 융성하게 된다는
                것이다.
                <br />
                <br />
                예2 : 서인이 위 4개 방위 중 정서쪽 집을 구했다면 <br />
                문은 반드시 동북 쪽으로 내야하고 <br />
                주인방, 신상, 불상은 내부 사정에 따라 서북, 동북,
                <br />
                남서, 정서 어느 한 쪽에 안치해야만 융성하게 된다는 것이다.
                <br />
                <br />
                이 명, 사, 문, 불이 맞지 않으면 인력은 결국 허공이 되고,
                <br /> 그 장소를 떠나게 된다.
                <br />
                <br />
                그러므로, 사정 상 기존 건물에 입주할 경우 현관 문이라도
                <br /> 해당 문이 될 수 있도록 가설함이 <br />
                지혜일 것이다.
                <br />
              </div>
              <br />
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "내정정황" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">내정정황 사과 삼전표</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 0px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 0,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate} style={{ width: `100%` }}>
                  <img
                    ref={imgRef}
                    src={
                      selectedNaejung &&
                      selectedNaejung.default &&
                      selectedNaejung.default.SAGWA_IMAGE1[selectedGook - 1]
                    }
                    style={{
                      width: `calc(100vw - 40px)`,
                      maxHeight: `500px`,
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "일시별문제즉단" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">연월일시별 결과적 운세 즉단</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <QuickPinchZoom onUpdate={onUpdate4}>
                <img
                  ref={imgRef4}
                  src={`/statics001-1.png`}
                  style={{
                    width: "100%",
                    height: `600px`,
                    objectFit: "contain",
                  }}
                  //  usemap="#image-map"
                  zoom={1812}
                />
              </QuickPinchZoom>
            </>
          )}
          {/* 띠별운세즉단 */}
          {/* { htmlPopupContent && htmlPopupContent == "띠별운세즉단" &&
                     <>
                     {parse(`<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">생일별 운세즉단</div>`)}
                     <br/><br/><br/><br/>
                     <QuickPinchZoom  onUpdate={onUpdate4}>
                             <img ref={imgRef4} 
                                 src={`/statics003-1.png`} style={{ width: '100%', height: `600px`, objectFit : 'contain'}}
                                 //  usemap="#image-map"
                                 zoom={1812}
                             />
                             </QuickPinchZoom>
                     </>
                    } */}
          {htmlPopupContent && htmlPopupContent == "형이상_귀신부정" && (
            <>
              {parse(`<br/><br/><div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 12px;">
                     <span  style="font-family: 'NanumBarunGothic';font-size: 18px;font-weight: 700">귀신 부정 구분</span><br/><br/>
                     <span style="font-weight: 900">⊕</span>표시 : 낮 시간 정단 해당<br/>
                     <span style="font-weight: 900">⊖</span> 표시 : 밤 시간 정단 해당<br/>
                     <span style="font-weight: 900">⊕⊖</span>표시 : 낮밤 정단 모두 해당<br/>
                     </div>`)}
              <br />
              <QuickPinchZoom onUpdate={onUpdate4}>
                <img
                  ref={imgRef4}
                  src={`/statics002-1.png`}
                  style={{
                    width: "100%",
                    height: `600px`,
                    objectFit: "contain",
                  }}
                  //  usemap="#image-map"
                  zoom={1812}
                />
              </QuickPinchZoom>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "이해보기" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `15px` }}>
                {parse(`
                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                    <div >

                                    <span style="font-weight: 700;  ">대안<br/></span>
                                    모사 <br/>
                                    자진오<br/>
                                    응일월 <br/>
                                    </div><br/>
                                    

                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left; flex-direction: column;">
                                    <span style="font-weight: 700;  ">목 (청룡) 몸체 부동 범위<br/></span>
                                    매사 잘됨<br/>
                                    구재</span> 남서쪽<br/>
                                    병질 무방함<br/>
                                    실물 멀리못감<br/>
                                    가택 안강함<br/>
                                    장군 전야로 돌아옴
                                    </div>	
                                </div>
                                <br/>

                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                
                                <div >

                                <span style="font-weight: 700;  ">유연<br/></span>
                                모사 <br/>
                                축미유<br/>
                                응일월 <br/>
                                </div><br/>
                                
                                
                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                                    <span style="font-weight: 700;  ">수 (현무) 졸 미귀 범위<br/></span>
                                    계류 미정<br/>
                                    
                                    일이 어려움 <br/>
                                    성사일 미정<br/>
                                    실물 남쪽 발견<br/>
                                    관사 지지부진<br/>
                                    구설 방지 <br/>
                                    </div>	
                                </div>
                                <br/>

                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                                <div >

                                <span style="font-weight: 700;  ">속희<br/></span>
                                모사 <br/>
                                인사신<br/>
                                응일월 <br/>
                                </div><br/>

                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                                    <span style="font-weight: 700;  ">화 (주작) 인편 희경 소식 도착 범위<br/></span>
                                    희경사 속히 당도<br/>
                                    관사 복덕<br/>
                                    구재 남쪽행<br/>
                                    병자 걱정없음<br/>
                                    전택가축 길함<br/>
                                    나간 자 소식 옴
                                    </div>	
                                </div>
                                <br/>

                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                    
                                <div>


                                <span style="font-weight: 700;  ">적구<br/></span>
                                모사 <br/>
                                묘오유<br/>
                                응일월 <br/>

                                </div><br/>

                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                                    <span style="font-weight: 700;  ">금 (백호) 관사 흉 범위<br/></span>
                                    구설 방지 필요<br/>
                                    관사 방해<br/>
                                    병자 서쪽을 찾으라<br/>
                                    예방 전염병<br/>
                                    나간자 경황없음<br/>
                                    육축 괴이사 많음
                                    </div>	
                                </div>
                                <br/>


                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">


                                <div>


                                <span style="font-weight: 700;  ">소길<br/></span>
                                모사 <br/>
                                자진오<br/>
                                응일월 <br/>

                                </div><br/>

                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                                    <span style="font-weight: 700;  ">목 (육합) 인래 희 범위<br/></span>
                                    최길창 함<br/>
                                    관계성 최강<br/>
                                    범사 화합됨<br/>
                                    음인 희보 전해옴<br/>
                                    실물 남서쪽
                                    </div>	
                                </div>
                                <br/>
                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                    
                                
                                <div>


                                <span style="font-weight: 700;  ">공망<br/></span>
                                모사 <br/>
                                인사신<br/>
                                응일월 <br/>
                                </div><br/>
                                    <div style="display : inline-flex; font-weight: 500;   width: 70%;  margin-left: auto; text-align: left;flex-direction: column;">
                                    <span style="font-weight: 700;  ">토 (구진) 소식 칭 범위<br/></span>
                                    매사 불상<br/>
                                    관사 형상<br/>
                                    구재 무익<br/>
                                    병자 암귀 침해(해병됨)<br/>
                                    나간자 재앙<br/>
                                    음인 남녀 다승함<br/>
                                    
                                    </div>	
                                </div>
                                <br/>


                                `)}
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "귀신치병" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `10px` }}>
                {parse(
                  `<span className="label0" style="font-family: 'NanumBarunGothic'"></span>  `
                )}
                :
                {objectGui &&
                  objectGui.default &&
                  parse(`${objectGui.default.GUISIN001[0].content}`)}
                <br />
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "성취기도일시" && (
            <>
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `14px` }}>
                {parse(`

                                <div style="font-weight: 700; font-size: 24px;">
                                    성취기도일시
                                </div>
                                <br/>
                                <div style="width: calc(100% - 80px); text-align: left; margin-left: auto; margin-right: auto;">
                                월장 亥 (우수 - 춘분) 갑일 卯時 酉時이다<br/>
                                월장 戌 (춘분 - 곡우) 무일 경일 申時 寅時이다<br/>
                                월장 酉 (곡우 - 소만) 무일 경일 未時 丑時이다<br/>
                                월장 申 (소만 - 하지) 무일 경일 午時 子時이다<br/>
                                월장 未 (하지 - 대서) 무일 경일 巳時 亥時이다<br/>
                                월장 午 (대서 - 처서) 무일 경일 辰時 戌時이다<br/>
                                월장 巳 (처서 - 추분) 무일 경일 卯時 酉時이다<br/>
                                월장 辰 (추분 - 상강) 갑일 申時 寅時이다<br/>
                                월장 卯 (상강 - 소설) 갑일 未時 丑時이다<br/>
                                월장 寅 (소설 - 동지) 갑일 午時 子時이다<br/>
                                월장 丑 (동지 - 대한) 갑일 巳時 亥時이다<br/>
                                월장 子 (대한 - 우수) 갑일 辰時 戌時이다<br/>
                                </div>
                            `)}
                <br />
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "가택부정 진압법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `14px` }}>
                {parse(
                  `<span className="label0" style="font-family: 'NanumBarunGothic'"></span>  `
                )}
                {objectGui &&
                  objectGui.default &&
                  parse(`${objectGui.default.GUISIN002[0].content}`)}
                <br />
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "옥추부" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `14px` }}>
                {objectGui &&
                  objectGui.default &&
                  objectGui.default.GUISIN003.map((mapItem, mapIndex) => {
                    return (
                      <div className="">
                        <br />
                        <div style={{ fontWeight: 900 }}>{mapItem.content}</div>
                        <br />
                        <img src={mapItem.src} style={{ maxWidth: `100%` }} />
                        <div
                          className=""
                          style={{
                            maxWidth: `calc(100% - 10px)`,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {parse(mapItem.desc)}
                        </div>
                        <br />
                      </div>
                    );
                  })}
                <br />
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "귀신침법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `14px` }}>
                {parse(
                  `<span className="label0" style="font-family: 'NanumBarunGothic'"></span>  `
                )}
                {objectGui &&
                  objectGui.default &&
                  objectGui.default.GUISIN004.map((mapItem, mapIndex) => {
                    return (
                      <div className="">
                        <br />
                        <div>{parse(`${mapItem.content}`)}</div>
                        <br />
                        <img src={mapItem.src} style={{ maxWidth: `100%` }} />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          {((htmlPopupContent && htmlPopupContent == "천기법") ||
            (htmlPopupContent && htmlPopupContent == "사과삼전")) && <></>}
          {htmlPopupContent && htmlPopupContent == "신명법" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px; font-weight: 700;">${htmlPopupContent}</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <div className="value0" style={{ fontSize: `14px` }}>
                {parse(
                  `<span className="label0" style="font-family: 'NanumBarunGothic'"></span>  `
                )}
                {objectGui &&
                  objectGui.default &&
                  parse(`${objectGui.default.GUISIN006[0].content}`)}
                <br />
              </div>
            </>
          )}
          {htmlPopupContent &&
            (htmlPopupContent == "국수해설_당면사" ||
              htmlPopupContent == "전체성" ||
              htmlPopupContent == "구체성") && (
              <>
                {parse(
                  `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">해당국수 보기</div>`
                )}
                <br />
                <br />
                <br />
                <br />
                <QuickPinchZoom onUpdate={onUpdate4}>
                  <img
                    ref={imgRef4}
                    src={
                      "/static/pdf_rae/" +
                      (objectGui &&
                        objectGui.default &&
                        objectGui.default.PDF_RAEJUNG_NAMEONLY1[
                          12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                        ]) +
                      ".png"
                    }
                    style={{
                      width: "100%",
                      height: `100%`,
                      objectFit: "contain",
                    }}
                    zoom={1812}
                  />
                </QuickPinchZoom>
              </>
            )}
          {/* {htmlPopupContent &&
            htmlPopupContent == "국수해설_일생사" &&
            selectedGapjaIndex_ILSAENG > 0 && (
              <>
                {parse(
                  `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">해당국수 보기</div>`
                )}
                <br />
                <br />
                <br />
                <br />
                <QuickPinchZoom onUpdate={onUpdate4}>
                  <img
                    ref={imgRef4}
                    src={`/static/pdf_my0/${
                      objectGui &&
                      objectGui.default &&
                      objectGui.default.PDFMY_HAESEOL_FILENAMES[
                        selectedGapjaIndex_ILSAENG - 1
                      ]
                    }.png`}
                    style={{
                      width: "100%",
                      height: `600px`,
                      objectFit: "contain",
                    }}
                    //  usemap="#image-map"
                    zoom={1812}
                  />
                </QuickPinchZoom>
              </>
            )} */}
          {htmlPopupContent && htmlPopupContent == "육효64" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">육효</div>`
              )}
              <br />
              <br />
              <br />
              <br />

              <div
                className="gueRow"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: `4px`,
                  marginBottom: `4px`,
                }}
              >
                <div className="gueLabel" style={{ fontSize: 10 }}>
                  괘\색
                </div>
                {Array.from([
                  "미백<br/>회색<br/>계통",
                  "순백색",
                  "분홍<br/>적색<br/>계통",
                  "녹색<br/>계통",
                  "연두색<br/>계통",
                  "곤색<br/>검정색<br/>계통",
                  "갈색<br/>계통",
                  "황색<br/>노란색<br/>계통",
                ]).map((gueItem, gueIndex) => {
                  return (
                    <div
                      className="gueItem"
                      style={{
                        fontSize: 10,
                        alignItem: "flex-end",
                        display: "flex",
                      }}
                    >
                      {parse(gueItem)}
                    </div>
                  );
                })}
              </div>

              <div
                className="gueRow"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="gueLabel">상괘</div>
                {Array.from([1, 2, 3, 4, 5, 6, 7, 8]).map(
                  (gueItem, gueIndex) => {
                    return (
                      <div
                        className="gueItem gueItem1"
                        style={{
                          fontWeight: selectedGue1 == gueItem ? "700" : "500",
                          backgroundColor:
                            selectedGue1 == gueItem ? "#ffff00" : "#ffffff",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedGue1(gueItem);
                        }}
                      >
                        {gueItem}
                      </div>
                    );
                  }
                )}
              </div>
              <div
                className="gueRow"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="gueLabel">하괘</div>
                {Array.from([1, 2, 3, 4, 5, 6, 7, 8]).map(
                  (gueItem, gueIndex) => {
                    return (
                      <div
                        className="gueItem gueItem1"
                        style={{
                          fontWeight: selectedGue2 == gueItem ? "700" : "500",
                          backgroundColor:
                            selectedGue2 == gueItem ? "#ffff00" : "#ffffff",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedGue2(gueItem);
                        }}
                      >
                        {gueItem}
                      </div>
                    );
                  }
                )}
              </div>

              <div
                className=""
                style={{
                  width: `calc(100% - 40px)`,
                  borderBottom: "1px solid #000",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: `20px`,
                  marginBottom: `12px`,
                  textAlign: "center",
                  paddingBottom: `8px`,
                }}
              >
                모자 + 구두 / 넥타이 + 양말 /<br />
                와이셔츠 · 브라우스 + 혁대 / <br />
                상의 + 하의 색을 상하괘로 하되 <br />
                이외 상하가 두드러진 색에 착목하여도 무방함.
                {/* 2. 아래 괘 상의는 시간법이므로 자동으로 나타납니다.<br/>
                            3. 아래 시간법은 5분 또는 10분 간격으로 변화하므로 <br/>
                            첫 정단이후 5분내지 10분을 경과하여 판단합니다.<br/>
                            그러나, 판단 시간이 5분내지 10분을 경과할 것이므로<br/>
                            다음사람의 정단을 자연스럽게 잇게 됩니다.<br/> */}
              </div>
              <br />
              {selectedGue1 > -1 && selectedGue2 > -1 && (
                <QuickPinchZoom onUpdate={onUpdate12}>
                  <img
                    ref={imgRef12}
                    src={`/static/images/yookhyo/${selectedGue1}_${selectedGue2}.png`}
                    style={{
                      width: "100%",
                      height: `400px`,
                      objectFit: "contain",
                    }}
                    //  usemap="#image-map"
                    zoom={900}
                  />
                </QuickPinchZoom>
              )}
              {/* {
                            selectedGue1 > -1  &&
                            selectedGue2 > -1 &&
                        <QuickPinchZoom  onUpdate={onUpdate11}>
                        <img ref={imgRef11} 
                            src={`/static/images/yookhyo/${selectedGue1}_${selectedGue2}_0.png`} style={{ width: '100%', height: `400px`, objectFit : 'contain'}}
                            //  usemap="#image-map"
                            zoom={900}
                        />
                        </QuickPinchZoom>
                        } */}
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "명운표" && (
            <>
              {parse(`
                                <div className="label0" style="font-size: 18px; width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex; font-weight: 700;">
                                2. 전체,구체,적합,행년,특이성 종합<br/><br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;해당 국수
                                </div>`)}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <QuickPinchZoom onUpdate={onUpdate2}>
                <img
                  ref={imgRef2}
                  src={`/static/pdf_my/${
                    objectGui &&
                    objectGui.default &&
                    objectGui.default.PDFMY_FILENAMES[
                      12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                    ]
                  }.png`}
                  style={{
                    width: "100%",
                    height: `600px`,
                    objectFit: "contain",
                  }}
                  //  usemap="#image-map"
                  zoom={1812}
                />
              </QuickPinchZoom>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "국수해설_일생사" && (
            <>
              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 24px;font-weight: 700;">해당국수 보기</div>`
              )}
              <br />
              <br />
              <br />
              <br />
              <QuickPinchZoom onUpdate={onUpdate4}>
                <img
                  ref={imgRef4}
                  src={
                    "/static/pdf_rae/" +
                    (objectGui &&
                      objectGui.default &&
                      objectGui.default.PDF_RAEJUNG_NAMEONLY1[
                        12 * (selectedGapjaIndex_ILSAENG - 1) +
                          (selectedGook_ILSAENG - 1)
                      ]) +
                    ".png"
                  }
                  style={{
                    width: "100%",
                    height: `100%`,
                    objectFit: "contain",
                  }}
                  zoom={1812}
                />
              </QuickPinchZoom>
            </>
          )}

          {htmlPopupContent && htmlPopupContent == "색인_일생사" && (
            <>
              <div
                className="maintable_row0 maintable_row1"
                style={{
                  minHeight: `40px`,
                  alignItems: "center",
                  justifyContent: "center",

                  borderTop: !(
                    selectedSiIndex1_ILSAENG == 1 ||
                    selectedSiIndex1_ILSAENG == 2 ||
                    selectedSiIndex1_ILSAENG == 3 ||
                    selectedSiIndex1_ILSAENG == 10 ||
                    selectedSiIndex1_ILSAENG == 11 ||
                    selectedSiIndex1_ILSAENG == 12
                  )
                    ? `8px solid #B93160`
                    : `8px solid #1F4690`,

                  color: !(
                    selectedSiIndex1_ILSAENG == 1 ||
                    selectedSiIndex1_ILSAENG == 2 ||
                    selectedSiIndex1_ILSAENG == 3 ||
                    selectedSiIndex1_ILSAENG == 10 ||
                    selectedSiIndex1_ILSAENG == 11 ||
                    selectedSiIndex1_ILSAENG == 12
                  )
                    ? `#B93160`
                    : `#1F4690`,
                }}
              >
                {parse(`<div className="" style="width: 100%; font-family: 'NanumBarunGothic';font-size: 16px;">
                                 ${
                                   currentDisplayDate_ILSAENG &&
                                   currentDisplayDate_ILSAENG.format(
                                     "양력 YYYY년 MM월 DD일"
                                   )
                                 }&nbsp;
                                 ${
                                   currentDisplayDate_ILSAENG &&
                                   solarToLunar(
                                     currentDisplayDate_ILSAENG.format("YYYY"),
                                     currentDisplayDate_ILSAENG.format("MM"),
                                     currentDisplayDate_ILSAENG.format("DD")
                                   )
                                 }
                                 <br/>
                                 ${selectedGapja_ILSAENG}일
                                 ${selectedSi_ILSAENG} 시 
                                 ${selectedGook_ILSAENG}국
                                 </div>`)}
              </div>

              <div
                className="maintable_column0 "
                style={{
                  height: `auto !important`,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexWrap: "nowrap",
                  overflow: "hidden",
                  minHeight: `200vh`,
                }}
              >
                <div
                  className="value0"
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 24,
                  }}
                >
                  {Array.from([
                    ...newmenu2,
                    //    .filter((filterItem, filterIndex)=> {if (filterIndex <= 6) { return filterItem }}),
                    "10. 국수해설",
                  ]).map((arrayItem, arrayIndex) => {
                    console.log("");

                    if (arrayItem == "" || arrayItem == "-") {
                      return <></>;
                    }
                    // if (arrayItem == "" || arrayItem == "8. 생일별 운세즉단") {
                    //     return <></>
                    // }
                    if (arrayItem == "10. 국수해설") {
                      return (
                        <div
                          style={{
                            alignItems: "flex-start",
                            width: "100%",
                            textAlign: "left",
                            overflow: "hidden",
                            height: "auto",
                            marginBottom: 20,
                            fontWeight: 700,
                            minHeight: 800,
                          }}
                        >
                          <hr
                            style={{
                              width: `calc(100vw - 80px)`,
                              height: 1,
                              background: `#fff`,
                              marginTop: 20,
                              marginBottom: 4,
                              fontWeight: 700,
                            }}
                          />
                          <div
                            style={{
                              width: `calc(100vw - 80px)`,
                              position: `relative`,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setShowIlsaengImage2(!showIlsaengImage2);
                            }}
                          >
                            {arrayItem}
                            <br />
                            <hr
                              style={{
                                width: `calc(100vw - 80px)`,
                                height: 1,
                                background: `#000`,
                                marginTop: 4,
                                marginBottom: 4,
                              }}
                            />
                            {
                              <div
                                className=""
                                style={{
                                  position: "absolute",
                                  minWidth: 40,
                                  color: "#000",
                                  height: 24,
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                {showIlsaengImage2 == true && "줄임"}
                                {showIlsaengImage2 == false && "키움"}
                              </div>
                            }
                          </div>
                          <br />
                          {showIlsaengImage2 && (
                            <>
                              <QuickPinchZoom onUpdate={onUpdate4}>
                                <img
                                  ref={imgRef4}
                                  src={`/static/pdf_my0/${
                                    objectGui &&
                                    objectGui.default &&
                                    objectGui.default.PDFMY_HAESEOL_FILENAMES[
                                      selectedGapjaIndex_ILSAENG - 1
                                    ]
                                  }.png`}
                                  style={{
                                    width: "100%",
                                    height: `600px`,
                                    objectFit: "contain",
                                  }}
                                  //  usemap="#image-map"
                                  zoom={1812}
                                />
                              </QuickPinchZoom>
                              <div
                                style={{
                                  width: `48px`,
                                  height: `24px`,
                                  background: `#8d8daa`,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#fff",
                                  border: `1px solid #000`,
                                  marginTop: "40px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  fontWeight: 700,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setShowIlsaengImage2(!showIlsaengImage2);
                                  setSelectedBirthCalendarGapjaIndex1(-1);
                                }}
                              >
                                닫기
                              </div>
                            </>
                          )}
                        </div>
                      );
                    }
                    return (
                      <div
                        style={{
                          alignItems: "flex-start",
                          width: "100%",
                          textAlign: "left",
                          overflow: "hidden",
                          height: "auto",
                          marginBottom: 20,
                          fontWeight: 700,
                          minHeight:
                            arrayIndex == 0
                              ? 500
                              : arrayIndex == 1 && showIlsaengImage1 == true
                              ? 704
                              : arrayIndex == 1 && showIlsaengImage1 == false
                              ? 56
                              : arrayIndex == 2
                              ? 300
                              : arrayIndex == 3
                              ? 300
                              : arrayIndex == 4
                              ? 1400
                              : arrayIndex == 5
                              ? 300
                              : arrayIndex == 6
                              ? 300
                              : arrayIndex == 7 && showIlsaengImage3 == true
                              ? 500
                              : arrayIndex == 7 && showIlsaengImage3 == false
                              ? 56
                              : arrayIndex == 8 && showIlsaengImage4 == true
                              ? 500
                              : arrayIndex == 8 && showIlsaengImage4 == false
                              ? 56
                              : 300,
                        }}
                      >
                        {
                          <>
                            <hr
                              style={{
                                width: `calc(100vw - 80px)`,
                                height: 1,
                                background: `#fff`,
                                marginTop: 20,
                                marginBottom: 4,
                              }}
                            />
                            <div
                              style={{
                                width: `calc(100vw - 80px)`,
                                position: `relative`,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (arrayIndex == 1) {
                                  setShowIlsaengImage1(!showIlsaengImage1);
                                }
                                if (arrayIndex == 7) {
                                  setShowIlsaengImage3(!showIlsaengImage3);
                                }
                                if (arrayIndex == 8) {
                                  setShowIlsaengImage4(!showIlsaengImage4);
                                }
                              }}
                            >
                              {arrayItem}
                              <br />
                              <hr
                                style={{
                                  width: `calc(100vw - 80px)`,
                                  height: 1,
                                  background: `#000`,
                                  marginTop: 4,
                                  marginBottom: 4,
                                }}
                              />
                              {arrayIndex == 1 && (
                                <div
                                  className=""
                                  style={{
                                    position: "absolute",
                                    minWidth: 40,
                                    color: "#000",
                                    height: 24,
                                    top: 0,
                                    right: 0,
                                  }}
                                >
                                  {showIlsaengImage1 == true && "줄임"}
                                  {showIlsaengImage1 == false && "키움"}
                                </div>
                              )}
                              {arrayIndex == 7 && (
                                <div
                                  className=""
                                  style={{
                                    position: "absolute",
                                    minWidth: 40,
                                    color: "#000",
                                    height: 24,
                                    top: 0,
                                    right: 0,
                                  }}
                                >
                                  {showIlsaengImage3 == true && "줄임"}
                                  {showIlsaengImage3 == false && "키움"}
                                </div>
                              )}
                              {arrayIndex == 8 && (
                                <div
                                  className=""
                                  style={{
                                    position: "absolute",
                                    minWidth: 40,
                                    color: "#000",
                                    height: 24,
                                    top: 0,
                                    right: 0,
                                  }}
                                >
                                  {showIlsaengImage4 == true && "줄임"}
                                  {showIlsaengImage4 == false && "키움"}
                                </div>
                              )}
                            </div>
                            <br />
                          </>
                        }
                        {arrayIndex == 0 &&
                          parse(`
                                                                
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                남
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      selectedGapjaIndex_ILSAENG &&
                                                                      selectedSiIndex1_ILSAENG &&
                                                                      selectedGapjaIndex_ILSAENG >
                                                                        -1 &&
                                                                      selectedSiIndex1_ILSAENG >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED[
                                                                        12 *
                                                                          (selectedGapjaIndex_ILSAENG -
                                                                            1) +
                                                                          (selectedGook_ILSAENG -
                                                                            1) -
                                                                          1
                                                                      ][3]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                여
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      selectedGapjaIndex_ILSAENG &&
                                                                      selectedSiIndex1_ILSAENG &&
                                                                      selectedGapjaIndex_ILSAENG >
                                                                        -1 &&
                                                                      selectedSiIndex1_ILSAENG >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED[
                                                                        12 *
                                                                          (selectedGapjaIndex_ILSAENG -
                                                                            1) +
                                                                          (selectedGook_ILSAENG -
                                                                            1) -
                                                                          1
                                                                      ][4]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                `)}

                        {showIlsaengImage1 && arrayIndex == 1 && (
                          <>
                            <QuickPinchZoom onUpdate={onUpdate5}>
                              <img
                                ref={imgRef5}
                                src={`/static/pdf_my/${
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default.PDFMY_FILENAMES[
                                    12 * (selectedGapjaIndex_ILSAENG - 1) +
                                      (selectedGook_ILSAENG - 1)
                                  ]
                                }.png`}
                                style={{
                                  width: "100%",
                                  height: `600px`,
                                  objectFit: "contain",
                                }}
                                //  usemap="#image-map"
                                zoom={1812}
                              />
                            </QuickPinchZoom>

                            <div
                              style={{
                                width: `48px`,
                                height: `24px`,
                                background: `#8d8daa`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                                border: `1px solid #000`,
                                marginTop: "10px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontWeight: 700,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowIlsaengImage1(!showIlsaengImage1);
                                setSelectedBirthCalendarGapjaIndex1(-1);
                              }}
                            >
                              닫기
                            </div>
                          </>
                        )}
                        {arrayIndex == 2 &&
                          parse(`
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                수명관계
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][20].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/><br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                질병관계
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][21].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/><br/>
                                                                `)}

                        {arrayIndex == 3 &&
                          parse(`
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                자녀원수 복덕 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][25]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "출산",
                                                                    "\n출산"
                                                                  )
                                                                  .replaceAll(
                                                                    "출산",
                                                                    "<br/>출산"
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/>`)}
                        {arrayIndex == 4 &&
                          parse(
                            `
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    자 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][8].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    축 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][9].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    인 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][10]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    묘 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][11].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    진 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex_ILSAENG -
                                                                      1) +
                                                                    (selectedGook_ILSAENG -
                                                                      1) -
                                                                    1
                                                                ][12].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    사 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][13]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    오 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][14]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    미 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][15]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    신 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][16]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    유 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][17]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    술 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][18]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    해 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex_ILSAENG -
                                                                        1) +
                                                                      (selectedGook_ILSAENG -
                                                                        1) -
                                                                      1
                                                                  ][19]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                `.replaceAll(
                              "&&",
                              "+"
                            )
                          )}
                        {arrayIndex == 5 &&
                          parse(
                            `
                                                                
                                                                
                                                                 <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                 일생침범귀
                                                                     <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                     
                                                                 ${objectGui.default.ILSAENGSA_COMBINED[
                                                                   12 *
                                                                     (selectedGapjaIndex_ILSAENG -
                                                                       1) +
                                                                     selectedSiIndex_ILSAENG -
                                                                     1
                                                                 ][22]
                                                                   .replaceAll(
                                                                     "&&",
                                                                     "+"
                                                                   )
                                                                   .replaceAll(
                                                                     "귀",
                                                                     "귀 "
                                                                   )}
                                                                     </div>	
                                                                 </div>
                                                                 <br/>
                                                                 
                                                                 
                                                                 <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                 일생침범산소탈
                                                                     <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                     
                                                                 ${objectGui.default.ILSAENGSA_COMBINED[
                                                                   12 *
                                                                     (selectedGapjaIndex_ILSAENG -
                                                                       1) +
                                                                     selectedSiIndex_ILSAENG -
                                                                     1
                                                                 ][23]
                                                                   .replaceAll(
                                                                     "&&",
                                                                     "+"
                                                                   )
                                                                   .replaceAll(
                                                                     "귀",
                                                                     "귀 "
                                                                   )}
                                                                     </div>	
                                                                 </div>
                                                                 <br/>
                                                                 `
                          )}
                        {arrayIndex == 6 &&
                          parse(`
                                                                
                                                                
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    남성력
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          // selectedGapjaIndex &&
                                                                          // selectedSiIndex &&
                                                                          selectedGapjaIndex >
                                                                            -1 &&
                                                                          selectedSiIndex >
                                                                            -1 &&
                                                                          objectGui &&
                                                                          objectGui.default &&
                                                                          objectGui
                                                                            .default
                                                                            .NEW_TAEWE1 &&
                                                                          objectGui
                                                                            .default
                                                                            .NEW_TAEWE1[
                                                                            selectedSiIndex %
                                                                              11
                                                                          ]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    여성력
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          // selectedGapjaIndex &&
                                                                          // selectedSiIndex &&
                                                                          selectedGapjaIndex >
                                                                            -1 &&
                                                                          selectedSiIndex >
                                                                            -1 &&
                                                                          objectGui &&
                                                                          objectGui.default &&
                                                                          objectGui
                                                                            .default
                                                                            .NEW_TAEWE2 &&
                                                                          objectGui
                                                                            .default
                                                                            .NEW_TAEWE2[
                                                                            selectedSiIndex %
                                                                              11
                                                                          ]
                                                                        }
                                                                        </div>	
                                                                    <br/>
                                                                    `)}
                        {arrayIndex == 7 && (
                          <>
                            <QuickPinchZoom onUpdate={onUpdate7}>
                              <img
                                ref={imgRef7}
                                src={`/image_newcontent002.png`}
                                style={{
                                  objectFit: "contain",
                                  border: `4px solid #000`,
                                  overflow: "hidden",
                                  maxHeight: 550,
                                  maxWidth: `100%`,
                                }}
                                zoom={108}
                              />
                            </QuickPinchZoom>
                          </>
                        )}
                        {arrayIndex == 8 && (
                          <>
                            <QuickPinchZoom onUpdate={onUpdate8}>
                              <img
                                ref={imgRef8}
                                src={`/image_newcontent001.png`}
                                style={{
                                  objectFit: "contain",
                                  border: `4px solid #000`,
                                  overflow: "hidden",
                                  maxHeight: 550,
                                  maxWidth: `100%`,
                                }}
                                zoom={192}
                              />
                            </QuickPinchZoom>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {htmlPopupContent && htmlPopupContent == "고귀명수" && (
            <>
              <br />
              {htmlPopupContentSub == "danjeongMenu/25" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`사복사 `}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>무 유물 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>생 사물 여부</td>
                      <td colSpan={2}>
                        <br />
                        {` 春 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}

                        <br />
                        {` 夏 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        <br />
                        {` 秋 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                        {` 冬 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>어떤 형상인가</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>형태 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>무엇인가?</td>
                      <td colSpan={2}>
                        <br />
                        {` 春 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}

                        <br />
                        {` 夏 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        <br />
                        {` 秋 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        <br />
                        {` 冬 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>신 구 장 노 여부</td>
                      <td colSpan={2}>
                        <br />
                        {` 春 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}

                        <br />
                        {` 夏 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                        <br />
                        {` 秋 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                        <br />
                        {` 冬 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>지난철, 제철 것 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>먹을 수 있을까 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>무슨 맛의 것인가</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>무슨 색인가 ?</td>
                      <td colSpan={2}>
                        <br />
                        {` 春 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}

                        <br />
                        {` 夏 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][19]}
                        <br />
                        {` 秋 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][20]}
                        <br />
                        {` 冬 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][21]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>몇 수인가 ?</td>
                      <td colSpan={2}>
                        <br />
                        {` 春 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][22]}

                        <br />
                        {` 夏 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][23]}
                        <br />
                        {` 秋 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][24]}
                        <br />
                        {` 冬 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][25]}
                      </td>
                    </tr>

                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>좌우 어느 쪽에 들었는가?</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION028[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][26]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}

              {htmlPopupContentSub == "danjeongMenu/24" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`도박사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>유리한 좌석은?</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>자 하 지지 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>공망 방위 여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>따는가? 잃는가?</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>승패여부</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>대응성</td>
                      <td colSpan={2}>
                        <br />
                        {`春 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        <br />
                        {`夏 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        <br />
                        {`秋 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        <br />
                        {`冬 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>전웅성</td>
                      <td colSpan={2}>
                        <br />
                        {`春 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        <br />
                        {`夏 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        <br />
                        {`秋 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                        <br />
                        {`冬 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>잃는 띠</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>띠</td>
                      <td colSpan={2}>결과</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[0]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[1]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[2]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[3]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[4]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[5]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[6]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][19]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[7]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][20]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[8]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][21]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[9]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][22]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[10]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][23]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[11]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION027[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][24]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}

              {htmlPopupContentSub == "danjeongMenu/23" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`수렵사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={2}>
                        고기 생선을 잡는가
                        <br />
                        못잡는가 여부
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>사고 유무 여부</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>년도</td>
                      <td colSpan={2}>사고</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[0]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[1]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[2]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[3]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[4]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[5]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[6]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[7]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[8]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[9]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[10]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{ganji12_0[11]}</td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION026[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/22" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`수렵사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={1}> </td>
                      <td colSpan={1}>{`春`}</td>
                      <td colSpan={1}>{`夏`}</td>
                      <td colSpan={1}>{`秋`}</td>
                      <td colSpan={1}>{`冬`}</td>
                    </tr>
                    <tr>
                      {/* 춘하추동 : 春 夏 秋 冬 */}
                      <td colSpan={1}>짐승을 잡을 수 있는가, 없는가?</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>무엇을 잡는가?</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>짐승의 피해 유무 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>
                        잠복이 유리한가?
                        <br />
                        나서는게 유리한가?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION025[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}

              {htmlPopupContentSub == "danjeongMenu/21" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`축산사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1}>이익이 될 것인가, 손해인가 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>이익이 큰가, 작은가 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION024[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`夏 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`秋 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        <br />
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`冬 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>어떤 육축이 좋은가 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>피해를 줄 가축은?</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>양축이 쉬운가 힘이드는가 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}

              {htmlPopupContentSub == "danjeongMenu/20" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`농경사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1}>년도</td>
                      <td colSpan={1}>기후 예시 여부</td>
                      <td colSpan={1}>피해 작물 여부</td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[0]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[1]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1}>{ganji12_0[2]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[3]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[4]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[5]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[6]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[7]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[8]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[9]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][19]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[10]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][20]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][21]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[11]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][22]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION023[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][23]}
                      </td>
                    </tr>
                    {/* <tr>
                                <td colSpan={1} >
                                        {ganji12_0[12 - 2]}
                                    </td>
                                    <td colSpan={1} >
                                    { selectedGapjaIndex > -1 && 
                                            selectedSiIndex > -1 
                                            && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION023
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][24] 
                                        }
                                    </td>
                                    <td colSpan={2} >
                                    { selectedGapjaIndex > -1 && 
                                            selectedSiIndex > -1 
                                            && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION023
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][25] 
                                        }
                                    </td>
                                </tr>
                                    <tr>
                                <td colSpan={1} >
                                        {ganji12_0[13 - 2]}
                                    </td>
                                    <td colSpan={1} >
                                    { selectedGapjaIndex > -1 && 
                                            selectedSiIndex > -1 
                                            && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION023
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][26] 
                                        }
                                    </td>
                                    <td colSpan={2} >
                                    { selectedGapjaIndex > -1 && 
                                            selectedSiIndex > -1 
                                            && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION023
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                            && objectDanJ.default.VERSION001_DAN_FUNCTION023[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][27] 
                                        }
                                    </td>
                                </tr> */}
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/19" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`몽중사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    {/* <tr>
                                        <td colSpan={1} >
                                            꿈 (길 / 흉)
                                        </td>
                                        <td colSpan={1} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][0] 
                                            }
                                        </td>
                                        <td colSpan={2} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][1] 
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={1} >
                                            꿈 (개꿈 / 여부)
                                        </td>
                                        <td colSpan={1} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][2] 
                                            }
                                        </td>
                                        <td colSpan={2} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][3] 
                                            }
                                        </td>
                                    </tr> */}

                    <tr>
                      <td colSpan={1}>띠</td>
                      <td colSpan={1}>낮정단</td>
                      <td colSpan={1}>밤정단</td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[0]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[1]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1}>{ganji12_0[2]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[3]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[4]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[5]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[6]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[7]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[8]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[9]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][19]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[10]}</td>

                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][20]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][21]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>{ganji12_0[11]}</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][22]}
                      </td>
                      <td colSpan={2}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION022[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][23]}
                      </td>
                    </tr>
                    {/* <tr>
                                    <td colSpan={1} >
                                            {ganji12_0[12 - 2]}
                                        </td>
                                        <td colSpan={1} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][24] 
                                            }
                                        </td>
                                        <td colSpan={2} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][25] 
                                            }
                                        </td>
                                    </tr>
                                        <tr>
                                    <td colSpan={1} >
                                            {ganji12_0[13 - 2]}
                                        </td>
                                        <td colSpan={1} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][26] 
                                            }
                                        </td>
                                        <td colSpan={2} >
                                        { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION022
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION022[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][27] 
                                            }
                                        </td>
                                    </tr> */}
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/18" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`이혼사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        이혼 성부 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        이혼 길흉 여부
                      </td>
                      <td colSpan={1}>
                        {`낮정단 : `}
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                        {`밤정단 : `}
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        손익 남녀 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION021[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/17" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`도난사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        도난사실 허위 유무 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도둑 체포 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도둑 상모 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도둑 남녀 장로 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        여러인 중 범인 단정 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도난품 회수 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도망 안전 방위 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도둑 잠복 방위 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION017[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        <br />
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/16" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`형이상사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        귀신 침법 여부
                      </td>
                      <td colSpan={1}>
                        {`낮점 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                        <br />
                        {`밤점 : `}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        비방법
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        ({"방위 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        &nbsp;
                        {"색상 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        &nbsp;
                        {"오행 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        &nbsp;
                        <br />
                        {"숫자 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        &nbsp;
                        {"수법횟수 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        &nbsp;
                        {"재료 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        &nbsp; )
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        부정빌미 사승 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2} className="td001">
                        비방법
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        산소탈 음덕 여부
                      </td>
                      <td colSpan={1}>
                        {`남 : `}
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        <br />
                        {`여 : `}
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        비방법
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        {"방위 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                        &nbsp;
                        {"물상 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                        &nbsp;
                        {"숫자 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                        &nbsp;
                        {"수법횟수 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                        &nbsp;
                        {"재료 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                        &nbsp;
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1} className="td001">
                        길지인가 흉지인가
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION016[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    {/* <tr>
                                        <td colSpan={2} className="td001">후손 종사 생업 분야 여부</td>


                                    </tr>
                                    <tr>
                                    <td colSpan={2} className="td001">

                                    {"오행 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][12] 
                                                }
                                                &nbsp;
                                    {"계절점 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][13] 
                                                }
                                                &nbsp;
                                    {"강력여부 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][14] 
                                                }
                                                &nbsp;
                                    {"설명 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][15] 
                                                }
                                                &nbsp;

                                            
                                        </td>
                                    </tr>

                                    <tr>
                                    <td colSpan={2} className="td001">

                                    {"오행 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][12] 
                                                }
                                                &nbsp;
                                    {"계절점 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][16] 
                                                }
                                                &nbsp;
                                    {"강력여부 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][17] 
                                                }
                                                &nbsp;
                                    {"설명 : "}
                                                { selectedGapjaIndex > -1 && 
                                                selectedSiIndex > -1 
                                                && objectDanJ && objectDanJ.default && objectDanJ.default.VERSION001_DAN_FUNCTION016
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1 ]
                                                && objectDanJ.default.VERSION001_DAN_FUNCTION016[(12 * (((selectedGapjaIndex - 1 ))) + selectedGook) -1][18] 
                                                }
                                                &nbsp;

                                            
                                        </td>
                                    </tr> */}
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/15" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`질병사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        무슨질병인가?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        질병발단 이유?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        치료가능 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        치료길방위 지정 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        효과있는 치료방법 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        교통사고 생사 예측 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        비방법
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        ({"방위 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        &nbsp;
                        {"색상 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        &nbsp;
                        {"오행 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        &nbsp;
                        <br />
                        {"숫자 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        &nbsp;
                        {"수법횟수 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        &nbsp;
                        {"재료 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION015[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                        &nbsp; )
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/14" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`길흉 예측사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        흉사 일어나는 날
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        흉사 해소되는 날
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        길사 일어나는 날
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        길사 완성되는 날
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        불행사 해소되는 기간 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        길흉 동정 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION014[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/13" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`가택 가정사`}</div>

                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        가택 선악 길흉 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        가정 길흉 예측 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        이사 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        이사 길방 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        이사 택일 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        육친 길흉 예지 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        가택 악상일때의 비방법
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="td001">
                        ({"방위 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        &nbsp;
                        {"색상 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        &nbsp;
                        {"오행 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        &nbsp;
                        <br />
                        {"숫자 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        &nbsp;
                        {"수법횟수 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        &nbsp;
                        {"재료 : "}
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION013[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                        &nbsp; )
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/12" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`공명사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        선거 당선 탈락 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        빌미 부정 방지할 띠
                      </td>
                      <td colSpan={1}>
                        {` 낮`} :
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                        {` 밤`} :
                        <br />
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1} className="td001">
                        천목살
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                        {`夏 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                        {`秋 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        <br />
                        {`冬 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        천기살
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        <br />
                        {`夏 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        <br />
                        {`秋 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        <br />
                        {`冬 `} :&nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        시험 합격 탈락여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        학과 적합여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        맞는 학교는?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        승진 누락 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        직장 안부 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        취직 가능 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION012[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/11" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`사건 심리사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        아(자의) 타(타의) 사
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        미(미발현) 기(발현) 사
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        내(내사 출) 외(외사 입) 사
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        길 흉 예지 내외 발생 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION011[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/10" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`매매사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        매매가능 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        가격 절충 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        매매 성사 시기 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION010[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/9" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className={""}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`구재사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        구재 가능 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        구재 상대는 누구?
                      </td>
                      <td colSpan={1}>
                        {`낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                        {`밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        구재 날짜는 언제?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        구재 금액의 다 소 여부
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        {`夏 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        {`秋 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        {`冬 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        금융재 차용가부 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1} className="td001">
                        구재 가능 확실한 띠 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION009[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/8" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`낙찰사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        낙찰 가능 불가 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        입찰 참가자 선정 여부
                      </td>
                      <td colSpan={1}>아래표를 기준하라</td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        낙찰 가능액 유리 숫자
                      </td>
                      <td colSpan={1}>아래표를 기준하라</td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        {/* 
                                                정단계절
                                                정단시간
                                                좋은 운세자 참가시킬 띠
                                                좋은 운세 참가자 선정 거주 방위
                                                참가자 선택 남녀
                                                유리한 숫자
                                                 */}
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                        {`春 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        <br />
                        {`夏 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                        <br />
                        {`夏 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                        <br />
                        {`秋 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][19]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][20]}
                        <br />
                        {`秋 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][21]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][22]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][23]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][24]}
                        <br />
                        {`冬 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][25]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][26]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][27]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][28]}
                        <br />
                        {`冬 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][29]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][30]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][31]}
                        &nbsp;
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION008[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][32]}
                        <br />
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/7" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`상품 구별사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        상품(물건) 신 구 품질
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        안건(사건) 신 구 반복
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION007[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/6" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`주식 투자사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        이익 되는 품목 선택 여부
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                        <br />
                        {`春 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                        <br />
                        {`夏 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        <br />
                        {`夏 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                        {`秋 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                        {`秋 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                        <br />
                        {`冬 / 낮`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                        <br />
                        {`冬 / 밤`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        구입가격 고 하 여부
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                        <br />
                        {`夏 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                        <br />
                        {`秋 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                        <br />
                        {`冬 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        팔까? 기다릴까? 여부
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春 夏 秋 冬 */}
                        {`春 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                        <br />
                        {`夏 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                        <br />
                        {`秋 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                        <br />
                        {`冬 `} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION006[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/5" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`소송 승패사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        소송 승소 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        구속 불구속 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        처벌 무혐의 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        중죄 경죄 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        도망갈 길 방위는 ?
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION005[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}

              {htmlPopupContentSub == "danjeongMenu/4" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`진위 파악사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        부탁 위임사 성부 길흉
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        뇌물증여 가납효과 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        중개(소개)사 허실 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        소문진위 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION004[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/3" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`직원 채용사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        직원 채용 가부 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        현직원 동태 알기 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        충성 배신 갈등 판단 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        여러명중 적합자 선택 결정 여부{" "}
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION003[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/2" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>
                  <div className="title001">{`대인 관계사`}</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        상하, 남녀 이해 득실여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][0]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        시, 종합, 배신 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][1]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        래방자 빈부 여부
                      </td>
                      <td colSpan={1}>
                        {/* 춘하추동 : 春夏秋冬 */}
                        {`春`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][2]}
                        <br />
                        {`夏`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                        <br />
                        {`秋`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                        <br />
                        {`冬`} :
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    {/* <tr>
                                        <td colSpan={1} className="td001">래방자 운세 여부</td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr> */}
                    <tr>
                      <td colSpan={1} className="td001">
                        래방자 선 악 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][30]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        래방자 소송, 관재 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][31]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        약속인 도착 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][32]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        관계인 결별 결심 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][33]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        래방자 손익 피해 무능 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][34]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} className="td001">
                        래방자 기숙 축출 필요
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION002[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][35]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "danjeongMenu/1" && (
                <div className="danjeongMenuTableContainer001">
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHtmlPopupContentSub("");
                    }}
                  >
                    &#x2190; 단정메뉴로
                  </div>

                  <br />
                  <span className="" style={{ marginTop: 20, fontSize: 14 }}>
                    {selectedGapja}일 &nbsp; {selectedSi} 시 &nbsp;{" "}
                    {selectedGook}국
                  </span>

                  <div className="title001">사업사</div>
                  <table
                    style={{ width: `100%` }}
                    className={`danjeongMenuTable001`}
                  >
                    <tr>
                      <td colSpan={1} className="td001">
                        어떤 사업이 좋은가 여부
                      </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][3]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>신규사업(계획) 성패 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][4]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>사업 진행 과정 길흉 여부 </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][5]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>거래(관계) 인 가정사 파악 여부 </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][6]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>난사 교섭 성부 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][7]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>교섭사 전후 난이 방침 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][8]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>공사 진행 차질 대비 지침여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][9]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>영업장 설치 성쇠여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][10]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>사업(업무) 진퇴 결심 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][11]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>계획사 늦고 빠른지 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][12]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>공적 사적 추진 길흉 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][13]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>투자 득실 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][14]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>회의(안건) 반대자 유무 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][15]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>중요상담 승부 요령 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][16]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>행사 예정일 청우 여부</td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][17]}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>개업 고사 행사일 택일 여부 </td>
                      <td colSpan={1}>
                        {selectedGapjaIndex > -1 &&
                          selectedSiIndex > -1 &&
                          objectDanJ &&
                          objectDanJ.default &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001 &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ] &&
                          objectDanJ.default.VERSION001_DAN_FUNCTION001[
                            12 * (selectedGapjaIndex - 1) + selectedGook - 1
                          ][18]}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              {htmlPopupContentSub == "" && (
                <>
                  {yookimGuide.indexOf("101") > -1 && (
                    <>
                      <span style={{ fontWeight: 700, fontSize: 24 }}>
                        단정
                      </span>
                      <br />
                      <span
                        className=""
                        style={{ marginTop: 20, fontSize: 14 }}
                      >
                        {selectedGapja}일 &nbsp; {selectedSi} 시 &nbsp;{" "}
                        {selectedGook}국
                      </span>

                      {/* <QuickPinchZoom  onUpdate={onUpdate3}>
                                                                    <img ref={imgRef3} src={`/static/pdf_gogui/${(objectGui && objectGui.default && objectGui.default.PNG_GOGUINAMES[(12 * (((selectedGapjaIndex - 1 )))+ selectedGook) -1])}.png`} style={{ width: '100%', height: `600px`, objectFit : 'contain'}}
                                                                     zoom={1812}
                                                                     />
                                                                     </QuickPinchZoom> */}

                      <div className="danjeongMenu_Container0 ">
                        {Array.from([...danjeongMenu1]).map(
                          (danItem, danIndex) => {
                            return (
                              <div
                                className="danjeongMenu_Item0 "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  // if (danItem == "사업사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/1")
                                  // }
                                  // if (danItem == "대인 관계사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "직원 채용사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "진위 파악사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "소송 승패사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "주식 투자사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "상품 구별사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "낙찰사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "구재사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }
                                  // if (danItem == "구재사") {
                                  //     setHtmlPopupContentSub("danjeongMenu/2")
                                  // }

                                  setHtmlPopupContentSub(
                                    "danjeongMenu/" + parseInt(danIndex + 1)
                                  );
                                }}
                              >
                                {danIndex + 1}. {danItem}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <br />
                      <br />
                      <br />
                    </>
                  )}

                  <span style={{ fontWeight: 700, fontSize: 24 }}>
                    묻는 항목별 출생 각기 성부 판단{" "}
                  </span>
                  <br />
                  <span className="" style={{ marginTop: 20, fontSize: 14 }}>
                    {selectedGapja}일 &nbsp; {selectedSi} 시 &nbsp;{" "}
                    {selectedGook}국
                  </span>

                  <div
                    className=""
                    style={{
                      width: tableMagnify == 1 ? `100%` : `${tableMagnify}00%`,
                      fontSize:
                        tableMagnify == 1
                          ? `10px`
                          : tableMagnify == 2
                          ? `12px`
                          : tableMagnify == 3
                          ? `14px`
                          : tableMagnify == 4
                          ? `16px`
                          : `10px`,
                      marginTop: `20px`,
                    }}
                  >
                    <table
                      style={{ width: `100%` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (tableMagnify == 4) {
                          setTableMagnify(1);
                        } else {
                          setTableMagnify(tableMagnify + 1);
                        }
                      }}
                    >
                      <tr>
                        <td colSpan={23}>
                          묻는 항목별 성 부 및 방문(전화포함) 자의 연명(띠) 별로
                          변화되는 확정적 판단
                        </td>
                        <td>낮</td>
                        <td style={{ background: `#808080` }}>밤</td>
                      </tr>
                      <tr>
                        <td colSpan={1}>사안</td>
                        <td colSpan={2} style={{ color: `#000000` }}>
                          {ganji12_0[0]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#F9F739` }}>
                          {ganji12_0[1]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#1CBE20` }}>
                          {ganji12_0[2]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#29FD2F` }}>
                          {ganji12_0[3]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#FFFD38` }}>
                          {ganji12_0[4]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#FB0D1B` }}>
                          {ganji12_0[5]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#FC28FC` }}>
                          {ganji12_0[6]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#FEFC37` }}>
                          {ganji12_0[7]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#AEAEAE` }}>
                          {ganji12_0[8]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#1a1a1a` }}>
                          {ganji12_0[9]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#FEFC37` }}>
                          {ganji12_0[10]}띠
                        </td>
                        <td colSpan={2} style={{ color: `#020C7B` }}>
                          {ganji12_0[11]}띠
                        </td>
                      </tr>
                      {Array.from([
                        "모망사",
                        "공명사",
                        "구재사",
                        "구직사",
                        "시험사",
                        "결혼사",
                        "임신사",
                        "가정사",
                        "이동사",
                        "소송사",
                        "질병사",
                        "매매사",
                        "출행사",
                        "도난사",
                        "가출사",
                        "대인사",
                        "면접사",
                        "채용사",
                        "투자사",
                        "승부사",
                        "가격사",
                        "경작사",
                        "묘지사",
                        "날씨사",
                      ]).map((saItem, saIndex) => {
                        return (
                          <>
                            <tr>
                              <td colSpan={1} rowSpan={2}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][4]}
                              </td>
                              <td colSpan={24}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][5]}
                              </td>
                            </tr>
                            <tr className="danjeongRow1">
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][6]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][7]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][8]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][9]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][10]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][11]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][12]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][13]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][14]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][15]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][16]}
                              </td>

                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][17]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][18]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][19]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][20]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][21]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][22]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][23]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][24]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][25]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][26]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][27]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][28]}
                              </td>

                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][29]}
                              </td>
                              <td colSpan={1}>
                                {selectedGapjaIndex > -1 &&
                                  selectedSiIndex > -1 &&
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001 &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1
                                  ] &&
                                  objectGui.default
                                    .VERSION001_DANJEONG_TABLE001[
                                    12 * (selectedGapjaIndex - 1) +
                                      selectedGook -
                                      1 +
                                      saIndex
                                  ][30]}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </>
              )}
            </>
          )}
          {htmlPopupContentSub && htmlPopupContentSub == "형이상" && (
            <>
              {/* 20230108 업데이트 */}
              {yookimGuide.indexOf("101_1_1") > -1 && (
                <>
                  {parse(`
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            
                                                            <div style="min-width : 100%; height: 40px; font-weight: 700; white-space : nowrap;">묘소 육친 판단</div>
                                                            <br/><br/>
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                </div>	
                                                            </div>

                                                            <div style="min-width : 100%; height: 40px; font-weight: 700; white-space : nowrap;">
                                                            1. 아래 일별 납음 오행의 묘신을 다음 지반에서 찾음
                                                            </div>
                                                            
                                                            
                                                            <table style="width : calc(100% - 20px); margin-left: 10px;">
                                                                <tr>
                                                                    <td>일별 납음오행</td>
                                                                    <td><span style="font-weight: 700;">木</span></td>
                                                                    <td><span style="font-weight: 700;">火</span></td>
                                                                    <td><span style="font-weight: 700;">土</span></td>
                                                                    <td><span style="font-weight: 700;">金</span></td>
                                                                    <td><span style="font-weight: 700;">水</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>지반묘신</td>
                                                                    <td>未</td>
                                                                    <td>戌</td>
                                                                    <td>戌</td>
                                                                    <td>丑</td>
                                                                    <td>辰</td>
                                                                </tr>
                                        
                                                            </table>
                                                            <br/>
                                        `)}
                  (예시1)
                  <br />
                  <img
                    src={"/static/nabeumto001.png"}
                    className=""
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                  <div>
                    납음 토 묘 지반 예시
                    <br />
                    (아래 납음표 참고)
                  </div>
                  <br />
                  (예시2)
                  <br />
                  <img
                    src={"/static/nabeumto002.png"}
                    className=""
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                  <div>
                    납음 토 묘 천반
                    <br />
                    (지지장간 초기중기본기 산출) 예시
                  </div>
                  {parse(`<div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            <br/><br/>
                                                            <div style="min-width : 100%; height: 40px; font-weight: 700; white-space : nowrap; margin-top: 16px;">
                                                            2. 육친 산출 지장간 일 수 구분
                                                            </div>
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                </div>	
                                                            </div>
                                                            <table  style="width : calc(100% - 20px); margin-left: 10px;">
                                                                <tr>
                                                                    <td>각월 초하루 기준</td>
                                                                    <td>
                                                                        寅申巳亥<br/>
                                                                        초기 7일<br/>
                                                                        중기 7일<br/>
                                                                        본기 16일<br/>
                                                                    </td>
                                                                    <td>
                                                                        子午卯酉<br/>
                                                                        초기 10일<br/>
                                                                        중기 10일<br/>
                                                                        본기 10일<br/>
                                                                    </td>
                                                                    <td>
                                                                        辰戌丑未<br/>
                                                                        초기 9일<br/>
                                                                        중기 3일<br/>
                                                                        본기 18일<br/>
                                                                    </td>
                                                                </tr>
                                                                </table>
                                                                <br/><br/>


                                                            <table  style="width : calc(100% - 20px); margin-left: 10px;">
                                                                <tr>
                                                                    <td>위 지반묘신의 상 천반지지</td>
                                                                    <td>
                                                                    辰戌丑未<br/>
                                                                    子午卯酉<br/>
                                                                    寅申巳亥<br/>
                                                                    </td>
                                                                    <td>
                                        
                                                                    辰戌丑未<br/>
                                                                    子午卯酉<br/>
                                                                    寅申巳亥<br/>
                                                                    </td>
                                                                    <td>
                                                                    辰戌丑未<br/>
                                                                    子午卯酉<br/>
                                                                    寅申巳亥
                                                                    </td>
                                                                    <td>
                                                                    辰戌丑未<br/>
                                                                    子午卯酉<br/>
                                                                    寅申巳亥<br/>
                                                                    </td>
                                                                    <td>
                                                                    辰戌丑未<br/>
                                                                    子午卯酉<br/>
                                                                    寅申巳亥<br/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>위 지반묘신의 하 천반지지</td>
                                                                    <td>未</td>
                                                                    <td>戌</td>
                                                                    <td>戌</td>
                                                                    <td>丑</td>
                                                                    <td>辰</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>확정된 납음오행</td>
                                                                    <td><span style="font-weight: 700;">木</span></td>
                                                                    <td><span style="font-weight: 700;">火</span></td>
                                                                    <td><span style="font-weight: 700;">土</span></td>
                                                                    <td><span style="font-weight: 700;">金</span></td>
                                                                    <td><span style="font-weight: 700;">水</span></td>
                                                                </tr>
                                                            </table>
                                                            <br/><br/><br/><br/>
                                        
                                        
                                                            
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            
                                                            <div style="min-width : 100%; height: 40px; font-weight: 700; white-space : nowrap;">
                                                            
                                                            3. 얼간 대묘 상신 초중 본기의 통변 육친 판단
                                                            </div>
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                </div>	
                                                            </div>
                                                            <table  style="width : calc(100% - 20px); margin-left: 10px;">
                                                                <tr>
                                                                    <td>식상 : </td>
                                                                    <td>양지지 </td>
                                                                    <td>고조부 </td>
                                                                    <td>음지지 </td>
                                                                    <td>고조모 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>재성 : </td>
                                                                    <td>양지지 </td>
                                                                    <td>증조부 </td>
                                                                    <td>음지지 </td>
                                                                    <td>증조모 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>재성 : </td>
                                                                    <td>양지지 </td>
                                                                    <td>정처 </td>
                                                                    <td>음지지 </td>
                                                                    <td>첩녀 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>관살 : </td>
                                                                    <td>양지지 </td>
                                                                    <td>조부 </td>
                                                                    <td>음지지 </td>
                                                                    <td>조모 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>인수 : </td>
                                                                    <td>양지지 </td>
                                                                    <td>부친 </td>
                                                                    <td>음지지 </td>
                                                                    <td>모친 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td > </td>
                                                                    <td> </td>
                                                                    <td style="font-weight: 700;">본인</td>
                                                                    <td> </td>
                                                                    <td> </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight : 500 }}>식상 </td>
                                                                    <td>양지지 </td>
                                                                    <td>자식 </td>
                                                                    <td>음지지 </td>
                                                                    <td>여식 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight : 500 }}>재성 </td>
                                                                    <td>양지지 </td>
                                                                    <td>손자 </td>
                                                                    <td>음지지 </td>
                                                                    <td>손녀 </td>
                                                                </tr>
                                                            </table>
                                                            <br/><br/><br/><br/>
                                        

                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            
                                                            
                                                            <div style="min-width : 100%; height: 40px; font-weight: 700; white-space : nowrap;">
                                                            
                                                            4. 천장별(묘상신에 접지된)<br/>
                                                            산소 망인 사망 이유 및 종사직 
                                                            </div>
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                </div>	
                                                            </div>
                                                            <table style="width : 100%;">
                                                                <tr>
                                                                    <td>귀 : </td>
                                                                    <td>관직 묘 고종명자</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>사 : </td>
                                                                    <td>산액 혈광사</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>주 : </td>
                                                                    <td>문서구설 놀라 자살 (자일 투정)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>합 : </td>
                                                                    <td>목가공기술자</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>구 : </td>
                                                                    <td>옥송사 졸도사</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>청 : </td>
                                                                    <td>부귀자가 천하게 사망함(눈병 간병) </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>공 : </td>
                                                                    <td>도로사망 자취없음 (풍질, 별전도 - 쓰러짐 간질)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>백 : </td>
                                                                    <td>훙인 흉병사망</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>상 : </td>
                                                                    <td>약식주 타살사망</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>현 : </td>
                                                                    <td>도적행중 도검 혈광사망</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>음 : </td>
                                                                    <td>간사병</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>후 : </td>
                                                                    <td>산액 빈졸사</td>
                                                                </tr>
                                        
                                                            </table>





`)}
                  <div
                    style={{
                      width: "calc(100% - 80px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 40,
                    }}
                  >
                    <QuickPinchZoom onUpdate={onUpdate10}>
                      <img
                        ref={imgRef10}
                        src={`/nap001-1.png`}
                        style={{
                          objectFit: "contain",
                          border: `4px solid #000`,
                          overflow: "hidden",
                          maxHeight: 550,
                        }}
                        zoom={192}
                      />
                    </QuickPinchZoom>
                  </div>
                  <br />
                </>
              )}

              {parse(
                `<div  className="label0" style="font-family: 'NanumBarunGothic';font-size: 22px; font-weight: 700;margin-top: 80px; margin-bottom: 20px;">오행 귀신 부정 산소탈 해소 작부법</div>`
              )}
              <div
                style={{
                  width: "calc(100% - 80px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 40,
                }}
              >
                <QuickPinchZoom onUpdate={onUpdate13}>
                  <img
                    ref={imgRef13}
                    src={`/static/images/hy_i_png/${
                      selectedGapjaIndex + 100
                    }.png`}
                    style={{
                      objectFit: "contain",
                      border: `4px solid #000`,
                      overflow: "hidden",
                      maxHeight: 550,
                    }}
                    zoom={192}
                  />
                </QuickPinchZoom>
              </div>

              <br />
            </>
          )}
        </PopupContainer>
      )}

      {boolPopup1 == true && (
        <PopupContainer>
          <Button0
            onClick={() => {
              setBoolPopup1(false);
              setShowIlsaengImage1(false);
              setShowIlsaengImage2(false);
              setHtmlPopupContent("");
              setHtmlPopupContentSub("");

              setSelectedBirthCalendarGapjaIndex1(-1);
            }}
          >
            닫기
          </Button0>

          <Button0
            style={{
              position: "fixed",
              bottom: 28,
              width: `calc(100vw - 70px)`,
              zIndex: 90,
            }}
            onClick={() => {
              setBoolPopup1(false);
              setShowIlsaengImage1(false);
              setShowIlsaengImage2(false);
              setHtmlPopupContent("");
              setHtmlPopupContentSub("");
              setTableMagnify(1);
              setSelectedBirthCalendarGapjaIndex1(-1);
            }}
          >
            닫기
          </Button0>

          {popupType == "location" && (
            <>
              <div
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  border: `1px solid #000`,
                  background:
                    activeEffect1 == "confirm" ? "#98adc1" : `#95B2B0`,
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: 20,
                  marginTop: 20,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPopup1(false);
                  setActiveEffect1("confirm");
                }}
              >
                확인
              </div>
              <div
                className="label0"
                style={{
                  marginBottom: 10,
                  marginTop: 40,
                  fontSize: 16,
                  fontWeight: "700",
                }}
              >
                국내
              </div>
              <div className="location_wrapper">
                <br />
                {Array.from([
                  ...location2,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ]).map((arrayItem, arrayIndex) => {
                  return (
                    <div
                      style={{
                        fontWeight: 700,
                      }}
                      className={
                        arrayItem.label == textSelectedLocation
                          ? "activeitem locationitem0 locationitem" + arrayIndex
                          : "locationitem0 locationitem" + arrayIndex
                      }
                      onClick={() => {
                        if (arrayItem == "") {
                          return;
                        }

                        setTextSelectedLocation(arrayItem.label);

                        if (messageFromAndroid != "") {
                          if (
                            messageFromAndroid &&
                            ("" + messageFromAndroid).indexOf("8210") == 0
                          ) {
                            console.log("[테스트] >> --------------------");
                            console.log(
                              "[테스트] >> messageFromAndroid.substr(2,11)",
                              messageFromAndroid.substr(4, 15)
                            );
                            console.log(
                              "[테스트] >> messageFromAndroid.substr(2,11) - 010",
                              "010" + messageFromAndroid.substr(4, 15)
                            );
                            console.log("[테스트] >> ===================");

                            firebase
                              .database()
                              .ref()
                              .child("contacts")
                              .child(`/${fbContactKeyName}`)
                              .update({ location: arrayItem.label });
                          }

                          if (
                            messageFromAndroid &&
                            ("" + messageFromAndroid).indexOf("010") == 0
                          ) {
                            firebase
                              .database()
                              .ref()
                              .child("contacts")
                              .child(`/${fbContactKeyName}`)
                              .update({ location: arrayItem.label });
                          } else {
                          }
                          if (
                            messageFromAndroid &&
                            ("" + messageFromAndroid).indexOf("10") == 0
                          ) {
                            firebase
                              .database()
                              .ref()
                              .child("contacts")
                              .child(`/${fbContactKeyName}`)
                              .update({ location: arrayItem.label });
                          } else {
                          }

                          setSearchedInput101(moment().format("YYYY"));
                          setSearchedInput102(moment().format("MM"));
                          setSearchedInput103(moment().format("DD"));
                          setSearchedInput104(moment().format("HH"));
                          setSearchedInput105(moment().format("mm"));
                          setSearchedInput106(moment().format("ss"));

                          setSearchedInput201(moment().format("YYYY"));
                          setSearchedInput202(moment().format("MM"));
                          setSearchedInput203(moment().format("DD"));
                          setSearchedInput204(moment().format("HH"));
                          setSearchedInput205(moment().format("mm"));
                          setSearchedInput206(moment().format("ss"));

                          __GOOKSU_DECIDE_AS_JOOGI(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );

                          __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );
                        }

                        setSearchedInput101(moment().format("YYYY"));
                        setSearchedInput102(moment().format("MM"));
                        setSearchedInput103(moment().format("DD"));
                        setSearchedInput104(moment().format("HH"));
                        setSearchedInput105(moment().format("mm"));
                        setSearchedInput106(moment().format("ss"));

                        setSearchedInput201(moment().format("YYYY"));
                        setSearchedInput202(moment().format("MM"));
                        setSearchedInput203(moment().format("DD"));
                        setSearchedInput204(moment().format("HH"));
                        setSearchedInput205(moment().format("mm"));
                        setSearchedInput206(moment().format("ss"));

                        __GOOKSU_DECIDE_AS_JOOGI(
                          moment().format("YYYY"),
                          moment().format("MM"),
                          moment().format("DD"),
                          moment().format("HH"),
                          moment().format("mm"),
                          moment().format("ss")
                        );

                        __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                          moment().format("YYYY"),
                          moment().format("MM"),
                          moment().format("DD"),
                          moment().format("HH"),
                          moment().format("mm"),
                          moment().format("ss")
                        );
                      }}
                    >
                      {arrayItem.label}
                      {arrayItem.label == textSelectedLocation && (
                        <>&nbsp;&nbsp;&#x2714;</>
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  border: `1px solid #000`,
                  background:
                    activeEffect1 == "confirm" ? "#98adc1" : `#95B2B0`,
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: 20,
                  marginTop: 20,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setBoolPopup1(false);
                  setActiveEffect1("confirm");
                }}
              >
                확인
              </div>
              <div
                className="label0"
                style={{
                  marginBottom: 10,
                  marginTop: 3,
                  fontSize: 16,
                  fontWeight: "700",
                }}
              >
                해외
              </div>
              <div className="location_wrapper">
                {Array.from([...location1, "", "", "", "", "", "", "", ""]).map(
                  (arrayItem, arrayIndex) => {
                    return (
                      <div
                        style={{
                          fontWeight: 700,
                        }}
                        className={
                          arrayItem.label == textSelectedLocation
                            ? "activeitem locationitem0 locationitem" +
                              arrayIndex
                            : "locationitem0 locationitem" + arrayIndex
                        }
                        onClick={() => {
                          if (arrayItem == "") {
                            return;
                          }

                          setTextSelectedLocation(arrayItem.label);

                          if (messageFromAndroid != "") {
                            if (
                              messageFromAndroid &&
                              ("" + messageFromAndroid).indexOf("8210") == 0
                            ) {
                              console.log("[테스트] >> --------------------");
                              console.log(
                                "[테스트] >> messageFromAndroid.substr(2,11)",
                                messageFromAndroid.substr(4, 15)
                              );
                              console.log(
                                "[테스트] >> messageFromAndroid.substr(2,11) - 010",
                                "010" + messageFromAndroid.substr(4, 15)
                              );
                              console.log("[테스트] >> ===================");

                              firebase
                                .database()
                                .ref()
                                .child("contacts")
                                .child(`/${fbContactKeyName}`)
                                .update({ location: arrayItem.label });
                            }

                            if (
                              messageFromAndroid &&
                              ("" + messageFromAndroid).indexOf("010") == 0
                            ) {
                              firebase
                                .database()
                                .ref()
                                .child("contacts")
                                .child(`/${fbContactKeyName}`)
                                .update({ location: arrayItem.label });
                            } else {
                            }
                            if (
                              messageFromAndroid &&
                              ("" + messageFromAndroid).indexOf("10") == 0
                            ) {
                              firebase
                                .database()
                                .ref()
                                .child("contacts")
                                .child(`/${fbContactKeyName}`)
                                .update({ location: arrayItem.label });
                            } else {
                            }

                            setSearchedInput101(moment().format("YYYY"));
                            setSearchedInput102(moment().format("MM"));
                            setSearchedInput103(moment().format("DD"));
                            setSearchedInput104(moment().format("HH"));
                            setSearchedInput105(moment().format("mm"));
                            setSearchedInput106(moment().format("ss"));

                            setSearchedInput201(moment().format("YYYY"));
                            setSearchedInput202(moment().format("MM"));
                            setSearchedInput203(moment().format("DD"));
                            setSearchedInput204(moment().format("HH"));
                            setSearchedInput205(moment().format("mm"));
                            setSearchedInput206(moment().format("ss"));

                            __GOOKSU_DECIDE_AS_JOOGI(
                              moment().format("YYYY"),
                              moment().format("MM"),
                              moment().format("DD"),
                              moment().format("HH"),
                              moment().format("mm"),
                              moment().format("ss")
                            );

                            __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                              moment().format("YYYY"),
                              moment().format("MM"),
                              moment().format("DD"),
                              moment().format("HH"),
                              moment().format("mm"),
                              moment().format("ss")
                            );
                          }

                          setSearchedInput101(moment().format("YYYY"));
                          setSearchedInput102(moment().format("MM"));
                          setSearchedInput103(moment().format("DD"));
                          setSearchedInput104(moment().format("HH"));
                          setSearchedInput105(moment().format("mm"));
                          setSearchedInput106(moment().format("ss"));

                          setSearchedInput201(moment().format("YYYY"));
                          setSearchedInput202(moment().format("MM"));
                          setSearchedInput203(moment().format("DD"));
                          setSearchedInput204(moment().format("HH"));
                          setSearchedInput205(moment().format("mm"));
                          setSearchedInput206(moment().format("ss"));

                          __GOOKSU_DECIDE_AS_JOOGI(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );

                          __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );
                        }}
                      >
                        {arrayItem.label}
                        {arrayItem.label == textSelectedLocation && (
                          <>&nbsp;&nbsp;&#x2714;</>
                        )}
                      </div>
                    );
                  }
                )}
              </div>

              {/* 2023-01-31수정 */}
              <QuickPinchZoom onUpdate={onUpdate} style={{ width: `100%` }}>
                <img
                  ref={imgRef}
                  src={`/static/yookim_166-1.png`}
                  style={{
                    width: `calc(100vw - 40px)`,
                    maxHeight: `500px`,
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  zoom={192}
                />
              </QuickPinchZoom>
            </>
          )}
          {popupType == "term" && (
            <div className="term_wrapper">
              {appStrings && appStrings.before1 && parse(appStrings.before1)}
            </div>
          )}
          {popupType == "calendar" && (
            <div className="term_wrapper">
              <iframe
                id="cal_iframe"
                src="/static/manse_calendar.html"
                // manse_frame_trans001
                style={{
                  width: `100%`,
                  height: `100%`,
                  minHeight: `calc(100vh - 100px)`,
                }}
                frameborder="0"
              />
            </div>
          )}
          {popupType == "day" && <></>}
        </PopupContainer>
      )}
      <ContentContainer>
        <div
          className=" "
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 60,
            width: `calc(100vw - 20px)`,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            className=" menu1 menubar"
            style={{ borderBottom: `1px solid #000`, fontWeight: `700` }}
            onClick={() => {
              setPopupType("term");
              setBoolPopup1(true);
            }}
          >
            보기요령
          </div>
          <div
            className=" menu1 menubar"
            style={{ borderBottom: `1px solid #000`, fontWeight: `700` }}
            onClick={() => {
              setPopupType("location");
              setBoolPopup1(true);
            }}
          >
            지역설정
          </div>
          <div
            className=" menu1 menubar"
            style={{ borderBottom: `1px solid #000`, fontWeight: `700` }}
            onClick={() => {
              if (inputType == "day") {
                setInputType("");
              } else {
                setInputType("day");
              }
            }}
          >
            일자변경
          </div>
          <div
            className="menu0 menu1 menubar"
            style={{ borderBottom: `1px solid #000`, fontWeight: `700` }}
            onClick={() => {
              setHtmlPopupContent(`성취기도일시`);
            }}
          >
            성취기도일시
          </div>
          <div
            className="menu0 menu1 menubar"
            style={{ borderBottom: `1px solid #000`, fontWeight: `700` }}
            onClick={() => {
              setPopupType("calendar");
              setBoolPopup1(true);
            }}
          >
            달력보기
          </div>
        </div>
        {inputType == "day" && (
          <>
            <div
              className=" "
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                width: `calc(100vw - 20px)`,
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
              }}
            >
              <select
                onChange={(e) => {
                  setSearchedInput101(e.target.value);
                }}
                value={searchedInput101}
              >
                {years1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;년&nbsp;</span>

              <select
                onChange={(e) => {
                  setSearchedInput102(e.target.value);
                }}
                value={searchedInput102}
              >
                {month1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;월&nbsp;</span>
              <select
                onChange={(e) => {
                  setSearchedInput103(e.target.value);
                }}
                value={searchedInput103}
              >
                {days1
                  .filter((filterItem, filterIndex) => {
                    // 1, 3, 5,7, 8,10,12
                    // 2, 4, 6, 9, , 11
                    // 2
                    if (filterItem == "31") {
                      if (
                        searchedInput102 == "01" ||
                        searchedInput102 == "03" ||
                        searchedInput102 == "05" ||
                        searchedInput102 == "07" ||
                        searchedInput102 == "08" ||
                        searchedInput102 == "10" ||
                        searchedInput102 == "12"
                      ) {
                        return filterItem;
                      } else {
                        return null;
                      }
                    } else {
                      return filterItem;
                    }
                  })
                  .map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;일&nbsp;</span>
              <div className="" style={{ marginLeft: "auto" }}></div>
            </div>

            <div
              className=" "
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                width: `calc(100vw - 20px)`,
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
              }}
            >
              <select
                onChange={(e) => {
                  setSearchedInput104(e.target.value);
                }}
                value={searchedInput104}
              >
                {hours1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;시&nbsp;</span>
              <select
                onChange={(e) => {
                  setSearchedInput105(e.target.value);
                }}
                value={searchedInput105}
              >
                {minutes1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;분&nbsp;</span>
              <select
                onChange={(e) => {
                  setSearchedInput106(e.target.value);
                }}
                value={searchedInput106}
              >
                {seconds1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span style={{ fontWeight: 700 }}>&nbsp;초&nbsp;</span>
              <div className="" style={{ marginLeft: "auto" }}></div>
            </div>
            <div
              className=" "
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                width: `calc(100vw - 20px)`,
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <select
                onChange={(e) => {
                  setSearchedInput107(e.target.value);
                }}
                value={searchedInput107}
              >
                {yinyang1.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span
                className=" button_1"
                style={{
                  width: `calc(30vw - 10px)`,
                  height: 20,
                  marginRight: "auto",
                  marginLeft: 20,
                  background:
                    activeEffect1 == "input" ? "#98adc1" : `rgb(141, 141, 170)`,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setActiveEffect1("input");
                  if (searchedInput107 == "양력") {
                    __GOOKSU_DECIDE_AS_JOOGI(
                      searchedInput101,
                      searchedInput102,
                      searchedInput103,
                      searchedInput104,
                      searchedInput105,
                      searchedInput106
                    );
                  } else {
                    /* 양력/음력 변환 */
                    var date = getSolar(
                      searchedInput101,
                      searchedInput102,
                      searchedInput103,
                      0
                    );
                    console.log("음력 -> 양력", date);
                    __GOOKSU_DECIDE_AS_JOOGI(
                      date.year,
                      date.month,
                      date.day,
                      searchedInput104,
                      searchedInput105,
                      searchedInput106
                    );
                  }
                }}
              >
                입력/검색
              </span>
              <span
                className=" button_1"
                style={{
                  width: `calc(30vw - 10px)`,
                  height: 20,
                  fontSize: 13,
                  marginRight: "20px",
                  marginLeft: 20,
                  background:
                    activeEffect1 == "reset" ? "#98adc1" : `rgb(141, 141, 170)`,
                  fontWeight: 500,
                }}
                onClick={() => {
                  // window.location.href = "/hyosa"

                  setBoolPopup1(false);
                  setActiveEffect1("reset");
                  setPopupType("");
                  setInputType("");

                  setSearchedInput101(moment().format("YYYY"));
                  setSearchedInput102(moment().format("MM"));
                  setSearchedInput103(moment().format("DD"));
                  setSearchedInput104(moment().format("HH"));
                  setSearchedInput105(moment().format("mm"));
                  setSearchedInput106(moment().format("ss"));

                  setSearchedInput201(moment().format("YYYY"));
                  setSearchedInput202(moment().format("MM"));
                  setSearchedInput203(moment().format("DD"));
                  setSearchedInput204(moment().format("HH"));
                  setSearchedInput205(moment().format("mm"));
                  setSearchedInput206(moment().format("ss"));

                  __GOOKSU_DECIDE_AS_JOOGI(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    moment().format("HH"),
                    moment().format("mm"),
                    moment().format("ss")
                  );

                  __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                    moment().format("YYYY"),
                    moment().format("MM"),
                    moment().format("DD"),
                    moment().format("HH"),
                    moment().format("mm"),
                    moment().format("ss")
                  );
                  // @@ TODO
                  // 1초마다 점검
                }}
              >
                초기화
              </span>
            </div>
          </>
        )}

        <Layout_DTM
          style={{
            marginTop: appEnv.isMarqueeEnabled == true ? 45 + 0 : 0,
          }}
        >
          {/* 헤더, 상단 일/시/국 일시국 */}
          {true && (
            <>
              <div
                className="maintable_wrapper0 maintable_wrapper1"
                style={{
                  borderTop: !(
                    selectedSiIndex1 == 1 ||
                    selectedSiIndex1 == 2 ||
                    selectedSiIndex1 == 3 ||
                    selectedSiIndex1 == 10 ||
                    selectedSiIndex1 == 11 ||
                    selectedSiIndex1 == 12
                  )
                    ? `8px solid #B93160`
                    : `8px solid #1F4690`,

                  // background:  !(selectedSiIndex1 == 1 || selectedSiIndex1 == 2 || selectedSiIndex1 == 3 || selectedSiIndex1 == 10 ||  selectedSiIndex1 == 11 ||  selectedSiIndex1 == 12)  ? `#B93160` : `#1F4690`,
                }}
              >
                {/* 
                                 ${parseInt(getRandomArbitrary(0,11))} */}

                <div
                  className="maintable_row0 maintable_row1"
                  style={{
                    minHeight: `40px`,
                    alignItems: "center",
                    justifyContent: "center",
                    // color:  !(selectedSiIndex1 == 1 || selectedSiIndex1 == 2 || selectedSiIndex1 == 3 || selectedSiIndex1 == 10 ||  selectedSiIndex1 == 11 ||  selectedSiIndex1 == 12)  ? `#B93160` : `#1F4690`
                    background: !(
                      selectedSiIndex1 == 1 ||
                      selectedSiIndex1 == 2 ||
                      selectedSiIndex1 == 3 ||
                      selectedSiIndex1 == 10 ||
                      selectedSiIndex1 == 11 ||
                      selectedSiIndex1 == 12
                    )
                      ? `#B93160`
                      : `#1F4690`,
                    color: `#fff`,
                  }}
                >
                  {parse(`<div className="" style="width: 100%; font-family: 'NanumBarunGothic';font-size: 16px; font-weight: 700;">
                                 ${
                                   currentDisplayDate &&
                                   currentDisplayDate.format(
                                     "양력 YYYY년 MM월 DD일"
                                   )
                                 }&nbsp;
                                 ${
                                   currentDisplayDate &&
                                   solarToLunar(
                                     currentDisplayDate.format("YYYY"),
                                     currentDisplayDate.format("MM"),
                                     currentDisplayDate.format("DD")
                                   )
                                 }
                                 <br/>
                                 ${selectedGapja}일
                                 ${selectedSi} 시 
                                 ${selectedGook}국
                                 </div>`)}
                </div>

                <div
                  className="maintable_row0 maintable_row1"
                  style={{ marginTop: 20 }}
                ></div>

                <div className="maintable_row0 maintable_row3">
                  {/* [3][0], [3][3], [3][6] */}
                  {/* 3부터 시작해서 5씩 추가 [3+5n][0] [3+5n][3] [3+5n][6]  */}
                  <div
                    className="maintable_column0 maintable_column3"
                    onClick={() => {
                      setBoolMagnifyPopup(true);
                      setObjectMagnify({
                        title: "방문(전화포함)자 신뢰심",
                        desc:
                          objectGui.default.NEW_SHINLOI[
                            12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                          ].indexOf("!!") > -1
                            ? "불성"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("子") > -1
                            ? "설득"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("午") > -1
                            ? "설득"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("卯") > -1
                            ? "설득"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("酉") > -1
                            ? "설득"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("寅") > -1
                            ? "고집"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("申") > -1
                            ? "고집"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("巳") > -1
                            ? "고집"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("亥") > -1
                            ? "고집"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("辰") > -1
                            ? "신뢰"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("戌") > -1
                            ? "신뢰"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("丑") > -1
                            ? "신뢰"
                            : objectGui.default.NEW_SHINLOI[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1)
                              ].indexOf("未") > -1
                            ? "신뢰"
                            : "",
                      });
                    }}
                  >
                    <div className="label0">상대 신뢰심</div>
                    <div
                      className="value0"
                      style={{
                        marginTop: `30px`,
                        marginLeft: `8px`,
                        height: `92px`,
                      }}
                    >
                      {/* (sootJaIndex - 1)  */}
                      {selectedGapjaIndex && selectedSiIndex1 ? (
                        <>
                          {parse(` ${
                            selectedGapjaIndex &&
                            selectedSiIndex1 &&
                            selectedGapjaIndex > -1 &&
                            selectedSiIndex1 > -1 &&
                            objectGui &&
                            objectGui.default &&
                            objectGui.default.NEW_SHINLOI &&
                            objectGui.default.NEW_SHINLOI[
                              12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                            ] &&
                            (objectGui.default.NEW_SHINLOI[
                              12 * (selectedGapjaIndex - 1) + (selectedGook - 1)
                            ].indexOf("!!") > -1
                              ? "불성"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("子") > -1
                              ? "설득"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("午") > -1
                              ? "설득"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("卯") > -1
                              ? "설득"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("酉") > -1
                              ? "설득"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("寅") > -1
                              ? "고집"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("申") > -1
                              ? "고집"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("巳") > -1
                              ? "고집"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("亥") > -1
                              ? "고집"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("辰") > -1
                              ? "신뢰"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("戌") > -1
                              ? "신뢰"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("丑") > -1
                              ? "신뢰"
                              : objectGui.default.NEW_SHINLOI[
                                  12 * (selectedGapjaIndex - 1) +
                                    (selectedGook - 1)
                                ].indexOf("未") > -1
                              ? "신뢰"
                              : "")
                          }
                                                
                                                
                                                <br/>`)}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="maintable_column0 maintable_column3"
                    onClick={() => {
                      setBoolMagnifyPopup(true);

                      if (yookimGuide == "100") {
                        setObjectMagnify({
                          title: "기도수법효과유무",
                          desc:
                            objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                              12 * (selectedGapjaIndex - 1) +
                                (selectedGook - 1) -
                                1
                            ][1] &&
                            objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                              12 * (selectedGapjaIndex - 1) +
                                (selectedGook - 1) -
                                1
                            ][1].trim(),
                        });
                      }
                      if (yookimGuide.indexOf("101") > -1) {
                        setObjectMagnify({
                          title: "기도수법효과유무",
                          desc:
                            objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                              12 * (selectedGapjaIndex - 1) +
                                (selectedGook - 1) -
                                1
                            ][1] &&
                            objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                              12 * (selectedGapjaIndex - 1) +
                                (selectedGook - 1) -
                                1
                            ][1].trim() +
                              `
                                                <br/>

                                            ${
                                              objectGui &&
                                              objectGui.default &&
                                              objectGui.default
                                                .VERSION001_GIDO_001[
                                                selectedGapjaIndex - 1
                                              ] &&
                                              objectGui.default
                                                .VERSION001_GIDO_001[
                                                selectedGapjaIndex - 1
                                              ][0] &&
                                              objectGui.default.VERSION001_GIDO_001[
                                                selectedGapjaIndex - 1
                                              ][0]
                                                .replaceAll("□", "<br/><br/>□")
                                                .replaceAll("☑", "<br/><br/>☑")
                                                .replaceAll("☐", "<br/><br/>□")
                                            }
                                        
                                                `,
                        });
                      }
                    }}
                  >
                    <div className="label0">수법효과유무</div>
                    <div
                      className="value0"
                      style={{
                        width: "calc(100vw / 3 - 20px)",
                        marginTop: `30px`,
                        marginLeft: `8px`,
                        height: `92px`,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        display: "flex",

                        position: "relative",
                      }}
                    >
                      {parse(
                        ` ${
                          selectedGapjaIndex > -1 &&
                          selectedSiIndex1 > -1 &&
                          objectGui &&
                          objectGui.default &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                            12 * (selectedGapjaIndex - 1) +
                              (selectedGook - 1) -
                              1
                          ] &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                            12 * (selectedGapjaIndex - 1) +
                              (selectedGook - 1) -
                              1
                          ][1]
                            ? objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1) -
                                  1
                              ][1]
                            : ""
                        }<br/>`
                      )}
                      {yookimGuide.indexOf("101") > -1 && (
                        <div
                          style={{
                            position: "absolute",
                            width: "calc(100vw / 3 - 24px)",
                            height: `20px`,
                            background: `#8d8daa`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            border: `1px solid #000`,
                            marginTop: "auto",
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 8,

                            bottom: 4,
                            left: 2,
                          }}
                        >
                          기도 분류
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="maintable_column0 maintable_column3_1"
                    onClick={() => {
                      setBoolMagnifyPopup(true);
                      setObjectMagnify({
                        title: "상대언행 진위여부",
                        desc: objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                          12 * (selectedGapjaIndex - 1) + (selectedGook - 1) - 1
                        ][2].replaceAll("거짓", "위선"),
                      });
                    }}
                  >
                    <div className="label0">상대진위여부</div>
                    <div
                      className="value0"
                      style={{
                        marginTop: `30px`,
                        marginLeft: `8px`,
                        textAlign: "left",
                        height: `92px`,
                      }}
                    >
                      {parse(
                        ` ${
                          selectedGapjaIndex &&
                          selectedSiIndex1 &&
                          selectedGapjaIndex > -1 &&
                          selectedSiIndex1 > -1 &&
                          objectGui &&
                          objectGui.default &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                            12 * (selectedGapjaIndex - 1) +
                              (selectedGook - 1) -
                              1
                          ] &&
                          objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                            12 * (selectedGapjaIndex - 1) +
                              (selectedGook - 1) -
                              1
                          ][2]
                            ? objectGui.default.HEADERINFO_ILSAENG_COMBINED[
                                12 * (selectedGapjaIndex - 1) +
                                  (selectedGook - 1) -
                                  1
                              ][2].replaceAll("거짓", "위선")
                            : ""
                        }<br/>`
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="maintable_row0 maintable_row2"
                  style={{
                    marginTop: 10,
                    justifyItems: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {yookimGuide.indexOf("101_1_1") == -1 && (
                    <span
                      style={{
                        width: `72px`,
                        height: `20px`,
                        // background: `#8d8daa`,

                        background: !(
                          selectedSiIndex1 == 1 ||
                          selectedSiIndex1 == 2 ||
                          selectedSiIndex1 == 3 ||
                          selectedSiIndex1 == 10 ||
                          selectedSiIndex1 == 11 ||
                          selectedSiIndex1 == 12
                        )
                          ? `#B93160`
                          : `#1F4690`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: 10,
                        fontWeight: 700,
                        marginLeft: 4,
                        marginTop: 2,
                        border: `1px solid #000`,
                        position: "absolute",
                        top: 4,
                        zIndex: 10,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setHtmlPopupContent("형이상_귀신부정");
                      }}
                    >
                      귀신 혼령 부정
                    </span>
                  )}
                  {yookimGuide.indexOf("101_1_1") > -1 && (
                    <span
                      style={{
                        width: "calc(100vw / 3 - 22px)",
                        height: `20px`,
                        // background: `#8d8daa`,

                        background: !(
                          selectedSiIndex1 == 1 ||
                          selectedSiIndex1 == 2 ||
                          selectedSiIndex1 == 3 ||
                          selectedSiIndex1 == 10 ||
                          selectedSiIndex1 == 11 ||
                          selectedSiIndex1 == 12
                        )
                          ? `#B93160`
                          : `#1F4690`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: 10,
                        fontWeight: 700,
                        marginLeft: 4,
                        marginTop: 2,
                        border: `1px solid #000`,
                        position: "absolute",
                        top: 4,
                        zIndex: 10,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setHtmlPopupContent("업데이트20230126_01");
                      }}
                    >
                      사안규모금전大小
                    </span>
                  )}
                  <div
                    className="maintable_column0 maintable_column1"
                    onClick={() => {
                      setBoolMagnifyPopup(true);
                      setObjectMagnify({
                        title: "초사1",
                        desc:
                          selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.CHOSA1[selectedGook - 1]
                            .replaceAll(
                              "⊖",
                              "<span style='font-weight: 700;'>⊖</span>"
                            )
                            .replaceAll(
                              "⊕",
                              "<span style='font-weight: 700;'>⊕</span>"
                            ),
                      });
                    }}
                  >
                    <div
                      className="label0"
                      style={{ width: `100%`, textAlign: "center", left: 0 }}
                    >
                      &#x2752; 초사1
                    </div>
                    <div
                      className="value0"
                      style={{
                        minHeight: 180,
                        marginLeft: 5,
                        width: `calc(100% - 10px)`,
                      }}
                    >
                      {selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.CHOSA1 &&
                        parse(
                          `${selectedNaejung.default.CHOSA1[selectedGook - 1]}`
                            .replaceAll(
                              "⊖",
                              "<span style='font-weight: 700;'>⊖</span>"
                            )
                            .replaceAll(
                              "⊕",
                              "<span style='font-weight: 700;'>⊕</span>"
                            )
                        )}
                    </div>
                  </div>

                  <div
                    className="maintable_column0 maintable_column1"
                    onClick={() => {
                      setBoolMagnifyPopup(true);
                      setObjectMagnify({
                        title: "초사2",
                        desc: selectedNaejung.default.YOGYEOL1[
                          selectedGook - 1
                        ],
                      });
                    }}
                  >
                    <div
                      className="label0"
                      style={{ width: `100%`, textAlign: "center", left: 0 }}
                    >
                      &#x2752; 초사2
                    </div>
                    <div
                      className="value0"
                      style={{
                        minHeight: 180,
                        marginLeft: 5,
                        width: `calc(100% - 10px)`,
                      }}
                    >
                      {selectedNaejung &&
                        selectedNaejung.default &&
                        selectedNaejung.default.YOGYEOL1 &&
                        parse(
                          `${
                            selectedNaejung.default.YOGYEOL1[selectedGook - 1]
                          }`
                        )}

                      {/* 20230108업데이트항목 */}

                      {yookimGuide.indexOf("101_1_1") > -1 && (
                        <>
                          {selectedGapja &&
                          selectedGapja.indexOf("갑") > -1 &&
                          selectedGook == 10 ? (
                            <>
                              {/* 초사3 */}
                              {/* <div className="label0"  style={{ width: `100%`, textAlign : 'center', left : 0}}> </div> */}
                              <div
                                className=""
                                style={{
                                  marginLeft: 5,
                                  width: `calc(100% - 10px)`,
                                }}
                              >
                                <br />
                                <span style={{ fontWeight: 700 }}>
                                  ■ 초사3
                                </span>{" "}
                                이 사람의 가정에 반드시 사망자가 있거나 반드시
                                사망지경자가 있다.
                                <br />
                                {/* <span style={{ fontSize: 10 }}>매 갑일 제 10국은 확실하여 제외하고, <br/>기타 월일 해당 국수에 명시된<br/> 사망 등 본 내용은<br/>가정 사망 버튼 속 초전 예시의 <br/>낮밤 시간 좌우 천장을 분간하여<br/>아래의 천장별 내용임을 판단하여<br/>지적하라.<br/></span> */}
                              </div>
                            </>
                          ) : (
                            <>
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 0 &&
                                selectedGapjaIndex <= 10 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 0 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 1 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 2 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 3 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 4 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 5 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 6 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 7 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 8 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 9 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1)
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 10 &&
                                selectedGapjaIndex <= 20 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 10 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 11 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 12 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 13 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 14 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 15 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 16 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 17 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 18 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 19 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 1
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 20 &&
                                selectedGapjaIndex <= 30 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 20 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 21 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 22 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 23 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 24 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 25 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 26 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 27 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 28 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 29 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 2
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 30 &&
                                selectedGapjaIndex <= 40 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 30 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 31 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 32 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 33 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 34 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 35 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 36 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 37 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 38 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 39 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 3
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 40 &&
                                selectedGapjaIndex <= 50 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 40 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 41 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 42 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 43 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 44 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 45 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 46 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 47 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 48 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 49 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 4
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              {gyeolGoolgi1 &&
                                selectedGapjaIndex > 50 &&
                                selectedGapjaIndex <= 60 && (
                                  <>
                                    {selectedGapjaIndex - 1 == 50 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][0] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 51 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][1] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 52 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][2] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 53 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][3] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 54 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][4] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 55 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][5] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 56 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][6] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 57 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][7] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 58 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][8] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                    {selectedGapjaIndex - 1 == 59 &&
                                      hyosagyeolTable1[
                                        6 * (gyeolGoolgi1 - 1) + 5
                                      ][9] == selectedGook && (
                                        <div
                                          className=""
                                          style={{
                                            marginLeft: 5,
                                            width: `calc(100% - 10px)`,
                                          }}
                                        >
                                          <br />
                                          <span style={{ fontWeight: 700 }}>
                                            ■ 초사3
                                          </span>{" "}
                                          이 사람의 가정에 반드시 사망자가
                                          있거나 반드시 사망지경자가 있다.
                                          <br />
                                          <span style={{ fontSize: 10 }}>
                                            매 갑일 제 10국은 확실하여 제외하고,{" "}
                                            <br />
                                            기타 월일 해당 국수에 명시된
                                            <br /> 사망 등 본 내용은
                                            <br />
                                            가정 사망 버튼 속 초전 예시의 <br />
                                            낮밤 시간 좌우 천장을 분간하여
                                            <br />
                                            아래의 천장별 내용임을 판단하여
                                            <br />
                                            지적하라.
                                            <br />
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {
                  // 업데이트20230108  //20240214
                  yookimGuide.indexOf("101_1_1") > -1 && (
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `40px !important`,
                        minHeight: `40px !important`,
                        maxHeight: `40px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                        marginTop: 0,
                      }}
                    >
                      {Array.from([
                        "귀신·혼령·부정",
                        "가정 사망",
                        "질병·원인",
                        "종사업",
                      ]).map((arrayItem, arrayIndex) => {
                        return (
                          <div
                            className={`button0_8`}
                            onClick={(e) => {
                              if (arrayIndex == 0) {
                                e.stopPropagation();
                                e.preventDefault();
                                setHtmlPopupContent("형이상_귀신부정");
                              }

                              if (arrayIndex == 1) {
                                e.stopPropagation();
                                e.preventDefault();
                                setHtmlPopupContent("메뉴1010101_가정사망");
                              }
                              if (arrayIndex == 2) {
                                e.stopPropagation();
                                e.preventDefault();
                                setHtmlPopupContent("메뉴1010101_질병원인");
                              }
                              if (arrayIndex == 3) {
                                e.stopPropagation();
                                e.preventDefault();
                                setHtmlPopupContent("메뉴1010101_종사업");
                              }
                            }}
                            style={{
                              height: 24,
                              fontSize: 12,
                              fontWeight: 900,
                              padding: 0,
                              width: `calc(100% / 4)`,
                              background: !(
                                selectedSiIndex1 == 1 ||
                                selectedSiIndex1 == 2 ||
                                selectedSiIndex1 == 3 ||
                                selectedSiIndex1 == 10 ||
                                selectedSiIndex1 == 11 ||
                                selectedSiIndex1 == 12
                              )
                                ? `#B93160`
                                : `#1F4690`,
                              fontWeight: 700,
                            }}
                          >
                            {arrayItem}
                          </div>
                        );
                      })}
                      {/* <div style={{width: 4}}></div> */}
                    </div>
                  )
                }

                {yookimGuide && yookimGuide.indexOf("101_1") > -1 ? (
                  <></>
                ) : (
                  <div
                    className="maintable_row0 "
                    style={{
                      height: `80px !important`,
                      minHeight: `80px !important`,
                      maxHeight: `80px !important`,
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      flexWrap: "nowrap",
                      fontWeight: 700,
                      fontSize: 14,
                      marginTop: 24,
                      marginBottom: 12,
                      borderBottom: `1px solid #fff`,
                    }}
                  >
                    ■ 明暗 즉단법
                  </div>
                )}

                {/* 여기를 TimeSelect.js를 이용하도록 수정해줘 */}

                <TimeSelect
                  ganji12_0={ganji12_0}
                  time12_0={time12_0}
                  ganji12_2={ganji12_2}
                  intSelectedIndex2={intSelectedIndex2}
                  specificPersonValue={specificPersonValue}
                  parse={parse}
                  headerTitle="시간 선택"
                  timeTitleList={[
                    "방문 전화 조우 시간 - 지역 시간 경계별 분 단위 적용됨",
                    "동일한 시간 내 (전화포함) 다음 사람",
                    "정시로 사용함",
                  ]}
                />

                <div
                  className=""
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    height: 20,
                    fontWeight: 700,
                  }}
                >
                  ■ 앉은방위, 판단장소 기준 방문자(전화포함) 거주 방위
                </div>
                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                  }}
                >
                  {Array.from([
                    "정<br/>북",
                    "북<br/>북동",
                    "동<br/>북",
                    "정<br/>동",
                    "동<br/>남",
                    "남<br/>남동",
                    "정<br/>남",
                    "남<br/>남서",
                    "서<br/>남",
                    "정<br/>서",
                    "서<br/>북",
                    "북<br/>북서",
                  ]).map((arrayItem, arrayIndex) => {
                    return (
                      <Button0
                        className={
                          arrayIndex == intSelectedIndex2
                            ? "activeitem item0 item" + arrayIndex
                            : "item0 item" + arrayIndex
                        }
                        onClick={() => {
                          specificPersonValue(arrayIndex);
                        }}
                        style={{
                          height: 40,
                          fontSize: 14,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 12)`,
                          background:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#FF869E"
                              : `#5CA6E8`,

                          color:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#000"
                              : `#fff`,
                        }}
                      >
                        {parse(arrayItem)}
                        {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                      </Button0>
                    );
                  })}
                </div>

                <div
                  className="maintable_row0 "
                  style={{
                    height: `80px !important`,
                    minHeight: `80px !important`,
                    maxHeight: `80px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    fontWeight: 700,
                    fontSize: 14,
                    marginTop: 10,
                    marginBottom: 12,
                    borderBottom: `1px solid #fff`,
                  }}
                >
                  ■ 明暗 즉단법
                </div>
                <div
                  className="maintable_row0 "
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    fontWeight: 700,
                    fontSize: 14,
                    marginTop: 0,
                    borderBottom: `1px solid #fff`,
                  }}
                ></div>

                <div
                  className=""
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    height: 20,
                    fontWeight: 700,
                  }}
                >
                  ■ 선택된 시간 (정시 차객 숫자 방위 적용){" "}
                </div>
                <br />
                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                  }}
                >
                  {Array.from([...ganji12_0]).map((arrayItem, arrayIndex) => {
                    return (
                      <Button0
                        className={
                          arrayIndex == intSelectedIndex2
                            ? "activeitem item0 item" + arrayIndex
                            : "item0 item" + arrayIndex
                        }
                        onClick={() => {
                          specificPersonValue(arrayIndex);
                        }}
                        style={{
                          height: 30,
                          fontSize: 14,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 12)`,

                          background:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#FF869E"
                              : `#5CA6E8`,

                          color:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#000"
                              : `#fff`,
                        }}
                      >
                        {/* {arrayItem.substring(0,1)} */}
                        {arrayItem.replace("(", "").replace(")", "")}
                        {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                      </Button0>
                    );
                  })}
                  <br />
                  <br />
                  <br />
                </div>

                <div
                  className=""
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    height: 20,
                    fontWeight: 700,
                  }}
                >
                  ■ 明暗 정도&nbsp;&nbsp;&nbsp;&nbsp;(운세 지점 및 조정)
                </div>
                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                  }}
                >
                  {Array.from([
                    "暗<br/>절정",
                    "暗<br/>하향",
                    "暗<br/>종점",
                    "明<br/>시작",
                    "明<br/>진행",
                    "明<br/>확장",
                    "明<br/>절정",
                    "明<br/>하향",
                    "明<br/>종점",
                    "暗<br/>시작",
                    "暗<br/>진행",
                    "暗<br/>확장",
                  ]).map((arrayItem, arrayIndex) => {
                    return (
                      <Button0
                        className={
                          arrayIndex == intSelectedIndex2
                            ? "activeitem item0 item" + arrayIndex
                            : "item0 item" + arrayIndex
                        }
                        // onClick={()=> {
                        //     specificPersonValue(arrayIndex);
                        // }}
                        style={{
                          height: 40,
                          fontSize: 14,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 12)`,
                          background:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#FF869E"
                              : `#5CA6E8`,

                          color:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#000"
                              : `#fff`,
                        }}
                      >
                        {parse(arrayItem)}
                        {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                      </Button0>
                    );
                  })}
                </div>

                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    marginTop: 0,
                    flexWrap: "nowrap",
                    borderBottom: `1px solid #fff`,
                  }}
                >
                  {Array.from([
                    "이해<br/>관계",
                    "전환<br/>암약",
                    "기회<br/>준비",
                    "최대<br/>진취",
                    "발전<br/>세력",
                    "확장<br/>조직",
                    "현재<br/>상태",
                    "축소<br/>지향",
                    "적극<br/>활동",
                    "막힘<br/>대비",
                    "회귀<br/>욕망",
                    "계획<br/>신규",
                  ]).map((arrayItem, arrayIndex) => {
                    return (
                      <Button0
                        className={
                          arrayIndex == intSelectedIndex2
                            ? "activeitem item0 item" + arrayIndex
                            : "item0 item" + arrayIndex
                        }
                        // onClick={()=> {
                        //     specificPersonValue(arrayIndex);
                        // }}
                        style={{
                          height: 40,
                          fontSize: 12,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 12)`,
                          background:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#FF869E"
                              : `#5CA6E8`,
                          color:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#000"
                              : `#000`,
                          // background : 'transparent'
                        }}
                      >
                        {parse(arrayItem)}
                        {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                      </Button0>
                    );
                  })}
                </div>
                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    marginTop: 0,
                    flexWrap: "nowrap",
                  }}
                >
                  {Array.from([
                    "단절<br/>필요",
                    "시도<br/>필요",
                    "조건<br/>필요",
                    "분발<br/>필요",
                    "독려<br/>필요",
                    "구축<br/>필요",
                    "유지<br/>필요",
                    "감세<br/>필요",
                    "중지<br/>필요",
                    "축소<br/>필요",
                    "불가<br/>필요",
                    "금지<br/>필요",
                  ]).map((arrayItem, arrayIndex) => {
                    return (
                      <Button0
                        className={
                          arrayIndex == intSelectedIndex2
                            ? "activeitem item0 item" + arrayIndex
                            : "item0 item" + arrayIndex
                        }
                        // onClick={()=> {
                        //     specificPersonValue(arrayIndex);
                        // }}
                        style={{
                          height: 40,
                          fontSize: 12,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 12)`,
                          background:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#FF869E"
                              : `#5CA6E8`,
                          color:
                            arrayIndex >= 3 && arrayIndex <= 8
                              ? "#000"
                              : `#000`,
                          // background : 'transparent'
                        }}
                      >
                        {parse(arrayItem)}
                        {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                      </Button0>
                    );
                  })}
                </div>

                {
                  //20240214 - 귀신,혼령 부적 등의 버튼 4개 시간 선택 위로 이동 됨
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                  //20240214
                }

                <SiboStatus
                  setBoolMagnifyPopup={setBoolMagnifyPopup}
                  setObjectMagnify={setObjectMagnify}
                  selectedNaejung={selectedNaejung}
                  selectedSiIndex={selectedSiIndex}
                  yookimGuide={yookimGuide}
                  selectedGook={selectedGook}
                  selectedSiIndex1={selectedSiIndex1}
                  setHtmlPopupContent={setHtmlPopupContent}
                  setPopupTitle={setPopupTitle}
                  setPopupContents={setPopupContents}
                  selectedGapjaIndex_ILSAENG={selectedGapjaIndex_ILSAENG}
                  isMyOpponentSForce={isMyOpponentSForce}
                />

                <div className="maintable_row0 maintable_row3">
                  {/* [3][0], [3][3], [3][6] */}
                  {/* 3부터 시작해서 5씩 추가 [3+5n][0] [3+5n][3] [3+5n][6]  */}
                  <CurrentSituation
                    setBoolMagnifyPopup={setBoolMagnifyPopup}
                    yookimGuide={yookimGuide}
                    setObjectMagnify={setObjectMagnify}
                    selectedNaejung={selectedNaejung}
                    sootJaIndex={sootJaIndex}
                    selectedGapjaIndex={selectedGapjaIndex}
                    selectedSiIndex={selectedSiIndex}
                    selectedGook={selectedGook}
                    currentSituationData={
                      isMyOpponentSForce
                        ? objectGui?.default?.VERSION002_GWA_001
                        : objectGui?.default?.VERSION001_GWA_001
                    }
                  />

                  <div
                    className="maintable_column0 maintable_column3"
                    onClick={() => {
                      setBoolMagnifyPopup(true);

                      if (yookimGuide == "100") {
                        setObjectMagnify({
                          title: "구체성",
                          desc:
                            selectedNaejung &&
                            selectedNaejung.default &&
                            selectedNaejung.default.columnAU[
                              5 * (sootJaIndex - 1) + 3
                            ] &&
                            selectedNaejung.default.columnAU[
                              5 * (sootJaIndex - 1) + 3
                            ][3]
                              .replaceAll("@", "")
                              .replaceAll("#", "")
                              .replaceAll("=", "")
                              .replaceAll("+", ""),
                        });
                      }
                      if (yookimGuide.indexOf("101") > -1) {
                        setObjectMagnify({
                          title: "구체성",
                          desc: `
                                                <br/>
                                                ${
                                                  selectedGapjaIndex > -1 &&
                                                  selectedSiIndex > -1 &&
                                                  objectGui &&
                                                  objectGui.default &&
                                                  objectGui.default
                                                    .VERSION001_GYEOK &&
                                                  objectGui.default.VERSION001_GYEOK[
                                                    12 *
                                                      (selectedGapjaIndex - 1) +
                                                      selectedGook -
                                                      1
                                                  ][0]
                                                    .replaceAll("@", "")
                                                    .replaceAll("#", "")
                                                    .replaceAll("=", "")
                                                    .replaceAll("+", "")
                                                }`,
                        });
                      }
                    }}
                  >
                    <div className="label0">구체성</div>
                    <div
                      className="value0"
                      style={{
                        marginTop: `30px`,
                        marginLeft: `8px`,
                        minHeight:
                          yookimGuide.indexOf("101") > -1 ? `334px` : `184px`,
                        height: `auto`,
                        textAlign: "left",
                      }}
                    >
                      {yookimGuide == "100" && (
                        <>
                          {parse(
                            `${
                              selectedNaejung &&
                              selectedNaejung.default &&
                              selectedNaejung.default.columnAU[
                                5 * (sootJaIndex - 1) + 3
                              ] &&
                              selectedNaejung.default.columnAU[
                                5 * (sootJaIndex - 1) + 3
                              ][3]
                                .replaceAll("@", "")
                                .replaceAll("#", "")
                                .replaceAll("=", "")
                                .replaceAll("+", "")
                            }`
                          )}
                          <br />
                          <br />
                        </>
                      )}
                      {yookimGuide.indexOf("101") > -1 && (
                        <>
                          <br />
                          {selectedGapjaIndex > -1 &&
                            selectedSiIndex > -1 &&
                            objectGui &&
                            objectGui.default &&
                            objectGui.default.VERSION001_GYEOK &&
                            objectGui &&
                            objectGui.default &&
                            objectGui.default.VERSION001_GYEOK &&
                            objectGui.default.VERSION001_GYEOK[
                              12 * (selectedGapjaIndex - 1) + selectedGook - 1
                            ] &&
                            objectGui.default.VERSION001_GYEOK[
                              12 * (selectedGapjaIndex - 1) + selectedGook - 1
                            ][0]
                              .replaceAll("@", "")
                              .replaceAll("#", "")
                              .replaceAll("=", "")
                              .replaceAll("+", "")}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="maintable_column0 maintable_column3_1"
                    onClick={() => {
                      setBoolMagnifyPopup(true);
                      setObjectMagnify({
                        title: "적확성",
                        desc:
                          selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                          ][6],
                      });
                    }}
                  >
                    <div className="label0">적확성</div>
                    <div
                      className="value0"
                      style={{
                        marginTop: `30px`,
                        marginLeft: `8px`,
                        minHeight:
                          yookimGuide.indexOf("101") > -1 ? `334px` : `184px`,
                        height: `auto`,
                        textAlign: "left",
                      }}
                    >
                      <br />
                      {parse(
                        `${
                          selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnAU[
                            5 * (sootJaIndex - 1) + 3
                          ][6]
                        }`
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="maintable_row0 maintable_row3"
                  style={{ alignItems: "stretch" }}
                >
                  {/* [3][0], [3][3], [3][6] */}
                  {/* 3부터 시작해서 5씩 추가 [3+5n][0] [3+5n][3] [3+5n][6]  */}

                  {/* 세밀동정 */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      className="maintable_column0 maintable_column3"
                      onClick={() => {
                        setBoolMagnifyPopup(true);
                        setObjectMagnify({
                          title: "세밀동정",
                          desc: `    
                                            
                                        <span className="label0" style="font-weight: 700;">득실사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][0]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">아타사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][1]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">미기사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][2]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">신구사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][3]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">내외사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][4]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">표리사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][5]
                                        }<br/><br/>
                                        <span className="label0"  style="font-weight: 700;">층배사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][6]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">천부성</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][7]
                                        }<br/><br/>
                                        <span className="label0" style="font-weight: 700;">진위사</span><br/> ${
                                          selectedNaejung &&
                                          selectedNaejung.default &&
                                          selectedNaejung.default.columnBE[
                                            5 * (sootJaIndex - 1) + 3
                                          ][8]
                                        }<br/> <br/>

                                            `,
                        });
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="label0">세밀동정</div>
                      <div
                        className="value0"
                        style={{
                          marginTop: `30px`,
                          marginLeft: `8px`,
                          textAlign: "left",
                          // height: `243px`,
                        }}
                      >
                        {/* {parse(`${selectedNaejung && selectedNaejung.default && selectedNaejung.default.columnAU[5 * selectedSiIndex + 3][0]}`)} */}
                        <span className="label0">득실사</span> :{" "}
                        <span style={{ fontWeight: 700 }}>
                          {selectedNaejung &&
                            selectedNaejung.default &&
                            selectedNaejung.default.columnBE[
                              5 * (sootJaIndex - 1) + 3
                            ] &&
                            selectedNaejung.default.columnBE[
                              5 * (sootJaIndex - 1) + 3
                            ][0]}
                        </span>
                        <br />
                        <span className="label0">아타사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][1]}
                        <br />
                        <span className="label0">미기사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][2]}
                        <br />
                        <span className="label0">신구사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][3]}
                        <br />
                        <span className="label0">내외사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][4]}
                        <br />
                        <span className="label0">표리사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][5]}
                        <br />
                        <span className="label0">층배사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][6]}
                        <br />
                        <span className="label0">천부성</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][7]}
                        <br />
                        <span className="label0">진위사</span> :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBE[
                            5 * (sootJaIndex - 1) + 3
                          ][8]}
                        <br />
                      </div>

                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            // width: `100%`,
                            // minWidth: `calc(100% - 12px)`,
                            // maxWidth: `calc(100% - 12px)`,
                            height: `24px`,
                            background: `#8d8daa`,

                            background: !(
                              selectedSiIndex1 == 1 ||
                              selectedSiIndex1 == 2 ||
                              selectedSiIndex1 == 3 ||
                              selectedSiIndex1 == 10 ||
                              selectedSiIndex1 == 11 ||
                              selectedSiIndex1 == 12
                            )
                              ? `#B93160`
                              : `#1F4690`,
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            color: "#fff",
                            border: `1px solid #000`,
                            marginTop: "20px",
                            marginRight: "5px",
                            marginLeft: "5px",
                            marginBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bottom: 12,
                            fontSize: 14,
                            fontWeight: 700,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setHtmlPopupContent("승부좌법");
                          }}
                        >
                          승부좌법
                        </div>
                      </div>
                    </div>

                    <div
                      className="maintable_column0 maintable_column3"
                      onClick={() => {
                        setBoolMagnifyPopup(true);
                        setObjectMagnify({
                          title: "방문자생업가정흥쇠",
                          desc: `    
                                            <span className="label0" style="font-weight: 700;">생업</span><br/> ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnBO[
                                                5 * (sootJaIndex - 1) + 3
                                              ][0]
                                            }<br/><br/>
                                            <span className="label0" style="font-weight: 700;">가정</span><br/> ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnBO[
                                                5 * (sootJaIndex - 1) + 3
                                              ][1]
                                            }<br/><br/>
                                            `,
                        });
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="label0">생업가정흥쇠</div>
                      <div
                        className="value0"
                        style={{
                          marginTop: `30px`,
                          marginLeft: `8px`,
                          marginBottom: "auto",
                          // height: `243px`,
                          textAlign: "left",
                        }}
                      >
                        <br />
                        <span className="label0" style={{ fontWeight: "700" }}>
                          생업
                        </span>{" "}
                        :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBO[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBO[
                            5 * (sootJaIndex - 1) + 3
                          ][0]}
                        <br />
                        <br />
                        <span className="label0" style={{ fontWeight: "700" }}>
                          가정
                        </span>{" "}
                        :{" "}
                        {selectedNaejung &&
                          selectedNaejung.default &&
                          selectedNaejung.default.columnBO[
                            5 * (sootJaIndex - 1) + 3
                          ] &&
                          selectedNaejung.default.columnBO[
                            5 * (sootJaIndex - 1) + 3
                          ][1]}
                        <br />
                        {yookimGuide == "100" && (
                          <div
                            style={{
                              width: `100%`,
                              minWidth: `calc(100% - 12px)`,
                              maxWidth: `calc(100% - 12px)`,
                              height: `24px`,
                              // background: `#8d8daa`,

                              background: !(
                                selectedSiIndex1 == 1 ||
                                selectedSiIndex1 == 2 ||
                                selectedSiIndex1 == 3 ||
                                selectedSiIndex1 == 10 ||
                                selectedSiIndex1 == 11 ||
                                selectedSiIndex1 == 12
                              )
                                ? `#B93160`
                                : `#1F4690`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              border: `1px solid #000`,
                              marginTop: "auto",
                              // position: "absolute",
                              bottom: yookimGuide == "100" ? 12 : 40,
                              marginLeft: -4,

                              fontWeight: 700,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setHtmlPopupContent("일시별문제즉단");
                            }}
                          >
                            일시별운세즉단
                          </div>
                        )}
                      </div>
                      {yookimGuide.indexOf("101") > -1 && (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              // width: `100%`,
                              // minWidth: `calc(100% - 12px)`,
                              // maxWidth: `calc(100% - 12px)`,
                              height: `24px`,
                              // background: `#8d8daa`,

                              background: !(
                                selectedSiIndex1 == 1 ||
                                selectedSiIndex1 == 2 ||
                                selectedSiIndex1 == 3 ||
                                selectedSiIndex1 == 10 ||
                                selectedSiIndex1 == 11 ||
                                selectedSiIndex1 == 12
                              )
                                ? `#B93160`
                                : `#1F4690`,
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                              color: "#fff",
                              border: `1px solid #000`,
                              marginTop: "20px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bottom: 12,

                              fontWeight: 700,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setHtmlPopupContent("대적_처신_방침");
                            }}
                          >
                            대적 처신 방침
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="maintable_column0 maintable_column3_1"
                      onClick={() => {
                        setBoolMagnifyPopup(true);
                        setObjectMagnify({
                          title: "소육임",
                          desc: `    
                                            <span className="label0" style="font-weight: 700;">월</span><br/> ${soYook1}<br/><br/>
                                            <span className="label0" style="font-weight: 700;">일</span><br/> ${soYook2}<br/><br/>
                                            <span className="label0" style="font-weight: 700;">시</span><br/> ${soYook3}<br/><br/>
                                            `,
                        });
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="label0">소육임</div>
                      <div
                        className="value0"
                        style={{
                          // width: `calc(100% - 10px)`,
                          marginTop: `30px`,
                          marginLeft: `8px`,
                          // height: `243px`,
                          textAlign: "left",
                          position: "relative",
                        }}
                      >
                        <br />
                        {selectedGapjaIndex && (
                          <>
                            {parse(
                              `
                                                    <span className="label0">월</span> : ${soYook1}<br/>
                                                    <span className="label0">일</span> : ${soYook2}<br/>
                                                    <span className="label0">시</span> : ${soYook3}<br/>
                                                    `
                            )}
                          </>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            // width: `100%`,
                            // minWidth: `calc(100% - 12px)`,
                            // maxWidth: `calc(100% - 12px)`,
                            height: `24px`,
                            // background: `#8d8daa`,

                            background: !(
                              selectedSiIndex1 == 1 ||
                              selectedSiIndex1 == 2 ||
                              selectedSiIndex1 == 3 ||
                              selectedSiIndex1 == 10 ||
                              selectedSiIndex1 == 11 ||
                              selectedSiIndex1 == 12
                            )
                              ? `#B93160`
                              : `#1F4690`,
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            color: "#fff",
                            border: `1px solid #000`,
                            marginTop: "20px",
                            marginRight: "5px",
                            marginLeft: "5px",
                            marginBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bottom: 12,

                            fontWeight: 700,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setHtmlPopupContent("이해보기");
                          }}
                        >
                          이해보기
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {yookimGuide && yookimGuide.indexOf("101_1") > -1 && (
                  <>
                    <TimeSelect
                      ganji12_0={ganji12_0}
                      time12_0={time12_0}
                      ganji12_2={ganji12_2}
                      intSelectedIndex2={intSelectedIndex2}
                      specificPersonValue={specificPersonValue}
                      parse={parse}
                      headerTitle="각 시간"
                      timeTitleList={["상호방문", "다음사람", "정시 사용"]}
                    />

                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        marginTop: 12,
                        width: "100%",
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      ■ 신분 우고자 판단
                    </div>
                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 극귀자&nbsp;&nbsp;&nbsp;&nbsp;(해당 년 왕상 월 왕상
                      시간)
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `120px !important`,
                        minHeight: `120px !important`,
                        maxHeight: `120px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_1]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 100,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>

                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 차귀人&nbsp;&nbsp;&nbsp;&nbsp;(해당 월 왕상 시간)
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `40px !important`,
                        minHeight: `40px !important`,
                        maxHeight: `40px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_2]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 40,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>

                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 고위人&nbsp;&nbsp;&nbsp;&nbsp;(해당 시간 중기 기간 내
                      기준)
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `60px !important`,
                        minHeight: `60px !important`,
                        maxHeight: `60px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_3]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 60,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>

                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 力人&nbsp;&nbsp;&nbsp;&nbsp;(해당 왕상 시간 왕상 계절)
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `60px !important`,
                        minHeight: `60px !important`,
                        maxHeight: `60px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_4]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 50,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>
                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 우고人&nbsp;&nbsp;&nbsp;&nbsp;(해당 시간 휴(퇴) 수
                      (무모) 사 (폐) 계절){" "}
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `110px !important`,
                        minHeight: `60px !important`,
                        maxHeight: `60px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                        borderBottom: `none`,
                      }}
                    >
                      {Array.from([...time12_5]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 100,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                                borderBottom:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "1px solid #FF869E"
                                    : `1px solid #5CA6E8`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `24px !important`,
                        minHeight: `24px !important`,
                        maxHeight: `24px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                        borderTop: `none`,
                      }}
                    >
                      {Array.from([...time12_5_1]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 20,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                                borderTop:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "1px solid #FF869E"
                                    : `1px solid #5CA6E8`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>

                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      <span>■ 종교·활인 성공(할)人</span>
                      <span style={{ marginLeft: 8 }}>
                        (해당시간 각 순 공망 지지 왕상)
                      </span>
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `60px !important`,
                        minHeight: `60px !important`,
                        maxHeight: `60px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_6]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 120,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>
                    <div
                      className=""
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        height: 20,
                        fontWeight: 700,
                      }}
                    >
                      ■ 고뇌해소(될)人&nbsp;&nbsp;&nbsp;&nbsp;(해당시간 각순
                      의신, 기신, 공망, 덕신)
                    </div>

                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `40px !important`,
                        minHeight: `40px !important`,
                        maxHeight: `40px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_6_1]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 40,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>
                    <div
                      className="maintable_row0 maintable_row3"
                      style={{
                        height: `130px !important`,
                        minHeight: `60px !important`,
                        maxHeight: `130px !important`,
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        flexWrap: "nowrap",
                      }}
                    >
                      {Array.from([...time12_7]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <Button0
                              className={
                                arrayIndex == intSelectedIndex2
                                  ? "activeitem item0 item" + arrayIndex
                                  : "item0 item" + arrayIndex
                              }
                              onClick={() => {
                                specificPersonValue(arrayIndex);
                              }}
                              style={{
                                height: 140,
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                width: `calc(100% / 12)`,

                                background:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#FF869E"
                                    : `#5CA6E8`,

                                color:
                                  arrayIndex >= 3 && arrayIndex <= 8
                                    ? "#000"
                                    : `#fff`,
                              }}
                            >
                              {/* {arrayItem.substring(0,1)} */}
                              {parse(
                                arrayItem.replace("(", "").replace(")", "")
                              )}
                              {/* {arrayIndex == intSelectedIndex1 && <>&nbsp;&nbsp;&#x2714;</>} */}
                            </Button0>
                          );
                        }
                      )}
                    </div>
                  </>
                )}

                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `40px !important`,
                    minHeight: `40px !important`,
                    maxHeight: `40px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    marginTop: 18,
                  }}
                >
                  {Array.from([
                    "가택부정",
                    "침범귀침",
                    "옥추부",
                    "신명법",
                    "흉재해소",
                    "소망사",
                    "이사길방",
                    "택일",
                  ]).map((arrayItem, arrayIndex) => {
                    return (
                      <div
                        className={`button0_8`}
                        onClick={() => {
                          if (arrayIndex == 0) {
                            setHtmlPopupContent("가택부정 진압법");
                          }
                          if (arrayIndex == 1) {
                            setHtmlPopupContent("귀신침법");
                          }
                          if (arrayIndex == 2) {
                            setHtmlPopupContent("옥추부");
                          }
                          if (arrayIndex == 3) {
                            setHtmlPopupContent("신명법");
                          }
                          if (arrayIndex == 4) {
                            //
                            // {parse(` ${selectedNaejung && selectedNaejung.default && selectedNaejung.default.columnD[5][7]}<br/>`)}
                            setHtmlPopupContent(`


                                                            <div style="font-size: 24px;">
                                                            흉재 해소
                                                            </div>
                                                            <br/><br/>
                                                            <br/><br/>
                                                            
                                                            <br/><br/><div style="font-size: 18px;">고뇌 해소 시기 : ${
                                                              selectedNaejung &&
                                                              selectedNaejung.default &&
                                                              selectedNaejung
                                                                .default
                                                                .columnD[
                                                                5 *
                                                                  (selectedSiIndex +
                                                                    1)
                                                              ][8]
                                                            } (일월년)</div>
                                                            <br/>
                                                            <div style="font-size: 13px; text-align: left; width: 150px; margin-left: auto; margin-right: auto;">왕상 : 일로 판단<br/>휴수 : 왕상되는 월로 판단<br/>사안의 크기로 년 판단 </div>
                                                            
                                                            `);
                          }
                          if (arrayIndex == 5) {
                            setHtmlPopupContent(`
                                                            

                                                            <div style="font-size: 24px;">
                                                            소망사
                                                            </div>
                                                            <br/><br/>
                                                            <br/><br/>
                                                            
                                                            <div style="font-size: 18px;">
                                                            소망 달성 시기 :&nbsp;&nbsp;&nbsp;&nbsp;
                                                            ${
                                                              selectedNaejung &&
                                                              selectedNaejung.default &&
                                                              selectedNaejung
                                                                .default
                                                                .columnD[
                                                                5 *
                                                                  (selectedSiIndex +
                                                                    1)
                                                              ][9]
                                                            }
                                                            &nbsp;&nbsp;&nbsp;&nbsp;(일 월 년)
                                                            </div>
                                                            <br/>
                                                            <div style="font-size: 13px; text-align: left; width: 150px; margin-left: auto; margin-right: auto;">왕상 : 일로 판단<br/>휴수 : 왕상되는 월로 판단<br/>사안의 크기로 년 판단 </div>
                                                            
                                                            `);
                          }
                          if (arrayIndex == 6) {
                            // console.log(
                            //   "objectGui.default.ILSAENGSA_COMBINED[(12 * (((selectedGapjaIndex - 1 ))) + selectedSiIndex) -1][1] ",
                            //   objectGui.default.ILSAENGSA_COMBINED[
                            //     12 * (selectedGapjaIndex - 1) +
                            //       selectedSiIndex -
                            //       1
                            //   ][1]
                            // );
                            const text1 =
                              `
                                                            
                                                            <div style="font-size: 24px;font-weight: 700">이사길방</div><br/>
                                                            <div style="font-size: 18px;">${
                                                              // selectedGapjaIndex &&
                                                              // selectedSiIndex &&
                                                              selectedGapjaIndex >
                                                                -1 &&
                                                              selectedSiIndex >
                                                                -1 &&
                                                              objectGui &&
                                                              objectGui.default &&
                                                              objectGui.default
                                                                .ILSAENGSA_COMBINED &&
                                                              objectGui.default
                                                                .ILSAENGSA_COMBINED[
                                                                12 *
                                                                  (selectedGapjaIndex -
                                                                    1) +
                                                                  selectedSiIndex -
                                                                  1
                                                              ][1]
                                                            }` +
                              " 방위</div>" +
                              `
                                                            <div style="font-size: 14px;">
                                                            <br/><br/>
                                                            위 방위로 다음 택일에 이사하면<br/>반드시 이사 방위 월 또는 년에 길사를 맞게 됨.<br/>단, 우측에 명시된 택일을 준수해야함
                                                            </div>
                                                            
                                                            `;

                            setHtmlPopupContent(text1);
                          }
                          if (arrayIndex == 7) {
                            const text1 =
                              `
                                                            
                                                            <div style="font-size: 24px;font-weight: 700">택일</div><br/>
                                                            <div style="font-size: 18px;">${
                                                              // selectedGapjaIndex &&
                                                              // selectedSiIndex &&
                                                              selectedGapjaIndex >
                                                                -1 &&
                                                              selectedSiIndex >
                                                                -1 &&
                                                              objectGui &&
                                                              objectGui.default &&
                                                              objectGui.default
                                                                .ILSAENGSA_COMBINED &&
                                                              objectGui.default
                                                                .ILSAENGSA_COMBINED[
                                                                12 *
                                                                  (selectedGapjaIndex -
                                                                    1) +
                                                                  selectedSiIndex -
                                                                  1
                                                              ][2]
                                                            }` +
                              " 일</div>" +
                              `
                                                            <div style="font-size: 14px;">
                                                            <br/><br/>
                                                            
                                                            본 택일에 좌 길방으로 이사하면<br/> 이사한 방위 월 또는 년에 반드시 길사를 맞게 됨.<br/> 단, 명시된 방위를 준수해야함
                                                            </div>
                                                            
                                                            `;

                            setHtmlPopupContent(text1);
                          }
                        }}
                        style={{
                          height: 30,
                          fontSize: 12,
                          fontWeight: 900,
                          padding: 0,
                          width: `calc(100% / 8)`,

                          fontWeight: 700,
                        }}
                      >
                        {arrayItem}
                      </div>
                    );
                  })}
                  <div style={{ width: 4 }}></div>
                </div>
                {/* 당면사/ 일생사 메뉴 */}
                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: 20,
                  }}
                >
                  <div
                    className="maintable_column0 maintable_column1"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      height: 585,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="label0"
                      style={{
                        fontSize: 14,
                        width: `calc(100% - 20px)`,
                        textAlign: "center",
                      }}
                    >
                      &#x2752; 당면사
                    </div>
                    <div
                      className="value0"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginTop: 44,
                        minHeight: 505,
                        overflow: "hidden",
                      }}
                    >
                      {Array.from([...newmenu1]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <div
                              className={" menuitem0 menuitem" + arrayIndex}
                              style={{
                                alignItems: "flex-start",
                                width: "100%",
                                textAlign: "left",
                                paddingBottom: `0px`,
                                marginBottom: `12px`,
                                overflow: "hidden",
                                fontWeight: "700",
                                fontSize: 15,
                              }}
                            >
                              <span
                                onClick={() => {
                                  if (arrayIndex == 0) {
                                    const returnString1 = [0, 1, 2, 3, 4]
                                      .map((mapItem, mapIndex) => {
                                        const returnValue = ``;
                                        return (
                                          returnValue +
                                          `
                                                                    <br/><br/>    
                                                                    &nbsp;&nbsp;
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex; ">
                                                                        방문음력月별
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][0]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: 50%; margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        방문(전화포함)자 당면세력
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][1]
                                                                        }
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][2]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        현재종사분야 성쇠 大中小
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][3]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    회복 공전 주기月年
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%;margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][4]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        천부적 성질
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto;  text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              mapItem +
                                                                              5
                                                                          ][5]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    상대천부성여부
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              5
                                                                          ][6]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    본인에 대한 방문자의 신뢰도
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%; margin-left: auto;  text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              5
                                                                          ][7]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    고뇌사해소시기 日月年
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              5
                                                                          ][8]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    소망사달성시기 日月年
                                                                        <div style="display : inline-flex; font-weight: 700;  width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              5
                                                                          ][9]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    기도효과여부
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnD[
                                                                            5 *
                                                                              selectedSiIndex +
                                                                              5
                                                                          ][10]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    &nbsp;&nbsp;
                                                                <br/><br/>
                                                                `
                                        );
                                      })
                                      .join("<br/>");
                                    // console.log("returnString1")
                                    // console.log(returnString1)
                                    setHtmlPopupContent(
                                      `
                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>` +
                                        returnString1
                                    );
                                  }
                                  if (arrayIndex == 1) {
                                    // console.log("selectedNaejung.default.columnZ",selectedNaejung.default.columnZ)

                                    setHtmlPopupContent(`

                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span>  
                                                                <br></br> 
                                                                <br/><br/>
                                                                <span  style="font-size : 15px;">
                                                                명암법상 다음사람은
                                                                <span style="font-weight: 700;"> ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][0] &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][0] != "0"
                                                                    ? selectedNaejung
                                                                        .default
                                                                        .columnZ[
                                                                        5 *
                                                                          selectedSiIndex +
                                                                          5
                                                                      ][0]
                                                                    : ""
                                                                }&nbsp;&nbsp;&nbsp;</span>
                                                                <br/>
                                                                <span style="font-weight: 700;">${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][1] &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][1] != "0"
                                                                    ? selectedNaejung
                                                                        .default
                                                                        .columnZ[
                                                                        5 *
                                                                          selectedSiIndex +
                                                                          5
                                                                      ][1]
                                                                    : ""
                                                                }&nbsp;&nbsp;&nbsp;</span>
                                                                <br/><br/>
                                                                생극법상 현재 사안 가부는
                                                                <span style="font-weight: 700;">${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][3] &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][3] != "0"
                                                                    ? selectedNaejung.default.columnZ[
                                                                        5 *
                                                                          selectedSiIndex +
                                                                          5
                                                                      ][3]
                                                                        .replaceAll(
                                                                          "불가",
                                                                          "<br/>불가"
                                                                        )
                                                                        .replaceAll(
                                                                          "가능",
                                                                          "<br/>가능"
                                                                        )
                                                                    : ""
                                                                }&nbsp;&nbsp;&nbsp;</span>
                                                                <br/>
                                                                <span style="font-weight: 700;">${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][4] &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnZ[
                                                                    5 *
                                                                      selectedSiIndex +
                                                                      5
                                                                  ][4] != "0"
                                                                    ? selectedNaejung.default.columnZ[
                                                                        5 *
                                                                          selectedSiIndex +
                                                                          5
                                                                      ][4]
                                                                        .replaceAll(
                                                                          "불가",
                                                                          "<br/>불가"
                                                                        )
                                                                        .replaceAll(
                                                                          "가능",
                                                                          "<br/>가능"
                                                                        )
                                                                    : ""
                                                                }&nbsp;&nbsp;&nbsp;</span>
                                                                <br/><br/>
                                                                </span>
                                                            `);
                                  }
                                  if (arrayIndex == 2) {
                                    const returnString1 = [0]
                                      .map((mapItem, mapIndex) => {
                                        const returnValue = ``;
                                        return (
                                          returnValue +
                                          `

                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: 50%; margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    <div style="width: 40px;">
                                                                        국가
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    남자
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    수상
                                                                    </div>
                                                                      
                                                                    <div style="display : inline-flex; width: 50%; margin-left: auto; text-align: left;"">
                                                                    <div style="width: 40px;">
                                                                    개인
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    여자
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    수하
                                                                    </div>
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style=" font-weight: 700;   font-family: 'NanumBarunGothic' width: 50%; margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    <div style="width: 40px;">
                                                                    
                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][0]
                                                                    }
                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][0] ==
                                                                        "" &&
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          3
                                                                      ][0] == ""
                                                                        ? "비김"
                                                                        : ""
                                                                    }
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    
                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][1]
                                                                    }

                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][1] ==
                                                                        "" &&
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          3
                                                                      ][1] == ""
                                                                        ? "비김"
                                                                        : ""
                                                                    }
                                                                    </div>
                                                                    <div style="width: 40px;">
                                                                    
                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][2]
                                                                    }
                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][1] ==
                                                                        "" &&
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          3
                                                                      ][2] == ""
                                                                        ? "비김"
                                                                        : ""
                                                                    }
                                                                    </div>
                                                                    <div style="display : inline-flex;   width: 50%; margin-left: auto; text-align: left;"">
                                                                        <div style="width: 40px;">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBS[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              3 +
                                                                              3
                                                                          ][0]
                                                                        }

                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][0] ==
                                                                        "" &&
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          3
                                                                      ][0] == ""
                                                                        ? "비김"
                                                                        : ""
                                                                    }
                                                                        </div>
                                                                        <div style="width: 40px;">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBS[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              3 +
                                                                              3
                                                                          ][1]
                                                                        }

                                                                    ${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          1
                                                                      ][1] ==
                                                                        "" &&
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnBS[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3 +
                                                                          3
                                                                      ][1] == ""
                                                                        ? "비김"
                                                                        : ""
                                                                    }
                                                                        </div>
                                                                        <div style="width: 40px;">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBS[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              3 +
                                                                              3
                                                                          ][2]
                                                                        }
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBS[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              3 +
                                                                              1
                                                                          ][1] ==
                                                                            "" &&
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBS[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              3 +
                                                                              3
                                                                          ][2] ==
                                                                            ""
                                                                            ? "비김"
                                                                            : ""
                                                                        }
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                

                                                                <br/><br/>
                                                                `
                                        );
                                      })
                                      .join("<br/>");
                                    // console.log("returnString1")
                                    // console.log(returnString1)
                                    setHtmlPopupContent(
                                      `
                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>` +
                                        returnString1
                                    );
                                  }
                                  if (arrayIndex == 3) {
                                    const returnString1 = [0]
                                      .map((mapItem, mapIndex) => {
                                        const returnValue = ``;
                                        return (
                                          returnValue +
                                          `
                                                                    <br/><br/>    

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        男 - 용모
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][0]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        男 - 가문
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][1]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        好不 - 6기준
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][2]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    배우시기
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][3]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/><br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        女 - 용모
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][4]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        女 - 가문
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][5]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        好不 - 6기준
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][6]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        배우시기
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnBV[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              0
                                                                          ][7]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    
                                                                <br/><br/>
                                                                `
                                        );
                                      })
                                      .join("<br/>");
                                    // console.log("returnString1")
                                    // console.log(returnString1)
                                    setHtmlPopupContent(
                                      `
                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>` +
                                        returnString1
                                    );
                                  }

                                  if (arrayIndex == 4) {
                                    const returnString1 = [0]
                                      .map((mapItem, mapIndex) => {
                                        const returnValue = ``;
                                        return (
                                          returnValue +
                                          `

                                                                <span style="font-size: 14px; font-family: 'NanumBarunGothic'">
                                                                    <br/><br/>    
                                                                    &nbsp;&nbsp;${
                                                                      selectedNaejung &&
                                                                      selectedNaejung.default &&
                                                                      selectedNaejung
                                                                        .default
                                                                        .columnCE[
                                                                        5 *
                                                                          (sootJaIndex -
                                                                            1) +
                                                                          mapItem +
                                                                          3
                                                                      ][0]
                                                                    }
                                                                    
                                                                <br/><br/>
                                                                </span>
                                                                `
                                        );
                                      })
                                      .join("<br/>");
                                    // console.log("returnString1")
                                    // console.log(returnString1)
                                    setHtmlPopupContent(
                                      `
                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}(성품 능력위주)</span><br/>` +
                                        returnString1
                                    );
                                  }

                                  if (arrayIndex == 5) {
                                    // 06. 계획, 도모 측정 PopupContent (data5_7.js)
                                    const returnString1 =
                                      generateReturnStringForArrayIndex5(
                                        selectedNaejung,
                                        sootJaIndex,
                                        arrayItem
                                      );

                                    setHtmlPopupContent(returnString1);
                                  }
                                  if (arrayIndex == 7) {
                                    const returnString1 = [0]
                                      .map((mapItem, mapIndex) => {
                                        const returnValue = ``;

                                        // 출생연도 : ${selectedNaejung && selectedNaejung.default && selectedNaejung.default.columnDH[5 * selectedSiIndex + mapItem + 6][0]}<br/>
                                        return (
                                          returnValue +
                                          `
                                                                    <br/><br/>    
                                                                    
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        쥐 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        19世紀 24,36, 48, 60, 72, 84
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][1]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][1]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        20世紀 96, 08, 20
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][2]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][2]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        소 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 25, 37, 49, 61, 73, 85
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][3]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][3]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 97, 09, 21
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][4]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][4]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        범 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 26, 38, 50, 62, 74, 86
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][5]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][5]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 98, 10, 2
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][6]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][6]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        토끼 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>



                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 27, 39, 51, 63, 75, 87 
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][7]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][7]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 99, 11, 23
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][8]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][8]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        용 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 28, 40, 52, 64, 76, 88
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][9]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][9]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 00, 12, 24
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][10]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][10]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        뱀 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 29, 41, 53, 65, 77, 89
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][11]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][11]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 01, 13, 25
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][12]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][12]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        말 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>


                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 30, 42, 54, 66, 78, 90
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][13]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][13]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 102, 02, 14, 26
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][14]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][14]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>


                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        양 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>


                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 9, 31, 43, 55, 67, 79
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][15]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][15]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀 91, 03, 15, 27
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][16]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][16]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        원숭이 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 20, 32, 44, 56, 68, 80
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][17]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][17]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀  92, 04, 16, 28
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][18]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][18]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        닭 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>


                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 21, 33, 45, 57, 69, 81
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][19]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][19]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀  93, 05, 17, 29
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][20]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][20]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        개 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>


                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 22, 34, 46, 58, 70, 82
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][21]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][21]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀  94, 06, 18
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][22]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][22]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                        돼지 띠
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        </div>	
                                                                    </div>
                                                                    <br/>

                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    19世紀 23, 35, 47, 59, 71, 83
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][23]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][23]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/>
                                                                    <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    20世紀   95, 07, 19
                                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6
                                                                          ][24]
                                                                        }
                                                                        <br/>
                                                                        ${
                                                                          selectedNaejung &&
                                                                          selectedNaejung.default &&
                                                                          selectedNaejung
                                                                            .default
                                                                            .columnDH[
                                                                            5 *
                                                                              (sootJaIndex -
                                                                                1) +
                                                                              mapItem +
                                                                              6 +
                                                                              3
                                                                          ][24]
                                                                        }
                                                                        </div>	
                                                                    </div>
                                                                    <br/><br/>

                                                            
                                                                    
                                                                <br/><br/>
                                                                `
                                        );
                                      })
                                      .join("<br/>");
                                    // console.log("returnString1")
                                    // console.log(returnString1)
                                    setHtmlPopupContent(
                                      `
                                                                <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>` +
                                        returnString1
                                    );
                                  }
                                  if (arrayIndex == 6) {
                                    setHtmlPopupContent(`
                                                            <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>
                                                            <br/><br/><br/><br/>

                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                귀신
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][1]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][2]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][1]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][2]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][1]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][2]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][1]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][2]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][1]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][2]
                                                                }
                                                                </div>	
                                                            </div>
                                                            <br/>
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            사숭
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][3]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][4]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][3]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][4]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][3]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][4]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][3]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][4]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][3]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][4]
                                                                }
                                                                </div>	
                                                            </div>
                                                            <br/>
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            묘지
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][5]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][6]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][5]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      1
                                                                  ][6]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][5]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      2
                                                                  ][6]
                                                                }<br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][5]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      3
                                                                  ][6]
                                                                }<br/>
                                                                <br/>
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][5]
                                                                }
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3 +
                                                                      4
                                                                  ][6]
                                                                }

                                                                </div>	
                                                            </div>
                                                            <br/>
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            기도수법효과유무
                                                                <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                ${
                                                                  selectedNaejung &&
                                                                  selectedNaejung.default &&
                                                                  selectedNaejung
                                                                    .default
                                                                    .columnCY[
                                                                    5 *
                                                                      (sootJaIndex -
                                                                        1) +
                                                                      3
                                                                  ][7]
                                                                }
                                                                </div>	
                                                            </div>
                                                            <br/>

                                                            <br/><br/><br/><br/>



                                                            `);

                                    setHtmlPopupContentSub("형이상");
                                  }
                                  if (arrayIndex == 8) {
                                    const text1 = `
                                                            
                                                            <span className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic'">${arrayItem}</span><br/>
                                                            <br/><br/><br/><br/>
                                                            
                                                            <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                            남성력
                                                            <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">${
                                                              // selectedGapjaIndex &&
                                                              // selectedSiIndex &&
                                                              selectedGapjaIndex >
                                                                -1 &&
                                                              selectedSiIndex >
                                                                -1 &&
                                                              objectGui &&
                                                              objectGui.default &&
                                                              objectGui.default
                                                                .NEW_TAEWE1 &&
                                                              objectGui.default
                                                                .NEW_TAEWE1[
                                                                selectedSiIndex %
                                                                  11
                                                              ]
                                                            }
                                                            </div>	
                                                        </div>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                        여성력
                                                        <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                        ${
                                                          // selectedGapjaIndex &&
                                                          // selectedSiIndex &&
                                                          selectedGapjaIndex >
                                                            -1 &&
                                                          selectedSiIndex >
                                                            -1 &&
                                                          objectGui &&
                                                          objectGui.default &&
                                                          objectGui.default
                                                            .NEW_TAEWE2 &&
                                                          objectGui.default
                                                            .NEW_TAEWE2[
                                                            selectedSiIndex % 11
                                                          ]
                                                        }

                                                        </div>	
                                                    </div>
 `;
                                    setHtmlPopupContent(text1);
                                    return;
                                  }
                                  if (arrayIndex == 9) {
                                    setHtmlPopupContent("고귀명수");
                                    return;
                                  }
                                  if (
                                    arrayIndex == 10 &&
                                    yookimGuide.indexOf("101") > -1
                                  ) {
                                    setHtmlPopupContent("전화상담즉단");
                                    return;
                                  }
                                  if (
                                    arrayIndex == 11 &&
                                    yookimGuide.indexOf("101") > -1
                                  ) {
                                    setHtmlPopupContent("지두법");
                                    return;
                                  }
                                  // if (arrayIndex == 12 && yookimGuide.indexOf("101") > -1 ) {
                                  //     // setHtmlPopupContent("출산길일길시선택")
                                  //     setHtmlPopupContent("출산길일길시선택");
                                  //     return;
                                  // }
                                }}
                              >
                                {arrayIndex == 10
                                  ? yookimGuide.indexOf("101") > -1
                                    ? "11. 전화상담즉단"
                                    : "-"
                                  : arrayIndex == 11
                                  ? yookimGuide.indexOf("101") > -1
                                    ? "12. 지두생활응용"
                                    : "-"
                                  : arrayIndex == 12
                                  ? yookimGuide.indexOf("101") > -1
                                    ? "-"
                                    : "-"
                                  : arrayItem}

                                {/* {arrayItem} */}
                              </span>
                            </div>
                          );
                        }
                      )}
                      {yookimGuide &&
                        yookimGuide.indexOf("101_1") > -1 &&
                        Array.from([...newmenu101_1]).map(
                          (arrayItem, arrayIndex) => {
                            return (
                              <div
                                className={" menuitem0 menuitem" + arrayIndex}
                                style={{
                                  alignItems: "flex-start",
                                  width: "100%",
                                  textAlign: "left",
                                  paddingBottom: `0px`,
                                  marginBottom: `12px`,
                                  overflow: "hidden",
                                  fontWeight: "700",
                                  fontSize: 15,
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (arrayIndex == 0) {
                                      setHtmlPopupContent("각 일별사");
                                      return;
                                    }
                                    if (arrayIndex == 1) {
                                      setHtmlPopupContent("년년세세");
                                      return;
                                    }
                                    if (arrayIndex == 2) {
                                      setHtmlPopupContent("행운감정");
                                      return;
                                    }
                                  }}
                                >
                                  {arrayItem}
                                </span>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div
                    className="maintable_column0 maintable_column1"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      height: `585px`,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="label0"
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        width: `calc(100% - 20px)`,
                      }}
                    >
                      &#x2752; 일생사
                    </div>
                    <div
                      className="value0"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        marginTop: 44,
                        minHeight: 505,
                        overflow: "hidden",
                      }}
                    >
                      {Array.from([...newmenu2, ""]).map(
                        (arrayItem, arrayIndex) => {
                          return (
                            <div
                              className={"menuitem0 menuitem" + arrayIndex}
                              style={{
                                alignItems: "flex-start",
                                width: "100%",
                                textAlign: "left",
                                paddingBottom: `0px`,
                                marginBottom: `12px`,
                                overflow: "hidden",
                                fontWeight: "700",
                                fontSize: 15,
                              }}
                              onClick={() => {
                                if (
                                  objectGui &&
                                  objectGui.default &&
                                  objectGui.default.ILSAENGSA_COMBINED
                                ) {
                                  var string1 = "";
                                  if (arrayIndex == 0) {
                                    string1 = `
                                                                
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                ${arrayItem}
                                                                </div>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                남
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      selectedGapjaIndex &&
                                                                      selectedSiIndex1 &&
                                                                      selectedGapjaIndex >
                                                                        -1 &&
                                                                      selectedSiIndex1 >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED[
                                                                        12 *
                                                                          (selectedGapjaIndex -
                                                                            1) +
                                                                          (sootJaIndex -
                                                                            1) -
                                                                          1
                                                                      ][3]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                여
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      selectedGapjaIndex &&
                                                                      selectedSiIndex1 &&
                                                                      selectedGapjaIndex >
                                                                        -1 &&
                                                                      selectedSiIndex1 >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED &&
                                                                      objectGui
                                                                        .default
                                                                        .HEADERINFO_ILSAENG_COMBINED[
                                                                        12 *
                                                                          (selectedGapjaIndex -
                                                                            1) +
                                                                          (sootJaIndex -
                                                                            1) -
                                                                          1
                                                                      ][4]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                `;
                                  }
                                  if (arrayIndex == 1) {
                                    setHtmlPopupContent("명운표");
                                  }
                                  // 질병
                                  if (arrayIndex == 2) {
                                    string1 = `
                                                                
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto;  display: flex;text-align: center; align-items: center; justify-content: center;">
                                                                3. 수명, 질병
                                                                </div>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                수명관계
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][20]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "단, ",
                                                                    "단. "
                                                                  )
                                                                  .replaceAll(
                                                                    ",",
                                                                    "<br/>"
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/><br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                질병관계
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][21]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "단, ",
                                                                    "단. "
                                                                  )
                                                                  .replaceAll(
                                                                    ",",
                                                                    "<br/>"
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/><br/>
                                                                `;
                                  }
                                  // 친화, 자녀
                                  if (arrayIndex == 3) {
                                    string1 = `
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto;  display: flex;text-align: center; align-items: center; justify-content: center;">
                                                                ${arrayItem}
                                                                </div>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                자녀원수 복덕 띠
                                                                <br/>
                                                                <br/>

                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][25]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "출산",
                                                                    "\n출산"
                                                                  )
                                                                  .replaceAll(
                                                                    "출산",
                                                                    "<br/>출산"
                                                                  )}
                                                                <br/><br/>
                                                                이는 가업 상속 · 후계지명으로도 판단할 수 있음.
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                
                                                                `;
                                  }
                                  // 현재신상
                                  if (arrayIndex == 4) {
                                    string1 = `
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto;  display: flex;text-align: center; align-items: center; justify-content: center;">
                                                                ${arrayItem}
                                                                </div>
                                                                <br/>
                                                                
                                                                <br/>
                                                                <br/>
                                                                
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    쥐 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][8].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    소 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][9].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    범 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][10]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    토끼 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][11].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    용 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][12].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    뱀 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][13]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    말 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][14]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    양 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][15]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    원숭이 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][16]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    닭 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][17]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    개 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][18]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                    돼지 띠
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 70%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${
                                                                  objectGui
                                                                    .default
                                                                    .ILSAENGSA_COMBINED[
                                                                    12 *
                                                                      (selectedGapjaIndex -
                                                                        1) +
                                                                      (sootJaIndex -
                                                                        1) -
                                                                      1
                                                                  ][19]
                                                                }
                                                                    </div>	
                                                                </div>
                                                                `.replaceAll(
                                      "&&",
                                      "+"
                                    );
                                  }
                                  // 일생침범 귀해
                                  if (arrayIndex == 5) {
                                    string1 = `
                                                                
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto;  display: flex;text-align: center; align-items: center; justify-content: center;">
                                                                ${arrayItem}
                                                                </div>
                                                                <br/>
                                                                
                                                                <br/>
                                                                <br/>
                                                                
                                                                
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                일생침범작사귀
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][22]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "귀",
                                                                    "귀 "
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                일생침범병귀
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][23]
                                                                  .replaceAll(
                                                                    "&&",
                                                                    "+"
                                                                  )
                                                                  .replaceAll(
                                                                    "귀",
                                                                    "귀 "
                                                                  )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                
                                                                <br/>
                                                                
                                                                <br/>
                                                                <br/>
                                                                
                                                                
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                일생침범산소탈
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    
                                                                ${objectGui.default.ILSAENGSA_COMBINED[
                                                                  12 *
                                                                    (selectedGapjaIndex -
                                                                      1) +
                                                                    (sootJaIndex -
                                                                      1) -
                                                                    1
                                                                ][24].replaceAll(
                                                                  "&&",
                                                                  "+"
                                                                )}
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                `;
                                  }
                                  // 남녀성력 및 태위 선호
                                  if (arrayIndex == 6) {
                                    string1 = `
                                                                <div className="label0" style="font-size: 18px; font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto;  display: flex;text-align: center; align-items: center; justify-content: center;">
                                                                ${arrayItem}
                                                                </div>
                                                                <br/>
                                                                
                                                                <br/>
                                                                <br/>
                                                                
                                                                
                                                                
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                남성력
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      // selectedGapjaIndex &&
                                                                      // selectedSiIndex &&
                                                                      selectedGapjaIndex >
                                                                        -1 &&
                                                                      selectedSiIndex >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .NEW_TAEWE1 &&
                                                                      objectGui
                                                                        .default
                                                                        .NEW_TAEWE1[
                                                                        selectedSiIndex %
                                                                          11
                                                                      ]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                <div className="label0" style="font-family: 'NanumBarunGothic' width: calc(100% - 40px); margin-left: 10px; margin-right: auto; text-align: left; display: flex;">
                                                                여성력
                                                                    <div style="display : inline-flex; font-weight: 700;   width: 50%;  margin-left: auto; text-align: left;"">
                                                                    ${
                                                                      // selectedGapjaIndex &&
                                                                      // selectedSiIndex &&
                                                                      selectedGapjaIndex >
                                                                        -1 &&
                                                                      selectedSiIndex >
                                                                        -1 &&
                                                                      objectGui &&
                                                                      objectGui.default &&
                                                                      objectGui
                                                                        .default
                                                                        .NEW_TAEWE2 &&
                                                                      objectGui
                                                                        .default
                                                                        .NEW_TAEWE2[
                                                                        selectedSiIndex %
                                                                          11
                                                                      ]
                                                                    }
                                                                    </div>	
                                                                </div>
                                                                <br/>
                                                                `;
                                  }
                                  // 8~19 (현재신상)
                                  // 20 (수명관계)
                                  // 21 (질병관계)
                                  // 22 (일생침범귀)
                                  // 23 (일생침범산소탈)
                                  // 24 (자녀원수 복덕 띠)
                                }
                                if (arrayIndex == 7) {
                                  setHtmlPopupContent("명좌문신불법");
                                  return;
                                }
                                if (arrayIndex == 8) {
                                  setHtmlPopupContent("육임가묘좌법");
                                  return;
                                }

                                if (
                                  arrayIndex == 9 &&
                                  yookimGuide.indexOf("101") > -1
                                ) {
                                  setHtmlPopupContent("육효64");
                                  return;
                                }

                                if (!(arrayIndex == 1)) {
                                  var displayValue = "";
                                  // console.log(`[${arrayItem}] ${string1}`);
                                  setHtmlPopupContent(string1);
                                }

                                if (arrayIndex == 9 && yookimGuide == "100") {
                                  setHtmlPopupContent("각순별육십갑자");
                                  return;
                                }
                                if (
                                  arrayIndex == 11 &&
                                  yookimGuide.indexOf("101") > -1
                                ) {
                                  setHtmlPopupContent("각순별육십갑자");
                                  return;
                                }
                                if (
                                  arrayIndex == 10 &&
                                  yookimGuide.indexOf("101") > -1
                                ) {
                                  // setHtmlPopupContent("출산길일길시선택")
                                  setHtmlPopupContent("출산길일길시선택");
                                  return;
                                }
                              }}
                            >
                              {
                                // arrayIndex == 9 && yookimGuide.indexOf("101") > -1  ? "10. 육효 "
                                arrayIndex == 9 &&
                                yookimGuide.indexOf("101") > -1
                                  ? "10. 복장상하육효판단 "
                                  : arrayIndex == 9 && yookimGuide == "100"
                                  ? "10. 각 순별 육십갑자"
                                  : arrayIndex == 10 &&
                                    yookimGuide.indexOf("101") > -1
                                  ? "11. 출산길일길시선택"
                                  : arrayIndex == 11 &&
                                    yookimGuide.indexOf("101") > -1
                                  ? "12. 각 순별 육십갑자"
                                  : arrayItem
                              }
                            </div>
                          );
                        }
                      )}
                      {yookimGuide &&
                        yookimGuide.indexOf("101_1") > -1 &&
                        Array.from([...newmenu101_2]).map(
                          (arrayItem, arrayIndex) => {
                            return (
                              <div
                                className={" menuitem0 menuitem" + arrayIndex}
                                style={{
                                  alignItems: "flex-start",
                                  width: "100%",
                                  textAlign: "left",
                                  paddingBottom: `0px`,
                                  marginBottom: `12px`,
                                  overflow: "hidden",
                                  fontWeight: "700",
                                  fontSize: 15,
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    // "13. 부모선후망 처 자녀수",
                                    // "14. 일간 나이별사상",
                                    // "15. 일주별 부부남녀궁합",
                                    if (arrayIndex == 0) {
                                      setHtmlPopupContent(
                                        "출생시각별부모선후망"
                                      );
                                      return;
                                    }
                                    if (arrayIndex == 1) {
                                      setHtmlPopupContent(
                                        "일간천장대나이별사상"
                                      );
                                      return;
                                    }
                                    if (arrayIndex == 2) {
                                      setHtmlPopupContent(
                                        "출생일주별부부남녀궁합"
                                      );
                                      return;
                                    }
                                  }}
                                >
                                  {arrayItem}
                                </span>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>

                <div
                  className="maintable_row0 maintable_row3"
                  style={{
                    height: `110px !important`,
                    minHeight: `24px !important`,
                    maxHeight: `24px !important`,
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    borderBottom: `1px solid #000`,
                    borderTop: `none`,
                  }}
                >
                  <div
                    style={{
                      width: `100%`,
                      minWidth: `calc(50vw - 24px)`,
                      maxWidth: `calc(50vw - 24px)`,
                      height: `24px`,
                      // background: `#8d8daa`,

                      background: !(
                        selectedSiIndex1 == 1 ||
                        selectedSiIndex1 == 2 ||
                        selectedSiIndex1 == 3 ||
                        selectedSiIndex1 == 10 ||
                        selectedSiIndex1 == 11 ||
                        selectedSiIndex1 == 12
                      )
                        ? `#B93160`
                        : `#1F4690`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      border: `1px solid #000`,
                      marginTop: "auto",

                      fontWeight: 700,
                    }}
                    onClick={() => {
                      setHtmlPopupContent("국수해설_당면사");
                    }}
                  >
                    당면사 국수해설
                  </div>

                  <div
                    style={{
                      width: `100%`,
                      minWidth: `calc(50vw - 24px)`,
                      maxWidth: `calc(50vw - 24px)`,
                      height: `24px`,
                      // background: `#8d8daa`,

                      background: !(
                        selectedSiIndex1 == 1 ||
                        selectedSiIndex1 == 2 ||
                        selectedSiIndex1 == 3 ||
                        selectedSiIndex1 == 10 ||
                        selectedSiIndex1 == 11 ||
                        selectedSiIndex1 == 12
                      )
                        ? `#B93160`
                        : `#1F4690`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      border: `1px solid #000`,
                      marginTop: "auto",
                      marginLeft: `24px`,
                      fontWeight: 700,
                    }}
                    onClick={() => {
                      setHtmlPopupContent("국수해설_일생사");
                    }}
                  >
                    일생사 국수해설
                  </div>
                </div>

                <div
                  className=" "
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>

                {/* <div className="maintable_row0 maintable_row3" style={{ height: `40px !important`, minHeight :  `40px !important`, maxHeight :`40px !important`, justifyContent : 'flex-end'  }}>
                                    
                                    <Button0 
                                        className={
                                            true
                                            ? "activeitem item0 item"
                                            : "item0 item" 
                                        }
                                        onClick={()=> {
                                            setHtmlPopupContent("고귀명수")
                                        }}
                                    >
                                        {`묻는 항목별 성, 부 및 방문(전화포함) 자의 연명(띠) 별로 변화되는 확정적 판단법 보기`}
                                    </Button0>
                                    <div style={{width: 4}}></div>

                            </div> */}
                {true && (
                  <>
                    <div
                      style={{
                        marginTop: 40,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      일생사 색인
                    </div>
                    <div
                      className=" "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <select
                        onChange={(e) => {
                          setSearchedInput201(e.target.value);
                        }}
                        value={searchedInput201}
                      >
                        {years1.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span style={{ fontWeight: 700 }}>&nbsp;년&nbsp;</span>
                      <select
                        onChange={(e) => {
                          setSearchedInput202(e.target.value);
                        }}
                        value={searchedInput202}
                      >
                        {month1.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span style={{ fontWeight: 700 }}>&nbsp;월&nbsp;</span>
                      <select
                        onChange={(e) => {
                          setSearchedInput203(e.target.value);
                        }}
                        value={searchedInput203}
                      >
                        {days1
                          .filter((filterItem, filterIndex) => {
                            // 1, 3, 5,7, 8,10,12
                            // 2, 4, 6, 9, , 11
                            // 2
                            if (filterItem == "31") {
                              if (
                                searchedInput102 == "01" ||
                                searchedInput102 == "03" ||
                                searchedInput102 == "05" ||
                                searchedInput102 == "07" ||
                                searchedInput102 == "08" ||
                                searchedInput102 == "10" ||
                                searchedInput102 == "12"
                              ) {
                                return filterItem;
                              } else {
                                return null;
                              }
                            } else {
                              return filterItem;
                            }
                          })
                          .map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                      <span style={{ fontWeight: 700 }}>&nbsp;일&nbsp;</span>
                      <div className="" style={{ marginLeft: "auto" }}></div>
                    </div>

                    <div
                      className=" "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <select
                        onChange={(e) => {
                          setSearchedInput204(e.target.value);
                        }}
                        value={searchedInput204}
                      >
                        {hours1.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span style={{ fontWeight: 700 }}>&nbsp;시&nbsp;</span>

                      <select
                        onChange={(e) => {
                          setSearchedInput205(e.target.value);
                        }}
                        value={searchedInput205}
                      >
                        {minutes1.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span style={{ fontWeight: 700 }}>&nbsp;분&nbsp;</span>

                      {/* <select onChange={(e)=> {setSearchedInput206(e.target.value)}} value={searchedInput206}>
                                {seconds1
                                .map((item) => (
                                    <option value={item} key={item}>
                                    {item}
                                    </option>
                                ))}
                            </select>
                            <span style={{ fontWeight: 700}}>
                                &nbsp;초&nbsp;
                                </span> */}

                      <select
                        onChange={(e) => {
                          setSearchedInput207(e.target.value);
                        }}
                        value={searchedInput207}
                      >
                        {yinyang1.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <div className="" style={{ marginLeft: "auto" }}></div>
                    </div>

                    <div
                      className=" "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        className=" button_1"
                        style={{
                          width: `calc(100vw - 10px)`,
                          height: 20,
                          marginRight: "auto",
                          background:
                            activeEffect1 == "input"
                              ? "#98adc1"
                              : `rgb(141, 141, 170)`,
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          // console.log("clicked - 207");
                          // console.log("clicked - 207");

                          setActiveEffect1("input");

                          if (searchedInput207 == "양력") {
                            __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                              searchedInput201,
                              searchedInput202,
                              searchedInput203,
                              searchedInput204,
                              searchedInput205,
                              searchedInput206
                            );
                          } else {
                            /* 양력/음력 변환 */
                            /* 양력/음력 변환 */
                            var date = getSolar(
                              searchedInput201,
                              searchedInput202,
                              searchedInput203,
                              0
                            );
                            // console.log("음력 -> 양력", date);
                            __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                              date.year,
                              date.month,
                              date.day,
                              searchedInput204,
                              searchedInput205,
                              searchedInput206
                            );
                          }
                          setHtmlPopupContent("색인_일생사");
                        }}
                      >
                        검색
                      </span>
                    </div>
                    <div
                      className=" "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 20,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        className=" button_1"
                        style={{
                          width: `calc(100vw - 10px)`,
                          height: 20,
                          fontSize: 14,
                          background:
                            activeEffect1 == "reset"
                              ? "#98adc1"
                              : `rgb(141, 141, 170)`,
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          // window.location.href = "/hyosa"

                          setBoolPopup1(false);
                          setPopupType("");
                          setInputType("");

                          setSearchedInput101(moment().format("YYYY"));
                          setSearchedInput102(moment().format("MM"));
                          setSearchedInput103(moment().format("DD"));
                          setSearchedInput104(moment().format("HH"));
                          setSearchedInput105(moment().format("mm"));
                          setSearchedInput106(moment().format("ss"));

                          setSearchedInput201(moment().format("YYYY"));
                          setSearchedInput202(moment().format("MM"));
                          setSearchedInput203(moment().format("DD"));
                          setSearchedInput204(moment().format("HH"));
                          setSearchedInput205(moment().format("mm"));
                          setSearchedInput206(moment().format("ss"));

                          __GOOKSU_DECIDE_AS_JOOGI(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );

                          __GOOKSU_DECIDE_AS_JOOGI_ILSAENG(
                            moment().format("YYYY"),
                            moment().format("MM"),
                            moment().format("DD"),
                            moment().format("HH"),
                            moment().format("mm"),
                            moment().format("ss")
                          );

                          setActiveEffect1("reset");
                        }}
                      >
                        초기화
                      </span>
                    </div>
                  </>
                )}

                {true && (
                  <>
                    <div
                      style={{
                        marginTop: 40,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      저장
                    </div>

                    <div
                      className=" "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                        width: `calc(100vw - 20px)`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        className=" button_1"
                        style={{
                          width: `calc(50vw - 10px)`,
                          height: 20,
                          marginRight: "auto",
                          background:
                            activeEffect1 == "input"
                              ? "#98adc1"
                              : `rgb(141, 141, 170)`,
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          setHtmlPopupContent("저장_정단");
                        }}
                      >
                        입력
                      </span>
                      <span
                        className=" button_1"
                        style={{
                          width: `calc(50vw - 10px)`,
                          height: 20,
                          fontSize: 14,
                          marginLeft: 20,
                          background:
                            activeEffect1 == "reset"
                              ? "#98adc1"
                              : `rgb(141, 141, 170)`,
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          // window.location.href = "/hyosa"
                          setHtmlPopupContent("색인_정단");
                        }}
                      >
                        색인
                      </span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div
            className=""
            style={{
              maxWidth: `300px`,
              marginLeft: "auto",
              marginRight: "auto",
              fontWeight: 700,
              textAlign: "center",
              fontSize: 10,
            }}
          >
            {/* 참고 1. 정단을 마친 후, 반드시 하단 버튼 `초기화`를 <br/>터치해두셔야만 다음판단에 착오가 없습니다.<br/><br/> */}
            {/* 참고 2. 사용자의 터치의 잘못에 따라 지역설정이 해지될 수 있으니 <br/>더러 확인이 필요합니다. */}
            {/* 참고 2. 국내 외 '지역설정'을 하신 후 반드시 `확인` 버튼을 눌러야만 <br/>지역설정이 해지되지 않습니다.<br/><br/> */}
            {/* 참고 3. 본문내용 중 소송 승패사의 우열이 없는 경우, 상호 비김 비김 비김으로 판단하십시오.<br/><br/> */}
            <br />
            {messageFromAndroid != "" ? "인증 : " : ""}
            {messageFromAndroid}
            <br />
            <br />

            <div
              className="maintable_row0 "
              style={{
                height: `40px !important`,
                minHeight: `40px !important`,
                maxHeight: `40px !important`,
                justifyContent: "center",
                whiteSpace: "nowrap",
                flexWrap: "nowrap",
                fontWeight: 700,
                fontSize: 14,
                marginTop: 0,
                marginBottom: 12,
                borderBottom: `1px solid #fff`,
              }}
            >
              <div
                className=""
                style={{
                  height: 20,
                  fontSize: 14,
                  fontWeight: 500,
                  padding: 0,
                  width: 60,
                  background: `rgb(141, 141, 170)`,
                  color: `#fff`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #000",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHtmlPopupContent("앱내용_출처");
                }}
              >
                출처
              </div>
            </div>

            <div
              className="maintable_row0 "
              style={{
                height: `40px !important`,
                minHeight: `40px !important`,
                maxHeight: `40px !important`,
                justifyContent: "center",
                whiteSpace: "nowrap",
                flexWrap: "nowrap",
                fontWeight: 700,
                fontSize: 14,
                marginTop: 0,
                marginBottom: 12,
                borderBottom: `1px solid #fff`,
              }}
            >
              <div
                className=""
                style={{
                  height: 20,
                  fontSize: 14,
                  fontWeight: 500,
                  padding: 0,
                  width: 60,
                  background: `rgb(141, 141, 170)`,
                  color: `#fff`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #000",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHtmlPopupContent("앱내용_상담");
                }}
              >
                상담
              </div>
            </div>
          </div>

          <div className="margin_top_auto"></div>
          <div style={{ marginTop: 120 }}></div>
        </Layout_DTM>

        <div className="margin_bottom_auto"></div>
        <div className="fab0">
          <div
            className="fabbutton1 button1"
            style={{
              background: !(
                selectedSiIndex1 == 1 ||
                selectedSiIndex1 == 2 ||
                selectedSiIndex1 == 3 ||
                selectedSiIndex1 == 10 ||
                selectedSiIndex1 == 11 ||
                selectedSiIndex1 == 12
              )
                ? `#B93160`
                : `#1F4690`,
              color: !(
                selectedSiIndex1 == 1 ||
                selectedSiIndex1 == 2 ||
                selectedSiIndex1 == 3 ||
                selectedSiIndex1 == 10 ||
                selectedSiIndex1 == 11 ||
                selectedSiIndex1 == 12
              )
                ? `#000`
                : `#fff`,
            }}
            onClick={() => {
              nextPerson("당면");
            }}
          >
            동일시간
            <br />
            다음사람
          </div>
          {/* <Button0 className="button0 button2"
                    onClick={()=> {
                        setHtmlPopupContent("사과삼전")
                    }} 
                    >사과삼전</Button0> */}
        </div>
        <div className="fab_l0">
          <div
            className="fabbutton1 button1"
            style={{
              fontSize: 15,

              background: !(
                selectedSiIndex1 == 1 ||
                selectedSiIndex1 == 2 ||
                selectedSiIndex1 == 3 ||
                selectedSiIndex1 == 10 ||
                selectedSiIndex1 == 11 ||
                selectedSiIndex1 == 12
              )
                ? `#B93160`
                : `#1F4690`,
              color: !(
                selectedSiIndex1 == 1 ||
                selectedSiIndex1 == 2 ||
                selectedSiIndex1 == 3 ||
                selectedSiIndex1 == 10 ||
                selectedSiIndex1 == 11 ||
                selectedSiIndex1 == 12
              )
                ? `#000`
                : `#fff`,
            }}
            onClick={() => {}}
          >
            {currentTime.format("HH")}:
            <br />
            {currentTime.format("mm")}:{currentTime.format("ss")}
          </div>
          {/* <Button0 className="button0 button2"
                    onClick={()=> {
                        setHtmlPopupContent("사과삼전")
                    }} 
                    >사과삼전</Button0> */}
        </div>
      </ContentContainer>

      <style jsx="true" global="true" suppressHydrationWarning>{`
        @font-face {
          font-family: "NanumBarunGothic";
          font-style: normal;
          font-weight: 400;
          src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot");
          src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix")
              format("embedded-opentype"),
            url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff")
              format("woff"),
            url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf")
              format("truetype");
        }
        .fab_l0 {
          position: fixed;
          bottom: 30px;
          left: 10px;
          z-index: 990;
        }
        .fab0 {
          position: fixed;
          bottom: 30px;
          right: 10px;
          z-index: 990;
        }
        .fab1 {
          position: fixed;
          top: 30px;
          right: 10px;
          z-index: 990;
        }
        .fab11 {
          position: fixed;
          bottom: 100px;
          right: 10px;
          z-index: 990;
        }
        .fab111 {
          position: fixed;
          bottom: 170px;
          right: 10px;
          z-index: 990;
        }
        .fab1111 {
          position: fixed;
          bottom: 240px;
          right: 10px;
          z-index: 990;
        }

        @font-face {
          font-family: "NanumBarunGothic";
          src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.5/NanumBarunGothic.woff")
            format("woff");
          font-weight: normal;
          font-style: normal;
        }
        html,
        body {
          overflow: scroll;
          font-size: 12px;
        }

        .small_alert {
          width: calc(100vw - 20px);
          min-width: 300px;
          min-height: 300px;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-left: auto;
          margin-right: auto;

          font-size: 14px;

          border: 1px solid #000;
          background-color: #8d8daa;
          color: #fff;
        }
        .location_wrapper,
        .gapja_wrapper {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }
        .location_wrapper .locationitem0,
        .gapja_wrapper .item0 {
          width: 80px;
          min-height: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          font-size: 12px;
        }
        .location_wrapper .activeitem,
        .gapja_wrapper .activeitem {
          font-weight: bold;
          text-decoration: underline;
        }

        .maintable_wrapper0 {
          width: calc(100vw - 8px);
          min-height: 300px;

          // max-height: calc(100vh - 200px);
          margin-left: auto;
          margin-right: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-top: 1px solid #000;
          border-left: 1px solid #000;
          border-right: 1px solid #000;

          margin-top: 20px;
          margin-bottom: 100px;

          align-items: flex-start;
          justify-content: flex-start;
        }

        .maintable_row0 {
          width: 100%;
          height: auto;
          border-bottom: 1px solid #000;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          position: relative;
        }
        .maintable_row0 .col0 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
        }
        .maintable_row0 .col1 {
          margin-left: auto;
          margin-right: 40px;
        }
        .maintable_row0 .col2 {
          margin-left: 40px;
          margin-right: auto;
        }
        .maintable_row0 .label0 {
          font-weight: 700;
          font-size: 12px;
        }

        .maintable_row1 {
          background-color: rgba(141, 141, 170, 0.3);
          color: #fff;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          line-height: 1.6;
        }

        .maintable_column0 {
          width: 100%;
          height: 100%;
          min-height: 50px;
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
        .label0 {
          font-family: "NanumBarunGothic" !important;
        }
        .maintable_row0 > .label0,
        .maintable_column0 > .label0 {
          position: absolute;
          left: 10px;
          top: 10px;
          font-size: 14px;
        }

        .maintable_column0 .value0 {
          margin-top: 32px;
          margin-left: 10px;
          font-size: 14px;
          text-align: center;
        }
        .maintable_column1 {
          width: 50%;
          height: 100%;
          text-align: center;
        }
        .maintable_column1_1 {
          width: 50%;
          height: 100%;
          border-right: 1px solid #000;
        }
        .maintable_column1_2 {
          width: 50%;
          height: 100%;
        }
        .maintable_column2 {
          width: calc(25% - 1px);
          height: 100%;
          border-right: 1px solid #000;
        }
        .maintable_column2_1 {
          width: 25%;
          height: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .maintable_column3 {
          width: calc((100% / 3) - 1px);
          height: 100%;
          border-right: 1px solid #000;
        }
        .maintable_column3_1 {
          width: calc(100% / 3);
          height: 100%;

          display: flex;
          flex-direction: column;
        }
        .maintable_column2_1 .button0 {
          width: 80px;
          height: 24px;
          font-size: 16px;
          border: 1px solid #000;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .fabbutton1 {
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 8px;

          width: 60px;
          height: 60px;
          border-radius: 30px;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: #95b2b0;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
          color: #000;
          font-weight: bold;
          border: 1px solid #000;
        }
        .fabbutton2 {
          background-color: #aeaeae;
        }

        .maintable_column3 {
          width: calc(100% / 3 - 1px);
          height: 100%;
          border-right: 1px solid #000;
          display: flex;
          flex-direction: column;
        }

        .maintable_column3 .value0 {
          margin-top: 60px;
          font-size: 14px;
        }

        .maintable_column3 .value1 {
          border-bottom: 1px solid #000;
          min-height: 50px;
          width: calc(100% - 40px);
        }

        .maintable_column3 .value2 {
          margin-top: 20px;
        }

        .gapja_wrapper {
          margin-bottom: 20px;
          margin-top: 60px;
        }
        .gapja_wrapper .item0 {
          width: calc(100% / 12 - 4px);
          height: 60px;
          border: 1px solid #000;
          margin-left: auto;
          margin-right: auto;
          margin-top: 4px;
          margin-bottom: 4px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
        }

        .menuitem0 {
          margin-bottom: 8px;
          font-weight: 700;
        }
        .iframe_full {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          z-index: 999999;
        }

        .term_wrapper {
          width: calc(100% - 40px);
          height: calc(100vh - 100px);
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto;
          overflow: scroll;
        }
        .yook_container {
          width: 100%;
          display: flex;
          flex-direction: row;
        }
        .yook_container .item0 {
          width: 20px;
          height: 20px;
          border: 1px solid #000;
          margin-left: auto;
          margin-right: auto;
          margin-top: 4px;
          margin-bottom: 4px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button0_3_wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        .button0_3_wrapper .button0_3 {
          display: flex;
          background-color: #8d8daa;
          color: #fff;
          width: calc(100% - 10px);
          border: 1px solid #000;
          margin-right: 1px;
          font-size: 10px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 2px;

          align-items: center;
          justify-content: center;
          box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
          white-space: nowrap;
        }
        .button0_8 {
          display: flex;
          background-color: #8d8daa;
          color: #fff;
          width: calc(100% / 8);
          height: 30px;
          border: 1px solid #000;
          margin-right: 1px;
          font-size: 10px;

          align-items: center;
          justify-content: center;
          box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
          white-space: nowrap;
        }

        .button0 {
          cursor: pointer;
        }
        .button_1 {
          display: flex;
          background-color: #8d8daa;
          color: #fff;
          border: 1px solid #000;
          margin-right: 1px;
          font-size: 10px;
          margin-top: 2px;

          align-items: center;
          justify-content: center;
          box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
          white-space: nowrap;
        }

        .bcolor_red : {
          background-color: #ff869e;
        }

        * {
          overflow-x: hidden;
        }
        * {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        *::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }

        select {
          width: calc(30vw - 10px);
          padding: 4px;
          margin: 8px 0;
          display: inline-block;
          border: none;
          border-bottom: 1px solid #000;
          border-radius: 0px;
          box-sizing: border-box;
        }
        .gueRow {
          width: calc(100% - 20px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .gueLabel {
          width: 32px;
          font-size: 18px;
        }
        .gueItem {
          width: 32px;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #fff;
        }
        .gueItem1 {
          border: 1px solid #000;
        }

        table,
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
        }
        .danjeongRow1 td:nth-child(1) {
          color: #000000;
        }
        .danjeongRow1 td:nth-child(2) {
          color: #000000;
        }
        .danjeongRow1 td:nth-child(3) {
          color: #f9f739;
        }
        .danjeongRow1 td:nth-child(4) {
          color: #f9f739;
        }
        .danjeongRow1 td:nth-child(5) {
          color: #1cbe20;
        }
        .danjeongRow1 td:nth-child(6) {
          color: #1cbe20;
        }
        .danjeongRow1 td:nth-child(7) {
          color: #29fd2f;
        }
        .danjeongRow1 td:nth-child(8) {
          color: #29fd2f;
        }
        .danjeongRow1 td:nth-child(9) {
          color: #fffd38;
        }
        .danjeongRow1 td:nth-child(10) {
          color: #fffd38;
        }
        .danjeongRow1 td:nth-child(11) {
          color: #fb0d1b;
        }
        .danjeongRow1 td:nth-child(12) {
          color: #fb0d1b;
        }
        .danjeongRow1 td:nth-child(13) {
          color: #fc28fc;
        }
        .danjeongRow1 td:nth-child(14) {
          color: #fc28fc;
        }
        .danjeongRow1 td:nth-child(15) {
          color: #fefc37;
        }
        .danjeongRow1 td:nth-child(16) {
          color: #fefc37;
        }
        .danjeongRow1 td:nth-child(17) {
          color: #aeaeae;
        }
        .danjeongRow1 td:nth-child(18) {
          color: #aeaeae;
        }
        .danjeongRow1 td:nth-child(19) {
          color: #1a1a1a;
        }
        .danjeongRow1 td:nth-child(20) {
          color: #1a1a1a;
        }
        .danjeongRow1 td:nth-child(21) {
          color: #fefc37;
        }
        .danjeongRow1 td:nth-child(22) {
          color: #fefc37;
        }
        .danjeongRow1 td:nth-child(23) {
          color: #020c7b;
        }
        .danjeongRow1 td:nth-child(24) {
          color: #020c7b;
        }
        .danjeongRow1 td:nth-child(2n - 1) {
          background-color: #fff;
        }
        .danjeongRow1 td:nth-child(2n) {
          background-color: #808080;
        }

        .danjeongMenu_Container0 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;

          font-size: 14px;

          margin-top: 20px;
        }

        .danjeongMenu_Item0 {
          width: calc((100% - 20px) / 3);
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;

          border: 1px solid #000;
          background-color: #c0c1fc;
          margin-left: auto;
          margin-right: auto;
        }
        .danjeongMenuTableContainer001,
        .danjeongMenuTable001 {
          width: 100%;
        }
        .danjeongMenuTable001 .td001 {
          width: 180px;
        }
        .danjeongMenuTable001 td {
          min-height: 50px;
          font-size: 14px;
        }
        .danjeongMenuTableContainer001 .title001 {
          font-size: 20px;
          font-weight: 700;
          margin-top: 8px;
          margin-bottom: 8px;
        }
        .jeukButton0 {
          background: #bfc2f7;
        }
      `}</style>
    </>
  );
};

export default PageComponent;
