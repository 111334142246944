import parse from "html-react-parser";
import React from "react";

import { loadGookData } from "../data/constants/gookPresent/index";
import { useCallback } from "react/cjs/react.production.min";

const colorSet = ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"];
const numSet = ["㉠", "㉡", "㉢", "㉣", "㉤"];

const SiboStatus = ({
  setBoolMagnifyPopup,
  setObjectMagnify,
  selectedNaejung,
  selectedSiIndex,
  yookimGuide,
  selectedGook, // 일자 국 인덱스
  selectedSiIndex1,
  setHtmlPopupContent,
  setPopupTitle,
  setPopupContents,
  selectedGapjaIndex_ILSAENG, //일자 잡자 인덱스
  isMyOpponentSForce, // 상대 세력 여부 권한
}) => {
  const showObjectMngnify = () => {
    setBoolMagnifyPopup(true);
    setObjectMagnify({
      title: "시보 용건 사정",
      desc: `    
                                            ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnS[
                                                5 * selectedSiIndex + 5
                                              ][0]
                                            } : ${
        selectedNaejung &&
        selectedNaejung.default &&
        selectedNaejung.default.columnS[5 * selectedSiIndex + 5][1]
      }<br/>
                                            ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnS[
                                                5 * selectedSiIndex + 6
                                              ][0]
                                            } : ${
        selectedNaejung &&
        selectedNaejung.default &&
        selectedNaejung.default.columnS[5 * selectedSiIndex + 6][1]
      }<br/>
                                            ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnS[
                                                5 * selectedSiIndex + 7
                                              ][0]
                                            } : ${
        selectedNaejung &&
        selectedNaejung.default &&
        selectedNaejung.default.columnS[5 * selectedSiIndex + 7][1]
      }<br/>
                                            ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnS[
                                                5 * selectedSiIndex + 8
                                              ][0]
                                            } : ${
        selectedNaejung &&
        selectedNaejung.default &&
        selectedNaejung.default.columnS[5 * selectedSiIndex + 8][1]
      }<br/>
                                            ${
                                              selectedNaejung &&
                                              selectedNaejung.default &&
                                              selectedNaejung.default.columnS[
                                                5 * selectedSiIndex + 9
                                              ][0]
                                            } : ${
        //20240130
        selectedNaejung &&
        selectedNaejung.default &&
        selectedNaejung.default.columnS[5 * selectedSiIndex + 9][1]
      }<br /><br /><br /><a href="javascript:window.open('http://vwck.org/ai/juya.html');" style="font-size:15px">효사 주야록 2024년 1차 업데이트</a>
                        `,
    });
  };

  const handleJuyaLinkClick = (e) => {
    e.preventDefault();
    window.open(
      "http://vwck.org/ai/juya.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleForceJuyaLinkClick = async (e) => {
    e.preventDefault();

    const data = await loadGookData(
      selectedGapjaIndex_ILSAENG,
      selectedGook,
      1
    );

    if (!data) {
      setPopupTitle("데이터 오류");
      setPopupContents(<div></div>);
      return;
    }
    setPopupTitle(data.title);
    setPopupContents(
      <div style={{ width: "100%", padding: "0px", marginTop: "15px" }}>
        {/* 좌우 테이블 */}
        <div style={{ display: "flex", width: "100%" }}>
          {/* 왼쪽 테이블 */}
          <div style={{ flex: 1, borderRight: "1px solid black" }}>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                padding: "3px",
              }}
            >
              * 본인 현재세력
            </div>
            <div style={{ padding: "0px" }}>
              {data.items[0].items.map((item, index) => (
                <div key={index} style={{ backgroundColor: colorSet[index] }}>
                  {numSet[index]} {item}
                </div>
              ))}
            </div>
            <div style={{ padding: "0px", borderTop: "1px solid black" }}>
              <div
                style={{
                  fontWeight: "bold",
                  padding: "3px",
                  borderBottom: "1px solid black",
                }}
              >
                본인 회복주기
              </div>
              {data.items[1].items.map((item, index) => (
                <div key={index} style={{ backgroundColor: colorSet[index] }}>
                  {numSet[index]} {item}
                </div>
              ))}
            </div>
          </div>

          {/* 오른쪽 테이블 */}
          <div style={{ flex: 1 }}>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                padding: "3px",
              }}
            >
              * 상대 현재세력
            </div>
            <div style={{ padding: "0px" }}>
              {data.items[2].items.map((item, index) => (
                <div key={index} style={{ backgroundColor: colorSet[index] }}>
                  {numSet[index]} {item}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "0",
                borderTop: "1px solid black",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  padding: "3px",
                  borderBottom: "1px solid black",
                }}
              >
                상대 회복주기
              </div>
              {data.items[3].items.map((item, index) => (
                <div key={index} style={{ backgroundColor: colorSet[index] }}>
                  {numSet[index]} {item}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* 사안 정보 */}
        <div
          style={{
            display: "flex",
            width: "100%",
            borderTop: "1px solid black",
          }}
        >
          {/* 왼쪽 사안 */}
          <div
            style={{
              flex: 1,
              borderRight: "1px solid black",
              padding: "5px",
            }}
          >
            {data.leftItems.map((item, index) => (
              <div key={index}>
                <div style={{ fontWeight: "bold" }}>{item.title}</div>
                {item.items.map((subItem, subIndex) => (
                  <div key={subIndex}>{subItem}</div>
                ))}
              </div>
            ))}
          </div>

          {/* 오른쪽 사안 */}
          <div style={{ flex: 1, padding: "5px" }}>
            {data.rightItems.map((item, index) => (
              <div key={index}>
                <div style={{ fontWeight: "bold" }}>{item.title}</div>
                {item.items.map((subItem, subIndex) => (
                  <div key={subIndex}>{subItem}</div>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* 하단 정보 */}
        <div
          style={{
            borderTop: "1px solid black",
            padding: "3px",
            fontWeight: 500,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <span>보기 </span>
          </div>
        </div>
        <div style={{ borderTop: "1px solid black", padding: "5px" }}>
          <div style={{ textAlign: "center" }}>
            {data.viewItems[0].title.map((item, index) => (
              <span key={index}>{item} </span>
            ))}
          </div>
          <div style={{ textAlign: "center" }}>
            {data.lastItems[0].title}
            {data.lastItems[0].items.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          textAlign: "left",
          flexDirection: "row",
          width: "100%",
          borderBottom: "1px solid #000",
          paddingBottom: "5px",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingRight: "2px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "center",
              paddingBottom: "5px",
            }}
          >
            &#x2752; 시보 용건 사정
          </div>
          <div
            style={{
              display: "flex",
              direction: "column",
              height: "100%",
              marginTop: "5px",
            }}
            onClick={showObjectMngnify}
          >
            <div style={{ fontSize: "14px", paddingLeft: "8px" }}>
              {parse(
                `<span style="font-weight: 700;line-height: 24px;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 5][0]
                }<span> <br/>: <span style="font-weight: 500;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 5][1]
                }<br/></span>`
              )}
              {parse(
                `<span style="font-weight: 700;line-height: 24px;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 6][0]
                }<span> <br/>: <span style="font-weight: 500;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 6][1]
                }<br/></span>`
              )}
              {parse(
                `<span style="font-weight: 700;line-height: 24px;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 7][0]
                }<span> <br/>: <span style="font-weight: 500;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 7][1]
                }<br/></span>`
              )}
              {parse(
                `<span style="font-weight: 700;line-height: 24px;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 8][0]
                }<span> <br/>: <span style="font-weight: 500;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 8][1]
                }<br/></span>`
              )}
              {parse(
                `<span style="font-weight: 700;line-height: 24px;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 9][0]
                }<span> <br/>: <span style="font-weight: 500;">${
                  selectedNaejung &&
                  selectedNaejung.default &&
                  selectedNaejung.default.columnS[5 * selectedSiIndex + 9][1]
                }<br/></span><br/>`
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <>
              {isMyOpponentSForce && (
                <div
                  style={{
                    width: "100%",
                    border: `1px solid #000`,
                    background: "#04B404",
                    color: "#fff",
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: 700,
                    padding: "3px 0 10px 0",
                    marginRight: "8px",
                    overflow: "hidden",
                  }}
                  onClick={handleForceJuyaLinkClick}
                >
                  본인 상대 세력 여부
                </div>
              )}

              <div
                style={{
                  width: "100%",
                  border: `1px solid #000`,
                  background: "#04B404",
                  color: "#fff",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: 700,
                  padding: "2px 0 10px 0",
                  marginRight: "8px",
                  marginTop: "9px",
                  overflow: "hidden",
                }}
                onClick={handleJuyaLinkClick}
              >
                효사육임 주야록
              </div>
            </>
          </div>
        </div>
        <div
          style={{
            flexGrow: 1,
            paddingLeft: "2px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "center",
              paddingBottom: "5px",
            }}
          >
            &#x2752; 내정 정황
          </div>
          <div style={{ textAlign: "center", marginTop: "5px", flexGrow: 1 }}>
            <img
              alt="내정정황"
              src={
                selectedNaejung &&
                selectedNaejung.default &&
                selectedNaejung.default.SAGWA_IMAGE1[selectedGook - 1]
              }
              style={{
                maxWidth: `110px`,
                objectFit: "contain",
                border: `4px solid #000`,
                overflow: "hidden",
              }}
              zoom={192}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHtmlPopupContent("내정정황");
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5px",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: `100%`,
                padding: "3px",
                background: !(
                  selectedSiIndex1 === 1 ||
                  selectedSiIndex1 === 2 ||
                  selectedSiIndex1 === 3 ||
                  selectedSiIndex1 === 10 ||
                  selectedSiIndex1 === 11 ||
                  selectedSiIndex1 === 12
                )
                  ? `#B93160`
                  : `#1F4690`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                border: `1px solid #000`,
                bottom: 12,
                fontSize: 12,
                fontWeight: 700,
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setHtmlPopupContent("사과삼전_보기요령");
              }}
            >
              보기요령
            </div>
            {yookimGuide && yookimGuide.indexOf("101_1_1") > -1 && (
              <div
                style={{
                  width: `100%`,
                  padding: "3px",
                  background: !(
                    selectedSiIndex1 === 1 ||
                    selectedSiIndex1 === 2 ||
                    selectedSiIndex1 === 3 ||
                    selectedSiIndex1 === 10 ||
                    selectedSiIndex1 === 11 ||
                    selectedSiIndex1 === 12
                  )
                    ? `#B93160`
                    : `#1F4690`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  border: `1px solid #000`,
                  bottom: 12,
                  fontSize: 12,
                  fontWeight: 700,
                  marginTop: 8,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHtmlPopupContent("동정즉단운세");
                }}
              >
                동정즉단운세
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SiboStatus;
