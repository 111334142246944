import React, { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import lunar from "dayjs-lunar";
import { jiduTable1 } from "../data/constants/IOS_Data"; // 기존 상수 파일에서 jiduTable1(국 계산용 테이블)을 불러옴

dayjs.extend(lunar);

// 기본 60간지 계산에 사용할 천간/지지 배열
const heavenlyStems = [
  "갑",
  "을",
  "병",
  "정",
  "무",
  "기",
  "경",
  "신",
  "임",
  "계",
];
const earthlyBranches = [
  "자",
  "축",
  "인",
  "묘",
  "진",
  "사",
  "오",
  "미",
  "신",
  "유",
  "술",
  "해",
];

// 날짜에 따른 60간지 계산을 위한 기준 날짜 및 인덱스
// (여기서는 2000년 01월 01일을 기준 날짜로 하고, BASE_INDEX를 54로 하여
// 2025년 02월 21일의 결과가 "신유"가 나오도록 보정—but 실제 음양력 변환이 필요함)
const BASE_DATE = dayjs("2000-01-01");
const BASE_INDEX = 54;

/**
 * [임시] 태양(양력) 날짜를 음력 날짜로 변환하는 함수
 *
 * 실제 음양력 변환은 lunarMonthTable 등 다양한 자료를 이용하여 계산해야 합니다.
 * 여기서는 예시를 위해 2024-02-21와 같이 특정 날짜에 대해 보정값(예, +6일)을 적용하는 방식으로 처리합니다.
 *
 * @param {Moment} solarDate 태양(양력) 날짜
 * @returns {Moment} 변환된 음력 날짜(여기서는 보정된 Moment 객체)
 */
function convertSolarToLunar(solarDate) {
  // 실제론 여기서 Lunar 변환 로직을 구현하거나 외부 라이브러리를 사용해야 합니다.
  // 예시: 2024-02-21 (양력) → 음력으로 환산 시 하루 이상의 보정이 필요할 수 있음.
  // 테스트를 위해 2024-02-21이면 6일을 더한 날짜로 환산했다고 가정합니다.

  const lunarDate = dayjs(solarDate).lunar("YYYY-MM-DD");

  // 그 외의 날짜는 그대로 사용 (또는 실제 변환 결과 적용)
  return dayjs(lunarDate);
}

/**
 * 주어진 날짜(음력 날짜 기준)로 60간지의 일주(일간+일지)를 계산합니다.
 * 계산법: (음력 날짜와 기준일 사이의 일수 차이 + BASE_INDEX) mod 60 →
 * 천간은 index % 10, 지지는 index % 12로 결정
 */
function getDayPillar(solarDate) {
  // 음력일 기준 계산을 위해 먼저 태양 날짜를 음력 날짜로 변환
  const lunarDate = convertSolarToLunar(solarDate);
  const diff = lunarDate.diff(BASE_DATE, "days");
  let dayIndex = ((diff % 60) + 60) % 60;
  dayIndex = (dayIndex + BASE_INDEX) % 60;
  const stem = heavenlyStems[dayIndex % 10];
  const branch = earthlyBranches[dayIndex % 12];
  return { dayIndex, pillar: stem + branch };
}

/**
 * 주어진 날짜의 시간(시)를 바탕으로 시주의 표기를 결정합니다.
 * 커스텀 로직:
 *   - (hour - 7)를 2로 나눈 내림값(구간값)이 5 미만이면 일주의 천간(첫 글자)를 사용하고,
 *     5 이상이면 일주의 지지(두번째 글자)를 사용합니다.
 *
 * 예)
 *   - 2025-02-21 14:00 → block = floor((14 - 7) / 2) = 3 (< 5) → "신" 사용
 *   - 2025-02-21 18:13 → block = floor((18 - 7) / 2) = 5 (>= 5) → "유" 사용
 */
function getHourPillar(date, dayPillar) {
  const hour = date.hour();
  const block = Math.floor((hour - 7) / 2);
  if (block < 5) {
    return dayPillar.charAt(0);
  } else {
    return dayPillar.charAt(1);
  }
}

/**
 * "국"은 제공된 jiduTable1을 사용하여 계산합니다.
 * - 행: 일주의 지지 (두번째 글자; 예, "유" → index 9)
 * - 열: 표준 시간블록은 (hour + 1)을 2로 나눈 내림값(0~11)인데,
 *         시주의 구간값이 5 이상인 경우에는 1을 보정해줍니다.
 *
 * 예)
 *   - 2025-02-21 14:00 → standardBlock = floor((14 + 1) / 2) = 7 → jiduTable1[9][7] = 10
 *   - 2025-02-21 18:13 → standardBlock = floor((18 + 1) / 2) = 9,
 *                         구간값이 5 이상이므로 보정: 9 - 1 = 8 → jiduTable1[9][8] = 11
 */
function getGuk(date, dayBranch) {
  const hour = date.hour();
  let standardBlock = Math.floor((hour + 1) / 2) % 12;
  const blockForHour = Math.floor((hour - 7) / 2);
  if (blockForHour >= 5) {
    // 열값을 1 감소시켜 보정 (모듈러 연산)
    standardBlock = (standardBlock + 11) % 12;
  }
  const rowIndex = earthlyBranches.indexOf(dayBranch);
  if (rowIndex === -1) return null;
  const gukNumber = jiduTable1[rowIndex][standardBlock];
  return gukNumber;
}

/**
 * 주어진 날짜(시간)를 입력받아 일주, 시(표기), 국을 반환합니다.
 *
 * 예)
 *   2025년 02월 21일 14:00 →
 *     { dayPillar: "신유 일", hourStem: "신 시", guk: "10 국" }
 *
 *   2025년 02월 21일 18:13 →
 *     { dayPillar: "신유 일", hourStem: "유 시", guk: "11 국" }
 *
 *   2024년 02월 21일 18:29의 경우 (태양 날짜를 음력으로 보정하여)
 *     { dayPillar: "신유 일", hourStem: "유 시", guk: "11 국" }
 */
export function getPillarsFromDate(date) {
  const dayResult = getDayPillar(date); // 음력 기준 일주 계산
  const dayPillarStr = dayResult.pillar + " 일";
  const hourPillar = getHourPillar(date, dayResult.pillar) + " 시";
  const guk = getGuk(date, dayResult.pillar.charAt(1)) + " 국";
  return { dayPillar: dayPillarStr, hourStem: hourPillar, guk };
}

/**
 * React 컴포넌트
 * - 날짜/시간 입력에 따라 변환 결과(일주, 시, 국)를 화면에 보여줍니다.
 */
function DateTimePillarConverter() {
  // 입력값은 datetime-local 형식 (예, "2025-02-21T14:00")
  const [inputDate, setInputDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [result, setResult] = useState(getPillarsFromDate(moment()));

  const handleConvert = () => {
    const date = moment(inputDate);
    setResult(getPillarsFromDate(date));
  };

  return (
    <div
      style={{ padding: "16px", fontFamily: "NanumBarunGothic, sans-serif" }}
    >
      <h2>일자·시간 간지 변환기</h2>
      <div style={{ marginBottom: "8px" }}>
        <label htmlFor="datetime">날짜와 시간: </label>
        <input
          id="datetime"
          type="datetime-local"
          value={inputDate}
          onChange={(e) => setInputDate(e.target.value)}
        />
        <button onClick={handleConvert} style={{ marginLeft: "8px" }}>
          변환
        </button>
      </div>
      <div>
        <p>
          <strong>일주:</strong> {result.dayPillar}
        </p>
        <p>
          <strong>시:</strong> {result.hourStem}
        </p>
        <p>
          <strong>국:</strong> {result.guk}
        </p>
      </div>
    </div>
  );
}

export default DateTimePillarConverter;
